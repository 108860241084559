// src/components/ClubCard.js
import React from "react";
import { Link } from "react-router-dom";

const ClubCard = ({ id, name, category, fees, logo, description, imageUrl }) => {
  const truncatedCategory =
    description.length > 20 ? description.slice(0, 20) + "..." : description;

  return (
    <div className="mx-auto !z-5 relative flex flex-col rounded-[20px] w-max bg-white bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full !p-4 3xl:p-![18px] bg-white undefined">
      <div className="h-full w-full">
        <div className="relative w-full">
          <img
            src={logo}
            className="h-[10rem] w-full rounded-xl"
            style={{    objectFit: 'cover'}}
            alt=""
          />
        </div>
        <div className="mb-3 flex items-center justify-between px-1 md:items-start">
          <div className="">
            <p className="text-lg font-bold text-navy-700 mb-0 mt-2"> {name} </p>
            <div className="flex flex-row flex-wrap gap-2">
            <p className="text-sm m-0 badge badge-info">
            {fees==="0.00"? (<span>FREE</span>) : (`AED ${parseInt(fees)}`)}
              
            </p>
            <p className="text-sm m-0 badge badge-success">
              Open for selection
            </p>
            </div>
            
          </div>
        </div>
        <div className="flex items-center justify-between md:items-center lg:justify-between ">
          <Link
            to={`/club_timeline/${id}`}
            className="btn btn-sm btn-ghost text-primary"
          >
            {" "}
            View Details{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ClubCard;
