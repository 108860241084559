import React, { useState } from "react";
import Calendar from "react-calendar";
import "./Calendar.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

function MiniCalendar({ events }) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const eventDates = new Set(
    events?.map((event) => new Date(event.start).toDateString()) || []
  );

  const eventsForSelectedDate = events?.filter(
    (event) =>
      new Date(event.start).toDateString() === selectedDate.toDateString()
  );

  const tileClassName = ({ date, view }) => {
    if (view === "month" && eventDates.has(date.toDateString())) {
      return "highlight";
    }
  };

  return (
    <div className="space-y-4">
      <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileClassName={tileClassName}
      />

      <div className="space-y-4">
        {events && eventsForSelectedDate.length > 0 ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            className="mySwiper cursor-pointer"
          >
            {eventsForSelectedDate.map((event, i) => {
              let start_date = new Date(event.start).toLocaleDateString(
                "en-US",
                {
                  month: "short",
                  day: "numeric",
                }
              );
              let time = new Date(event.start).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              });
              return (
                <SwiperSlide key={i}>
                  <div className="flex flex-col bg-[#4f06960d] border shadow-sm rounded-xl">
                    <div className="p-4 md:p-7">
                    <div className="flex flex-row justify-between items-center">
                      <h3 className="text-base font-bold text-gray-800">
                        {event.title}
                      </h3>
                      <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium border border-blue-600 text-blue-600">{event.location}</span>
</div>
                      <p className="mt-2 text-gray-500 mb-0">
                        {start_date} • {time}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className="p-4 border rounded-lg shadow">
            <h3 className="text-center text-xl font-medium text-violet-900">
              No events for this date
            </h3>
          </div>
        )}
      </div>

      <div className="flex gap-2 justify-center">
        <span
          className="badge text-xs text-white"
          style={{ background: "#006edc" }}
        >
          Selected
        </span>
        <span className="badge text-xs" style={{ background: "#ffff76" }}>
          Current Date
        </span>
        <span className="badge text-xs" style={{ background: "#c4b5fd" }}>
          Scheduled Event
        </span>
      </div>
    </div>
  );
}

export default MiniCalendar;
