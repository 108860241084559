import React from 'react';
import { Link } from 'react-router-dom';

function CampusTable({ campuses }) {
  return (
    <div className="overflow-x-auto">
    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
      <thead className="ltr:text-left rtl:text-right">
        <tr>
          <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Name
          </th>

          <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Students
          </th>
          <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Staff Members
          </th>
          <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Events
          </th>
          <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Budget
          </th>
          <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Participation
          </th>
          <th className="px-4 py-2" />
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 text-center">
        {campuses.map((campus, index) => (
          <tr key={index}>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              {campus.name}<br/><span className='text-gray-400 font-normal'>{campus.location}</span>
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {campus.students}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {campus.staffMembers}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {campus.events}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {campus.budget}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              {campus.participation}
            </td>
            <td className="whitespace-nowrap px-4 py-2">
              <Link
                to={`/school_data/${campus.id}`}
                className="inline-block rounded bg-primary px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"
              >
                View
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
}

export default CampusTable;
