import React from "react";
import CommentThread from "./CommentThread";

const ThreadWrapper = (props) => {
  const commentsData = [
    {
      author: "Noob master",
      timeAgo: "2 weeks ago",
      text: "Wow!!! how you did you create this?",
      replies: [
        {
          author: "Emily",
          timeAgo: "5 days ago",
          text: "I created it using TailwindCSS",
        },
      ],
    },
    {
      author: "John Doe",
      timeAgo: "3 days ago",
      text: "This looks amazing! Great job!",
    },
    {
      author: "Jane Smith",
      timeAgo: "1 week ago",
      text: "Can I get a tutorial on how to do this?",
      replies: [
        {
          author: "Noob master",
          timeAgo: "5 days ago",
          text: "Sure! I can share some resources.",
        },
      ],
    },
    {
      author: "Alice",
      timeAgo: "6 days ago",
      text: "Love the design!",
    },
    {
      author: "Bob",
      timeAgo: "4 days ago",
      text: "Impressive work!",
    },
    {
      author: "Eve",
      timeAgo: "2 days ago",
      text: "Is this built with React?",
      replies: [
        {
          author: "Noob master",
          timeAgo: "1 day ago",
          text: "Yes, it is!",
        },
      ],
    },
    {
      author: "Charlie",
      timeAgo: "5 days ago",
      text: "Awesome!",
    },
    {
      author: "Grace",
      timeAgo: "7 days ago",
      text: "Can you share the code for this?",
    },
    {
      author: "Mike",
      timeAgo: "3 days ago",
      text: "I want to learn how to do this!",
    },
    {
      author: "Sophia",
      timeAgo: "6 days ago",
      text: "Great use of colors!",
    },
  ];
  console.warn(props.comments);
  return (
    <div className="App">
      <CommentThread comments={props.comments} />
    </div>
  );
};

export default ThreadWrapper;
