import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import CampusMap from "./CampusMap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SchoolHouseRubric from "./SchoolHouseRubric";

function SchoolHousesDash() {
  return (
    <AdminLayout>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        {/* Grid */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          {/* Card */}

          <Link to={'/house_students'} className="flex flex-row gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 justify-center m-0 gap-4 hover:scale-105 hover:bg-[#ede2ff]">
            <div className="inline-flex justify-center items-center">
            <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      className="w-8 h-8"
    >
      <path
        fill="#fcb121"
        fillRule="evenodd"
        d="M7 14A7 7 0 107 0a7 7 0 000 14zM3.702 3.131a.625.625 0 01.884 0l2.045 2.045.002.002a.517.517 0 00.734 0l.002-.002L9.414 3.13a.625.625 0 11.883.884L8.255 6.058l-.001.001a1.767 1.767 0 01-2.508 0L3.702 4.015a.625.625 0 010-.884zm3.1 5.578a.517.517 0 01.565.113l.002.002 2.045 2.045a.625.625 0 10.883-.884L8.255 7.942l-.001-.001a1.767 1.767 0 00-2.508 0L3.702 9.985a.625.625 0 00.884.884l2.045-2.045.002-.002A.517.517 0 016.8 8.71z"
        clipRule="evenodd"
        strokeWidth={1}
      />
    </svg>
            </div>
            <div className="text-center flex items-center">
              <h3 className="text-xl font-bold text-gray-800 darki:text-gray-200 m-0">
                Points
              </h3>
            </div>
          </Link>

          <Link className="flex flex-row gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 justify-center m-0 gap-4 hover:scale-105 hover:bg-[#ede2ff]">
            <div className="inline-flex justify-center items-center">
            <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      className="w-8 h-8"
    >
      <path
        fill="#9521fc"
        fillRule="evenodd"
        d="M9.997.22a.75.75 0 01.819-.163l2.127.882a.75.75 0 01.408.977l-.869 2.127a.75.75 0 01-1.386.006l-.343-.82L2.115 6.92a.75.75 0 11-.59-1.38l8.648-3.695-.336-.805a.75.75 0 01.16-.82zm2.753 5.26a1 1 0 011 1v7a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5v-7a1 1 0 011-1h1.5zM8.457 7.272a1 1 0 00-.707-.292h-1.5a1 1 0 00-1 1v5.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-5.5a1 1 0 00-.293-.708zM2.75 8.48a1 1 0 011 1v4a.5.5 0 01-.5.5H.75a.5.5 0 01-.5-.5v-4a1 1 0 011-1h1.5z"
        clipRule="evenodd"
        strokeWidth={1}
      />
    </svg>
            </div>
            <div className="text-center flex items-center">
              <h3 className="text-xl font-bold text-gray-800 darki:text-gray-200 m-0">
                Insights
              </h3>
            </div>
          </Link>

          <Link to={'/school_houses'} className="flex flex-row gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 justify-center m-0 gap-4 hover:scale-105 hover:bg-[#ede2ff]">
            <div className="inline-flex justify-center items-center">
            <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      className="w-8 h-8"
    >
      <path
        fill="#92ffc6"
        fillRule="evenodd"
        d="M7.412.217A.5.5 0 006.6.202 5.86 5.86 0 011.07 2.505.5.5 0 00.5 3v2.781a8.5 8.5 0 005.397 7.894h.001l.59.23.006.003a1.44 1.44 0 001.012 0l.006-.002.59-.23-.182-.466.182.466A8.5 8.5 0 0013.5 5.78V3a.5.5 0 00-.534-.499c-2.549.171-4.427-.644-5.554-2.284z"
        clipRule="evenodd"
        strokeWidth={1}
      />
    </svg>
            </div>
            <div className="text-center flex items-center">
              <h3 className="text-xl font-bold text-gray-800 darki:text-gray-200 m-0">
                Houses
              </h3>
            </div>
          </Link>

          <Link className="flex flex-row gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 justify-center m-0 gap-4 hover:scale-105 hover:bg-[#ede2ff]">
            <div className="inline-flex justify-center items-center">
            <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
      className="w-8 h-8"
    >
      <path
        fill="#ffb092"
        fillRule="evenodd"
        d="M9.805.745c.18-.825 1.356-.83 1.544-.006l.009.038.017.075c.21.895.935 1.58 1.843 1.738.861.15.861 1.386 0 1.536-.912.159-1.64.85-1.846 1.752l-.023.099c-.188.824-1.363.819-1.544-.007l-.018-.084a2.283 2.283 0 00-1.84-1.761c-.86-.15-.86-1.384 0-1.534A2.283 2.283 0 009.785.84l.014-.063.007-.032zM7.771 5.142C6.429 4.91 5.987 3.541 6.444 2.56a.888.888 0 00-.622-.256h-.73a.89.89 0 00-.827.569l-.382.987-1.316.747-1.05-.16a.89.89 0 00-.889.435l-.355.623a.89.89 0 00.07 1.005l.668.827V8.83l-.65.827a.89.89 0 00-.07 1.004l.355.623a.89.89 0 00.89.435l1.049-.16 1.298.747.382.988a.889.889 0 00.827.569h.747a.89.89 0 00.827-.57l.383-.987 1.298-.746 1.05.16a.888.888 0 00.889-.436l.355-.623a.89.89 0 00-.07-1.004l-.668-.827V7.503c-.522-.198-.96-.636-1.107-1.312l-.019-.085a1.25 1.25 0 00-1.006-.964zM5.457 9.94a1.858 1.858 0 100-3.715 1.858 1.858 0 000 3.715z"
        clipRule="evenodd"
        strokeWidth={1}
      />
    </svg>
            </div>
            <div className="text-center flex items-center">
              <h3 className="text-xl font-bold text-gray-800 darki:text-gray-200 m-0">
                Setup
              </h3>
            </div>
          </Link>

          

    
        </div>
        {/* End Grid */}



        
    {/* Grid */}
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mt-8">
      {/* Card */}
      <div className="flex flex-col gap-y-3 bg-[#f4e9ff] lg:gap-y-5 p-4 md:p-5 border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800">
        <div className="inline-flex justify-center items-center">
          <span className="size-2 inline-block bg-gray-500 rounded-full me-2" />
          <span className="text-xs font-semibold uppercase text-gray-600 darki:text-gray-400">
          HAWKS
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            15,000
          </h3>
        </div>
        <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-green-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">1.7%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              3
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl>
      </div>
      {/* End Card */}
      {/* Card */}
      <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-[#e0f9f4] border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800">
        <div className="inline-flex justify-center items-center">
          <span className="size-2 inline-block bg-green-500 rounded-full me-2" />
          <span className="text-xs font-semibold uppercase text-gray-600 darki:text-gray-400">
          EAGLES
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            6,500
          </h3>
        </div>
        <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-green-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">5.6%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              2
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl>
      </div>
      {/* End Card */}
      {/* Card */}
      <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-[#ffecb1] border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800">
        <div className="inline-flex justify-center items-center">
          <span className="size-2 inline-block bg-red-500 rounded-full me-2" />
          <span className="text-xs font-semibold uppercase text-gray-600 darki:text-gray-400">
          FALCON
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            3,890
          </h3>
        </div>
        <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-red-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">5.6%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              2
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl>
      </div>



      <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-[#ffe0e0] border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800">
        <div className="inline-flex justify-center items-center">
          <span className="size-2 inline-block bg-red-500 rounded-full me-2" />
          <span className="text-xs font-semibold uppercase text-gray-600 darki:text-gray-400">
          KESTRELS
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            2,500
          </h3>
        </div>
        <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-red-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">5.6%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              1
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl>
      </div>





      <div className="flex flex-col gap-y-3 justify-center cursor-pointer lg:gap-y-5 p-4 md:p-5 bg-[#fff] border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800" style={{border:'4px dashed #0000007d'}}>

        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            +
          </h3>
        </div>
        
      </div>



      {/* End Card */}
    </div>
    {/* End Grid */}

      </div>
    </AdminLayout>
  );
}

export default SchoolHousesDash;
