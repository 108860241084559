import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import AuthContext from "../context/AuthContext";
import BackNav from "./BackNav";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useLanguage } from "../components/language";

const Talent = () => {
  let { user } = useContext(AuthContext);
  const history = useNavigate();
  const { t, changeLanguage, lanArabic } = useLanguage();
  // extract profile id
  let params = useParams();
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }

  // validate the user if he is the owner of the profile
  let isMyself = true;
  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initialize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const getProfile = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
      );
      const data = await response.json();

      setProfile(data);
      setIsLoading(false);
    } catch (err) {
      console.log("The requested profile does not exist.");
      setIsLoading(false);
    }
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  console.log("profile LEad" + profile);

  // Function to get the current date in the required format (YYYY-MM-DD)
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    activity_name: "",
    grade: 1,
    duration: "",
    scope: "",
    service_hours: "",
    school_in: profile ? profile.school_in : "", // Use profile data if available
    status: 0,
    score: 0,
    feedback: 0,
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Update post_date and posted_by fields
    const dataToSend = {
      ...formData,
      post_date: getCurrentDate(),
      posted_by: user ? user.user_id : "", // Use the user's ID if available
      school_in: profile?.school_in,
      status: 0,
      score: 0,
      feedback: 0,
      start_date: 0,
    };

    try {
      setIsSubmitting(true); // Set the submitting state to true

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/add_talent/`,
        dataToSend
      );

      // Reset the form data after successful submission
      setFormData({
        activity_name: "",
        grade: 1,
        duration: "",
        scope: "",
        service_hours: "",
        school_in: profile ? profile.school_in : "",
      });

      // Reset the date range picker
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);

      toast.success("Good Job! Activity Saved", {
        icon: "👏",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    } catch (error) {
      console.error("Error creating activity:", error.response.data);
      toast.error("Oh No! Something went wrong", {
        icon: "😓",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    } finally {
      setIsSubmitting(false); // Set the submitting state back to false after the request is done
    }
  };

  const getDurationMonths = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    return diffMonths;
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleChangeDate = (ranges) => {
    setDateRange([ranges.selection]);
    const duration = getDurationMonths(
      ranges.selection.startDate,
      ranges.selection.endDate
    );
    setFormData((prevData) => ({
      ...prevData,
      duration: String(duration),
      start_date: ranges.selection.startDate.toISOString().split("T")[0],
      end_date: ranges.selection.endDate.toISOString().split("T")[0],
    }));
  };

  const handleIncrementGrade = () => {
    if (formData.grade < 13) {
      setFormData((prevData) => ({
        ...prevData,
        grade: String(parseInt(prevData.grade) + 1),
      }));
    }
  };

  const handleDecrementGrade = () => {
    if (formData.grade > 1) {
      setFormData((prevData) => ({
        ...prevData,
        grade: String(parseInt(prevData.grade) - 1),
      }));
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="">
      <BackNav />

      <div className="mx-auto max-w-screen-xl px-4 py- sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg lg:max-w-2xl">
          <form
            className="mb-0 mt-6 space-y-4 rounded-lg px-6 py-8 bg-white"
            onSubmit={handleSubmit}
          >
            <h1 className="text-center text-2xl font-bold sm:text-3xl mt-6">
              🎨 Special Talent
            </h1>
            <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
              Do you have a hobby that you practice it in a personal capacity
            </p>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Activity</span>
              </label>
              <select
                className="select select-bordered"
                name="activity_name"
                value={formData.activity_name}
                onChange={handleChange}
              >
                <option selected>Pick one</option>
                <option value="Badminton">Badminton</option>
                <option value="Ballet">Ballet</option>
                <option value="Basketball">Basketball</option>
                <option value="Cardio">Cardio</option>
                <option value="Chess">Chess</option>
                <option value="Cooking">Cooking</option>
                <option value="Cricket">Cricket</option>
                <option value="Cycling">Cycling</option>
                <option value="Dancing">Dancing</option>
                <option value="Drawing">Drawing</option>
                <option value="Editing">Editing</option>
                <option value="Embroidery">Embroidery</option>
                <option value="Flute">Flute</option>
                <option value="Football">Football</option>
                <option value="GFX">GFX</option>
                <option value="Gaming">Gaming</option>
                <option value="Graphic Design">Graphic Design</option>
                <option value="Guitar">Guitar</option>
                <option value="Hockey">Hockey</option>
                <option value="Horseriding">Horseriding</option>
                <option value="Jewellery Making">Jewellery Making</option>
                <option value="Knitting/Crochet">Knitting/Crochet</option>
                <option value="Makeup">Makeup</option>
                <option value="Painting">Painting</option>
                <option value="Piano">Piano</option>
                <option value="Photography">Photography</option>
                <option value="Poetry">Poetry</option>
                <option value="Reading">Reading</option>
                <option value="Resin art">Resin art</option>
                <option value="SEO">SEO</option>
                <option value="Sculpture">Sculpture</option>
                <option value="Singing">Singing</option>
                <option value="Skating">Skating</option>
                <option value="Social Media Influencer">
                  Social Media Influencer
                </option>
                <option value="Swimming">Swimming</option>
                <option value="Table Tennis">Table Tennis</option>
                <option value="Tennis">Tennis</option>
                <option value="Ukulele">Ukulele</option>
                <option value="Videography">Videography</option>
                <option value="Violin">Violin</option>
                <option value="Weightlifting">Weightlifting</option>
                <option value="Writing">Writing</option>
              </select>
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Engagement</span>
              </label>
              <select
                className="select select-bordered"
                name="service_hours"
                value={formData.service_hours}
                onChange={handleChange}
                required
              >
                <option selected>Pick one</option>
                <option value="1">Light Engagement (1-2 hours/week) </option>
                <option value="1.2">
                  Moderate Engagement (3-5 hours/week)
                </option>
                <option value="1.4">Heavy Engagement (6-7 hours/week)</option>
                <option value="1.5">Extra Engagement (7+ hours/week)</option>
              </select>
            </div>
            <textarea
              name="description"
              id="description"
              class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 dark:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
              rows="3"
              placeholder="Tell us more about your experience.."
              onChange={handleChange}
              value={formData.description}
            ></textarea>

            <button
              type="submit"
              className="block btn-primary mt-4 btn w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
              disabled={
                isSubmitting ||
                !formData.activity_name ||
                !formData.service_hours
              }
            >
              Add Activity
            </button>
          </form>
        </div>
      </div>

      <div style={{ zIndex: 999999999999999, marginBottom: "2vh" }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
};

export default Talent;
