import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

function ClubSegregation() {
  const [chartData, setChartData] = useState({
    series: [15, 5],
    options: {
      chart: {
        width: '100%',
        type: 'pie',
      },
      labels: ['Participating', 'Not Participating'],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center', // Set horizontal alignment to center
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'center', // Set horizontal alignment to center
            },
          },
        },
      ],
    },
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={chartData.options} series={chartData.series} type="pie" width={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default ClubSegregation;
