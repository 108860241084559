// Chat.js
import { useState, useEffect, useRef, useContext } from "react"
import {
    collection,
    doc,
    setDoc,
    getDoc,
    addDoc,
    serverTimestamp,
    arrayUnion
} from "firebase/firestore" // Import Firestore functions
import { firestore, auth } from "./firebaseConfig" // Import Firestore instance and auth
import CryptoJS from "crypto-js"
import sha1 from "sha1"
import ChatBox from "./chatbox"
import ChatList from "./chat-list"
import ChatHeader from "./chat-header"
import AuthContext from "../../context/AuthContext"
import ChatModal from "./chat-modal"
import "./chat.css"
import {
    QueryClient,
    QueryClientProvider
} from "@tanstack/react-query"

const queryClient = new QueryClient()

function Chat() {
    const { user, logoutUser, authTokens } = useContext(AuthContext)
    const [selectedChat, setSelectedChat] = useState(null)
    const [openChatModal, setOpenChatModal] = useState(false)
    const [chatModalMode, setChatModalMode] = useState("")
    const authTkn = useRef("")

    useEffect(() => {
        // Set the auth token from the context if available
        authTkn.current = authTokens?.django_token?.access || ""
    }, [authTokens])

    const sendMsg = async (
        msg,
        groupMsg,
        textFlag = true,
        filename = "",
        fileSize,
        fileType
    ) => {
        const [uid, otherUserUid] = [
            sessionStorage.getItem("uid"),
            sessionStorage.getItem("other-user-uid")
        ]
        const uids = [uid, otherUserUid].sort()
        const chatUid = sha1(uids[0] + uids[1])
        try {
            const dirColRef = collection(firestore, "chat-directory")
            const dirDocRefSelf = doc(dirColRef, uid)
            const dirDocDataSelf =
                (await getDoc(dirDocRefSelf))
                    .data()
                    ?.chats?.filter(
                        (chat) =>
                            chat.chatId !==
                            (groupMsg ? selectedChat.uid : chatUid)
                    ) ?? []
            const chatColRef = collection(firestore, selectedChat.uid)
            const participantsDocRef = doc(chatColRef, "participants")
            const participantsData =
                (await getDoc(participantsDocRef)).data()?.userData ?? []
            // adding msg doc
            textFlag
                ? await addDoc(chatColRef, {
                      text: CryptoJS.AES.encrypt(
                          msg,
                          process.env.REACT_APP_SECRET_KEY
                      ).toString(),
                      createdAt: serverTimestamp(),
                      uid
                  })
                : await addDoc(chatColRef, {
                      file: msg,
                      filename: CryptoJS.AES.encrypt(
                          filename,
                          process.env.REACT_APP_SECRET_KEY
                      ).toString(),
                      createdAt: serverTimestamp(),
                      uid,
                      size: fileSize,
                      type: fileType
                  })
            if (!groupMsg) {
                const dirDocRefOtherUser = doc(dirColRef, otherUserUid)
                const dirDocDataOtherUser =
                    (await getDoc(dirDocRefOtherUser))
                        .data()
                        ?.chats?.filter((chat) => chat.chatId !== chatUid) ?? []
                const otherUserUndreadMsgs = participantsData.filter(
                    (participant) => participant.uid === otherUserUid
                )[0]?.unread
                const selfUndreadMsgs = participantsData.filter(
                    (participant) => participant.uid === uid
                )[0]?.unread
                await setDoc(participantsDocRef, {
                    userData: arrayUnion(
                        {
                            uid: uid,
                            name: sessionStorage.getItem("displayName"),
                            photoURL: sessionStorage.getItem("photoURL"),
                            unread: selfUndreadMsgs ?? 0
                        },
                        {
                            uid: otherUserUid,
                            name: sessionStorage.getItem(
                                "other-user-displayName"
                            ),
                            photoURL: sessionStorage.getItem(
                                "other-user-photoURL"
                            ),
                            unread:
                                otherUserUndreadMsgs >= 0
                                    ? otherUserUndreadMsgs + 1
                                    : 1
                        }
                    )
                })
                await setDoc(dirDocRefSelf, {
                    chats: [
                        ...dirDocDataSelf,
                        {
                            chatId: chatUid,
                            latestMsgTimestamp: new Date()
                        }
                    ]
                })
                await setDoc(dirDocRefOtherUser, {
                    chats: [
                        ...dirDocDataOtherUser,
                        {
                            chatId: chatUid,
                            latestMsgTimestamp: new Date()
                        }
                    ]
                })
            } else {
                const dirDocRefsOtherUsers = JSON.parse(
                    sessionStorage.getItem("other-user-uids")
                ).map((uid) => doc(dirColRef, uid))
                const dirDocDataOtherUsers = dirDocRefsOtherUsers.map(
                    async (docRef) =>
                        (await getDoc(docRef))
                            .data()
                            ?.chats?.filter(
                                (chat) => chat.chatId !== selectedChat.uid
                            ) ?? []
                )
                await setDoc(dirDocRefSelf, {
                    chats: [
                        ...dirDocDataSelf,
                        {
                            chatId: selectedChat.uid,
                            latestMsgTimestamp: new Date()
                        }
                    ]
                })
                dirDocRefsOtherUsers.map(
                    async (docRef, i) =>
                        await setDoc(docRef, {
                            chats: [
                                ...dirDocDataOtherUsers[i],
                                {
                                    chatId: selectedChat.uid,
                                    latestMsgTimestamp: new Date()
                                }
                            ]
                        })
                )
                const otherUsers = participantsData.filter(
                    (participant) => participant.uid !== uid
                )
                const selfUndreadMsgs = participantsData.filter(
                    (participant) => participant.uid === uid
                )[0].unread
                await setDoc(participantsDocRef, {
                    userData: arrayUnion(
                        {
                            uid: uid,
                            name: sessionStorage.getItem("displayName"),
                            photoURL: sessionStorage.getItem("photoURL"),
                            unread: selfUndreadMsgs ?? 0
                        },
                        ...otherUsers.map((user) => {
                            return {
                                uid: user.uid,
                                name: user.name,
                                photoURL: user.photoURL,
                                unread: user.unread >= 0 ? user.unread + 1 : 1
                            }
                        })
                    )
                })
            }
        } catch (error) {
            console.error("Error writing document: ", error)
        }
    }

    const createGroup = async (members, groupName) => {
        const dirColRef = collection(firestore, "chat-directory")
        const dirDocRefsGroupMembers = members.map((member) =>
            doc(dirColRef, member.uid.toString())
        )
        dirDocRefsGroupMembers.push(
            doc(dirColRef, sessionStorage.getItem("uid"))
        )
        let memberIds = ""
        let groupChatUid
        members.forEach((member) => {
            memberIds += member.uid
        })
        groupChatUid = sha1(memberIds)
        members = [
            ...members,
            {
                uid: sessionStorage.getItem("uid"),
                name: sessionStorage.getItem("displayName"),
                photoURL: sessionStorage.getItem("photoURL")
            }
        ]
        try {
            const chatColRef = collection(firestore, groupChatUid)
            await setDoc(doc(chatColRef, "participants"), {
                userData: [...members]
            })
            await setDoc(doc(chatColRef, "groupData"), {
                name: groupName
            })
            dirDocRefsGroupMembers.forEach(async (dirDocRef) => {
                await setDoc(
                    dirDocRef,
                    {
                        chats: arrayUnion({ chatId: groupChatUid })
                    },
                    { merge: true }
                )
            })
        } catch (error) {
            console.error("Error creating group: ", error)
        }
    }

    const logOut = () => {
        sessionStorage.clear()
        setSelectedChat(null)
        logoutUser() // Use the context's logout function
    }

    return (
        <div className="App">
            <ChatModal
                openModal={setOpenChatModal}
                open={openChatModal}
                firestore={firestore}
                createGroup={createGroup}
                newChat={chatModalMode === "newChat"}
                selectChat={setSelectedChat}
            />
            {user && (
                <ChatHeader
                    signOut={logOut}
                    openChatModal={(mode) => {
                        setOpenChatModal(true)
                        setChatModalMode(mode)
                    }}
                    chatName={selectedChat?.name}
                    chatImg={selectedChat?.avatar}
                />
            )}
            <section className="chats">
                {user ? (
                    <>
                        <ChatList
                            selectChat={setSelectedChat}
                            user={user}
                            firestore={firestore}
                        />
                        <QueryClientProvider client={queryClient}>
                            <ChatBox
                                auth={auth}
                                firestore={firestore}
                                send={sendMsg}
                                selectedChat={selectedChat}
                            />
                        </QueryClientProvider>
                    </>
                ) : (
                    <span>Not logged in</span>
                )}
            </section>
        </div>
    )
}

export default Chat
