// ScheduleTrend.js

import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ScheduleTrend = () => {
  const [series] = useState([
    {
      name: 'Busy Slots',
      data: [13, 9, 8, 7, 6, 1, 0] // Adjusted data for Monday to Sunday
    }
  ]);

  const [options] = useState({
    chart: {
      height: 350,
      type: 'bar',
      columnWidth: '10%',
    },
    toolbar: {
      enabled: false,
      show: false
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        columnWidth: '35%',
      }
    },
    
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + " events";
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"]
      }
    },
    
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], // Adjusted to show Monday to Sunday
      position: 'top',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      }
    },
    title: {
        show: false,
        enabled: false,
      floating: true,
      offsetY: 330,
      align: 'center',
      style: {
        color: '#444'
      }
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ScheduleTrend;
