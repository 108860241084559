import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Sidebar from '../components/Sidebar';
import Joyride, { STATUS } from 'react-joyride';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import SideInnerSM from '../components/SideInnerSM';
import Navbar from '../components/Navbar';
import TimelineStack from './TimelineStack';
import { useLanguage } from '../components/language';

const CategorySM = () => {
  const [profile, setProfile] = useState();
  const [tourSteps, setTourSteps] = useState([]);
  //const [isTourRunning, setIsTourRunning] = useState(true); // Start the tour on page load

  const { t, changeLanguage, lanArabic } = useLanguage();
  const navigate = useNavigate();
  let { user } = useContext(AuthContext);
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`);
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        // console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  useEffect(() => {

      // Define the tour steps here
      const newTourSteps = [
        {
          target: '[data-tour="step1"]',
          content: `🌟 Welcome to Activities Page 🌟

          Enhance your profile by adding your Extracurricular details! 🏆`,
        },
        {
          target: '[data-tour="step2"]',
          content: `Select a category that your activity falls under. This helps us organize and track your activities ✨`,
        },


        // Add more steps as needed
      ];
      setTourSteps(newTourSteps);

  }, [profile]);
/*
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setIsTourRunning(false);
    }
  };*/



  let lead_count = 0;
  let society_count=0;
  let social_count=0;
  let award_count=0;
  let sports_count =0;
  let intern_count = 0;
  let cert_count = 0;
  let talent_count = 0;
  let lang_count = 0;
  let event_count=0;
  let sum_lead = 0;
if(profile?.lead_activity !== "[]"){
  for (let i = 0; i < profile?.lead_activity?.length; i++) {
    let obj = profile?.lead_activity[i];
    let lead_activity_val = parseFloat(obj.name) || 0;
    let lead_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let lead_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
    let lead_duration_val = 0;
    let lead_scope_val = parseFloat(obj.scope) || 0;
    let lead_service_val = parseFloat(obj.service) || 0;
  
    if (lead_startdate_val && lead_enddate_val) {
      let diffInMonths = (lead_enddate_val.getFullYear() - lead_startdate_val.getFullYear()) * 12;
      diffInMonths += lead_enddate_val.getMonth() - lead_startdate_val.getMonth();
      lead_duration_val = diffInMonths/10;
    }
  
    sum_lead +=
      lead_activity_val * lead_duration_val * lead_scope_val * lead_service_val;

      // console.log("Data: "+lead_activity_val+','+lead_duration_val+','+lead_scope_val+','+lead_service_val);

      lead_count++;
  }

}
let sum_society = 0;

if(profile?.society_activity !== "[]"){
for (let i = 0; i < profile?.society_activity?.length; i++) {
  let obj = profile?.society_activity[i];
  let society_name_val = parseFloat(obj.name) || 0;
  let society_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let society_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
  let society_duration_val = 0;
  let society_service_val = parseFloat(obj.service) || 0;
  let society_role_val = parseFloat(obj.role) || 0;

  if (society_startdate_val && society_enddate_val) {
    let diffInMonths = (society_enddate_val.getFullYear() - society_startdate_val.getFullYear()) * 12;
    diffInMonths += society_enddate_val.getMonth() - society_startdate_val.getMonth();
    society_duration_val = diffInMonths/10;
  }

  sum_society +=
  society_name_val * society_duration_val * society_service_val * society_role_val;

  society_count++;
  }
}

let sum_social = 0;
if(profile?.social_activity !== "[]"){
for (let i = 0; i < profile?.social_activity?.length; i++) {
  let obj = profile?.social_activity[i];
  let social_name = parseFloat(obj.name) || 0;
  let social_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let social_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
  let social_duration = 0;
  let social_scope = parseFloat(obj.scope) || 0;
  let social_service = parseFloat(obj.service) || 0;
  let social_role = parseFloat(obj.role) || 0;

  if (social_startdate_val && social_enddate_val) {
    let diffInMonths = (social_enddate_val.getFullYear() - social_startdate_val.getFullYear()) * 12;
    diffInMonths += social_enddate_val.getMonth() - social_startdate_val.getMonth();
    social_duration = diffInMonths/10;
  }

  sum_social +=
  social_name * social_duration * social_scope * social_service * social_role;
  social_count++;
  }
}

let sum_award = 0;

if(profile?.award_activity !== "[]"){
for (let i = 0; i < profile?.award_activity?.length; i++) {
  let obj = profile?.award_activity[i];
  let award_name = parseFloat(obj.name) || 0;
  let award_scope = parseFloat(obj.scope) || 0;
  let award_role = parseFloat(obj.rank) || 0;

  sum_award +=
  award_name * award_scope * award_role;
  award_count++;
  }

}

let sum_sports = 0;
if(profile?.sports_activity !== "[]"){
for (let i = 0; i < profile?.sports_activity?.length; i++) {
  let obj = profile?.sports_activity[i];
  let sports_name = parseFloat(obj.name) || 0;
  let sports_scope = parseFloat(obj.scope) || 0;
  let sports_role = parseFloat(obj.role) || 0;
  let sports_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let sports_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
  let sports_duration = 0;
  let sports_service = parseFloat(obj.service) || 0;

  if (sports_startdate_val && sports_enddate_val) {
    let diffInMonths = (sports_enddate_val.getFullYear() - sports_startdate_val.getFullYear()) * 12;
    diffInMonths += sports_enddate_val.getMonth() - sports_startdate_val.getMonth();
    sports_duration = diffInMonths/10;
  }

  sum_sports +=
  sports_name * sports_scope * sports_role * sports_duration * sports_service;
  sports_count++;
  }
}

let sum_intern = 0;
if(profile?.intern_activity !== "[]"){
for (let i = 0; i < profile?.intern_activity?.length; i++) {
  let obj = profile?.intern_activity[i];
  let intern_name = parseFloat(obj.name) || 0;
  let intern_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
    let intern_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
  let intern_duration = 0;
  let intern_role = parseFloat(obj.role) || 0;
  let intern_scope = parseFloat(obj.scope) || 0;


  if (intern_startdate_val && intern_enddate_val) {
    let diffInMonths = (intern_enddate_val.getFullYear() - intern_startdate_val.getFullYear()) * 12;
    diffInMonths += intern_enddate_val.getMonth() - intern_startdate_val.getMonth();
    intern_duration = diffInMonths/10;
  }

  sum_intern +=
  intern_name * intern_duration * intern_role * intern_scope;

  intern_count++;
  }
}

let sum_cert = 0;
if(profile?.cert_activity !== "[]"){
for (let i = 0; i < profile?.cert_activity?.length; i++) {
  let obj = profile?.cert_activity[i];
  let cert_type = parseFloat(obj.type) || 0;
  sum_cert +=
  cert_type;

  cert_count++;
  }
}

let sum_talent = 0;
if(profile?.talent_activity !== "[]"){
for (let i = 0; i < profile?.talent_activity?.length; i++) {
  let obj = profile?.talent_activity[i];
  let talent_name = parseFloat(obj.name) || 0;
  let talent_service = parseFloat(obj.service) || 0;
  sum_talent +=
  talent_name * talent_service;

  talent_count++;
  }
}

let sum_lang = 0;
if(profile?.lang_activity !== "[]"){
for (let i = 0; i < profile?.lang_activity?.length; i++) {
  let obj = profile?.lang_activity[i];
  let lang_prof = parseFloat(obj.prof) || 0;
  sum_lang +=
  7*lang_prof;

  lang_count++;
  }
}


let events_count = 0;
if(profile?.event_activity !== "[]"){
for (let i = 0; i < profile?.event_activity?.length; i++) {

  events_count++;
  }
}


if(profile?.hobbies !== "[]" || profile?.hobbies !== 0 || profile?.hobbies !== '' || profile?.hobbies !== null){
  let count_hobbies = 0; 
  
  count_hobbies++;
  }



const round_lead_result = Math.floor(sum_lead)
const round_society_result = Math.floor(sum_society)
const round_social_result = Math.floor(sum_social)
const round_award_result = Math.floor(sum_award)
const round_sports_result = Math.floor(sum_sports)
const round_intern_result = Math.floor(sum_intern)
const round_cert_result = Math.floor(sum_cert)

const round_lead_duration = profile?.lead_duration*10
const round_lead_duration2 = profile?.lead_duration2*10
const round_lead_duration3 = profile?.lead_duration3*10

const round_society_duration = profile?.society_duration*10
const round_society_duration2 = profile?.society_duration2*10
const round_society_duration3 = profile?.society_duration3*10

const round_social_duration = profile?.social_duration*10
const round_social_duration2 = profile?.social_duration2*10
const round_social_duration3 = profile?.social_duration3*10

const totalScore = (round_lead_result + round_society_result + round_social_result + round_award_result + round_sports_result + round_cert_result + round_intern_result )*15


const [scroll, setScroll] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    setScroll(isScrolled);
  };

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


let {logoutUser} = useContext(AuthContext)



const [leads, setLeads] = useState();
const [society, setsociety] = useState();
const [social, setsocial] = useState();
const [awards, setawards] = useState();
const [sports, setsports] = useState();
const [intern, setintern] = useState();
const [cert, setcert] = useState();
const [talents, settalents] = useState();
const [lang, setlang] = useState();
const [events, setevents] = useState();

useEffect(() => {


    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lead/`)
    .then(response => {
      setLeads(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });



    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_society/`)
    .then(response => {
      setsociety(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });



    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_social/`)
    .then(response => {
      setsocial(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });


    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_awards/`)
    .then(response => {
      setawards(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });



    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_sports/`)
    .then(response => {
      setsports(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });



    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_intern/`)
    .then(response => {
      setintern(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });



    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_cert/`)
    .then(response => {
      setcert(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });



    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_talent/`)
    .then(response => {
      settalents(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });



    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_eventStd/`)
    .then(response => {
      setevents(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });



    axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lang/`)
    .then(response => {
      setlang(response.data);
    })
    .catch(error => {
      toast.error('Oh No! Something went wrong: '+error, {
        icon: '😓',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
    });




}, [profileID]);


const [isSidebarVisible, setIsSidebarVisible] = useState(false);

const toggleSidebar = () => {
  setIsSidebarVisible(prevState => !prevState);
}


  return (
    <div>






<div>


<div><>

<div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>


<Sidebar />

{isSidebarVisible && <SideInnerSM/>}

    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">



      <div>






<div className='bg-white rounded-lg my-12'>

<div>






{/* Display the profile data here */}
{profile && (
  <div>


  <div className="container  mx-auto px-8 py-12 md:px-12" >
<div className="flex flex-wrap w-full justify-center">
<h6 className="mb-2 ">Categories</h6>
                  <p className="text-sm capitalize leading-normal "> Select a category to add detail </p>
<div className='mt-4 mb-12'>
<div className="container mx-auto p-0" data-tour="step2">
<div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-5 gap-6 lg:gap-12">



<Link to={`/leads`} state={{ profile: profile }} >
<div className="flex justify-center flex-col items-center"> 

<div className="avatar" style={{ position: "relative", display: "inline-block" }}>
<div className="w-20 lg:w-32 mask mask-squircle">
<img
src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_1_bchtkv.png"
alt="Avatar"
/>
</div>


{(() => {
if (leads?.filter(lead => parseInt(lead.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{leads?.filter(lead => parseInt(lead.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}

</div>

<span className='text-center font-bold mt-2 text-sm'> Leadership </span>

</div>
</Link>


<Link to={`/societies`} state={{ profile: profile }} data-tour="step3"> 
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_3_a8qfyg.png"/>
</div>

{(() => {
if (society?.filter(society => parseInt(society.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{society?.filter(society => parseInt(society.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}

</div>

<span className='text-center font-bold mt-2 text-sm'> Societies </span>

</div>
</Link>

<Link to={`/awards`} state={{ profile: profile }} data-tour="step4">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_4_zynxst.png" />
</div>
{(() => {
if (awards?.filter(awards => parseInt(awards.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{awards?.filter(awards => parseInt(awards.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}
</div>

<span className='text-center font-bold mt-2 text-sm'> Awards </span>

</div>
</Link>
<Link to={`/socs/${user.user_id}`} state={{ profile: profile }} data-tour="step5">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_5_ptipyr.png" />
</div>
{(() => {
if (social?.filter(social => parseInt(social.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{social?.filter(social => parseInt(social.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}
</div>

<span className='text-center font-bold mt-2 text-sm'> Social Work </span>

</div>

</Link>

<Link to={`/sportstage/${user.user_id}`} state={{ profile: profile }} data-tour="step6"> 
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_6_izaf6r.png" />
</div>
{(() => {
if (sports?.filter(sports => parseInt(sports.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{sports?.filter(sports => parseInt(sports.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}
</div>

<span className='text-center font-bold mt-2 text-sm'> Sports </span>

</div>
</Link>

<Link to={`/interstage/${user.user_id}`} state={{ profile: profile }} data-tour="step7">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_7_ai4idr.png" />
</div>
{(() => {
if (intern?.filter(intern => parseInt(intern.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{intern?.filter(intern => parseInt(intern.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}
</div>

<span className='text-center font-bold mt-2 text-sm'> Internship </span>

</div>

</Link>

<Link to={`/certstage/${user.user_id}`} state={{ profile: profile }} data-tour="step8">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736104/AHbranding/Cats/Group_8_bfgnn2.png" />
</div>
{(() => {
if (cert?.filter(cert => parseInt(cert.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{cert?.filter(cert => parseInt(cert.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}
</div>

<span className='text-center font-bold mt-2 text-sm'> Certification </span>

</div>
</Link>

<Link to={`/talentstage/${user.user_id}`} state={{ profile: profile }} data-tour="step9">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736104/AHbranding/Cats/Group_9_b7sw2z.png" />
</div>
{(() => {
if (talents?.filter(talents => parseInt(talents.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{talents?.filter(talents => parseInt(talents.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}
</div>

<span className='text-center font-bold mt-2 text-sm'> Talents </span>

</div>
</Link>

<Link to={`/langstage/${user.user_id}`} state={{ profile: profile }} data-tour="step10">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_10_s5ghqf.png"/>
</div>
{(() => {
if (lang?.filter(lang => parseInt(lang.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{lang?.filter(lang => parseInt(lang.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}
</div>

<span className='text-center font-bold mt-2 text-sm'> Languages </span>

</div>
</Link>

<Link to={`/add_hobbies`} className='hidden' state={{ profile: profile }} data-tour="step11">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_11_bvykxy.png" />
</div>
</div>

<span className='text-center font-bold mt-2 text-sm'> Hobbies </span>

</div>

</Link>

<Link to={`/eventstage/${user.user_id}`} state={{ profile: profile }} data-tour="step12">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686827351/AHbranding/Cats/Group_13_qhaqot.png" />
</div>
{(() => {
if (events?.filter(events => parseInt(events.posted_by) === profileID).length > 0) {
return (
<div
className="badge"
style={{
position: 'absolute',
top: '-4px',
right: '9px',
backgroundColor: 'red',
color: 'white',
borderRadius: '100px',
padding: '0px',
fontSize: '15px',
textAlign: 'center',
border: 'none'
}}
>
{events?.filter(events => parseInt(events.posted_by) === profileID).length}
</div>
)
} else {
return null; // Return null if the condition is false
}
})()}
</div>

<span className='text-center font-bold mt-2 text-sm'> Events </span>

</div>

</Link>
{/*
<Link to={`/otherstage/${user.user_id}`} state={{ profile: profile }} data-tour="step13">
<div className="flex justify-center flex-col items-center"> 

<div className="avatar">
<div className="w-20 lg:w-32 mask mask-squircle">
<img src="https://res.cloudinary.com/ahbranding/image/upload/v1686736103/AHbranding/Cats/Group_12_if2svh.png" />
</div>
</div>

<span className='text-center font-bold mt-2 text-sm'> Other Details </span>

</div>

</Link>*/}
</div>
</div>
</div>

</div>
</div>



  {(() => {
if (totalScore<=50 && profile?.lead_activity === "[]") {
return (
  <Joyride
steps={tourSteps}
run
continuous
scrollToFirstStep
showProgress
disableBeacon // Start the tour automatically without highlighting

styles={{
  options: {
    arrowColor: '#4F0696',
    backgroundColor: '#fff',
    overlayColor: 'rgba(19, 10, 32, 0.46)',
    primaryColor: '#4F0696',
    textColor: '#000',
    width: 900,
    zIndex: 1000,
  }
}}
/>
)
} 
})()}

  </div>
)}
</div>

</div>


</div>



      </div>
    </main>
  </>
  </div>

    
    </div>




    </div>
  );
};

export default CategorySM;
