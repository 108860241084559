import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { toast, Toaster } from "react-hot-toast";
import SSMS_logo_white from "../media/SSMS_white.png";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import ReactModal from "react-modal";
import LanguageDropdown from "../components/LanguageDropdown";

export default function SignIn() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [parentDivWidth, setParentDivWidth] = useState(0); // State to store parent div width

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);

    // Access parent div by ID and update parentDivWidth state
    const parentDiv = document.getElementById("getiv");
    if (parentDiv) {
      setParentDivWidth(parentDiv.offsetWidth);
    }
  };

  useEffect(() => {
    resizeWindow();

    window.addEventListener("resize", resizeWindow);

    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, [parentDivWidth]);

  console.warn(parentDivWidth, "getiv");
  const lanArabic = i18n.language === "ar";
  const { loginUser, googleLoginUser, googleLoginError } =
    useContext(AuthContext);

  const handleSubmit = (event) => {
    /*
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          username: data.get('username'),
          password: data.get('password'),
        });
        */
  };
  const [loading, setLoading] = useState(true);
  const login = useGoogleLogin({
    onSuccess: googleLoginUser,
    onFailure: googleLoginError,
    // You can also specify other options here
  });

  useEffect(() => {
    // Simulate a delay to mimic content loading
    setTimeout(() => {
      setLoading(false); // Set loading to false when content is loaded
    }, 2000); // Adjust the delay as needed
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [loader, setloader] = useState(false);

  const handleClick = () => {
    setloader(true);

    // Simulate a delay of 4 seconds before hiding the spinner
    setTimeout(() => {
      setloader(false);
    }, 4000);
  };

  const getWidth = (parentDivWidth) => {
    if (parentDivWidth <= 640) {
      return `${parentDivWidth - 100}px`; // Adjust as needed
    } else if (parentDivWidth > 425) {
      return `${parentDivWidth - 100}px`; // Adjust as needed
    } else if (parentDivWidth >= 280) {
      return `${parentDivWidth - 85}px`; // Adjust as needed
    }
  };


  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  

  return (
    <div>
      {loading ? (
        <div className="flex items-center gap-4 justify-center h-screen">
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
        </div> // Your page content goes here
      ) : (
        <div>
          <div className="flex w-screen flex-wrap text-slate-800">
            <div className="relative hidden h-screen select-none flex-col justify-center bg-primary text-center md:flex md:w-1/2">
          <img
                class="mx-auto w-full h-screen rounded-lg object-scale-down"
                src={`${i18n.language==='en'? 'https://res.cloudinary.com/ahbranding/image/upload/v1707847046/Group_34784567_dck5n6.png' : 'https://res.cloudinary.com/ahbranding/image/upload/v1718124674/AHbranding/Group_34784585_bpnexs.png'}`}
        />
            </div>
            <div className="flex w-full flex-col md:w-1/2">

<div className="flex justify-end "> <LanguageDropdown/> </div>

              <div className="flex justify-center items-center"></div>
              <div className="my-auto mx-auto flex flex-col justify-center px-6 pt-8 md:justify-start w-full md:w-10/12 ">
                <img
                  className="w-20 mx-auto mb-8"
                  src="https://res.cloudinary.com/ahbranding/image/upload/v1682359716/AHbranding/Start_Up_Early_Logo_vu73y8.png"
                />
                <div className="p-4 sm:p-7">
                  <div className="text-center">
                    <h1 className="block text-2xl font-bold text-gray-800 darki:text-white">
                      {t('Sign In')}
                    </h1>
                  </div>
                  <div className="mt-5">
                    {/* <button
          type="button"
          className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-[#4285f4] text-gray-800 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        >
        
          <GoogleLogin
                                  onSuccess={googleLoginUser} // Call Google login function on success
                                  onFailure={googleLoginError} // Call error handler on failure
                                  className="w-full"


                                />
        </button> */}

                    {/* 
<div className="flex justify-center w-full">
        <GoogleLogin
                                  onSuccess={googleLoginUser} // Call Google login function on success
                                  onFailure={googleLoginError} // Call error handler on failure
                                  className="w-full"
                                  

                                /></div> */}

                    {/* Form */}
                    <form onSubmit={loginUser}>
                      <div className="grid gap-y-4">
                        {/* Form Group */}
                        <div>
                          <div className="relative">
                            <input
                              type="text"
                              placeholder={t('Username')}
                              id="email"
                              name="username"
                              class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none pr-10"
                              required=""
                              aria-describedby="email-error"
                            />
                          </div>
                        </div>
                        {/* End Form Group */}
                        {/* Form Group */}
                        <div>
                          <div className="flex justify-between items-center">
                            {/* <Link
                  to="/pass_reset"
                  className="text-sm text-blue-600 decoration-2 hover:underline font-medium darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                >
                  Forgot password?
                </Link> */}
                          </div>
                          <div class="mb-4 relative">
                            <input
                              id="getiv"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder={t('Password')}
                              class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none pr-10"
                            />
                            <button
                              type="button"
                              onClick={() => setShowPassword(!showPassword)}
                              class="absolute top-0 right-0 h-full px-3 flex items-center text-gray-600 hover:text-gray-800 focus:outline-none"
                            >
                              {showPassword ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                        {/* End Form Group */}
                        {/* Checkbox */}

                        {/* End Checkbox */}
                        {/* <button
              type="button"
              class="inline-block w-full px-16 py-3.5 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-primary border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
            >
              Sign In
            </button> */}
                        <button
                          type="submit"
                          onClick={handleClick}
                          // disabled={loading}
                          class="inline-block w-full px-16 py-3.5 mt-2 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-primary border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
                        >
                          {loader ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                           <> {t('Sign In')}</>
                          )}
                        </button>

                        <p className="mx-auto mb-6 leading-normal text-sm">
                          {t('Forgot Password?')}{" "}
                          <Link
                            to="/pass_reset"
                            class="font-semibold text-transparent bg-clip-text bg-gradient-to-tl from-blue-500 to-violet-500"
                          >
                            {t('Reset Now')}
                          </Link>
                        </p>
                      </div>
                    </form>
                    <div className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-gray-500 darki:before:border-gray-600 darki:after:border-gray-600">
                      {t('Or continue with')}
                    </div>
{/* 
                    <Link
                      // to={`https://accounts.google.com/gsi/select?client_id=412283018548-ls0cgmii17jb4netsfok42n532snb0rk.apps.googleusercontent.com&ux_mode=redirect&ui_mode=card&as=Rcx%2BGSyp4CpiJyjVAVfgyQ&login_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&g_csrf_token=b1952da8ef24be89&origin=http%3A%2F%2Flocalhost%3A3000`}
                      type="button"
                      onClick={openModal}
                      class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800"
                    >
                      <svg
                        class="w-4 h-auto"
                        width="46"
                        height="47"
                        viewBox="0 0 46 47"
                        fill="none"
                      >
                        <path
                          d="M46 24.0287C46 22.09 45.8533 20.68 45.5013 19.2112H23.4694V27.9356H36.4069C36.1429 30.1094 34.7347 33.37 31.5957 35.5731L31.5663 35.8669L38.5191 41.2719L38.9885 41.3306C43.4477 37.2181 46 31.1669 46 24.0287Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M23.4694 47C29.8061 47 35.1161 44.9144 39.0179 41.3012L31.625 35.5437C29.6301 36.9244 26.9898 37.8937 23.4987 37.8937C17.2793 37.8937 12.0281 33.7812 10.1505 28.1412L9.88649 28.1706L2.61097 33.7812L2.52296 34.0456C6.36608 41.7125 14.287 47 23.4694 47Z"
                          fill="#34A853"
                        />
                        <path
                          d="M10.1212 28.1413C9.62245 26.6725 9.32908 25.1156 9.32908 23.5C9.32908 21.8844 9.62245 20.3275 10.0918 18.8588V18.5356L2.75765 12.8369L2.52296 12.9544C0.909439 16.1269 0 19.7106 0 23.5C0 27.2894 0.909439 30.8731 2.49362 34.0456L10.1212 28.1413Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M23.4694 9.07688C27.8699 9.07688 30.8622 10.9863 32.5344 12.5725L39.1645 6.11C35.0867 2.32063 29.8061 0 23.4694 0C14.287 0 6.36607 5.2875 2.49362 12.9544L10.0918 18.8588C11.9987 13.1894 17.25 9.07688 23.4694 9.07688Z"
                          fill="#EB4335"
                        />
                      </svg>
                      Sign in with Google
                    </Link> */}


                    <div className="grid grid-cols-5">
                    
                      <div className="flex justify-center">
                      <GoogleLogin
                        width="700"
                        size="large"
                        useOneTap='true'
                        text="signin_with"
                        type="icon"
                        shape="square"
                        theme="filled_black"
                        onSuccess={googleLoginUser}
                        onFailure={googleLoginError}
                        
                        />
                      </div>     




<img className="w-[2.6rem] h-auto opacity-50 mx-auto" src="https://res.cloudinary.com/ahbranding/image/upload/v1708345626/AHbranding/Group_34784937_cje54f.png"/>
<img className="w-[2.6rem] h-auto opacity-50 mx-auto" src="https://res.cloudinary.com/ahbranding/image/upload/v1708345626/AHbranding/Group_34784938_sv1fjk.png"/>
<img className="w-[2.6rem] h-auto opacity-50 mx-auto" src="https://res.cloudinary.com/ahbranding/image/upload/v1708345626/AHbranding/Group_34784939_eytxab.png"/>
<img className="w-[2.6rem] h-auto opacity-50 mx-auto" src="https://res.cloudinary.com/ahbranding/image/upload/v1708345626/AHbranding/Group_34784936_vbdfr2.png"/>

                      </div>
                    {/* End Form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
