import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";

import Network_StudentList from "../components/Network_StudentList";
import NetworkStats from "../components/NetworkStats";
import NetworkStudents from "../components/NetworkStudents";
import ReactModal from "react-modal";
import NetworkAddBulkStd from "../components/NetworkAddBulkStd";
import AddSingleStudent from "./AddSingleStudent";
import { Link } from "react-router-dom";
import AdminLayout from "./AdminLayout";
export default function AllNetworkStudents() {
  let { user } = useContext(AuthContext);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const lanArabic = i18n.language === "ar";

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const openModal2 = () => {
    setIsOpen2(true);
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  return (
    <AdminLayout>
      <div className="">
        <div className="flex justify-end mb-4">
          {/* <div className="dropdown dropdown-end">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn btn-primary m-1"
                  >
                    + Add Students
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <li>
                      <button onClick={openModal2} className="btn btn-ghost justify-start p-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                          />
                        </svg>
                        Add New
                      </button>
                    </li>
                    <li>
                      <Link to={'/bulk_students'} className="btn btn-ghost justify-start p-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                          />
                        </svg>
                        Bulk Upload
                      </Link>
                    </li>
                  </ul>
                </div> */}
        </div>
        {/* <NetworkStudents/> */}
        <div className="bg-white rounded-lg shadow-xl">
          <Network_StudentList default_itemsPerPage={20} />
        </div>

        
      </div>
    </AdminLayout>
  );
}
