import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import CampusMap from "./CampusMap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SchoolHouseRubric from "./SchoolHouseRubric";

function HouseStudentList() {



  const grades = Array.from({ length: 13 }, (_, i) => i + 1);
  const [selectedGrade, setSelectedGrade] = useState(""); // State to store selected grade


  const [students, setStudents] = useState([
    {
      "name": "Anant Jain",
      "position": "Head Boy",
      "grade": 13,
      "points": 0
    },
    {
      "name": "Daniya Fatima",
      "position": "Head Girl",
      "grade": 13,
      "points": 0
    },
    {
      "name": "Mohammed Usman",
      "position": "Deputy Head Boy",
      "grade": 12,
      "points": 0
    },
    {
      "name": "Afifa Khan",
      "position": "Deputy Head Girl",
      "grade": 12,
      "points": 0
    },
    {
      "name": "Tayyib Hasan Abdullah",
      "position": "Sports Captain",
      "grade": 11,
      "points": 0
    },
    {
      "name": "Aiman Kashif",
      "position": "Sports Captain",
      "grade": 10,
      "points": 0
    },
    {
      "name": "Nishith Kamlesh",
      "position": "Blue House Captain",
      "grade": 8,
      "points": 0
    },
    {
      "name": "Mehak Faisal",
      "position": "Yellow House Captain",
      "grade": 11,
      "points": 0
    },
    {
      "name": "Mubashira Khanam",
      "position": "Red House Captain",
      "grade": 9,
      "points": 0
    },
    {
      "name": "Hassaan Rashid",
      "position": "Green House Captain",
      "grade": 8,
      "points": 0
    },
    {
      "name": "Mustafa Khurram",
      "position": "Blue House Vice Captain",
      "grade": 7,
      "points": 0
    },
    {
      "name": "Qusai Sabri Tayseer Mahmod",
      "position": "Yellow House Vice Captain",
      "grade": 6,
      "points": 0
    },
    {
      "name": "Sana Faisal",
      "position": "Red House Vice Captain",
      "grade": 8,
      "points": 0
    },
    {
      "name": "Jibran Salman Ansari",
      "position": "Green House Vice Captain",
      "grade": 9,
      "points": 0
    },
    {
      "name": "Sana Faisal",
      "position": "Entrepreneur Leader",
      "grade": 10,
      "points": 0
    },
    {
      "name": "Kimberly Savia Fernandes",
      "position": "Well Being Leader",
      "grade": 9,
      "points": 0
    },
    {
      "name": "Heba Qaiser",
      "position": "Well Being Leader",
      "grade": 10,
      "points": 0
    },
    {
      "name": "Wania Khalid",
      "position": "Environment Leader",
      "grade": 8,
      "points": 0
    },
    {
      "name": "Hassaan Ahmed",
      "position": "Environment Leader",
      "grade": 7,
      "points": 0
    },
    {
      "name": "Adam Noor",
      "position": "Innovation Leader",
      "grade": 9,
      "points": 0
    },
    {
      "name": "Mohammed Kashan",
      "position": "Media & Communication Leader",
      "grade": 11,
      "points": 0
    },
    {
      "name": "Ali Affan",
      "position": "Discipline Leader",
      "grade": 10,
      "points": 0
    },
    {
      "name": "Hamdiya Ahmed",
      "position": "Discipline Leader",
      "grade": 9,
      "points": 0
    },
    {
      "name": "Abdur Rehman",
      "position": "UAE Culture Leader",
      "grade": 8,
      "points": 0
    },
    {
      "name": "Kimberly Fernandes",
      "position": "Year 12",
      "points": 0
    },
    {
      "name": "Faseh-ur Rehman",
      "position": "Year 11 A",
      "grade": 11,
      "points": 0
    },
    {
      "name": "Laiba Farooqi",
      "position": "Year 11 A",
      "grade": 11,
      "points": 0
    },
    {
      "name": "Sneha Haresh",
      "position": "Year 11 B",
      "grade": 11,
      "points": 0
    },
    {
      "name": "Abdullah Malik",
      "position": "Year 11 B",
      "grade": 11,
      "points": 0
    },
    {
      "name": "Zaid Khalil",
      "position": "Year 10 A",
      "grade": 10,
      "points": 0
    },
    {
      "name": "Mohammed Sabith",
      "position": "Year 9 A",
      "grade": 9,
      "points": 0
    },
    {
      "name": "Haniya Abdul Gafar",
      "position": "Year 9 B",
      "grade": 9,
      "points": 0
    },
    {
      "name": "Farhat Aiman",
      "position": "Year 8 A",
      "grade": 8,
      "points": 0
    },
    {
      "name": "Azka Javad",
      "position": "Year 8 B",
      "grade": 8,
      "points": 0
    },
    {
      "name": "Adeen Anas",
      "position": "Year 8 C",
      "grade": 8,
      "points": 0
    },
    {
      "name": "Uzma Wazir",
      "position": "Year 7 A",
      "grade": 7,
      "points": 0
    },
    {
      "name": "Hafsa Abdul Gafar",
      "position": "Year 7 B",
      "grade": 7,
      "points": 0
    },
    {
      "name": "Zeenat Aiman",
      "position": "Year 7 C",
      "grade": 7,
      "points": 0
    },
    {
      "name": "Mohammed Riyan Ainan",
      "position": "Year 6 A",
      "grade": 6,
      "points": 0
    },
    {
      "name": "Aisha Asadullah",
      "position": "Year 6 B",
      "grade": 6,
      "points": 0
    },
    {
      "name": "Mohammed Altaf",
      "position": "Year 6 C",
      "grade": 6,
      "points": 0
    },
    {
      "name": "Mohammed Ali",
      "position": "Year 6 D",
      "grade": 6,
      "points": 0
    },
    {
      "name": "Taimullah",
      "position": "Year 5 A",
      "grade": 5,
      "points": 0
    },
    {
      "name": "Ebad",
      "position": "Year 5 B",
      "grade": 5,
      "points": 0
    },
    {
      "name": "Shahvir",
      "position": "Year 5 C",
      "grade": 5,
      "points": 0
    },
    {
      "name": "Aroush",
      "position": "Year 4 A",
      "grade": 5,
      "points": 0
    },
    {
      "name": "Ehsan ullah",
      "position": "Year 4 B",
      "grade": 5,
      "points": 0
    },
    {
      "name": "Abiha Fatima",
      "position": "Year 4 C",
      "grade": 5,
      "points": 0
    },
    {
      "name": "Arshan",
      "position": "Year 4 D",
      "grade": 5,
      "points": 0
    },
    {
      "name": "Mohammed Omer",
      "position": "Year 3 A",
      "grade": 5,
      "points": 0
    },
    {
      "name": "Amalia Nigeri",
      "position": "Year 3 B",
      "grade": 5,
      "points": 0
    }
  ]);
  
  const filteredStudents = selectedGrade ? students.filter(student => student.grade === selectedGrade) : students;

  // Function to add points
  const addPoints = (index, points) => {
    const newStudents = [...students];
    newStudents[index].points += points;
    setStudents(newStudents);
  };

  // Function to subtract points
  const subtractPoints = (index, points) => {
    const newStudents = [...students];
    newStudents[index].points -= points;
    setStudents(newStudents);
  };

  // Calculate total points of all students
  const totalPoints = students.reduce((total, student) => total + student.points, 0);

  return (
    <AdminLayout>
      {/* <div>
        <div>
          <div className="max-w-[85rem] p-4 mx-auto">
            <div className=" text-sm breadcrumbs flex flex-row justify-between">


              <select className="select select-primary w-full max-w-xs">
                <option disabled selected>
                  Filter Grade
                </option>
                <option>Grade 1</option>
                <option>Grade 2</option>
                <option>Grade 3</option>
                <option>Grade 4</option>
                <option>Grade 5</option>
                <option>Grade 6</option>
                <option>Grade 7</option>
                <option>Grade 8</option>
                <option>Grade 9</option>
                <option>Grade 10</option>
              </select>
            </div>

            <div className="grid sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-5 gap-4 sm:gap-6 mt-12">

              {filteredStudents.map((student) => (
                <Link
                to={`/pointsheet/${student?.user}`} 
                 className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 hover:scale-110 hover:bg-[#efebf7]"
                key={student?.id}
                
                >
                  <div className="text-center">
                    <h3 className="text-2xl font-semibold text-gray-800 darki:text-gray-200 m-0">
                      {student?.firstname}
                    </h3>
                    <p className="text-base font-normal m-0">
                      {student?.lastname}
                    </p>
                  </div>
                  <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
                    <dt className="pe-3 text-center ">
                      <span className="text-green-600">
                        <span className="inline-block text-sm">{student?.points}</span>
                      </span>
                      <span className="block text-sm text-gray-500">
                        points
                      </span>
                    </dt>
                    <dd className="text-center ps-3">
                      <span className="text-sm font-semibold text-red-600 darki:text-gray-200">
                      {student?.demerits}
                      </span>
                      <span className="block text-sm text-gray-500">
                        demerits
                      </span>
                    </dd>
                  </dl>
                </Link>
              ))}
            </div>


          </div>
    
        </div>
      </div> */}



<div className="grid grid-cols-2 gap-4 w-5/12 mb-8">

<select className="select select-bordered" value={selectedGrade} onChange={(e) => setSelectedGrade(parseInt(e.target.value))}>
        <option value="">Select Grade</option>
        {grades.map((grade) => (
          <option key={grade} value={grade}>
            {grade}
          </option>
        ))}
      </select>


      <input type="date" className="input input-bordered" defaultValue="2024-08-01" />


</div>



      <div class="grid divide-y divide-neutral-200 ">
		<div class="p-4 rounded-xl shadow-xl mb-4 bg-[#f4e9ff]">
			<details class="group">
				<summary class="flex justify-between items-center font-medium cursor-pointer list-none">
					<span className="my-4 text-lg font-bold"> HAWKS</span>
					<span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
</svg>
              </span>
				</summary>

      <table className="table bg-white p-4 rounded-xl w-full ">
        <thead>
          <tr>
            <th className="px-4 py-2">Index</th>
            <th className="px-4 py-2">Student</th>
            <th className="px-4 py-2">Points Add (R) </th>
            <th className="px-4 py-2">Points Negate (V) </th>
            <th className="px-4 py-2">Tally</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <tr key={index}>
              <td className="px-4 py-2">{index + 1}</td>
              <td className="px-4 py-2">{student.name}</td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  className="input input-bordered w-full max-w-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addPoints(index, parseInt(e.target.value, 10));
                      e.target.value = '';
                    }
                  }}
                />
              </td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  className="input input-bordered w-full max-w-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      subtractPoints(index, parseInt(e.target.value, 10));
                      e.target.value = '';
                    }
                  }}
                />
              </td>
              <td className="px-4 py-2">{student.points}</td>
            </tr>
          ))}
          <tr className="bg-amber-100 rounded-xl ">
            <td className="px-4 py-2" colSpan="4">Total Points</td>
            <td className="px-4 py-2 font-bold">{totalPoints}</td>
          </tr>
        </tbody>
      </table>
			</details>

		</div>
	</div>







  <div class="grid divide-y divide-neutral-200 ">
		<div class="p-4 rounded-xl shadow-xl mb-4 bg-[#e0f9f4]">
			<details class="group">
				<summary class="flex justify-between items-center font-medium cursor-pointer list-none">
					<span className="my-4 text-lg font-bold"> EAGLES</span>
					<span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
</svg>
              </span>
				</summary>

      <table className="table bg-white p-4 rounded-xl w-full ">
        <thead>
          <tr>
            <th className="px-4 py-2">Index</th>
            <th className="px-4 py-2">Student</th>
            <th className="px-4 py-2">Points Add (R) </th>
            <th className="px-4 py-2">Points Negate (V) </th>
            <th className="px-4 py-2">Tally</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <tr key={index}>
              <td className="px-4 py-2">{index + 1}</td>
              <td className="px-4 py-2">{student.name}</td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  className="input input-bordered w-full max-w-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addPoints(index, parseInt(e.target.value, 10));
                      e.target.value = '';
                    }
                  }}
                />
              </td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  className="input input-bordered w-full max-w-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      subtractPoints(index, parseInt(e.target.value, 10));
                      e.target.value = '';
                    }
                  }}
                />
              </td>
              <td className="px-4 py-2">{student.points}</td>
            </tr>
          ))}
          <tr className="bg-amber-100 rounded-xl ">
            <td className="px-4 py-2" colSpan="4">Total Points</td>
            <td className="px-4 py-2 font-bold">{totalPoints}</td>
          </tr>
        </tbody>
      </table>
			</details>

		</div>
	</div>





  <div class="grid divide-y divide-neutral-200 ">
		<div class="p-4 rounded-xl shadow-xl mb-4 bg-[#ffecb1]">
			<details class="group">
				<summary class="flex justify-between items-center font-medium cursor-pointer list-none">
					<span className="my-4 text-lg font-bold"> FALCON</span>
					<span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
</svg>
              </span>
				</summary>

      <table className="table bg-white p-4 rounded-xl w-full ">
        <thead>
          <tr>
            <th className="px-4 py-2">Index</th>
            <th className="px-4 py-2">Student</th>
            <th className="px-4 py-2">Points Add (R) </th>
            <th className="px-4 py-2">Points Negate (V) </th>
            <th className="px-4 py-2">Tally</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <tr key={index}>
              <td className="px-4 py-2">{index + 1}</td>
              <td className="px-4 py-2">{student.name}</td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  className="input input-bordered w-full max-w-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addPoints(index, parseInt(e.target.value, 10));
                      e.target.value = '';
                    }
                  }}
                />
              </td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  className="input input-bordered w-full max-w-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      subtractPoints(index, parseInt(e.target.value, 10));
                      e.target.value = '';
                    }
                  }}
                />
              </td>
              <td className="px-4 py-2">{student.points}</td>
            </tr>
          ))}
          <tr className="bg-amber-100 rounded-xl ">
            <td className="px-4 py-2" colSpan="4">Total Points</td>
            <td className="px-4 py-2 font-bold">{totalPoints}</td>
          </tr>
        </tbody>
      </table>
			</details>

		</div>
	</div>






  <div class="grid divide-y divide-neutral-200 ">
		<div class="p-4 rounded-xl shadow-xl mb-4 bg-[#ffe0e0]">
			<details class="group">
				<summary class="flex justify-between items-center font-medium cursor-pointer list-none">
					<span className="my-4 text-lg font-bold"> KESTRELS</span>
					<span class="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
</svg>
              </span>
				</summary>

      <table className="table bg-white p-4 rounded-xl w-full ">
        <thead>
          <tr>
            <th className="px-4 py-2">Index</th>
            <th className="px-4 py-2">Student</th>
            <th className="px-4 py-2">Points Add (R) </th>
            <th className="px-4 py-2">Points Negate (V) </th>
            <th className="px-4 py-2">Tally</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <tr key={index}>
              <td className="px-4 py-2">{index + 1}</td>
              <td className="px-4 py-2">{student.name}</td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  className="input input-bordered w-full max-w-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addPoints(index, parseInt(e.target.value, 10));
                      e.target.value = '';
                    }
                  }}
                />
              </td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  className="input input-bordered w-full max-w-xs"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      subtractPoints(index, parseInt(e.target.value, 10));
                      e.target.value = '';
                    }
                  }}
                />
              </td>
              <td className="px-4 py-2">{student.points}</td>
            </tr>
          ))}
          <tr className="bg-amber-100 rounded-xl ">
            <td className="px-4 py-2" colSpan="4">Total Points</td>
            <td className="px-4 py-2 font-bold">{totalPoints}</td>
          </tr>
        </tbody>
      </table>
			</details>

		</div>
	</div>


    </AdminLayout>
  );
}

export default HouseStudentList;
