import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import ApexChart from "../components/AreaChart";

import Navbar from "../components/Navbar";
import BarChart from "../components/Barchart";
import RadarChart from "../components/RadarChart";
import BarStacked from "../components/BarStacked";
import CoCurricularEventsTable from "../components/CoCurricularEventsTable";
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import NetworkStudents from "../components/NetworkStudents";
import { useLanguage } from "../components/language";
import SidebarPilot from "../components/SidebarPilot";
import SideInnerSMPilot from "../components/SideInnerSMPilot";
import AddStaff from "../pages/AddStaff";
import randomColor from "randomcolor";
import AdminLayout from "../pages/AdminLayout";
import AddStaffAPI from "../pages/AddStaffAPI";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import AddSingleStudent from "../pages/AddSingleStudent";
import AddSingleStaff from "../pages/AddSingleStaff";

export default function NetworkStaff() {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  // const randomBgColor = randomColor();

  let { user } = useContext(AuthContext);
  let profileID = 0;

  if (user[`role`] === `Parent`) {
    profileID = 12;
  } else {
    profileID = user["user_id"];
  }
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);

  const [staff, setStaff] = useState([]);

  let url = "";
  if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        //console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter(
          (event) => event.campus === user.username
        );
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes

  const APIurl =
    user[`role`] === "Network Admin"
      ? `${process.env.REACT_APP_API_KEY}/api/get_network_staffs/${profile?.network_id}/`
      : `${process.env.REACT_APP_API_KEY}/api/get_campus_staffs/${profile?.school_id}/`;

  useEffect(() => {
    // Fetch data from the '/get_staffs/' endpoint
    fetch(APIurl)
      .then((response) => response.json())
      .then((data) => {
        let filteredStaff = [];
        if (user.role === "Network Admin") {
          filteredStaff = data.filter(
            (staff) => staff.network === profile.network_id
          );
        } else if (user.role === "School Admin") {
          filteredStaff = data.filter(
            (staff) => staff.school === profile.school_id
          );
        }

        setStaff(filteredStaff);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user, profile]);

  const [isOpen2, setIsOpen2] = useState(false);

  const openModal2 = () => {
    setIsOpen2(true);
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  return (
    <AdminLayout>
      <div className="">
        <Tabs>
          {/* <div className="">
          <div className="border-b border-gray-200">
            <TabList className="-mb-px flex gap-6" aria-label="Tabs">
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                All Staff Members
              </Tab>
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Add New Staff Members
              </Tab>
            </TabList>
          </div>
        </div> */}

          <div className="flex justify-end mb-4">
            <div className="dropdown dropdown-end">
              <div tabIndex={0} role="button" className="btn btn-primary m-1">
                + Add Staff Member
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
              >
                <li>
                  <button
                    onClick={openModal2}
                    className="btn btn-ghost justify-start p-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                      />
                    </svg>
                    Add New
                  </button>
                </li>
                <li>
                  <Link
                    to={"/bulk_staff"}
                    className="btn btn-ghost justify-start p-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                      />
                    </svg>
                    Bulk Upload
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <TabPanel>
            <div className="mt-8 bg-white shadow-xl rounded-xl overflow-hidden darki:bg-slate-900 darki:border-gray-700">
              {loading ? (
                <div className="flex items-center justify-center space-x-2">
                  <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
                  <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
                  <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
                </div>
              ) : (
                <div className="overflow-x-auto bg-white rounded-xl">
                  {staff.length === 0 ? (
                    <div>
                      <div className="min-h-[15rem] flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-700 darki:shadow-slate-700/[.7]">
                        <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="text-gray-400 mb-6 w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                            />
                          </svg>

                          <h3 className="text-lg font-bold text-gray-800 darki:text-white">
                            No Staff Members Found!
                          </h3>
                          <p className="mt-2 text-gray-500 darki:text-gray-400">
                            Add staff members in your campus to automate the
                            workflow
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <table className="table">
                      {/* head */}
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Role(s)</th>
                          <th>Key Stage Alloted</th>
                          {(() => {
                            if (user["role"] === "Network Admin") {
                              return <th>Campus Assigned</th>;
                            }
                          })()}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {staff.map((st, index) => {
                          const randomBgColor = randomColor({
                            luminosity: "light",
                            format: "rgba",
                            alpha: 0.5,
                          });
                          const randomKeyStage = Math.floor(Math.random() * 5) + 1;

                          return (
                            <tr key={index}>
                              <td>
                                <div className="flex items-center gap-3">
                                  <div className="avatar  placeholder">
                                    <div
                                      className="rounded-full w-12"
                                      style={{
                                        backgroundColor: randomBgColor,
                                      }}
                                    >
                                      <span className="text-xl">
                                        {st.name.charAt(0)}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="font-bold">
                                      {st.firstname} {st.lastname}
                                    </div>
                                    <div className="text-sm opacity-50">
                                      {st.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className="badge-sm font-medium">
                                  {st.roles.map((role, index) => (
                                    <React.Fragment key={index}>
                                      <span className="badge bg-primary mr-1">
                                        {role
                                          .replace(/_/g, " ")
                                          .toLowerCase()
                                          .replace(/\b\w/g, (c) =>
                                            c.toUpperCase()
                                          )}
                                      </span>
                                    </React.Fragment>
                                  ))}
                                  <br />
                                </span>

                                <br />
                                
                              </td>
                              <td>KS - {randomKeyStage}</td>
                              {(() => {
                                if (user["role"] === "Network Admin") {
                                  return <td>{st.campus}</td>;
                                }
                              })()}

                              <th>
                                <div className="dropdown dropdown-end">
                                  <div
                                    tabIndex={0}
                                    role="button"
                                    className="btn m-1"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                                      />
                                    </svg>
                                  </div>
                                  <ul
                                    tabIndex={0}
                                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                                  >
                                    {/* <button className="btn btn-ghost">
                                      Details
                                    </button> */}
                                    <button className="btn btn-ghost">
                                      Edit
                                    </button>
                                    <button className="btn btn-ghost text-error">
                                      Delete
                                    </button>
                                  </ul>
                                </div>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <>
              <Tabs>
                <div className="mt-4">
                  <div className="bg-transparent">
                    <TabList
                      role="tablist"
                      className="tabs tabs-boxed bg-transparent"
                    >
                      <Tab role="tab" className="tab">
                        Import via File
                      </Tab>
                      <Tab role="tab" className="tab">
                        Import via API
                      </Tab>
                    </TabList>
                  </div>
                </div>

                <TabPanel>
                  <AddStaff />
                </TabPanel>
                <TabPanel>
                  <AddStaffAPI />
                </TabPanel>
              </Tabs>
            </>
          </TabPanel>
        </Tabs>

        <ReactModal
          className="w-5/12 h-min m-auto mt-8 "
          isOpen={isOpen2}
          onClose={closeModal2}
        >
          <div className="flex justify-end">
            <button
              className="btn btn-error btn-circle mb-4"
              onClick={closeModal2}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <AddSingleStaff />
          </div>
        </ReactModal>
      </div>
    </AdminLayout>
  );
}
