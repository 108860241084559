// CampusMap.js
import React, { useState } from 'react';
import MapPin from './MapPin';
import MapPopover from './MapPopover';

const CampusMap = () => {
  // const [popoverContent, setPopoverContent] = useState(null);
  // const [popoverContentCat, setPopoverContentCat] = useState(null);
  // const [popoverContentImg, setPopoverContentImg] = useState(null);
  // const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });

  // const handlePinClick = (position, content, cat, img) => {
  //   setPopoverContent(content);
  //   setPopoverContentCat(cat);
  //   setPopoverContentImg(img);
  //   setPopoverPosition({ x: position.x + 30, y: position.y - 10 });
  // };

  // const handleClosePopover = () => {
  //   setPopoverContent(null);
  // };


  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverContent, setPopoverContent] = useState(null);

  const openPopover = (title, type, description, image) => {
    setPopoverContent({ title, type, description, image });
    setPopoverOpen(true);
  };

  const closePopover = () => {
    setPopoverOpen(false);
    setPopoverContent(null);
  };

  const popover = popoverContent && (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        padding: '10px',
      }}
      className='p-6 rounded-xl shadow-xl w-5/12 mt-[12rem]'
    >
      <img className='w-full h-auto img-fit rounded-lg' src={popoverContent.image} alt={popoverContent.title} />


<div className='flex flex-row items-center gap-4 mt-4'>

<h3 className='text-xl font-semibold m-0'>{popoverContent.title}</h3>
      <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">{popoverContent.type}</span>
</div>
      

      <p className='text-sm text-base'>{popoverContent.description}</p>








      <div className="max-w-[85rem] p-2 mx-auto">
    {/* Grid */}
    <div className="grid items-center lg:grid-cols-12 gap-6 lg:gap-12">
      <div className="lg:col-span-8 relative lg:before:absolute lg:before:top-0 lg:before:-start-12 lg:before:w-px lg:before:h-full lg:before:bg-gray-200 lg:before:darki:bg-gray-700">
        <div className="grid gap-6 grid-cols-2 md:grid-cols-4 lg:grid-cols-3 sm:gap-8">
          {/* Stats */}
          <div>
            <p className="text-xl m-0 font-semibold text-success">Available</p>
            <p className="mt-1 text-gray-500">Status</p>
          </div>
          {/* End Stats */}
          {/* Stats */}
          <div>
            <p className="text-xl m-0 font-semibold text-blue-600">600</p>
            <p className="mt-1 text-gray-500">Capacity</p>
          </div>
          {/* End Stats */}
        </div>
      </div>
      {/* End Col */}
    </div>
    {/* End Grid */}
  </div>




      
      <button className='opacity-50' onClick={closePopover}>Close</button>
    </div>
  );

  return (
    <>

<div className='relative'>
{popover}
</div>


<div className='mapBG w-full rounded-xl'>
<svg
      viewBox="0 0 1600 770"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >

    <g className='cursor-pointer'onClick={() => openPopover('Rugby/Football/Cricket fields', 'SPORTS FACILITIES' , 'Our field has first class rugby, football and cricket pitches along with cricket nets and spectator seating.', 'https://resources.finalsite.net/images/f_auto,q_auto/v1654778667/dubaicollegeorg/p5i5yu5zcpx7k09ywrpf/RugbyFootballCricketfields.png')}>
    
      <g filter="url(#filter0_d_770_7691)"  >
        <path
          d="M120.013 117.375c0-2.787.667-5.357 1.999-7.707a14.875 14.875 0 015.478-5.576 14.85 14.85 0 017.517-2.091c2.692-.028 5.197.669 7.516 2.091 2.319 1.421 4.145 3.28 5.478 5.576 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.678 34.678 0 01-2.119 4.715 60.197 60.197 0 01-2.918 4.838 127.706 127.706 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 895.934 895.934 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.862 78.862 0 01-2.119-4.633c-.64-1.53-.92-2.911-.84-4.141zm4.998 0c0 2.843.973 5.262 2.919 7.257 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={121} y={102.001} width={28} height={30} rx={14} fill="#fff" />
      
      <path
        d="M126.316 122.501v-1.488c.683-.565 1.344-1.125 1.984-1.68a19.88 19.88 0 001.728-1.648 8.891 8.891 0 001.216-1.584c.309-.523.464-1.029.464-1.52 0-.459-.128-.864-.384-1.216-.245-.352-.656-.528-1.232-.528-.587 0-1.029.192-1.328.576-.299.384-.448.848-.448 1.392h-1.984c.021-.821.203-1.504.544-2.048a3.153 3.153 0 011.36-1.232c.565-.277 1.2-.416 1.904-.416 1.141 0 2.027.315 2.656.944.64.619.96 1.424.96 2.416 0 .619-.144 1.221-.432 1.808a7.785 7.785 0 01-1.104 1.696 15.625 15.625 0 01-1.488 1.536c-.533.469-1.051.907-1.552 1.312h4.848v1.68h-7.712zm9.141 0v-1.488c.682-.565 1.344-1.125 1.984-1.68a20.172 20.172 0 001.728-1.648 8.891 8.891 0 001.216-1.584c.309-.523.464-1.029.464-1.52 0-.459-.128-.864-.384-1.216-.246-.352-.656-.528-1.232-.528-.587 0-1.03.192-1.328.576-.299.384-.448.848-.448 1.392h-1.984c.021-.821.202-1.504.544-2.048a3.147 3.147 0 011.36-1.232c.565-.277 1.2-.416 1.904-.416 1.141 0 2.026.315 2.656.944.64.619.96 1.424.96 2.416 0 .619-.144 1.221-.432 1.808a7.86 7.86 0 01-1.104 1.696 15.625 15.625 0 01-1.488 1.536c-.534.469-1.051.907-1.552 1.312h4.848v1.68h-7.712z"
        fill="#4F0696"
      /></g>


          <g className='cursor-pointer'onClick={() => openPopover('Auditorium', 'GENERAL FACILITIES' , 'Our 900 seater auditorium is used for whole school assemblies, our annual production, music performances and visiting speakers.', 'https://resources.finalsite.net/images/f_auto,q_auto/v1654762114/dubaicollegeorg/rlfrpnps3qpfzo6mcola/Auditorium.png')}>

      <g filter="url(#filter1_d_770_7691)">
        <path
          d="M270.013 232.375c0-2.787.667-5.357 1.999-7.707a14.875 14.875 0 015.478-5.576 14.85 14.85 0 017.517-2.091c2.692-.028 5.197.669 7.516 2.091 2.319 1.421 4.145 3.28 5.478 5.576 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.678 34.678 0 01-2.119 4.715 60.197 60.197 0 01-2.918 4.838 127.706 127.706 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 895.934 895.934 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.862 78.862 0 01-2.119-4.633c-.64-1.53-.92-2.911-.84-4.141zm4.998 0c0 2.843.973 5.262 2.919 7.257 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={271} y={217.001} width={28} height={30} rx={14} fill="#fff" />
      
      <path
        d="M285.708 237.501v-2.08h-5.52v-1.632l5.248-7.488h2.32v7.344h1.472v1.776h-1.472v2.08h-2.048zm-3.328-3.856h3.456v-5.088l-3.456 5.088z"
        fill="red"
      /></g>


      <g filter="url(#filter2_d_770_7691)">
        <path
          d="M355.013 111.375c0-2.787.667-5.357 1.999-7.707a14.881 14.881 0 015.478-5.576A14.857 14.857 0 01370.007 96c2.692-.028 5.197.67 7.516 2.09 2.319 1.422 4.145 3.281 5.478 5.577 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.678 34.678 0 01-2.119 4.715 60.197 60.197 0 01-2.918 4.838 127.706 127.706 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 895.934 895.934 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.862 78.862 0 01-2.119-4.633c-.64-1.53-.92-2.911-.84-4.141zm4.998 0c0 2.843.973 5.262 2.919 7.257 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={356} y={96.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M364.804 116.501v-8.864l-1.84.432v-1.568l2.704-1.2h1.264v11.2h-2.128zm4.028 0v-1.488c.682-.565 1.344-1.125 1.984-1.68a20.172 20.172 0 001.728-1.648 8.891 8.891 0 001.216-1.584c.309-.523.464-1.029.464-1.52 0-.459-.128-.864-.384-1.216-.246-.352-.656-.528-1.232-.528-.587 0-1.03.192-1.328.576-.299.384-.448.848-.448 1.392h-1.984c.021-.821.202-1.504.544-2.048a3.147 3.147 0 011.36-1.232c.565-.277 1.2-.416 1.904-.416 1.141 0 2.026.315 2.656.944.64.619.96 1.424.96 2.416 0 .619-.144 1.221-.432 1.808a7.86 7.86 0 01-1.104 1.696 15.625 15.625 0 01-1.488 1.536c-.534.469-1.051.907-1.552 1.312h4.848v1.68h-7.712z"
        fill="#0FBB9B"
      />
      <g filter="url(#filter3_d_770_7691)">
        <path
          d="M345.013 52.376c0-2.788.667-5.358 1.999-7.708a14.883 14.883 0 015.478-5.576A14.857 14.857 0 01360.007 37c2.692-.028 5.197.67 7.516 2.09 2.319 1.422 4.145 3.28 5.478 5.577 1.333 2.296 1.999 4.865 1.999 7.708 0 1.175-.28 2.541-.84 4.1a34.694 34.694 0 01-2.119 4.714 60.3 60.3 0 01-2.918 4.838 127.967 127.967 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.778 121.778 0 00-2.159 2.665l-.839.943a13.399 13.399 0 01-.84-.984 181.388 181.388 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 923.8 923.8 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.979 78.979 0 01-2.119-4.633c-.64-1.53-.92-2.91-.84-4.14zm4.998 0c0 2.842.973 5.261 2.919 7.256 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.256 0-2.843-.973-5.248-2.918-7.216-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.216z"
          fill="#fff"
        />
      </g>
      <rect x={346} y={37.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M351.316 57.501v-1.488c.683-.565 1.344-1.125 1.984-1.68a19.835 19.835 0 001.728-1.648 8.891 8.891 0 001.216-1.584c.309-.523.464-1.03.464-1.52 0-.459-.128-.864-.384-1.216-.245-.352-.656-.528-1.232-.528-.587 0-1.029.192-1.328.576-.299.384-.448.848-.448 1.392h-1.984c.021-.821.203-1.504.544-2.048a3.152 3.152 0 011.36-1.232c.565-.277 1.2-.416 1.904-.416 1.141 0 2.027.315 2.656.944.64.619.96 1.424.96 2.416a4.06 4.06 0 01-.432 1.808 7.795 7.795 0 01-1.104 1.696 15.625 15.625 0 01-1.488 1.536c-.533.47-1.051.907-1.552 1.312h4.848v1.68h-7.712zm13.077.192c-.736 0-1.403-.128-2-.384a3.32 3.32 0 01-1.44-1.216c-.363-.544-.555-1.227-.576-2.048h2.016c.01.544.186 1.003.528 1.376.352.363.842.544 1.472.544.597 0 1.056-.165 1.376-.496.32-.33.48-.747.48-1.248 0-.587-.214-1.03-.64-1.328-.416-.31-.955-.464-1.616-.464h-.832v-1.68h.848c.544 0 .997-.128 1.36-.384.362-.256.544-.635.544-1.136 0-.416-.139-.747-.416-.992-.267-.256-.64-.384-1.12-.384-.523 0-.934.155-1.232.464-.288.31-.448.688-.48 1.136h-2c.042-1.035.4-1.85 1.072-2.448.682-.597 1.562-.896 2.64-.896.768 0 1.413.139 1.936.416.533.267.933.624 1.2 1.072.277.448.416.944.416 1.488 0 .63-.176 1.163-.528 1.6a2.508 2.508 0 01-1.28.864 2.569 2.569 0 011.536.928c.394.48.592 1.088.592 1.824 0 .619-.15 1.184-.448 1.696-.299.512-.736.923-1.312 1.232-.566.31-1.264.464-2.096.464z"
        fill="#4F0696"
      />
      <g filter="url(#filter4_d_770_7691)">
        <path
          d="M459.013 52.376c0-2.788.667-5.358 1.999-7.708a14.883 14.883 0 015.478-5.576A14.857 14.857 0 01474.007 37c2.692-.028 5.197.67 7.516 2.09 2.319 1.422 4.145 3.28 5.478 5.577 1.333 2.296 1.999 4.865 1.999 7.708 0 1.175-.28 2.541-.84 4.1a34.694 34.694 0 01-2.119 4.714 60.3 60.3 0 01-2.918 4.838 127.967 127.967 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.778 121.778 0 00-2.159 2.665l-.839.943a13.399 13.399 0 01-.84-.984 181.388 181.388 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 923.8 923.8 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.979 78.979 0 01-2.119-4.633c-.64-1.53-.92-2.91-.84-4.14zm4.998 0c0 2.842.973 5.261 2.919 7.256 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.256 0-2.843-.973-5.248-2.918-7.216-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.216z"
          fill="#fff"
        />
      </g>
      <rect x={460} y={37.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M467.316 57.501v-1.488c.683-.565 1.344-1.125 1.984-1.68a19.835 19.835 0 001.728-1.648 8.891 8.891 0 001.216-1.584c.309-.523.464-1.03.464-1.52 0-.459-.128-.864-.384-1.216-.245-.352-.656-.528-1.232-.528-.587 0-1.029.192-1.328.576-.299.384-.448.848-.448 1.392h-1.984c.021-.821.203-1.504.544-2.048a3.152 3.152 0 011.36-1.232c.565-.277 1.2-.416 1.904-.416 1.141 0 2.027.315 2.656.944.64.619.96 1.424.96 2.416a4.06 4.06 0 01-.432 1.808 7.795 7.795 0 01-1.104 1.696 15.625 15.625 0 01-1.488 1.536c-.533.47-1.051.907-1.552 1.312h4.848v1.68h-7.712zm10.629 0v-8.864l-1.84.432v-1.568l2.704-1.2h1.264v11.2h-2.128z"
        fill="#4F0696"
      />
      <g filter="url(#filter5_d_770_7691)">
        <path
          d="M629.013 79.376c0-2.788.667-5.358 1.999-7.708a14.883 14.883 0 015.478-5.576A14.857 14.857 0 01644.007 64c2.692-.028 5.197.67 7.516 2.09 2.319 1.422 4.145 3.28 5.478 5.577 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.694 34.694 0 01-2.119 4.715 60.3 60.3 0 01-2.918 4.838 127.967 127.967 0 01-3.199 4.592 78.987 78.987 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.499 62.499 0 01-2.959-3.854 923.8 923.8 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.979 78.979 0 01-2.119-4.633c-.64-1.53-.92-2.91-.84-4.14zm4.998 0c0 2.842.973 5.261 2.919 7.256 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.216z"
          fill="#fff"
        />
      </g>
      <rect x={630} y={64.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M639.816 84.501v-1.488c.683-.565 1.344-1.125 1.984-1.68a19.835 19.835 0 001.728-1.648 8.891 8.891 0 001.216-1.584c.309-.523.464-1.03.464-1.52 0-.459-.128-.864-.384-1.216-.245-.352-.656-.528-1.232-.528-.587 0-1.029.192-1.328.576-.299.384-.448.848-.448 1.392h-1.984c.021-.821.203-1.504.544-2.048a3.152 3.152 0 011.36-1.232c.565-.277 1.2-.416 1.904-.416 1.141 0 2.027.315 2.656.944.64.619.96 1.424.96 2.416a4.06 4.06 0 01-.432 1.808 7.795 7.795 0 01-1.104 1.696 15.625 15.625 0 01-1.488 1.536c-.533.47-1.051.907-1.552 1.312h4.848v1.68h-7.712z"
        fill="red"
      />
      <g filter="url(#filter6_d_770_7691)">
        <path
          d="M729.013 452.375c0-2.787.667-5.357 1.999-7.707a14.875 14.875 0 015.478-5.576 14.85 14.85 0 017.517-2.091c2.692-.028 5.197.669 7.516 2.091 2.319 1.421 4.145 3.28 5.478 5.576 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.678 34.678 0 01-2.119 4.715 60.197 60.197 0 01-2.918 4.838 127.706 127.706 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 895.934 895.934 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.862 78.862 0 01-2.119-4.633c-.64-1.53-.92-2.911-.84-4.141zm4.998 0c0 2.843.973 5.262 2.919 7.257 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={730} y={437.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M743.304 457.501v-8.864l-1.84.432v-1.568l2.704-1.2h1.264v11.2h-2.128z"
        fill="red"
      />
      <g filter="url(#filter7_d_770_7691)">
        <path
          d="M1188.01 394.375c0-2.787.67-5.357 2-7.707 1.34-2.351 3.16-4.21 5.48-5.576a14.841 14.841 0 017.52-2.091c2.69-.028 5.19.669 7.51 2.091 2.32 1.421 4.15 3.28 5.48 5.576s2 4.865 2 7.707c0 1.176-.28 2.542-.84 4.1a35.185 35.185 0 01-2.12 4.715 57.19 57.19 0 01-2.92 4.838c-1.09 1.64-2.16 3.171-3.2 4.592a84.293 84.293 0 01-2.91 3.772c-.91 1.093-1.63 1.982-2.16 2.665l-.84.943c-.22-.219-.5-.547-.84-.984-.35-.437-1.06-1.298-2.12-2.583a62.043 62.043 0 01-2.96-3.854 726.99 726.99 0 01-3.16-4.51c-1.2-1.722-2.18-3.362-2.96-4.92a80.896 80.896 0 01-2.12-4.633c-.64-1.53-.92-2.911-.84-4.141zm5 0c0 2.843.97 5.262 2.92 7.257 1.95 1.996 4.3 2.993 7.08 2.993 2.77 0 5.13-.997 7.07-2.993 1.95-1.995 2.92-4.414 2.92-7.257 0-2.842-.97-5.247-2.92-7.215-1.94-1.968-4.3-2.98-7.07-3.034-2.78-.055-5.13.956-7.08 3.034-1.95 2.077-2.92 4.482-2.92 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={1189} y={379.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M1203.1 399.693c-.82 0-1.53-.144-2.12-.432a3.587 3.587 0 01-1.4-1.184 3.663 3.663 0 01-.57-1.728h2c.09.459.33.843.7 1.152.38.299.84.448 1.39.448.6 0 1.09-.219 1.46-.656.38-.437.58-.981.58-1.632 0-.672-.2-1.205-.58-1.6-.37-.395-.85-.592-1.42-.592-.48 0-.9.117-1.25.352s-.6.528-.75.88h-1.97l.96-6.4h6.21v1.792h-4.68l-.51 2.592c.24-.256.56-.464.96-.624.41-.171.86-.256 1.36-.256.8 0 1.47.181 2 .544.54.352.94.821 1.22 1.408.28.576.41 1.205.41 1.888 0 .779-.17 1.472-.51 2.08-.33.608-.8 1.088-1.41 1.44-.59.352-1.29.528-2.08.528z"
        fill="red"
      />
      <g filter="url(#filter8_d_770_7691)">
        <path
          d="M599.013 212.375c0-2.787.667-5.357 1.999-7.707a14.875 14.875 0 015.478-5.576 14.85 14.85 0 017.517-2.091c2.692-.028 5.197.669 7.516 2.091 2.319 1.421 4.145 3.28 5.478 5.576 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.678 34.678 0 01-2.119 4.715 60.197 60.197 0 01-2.918 4.838 127.706 127.706 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 895.934 895.934 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.862 78.862 0 01-2.119-4.633c-.64-1.53-.92-2.911-.84-4.141zm4.998 0c0 2.843.973 5.262 2.919 7.257 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={600} y={197.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M613.412 217.693c-.779 0-1.435-.149-1.968-.448a3.46 3.46 0 01-1.248-1.2 3.667 3.667 0 01-.528-1.584h1.904c.085.491.288.869.608 1.136.331.267.747.4 1.248.4.715 0 1.291-.325 1.728-.976.437-.661.672-1.664.704-3.008-.256.437-.645.8-1.168 1.088a3.399 3.399 0 01-1.696.432c-.619 0-1.2-.149-1.744-.448a3.589 3.589 0 01-1.328-1.264c-.341-.544-.512-1.184-.512-1.92 0-.672.165-1.296.496-1.872a3.824 3.824 0 011.376-1.392c.587-.352 1.275-.528 2.064-.528.843 0 1.552.155 2.128.464a3.694 3.694 0 011.376 1.248 5.21 5.21 0 01.752 1.744c.16.64.24 1.296.24 1.968 0 1.248-.176 2.336-.528 3.264-.341.917-.848 1.632-1.52 2.144-.661.501-1.456.752-2.384.752zm.064-5.888c.395 0 .752-.085 1.072-.256.32-.181.571-.416.752-.704.181-.299.272-.629.272-.992 0-.576-.203-1.051-.608-1.424-.395-.373-.896-.56-1.504-.56-.597 0-1.093.187-1.488.56-.395.363-.592.827-.592 1.392 0 .597.197 1.077.592 1.44.395.363.896.544 1.504.544z"
        fill="#0FBB9B"
      />
      <g filter="url(#filter9_d_770_7691)">
        <path
          d="M892.013 208.375c0-2.787.667-5.357 1.999-7.707a14.875 14.875 0 015.478-5.576 14.85 14.85 0 017.517-2.091c2.692-.028 5.197.669 7.516 2.091 2.319 1.421 4.145 3.28 5.478 5.576 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.678 34.678 0 01-2.119 4.715 60.197 60.197 0 01-2.918 4.838 127.706 127.706 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 895.934 895.934 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.862 78.862 0 01-2.119-4.633c-.64-1.53-.92-2.911-.84-4.141zm4.998 0c0 2.843.973 5.262 2.919 7.257 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={893} y={193.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M906.54 213.693c-.768 0-1.461-.133-2.08-.4a3.695 3.695 0 01-1.488-1.168c-.363-.501-.544-1.083-.544-1.744 0-.683.176-1.28.528-1.792.363-.512.837-.88 1.424-1.104a2.882 2.882 0 01-1.216-.96 2.424 2.424 0 01-.448-1.424c0-.533.144-1.024.432-1.472.288-.459.715-.827 1.28-1.104.576-.277 1.28-.416 2.112-.416.832 0 1.531.139 2.096.416.565.277.992.645 1.28 1.104.288.448.432.939.432 1.472 0 .512-.155.987-.464 1.424a2.739 2.739 0 01-1.2.96 2.936 2.936 0 011.424 1.104c.352.512.528 1.109.528 1.792 0 .661-.181 1.243-.544 1.744-.363.501-.859.891-1.488 1.168-.619.267-1.307.4-2.064.4zm0-6.816c.555 0 .992-.144 1.312-.432.32-.288.48-.656.48-1.104 0-.491-.16-.875-.48-1.152-.309-.277-.747-.416-1.312-.416-.576 0-1.019.139-1.328.416-.309.277-.464.661-.464 1.152 0 .459.155.832.464 1.12.32.277.763.416 1.328.416zm0 5.104c.683 0 1.2-.165 1.552-.496.352-.341.528-.768.528-1.28 0-.565-.187-1.008-.56-1.328-.363-.32-.869-.48-1.52-.48s-1.168.16-1.552.48c-.373.32-.56.763-.56 1.328 0 .512.176.939.528 1.28.363.331.891.496 1.584.496z"
        fill="#0FBB9B"
      />
      <g filter="url(#filter10_d_770_7691)">
        <path
          d="M1237.01 315.375c0-2.787.67-5.357 2-7.707 1.34-2.351 3.16-4.21 5.48-5.576a14.841 14.841 0 017.52-2.091c2.69-.028 5.19.669 7.51 2.091 2.32 1.421 4.15 3.28 5.48 5.576s2 4.865 2 7.707c0 1.176-.28 2.542-.84 4.1a35.185 35.185 0 01-2.12 4.715 57.19 57.19 0 01-2.92 4.838c-1.09 1.64-2.16 3.171-3.2 4.592a84.293 84.293 0 01-2.91 3.772c-.91 1.093-1.63 1.982-2.16 2.665l-.84.943c-.22-.219-.5-.547-.84-.984-.35-.437-1.06-1.298-2.12-2.583a62.043 62.043 0 01-2.96-3.854 726.99 726.99 0 01-3.16-4.51c-1.2-1.722-2.18-3.362-2.96-4.92a80.896 80.896 0 01-2.12-4.633c-.64-1.53-.92-2.911-.84-4.141zm5 0c0 2.843.97 5.262 2.92 7.257 1.95 1.996 4.3 2.993 7.08 2.993 2.77 0 5.13-.997 7.07-2.993 1.95-1.995 2.92-4.414 2.92-7.257 0-2.842-.97-5.247-2.92-7.215-1.94-1.968-4.3-2.98-7.07-3.034-2.78-.055-5.13.956-7.08 3.034-1.95 2.077-2.92 4.482-2.92 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={1238} y={300.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M1251.8 320.693c-.85 0-1.56-.155-2.13-.464a3.776 3.776 0 01-1.39-1.248 5.305 5.305 0 01-.77-1.744 8.598 8.598 0 01-.23-1.968c0-1.248.18-2.331.53-3.248.35-.928.86-1.643 1.52-2.144.66-.512 1.46-.768 2.4-.768.77 0 1.42.155 1.95.464.55.299.97.693 1.27 1.184.3.491.47 1.019.53 1.584h-1.91c-.09-.491-.3-.869-.62-1.136-.32-.267-.73-.4-1.23-.4-.72 0-1.29.331-1.73.992-.44.651-.67 1.648-.71 2.992.26-.437.64-.8 1.16-1.088a3.416 3.416 0 011.71-.432c.62 0 1.2.149 1.74.448.55.299.98.72 1.31 1.264.35.544.52 1.184.52 1.92 0 .672-.17 1.296-.5 1.872-.32.576-.78 1.04-1.38 1.392-.58.352-1.27.528-2.04.528zm-.12-1.76c.6 0 1.09-.181 1.48-.544.39-.373.59-.843.59-1.408 0-.597-.2-1.077-.59-1.44-.4-.363-.9-.544-1.51-.544-.39 0-.75.091-1.07.272-.31.171-.55.405-.74.704-.18.288-.27.613-.27.976 0 .576.2 1.051.59 1.424.41.373.92.56 1.52.56z"
        fill="#0FBB9B"
      />
      <g filter="url(#filter11_d_770_7691)">
        <path
          d="M1106.01 228.375c0-2.787.67-5.357 2-7.707 1.34-2.351 3.16-4.21 5.48-5.576a14.841 14.841 0 017.52-2.091c2.69-.028 5.19.669 7.51 2.091 2.32 1.421 4.15 3.28 5.48 5.576s2 4.865 2 7.707c0 1.176-.28 2.542-.84 4.1a35.185 35.185 0 01-2.12 4.715 57.19 57.19 0 01-2.92 4.838c-1.09 1.64-2.16 3.171-3.2 4.592a84.293 84.293 0 01-2.91 3.772c-.91 1.093-1.63 1.982-2.16 2.665l-.84.943c-.22-.219-.5-.547-.84-.984-.35-.437-1.06-1.298-2.12-2.583a62.043 62.043 0 01-2.96-3.854 726.99 726.99 0 01-3.16-4.51c-1.2-1.722-2.18-3.362-2.96-4.92a80.896 80.896 0 01-2.12-4.633c-.64-1.53-.92-2.911-.84-4.141zm5 0c0 2.843.97 5.262 2.92 7.257 1.95 1.996 4.3 2.993 7.08 2.993 2.77 0 5.13-.997 7.07-2.993 1.95-1.995 2.92-4.414 2.92-7.257 0-2.842-.97-5.247-2.92-7.215-1.94-1.968-4.3-2.98-7.07-3.034-2.78-.055-5.13.956-7.08 3.034-1.95 2.077-2.92 4.482-2.92 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={1107} y={213.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M1115.3 233.501v-8.864l-1.84.432v-1.568l2.71-1.2h1.26v11.2h-2.13zm9.42 0v-2.08h-5.52v-1.632l5.25-7.488h2.32v7.344h1.47v1.776h-1.47v2.08h-2.05zm-3.32-3.856h3.45v-5.088l-3.45 5.088z"
        fill="#4F0696"
      />
      <g filter="url(#filter12_d_770_7691)">
        <path
          d="M839.013 38.376c0-2.788.667-5.358 1.999-7.708a14.883 14.883 0 015.478-5.576A14.857 14.857 0 01854.007 23c2.692-.028 5.197.67 7.516 2.09 2.319 1.422 4.145 3.28 5.478 5.577 1.333 2.296 1.999 4.865 1.999 7.708 0 1.175-.28 2.541-.84 4.1a34.694 34.694 0 01-2.119 4.714 60.3 60.3 0 01-2.918 4.838 127.967 127.967 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.778 121.778 0 00-2.159 2.665l-.839.943a13.399 13.399 0 01-.84-.984 181.388 181.388 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 923.8 923.8 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.979 78.979 0 01-2.119-4.633c-.64-1.53-.92-2.91-.84-4.14zm4.998 0c0 2.842.973 5.261 2.919 7.256 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.256 0-2.843-.973-5.248-2.918-7.216-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.216z"
          fill="#fff"
        />
      </g>
      <rect x={840} y={23.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M848.304 43.501v-8.864l-1.84.432v-1.568l2.704-1.2h1.264v11.2h-2.128zm8.316.192c-.822 0-1.531-.144-2.128-.432a3.518 3.518 0 01-1.392-1.184 3.692 3.692 0 01-.576-1.728h2c.096.459.33.843.704 1.152.373.299.837.448 1.392.448.597 0 1.082-.219 1.456-.656.384-.437.576-.981.576-1.632 0-.672-.192-1.205-.576-1.6-.374-.395-.848-.592-1.424-.592-.48 0-.896.117-1.248.352a1.979 1.979 0 00-.752.88h-1.968l.96-6.4h6.208v1.792h-4.672l-.512 2.592c.234-.256.554-.464.96-.624.405-.17.858-.256 1.36-.256.8 0 1.466.181 2 .544.533.352.938.821 1.216 1.408a4.3 4.3 0 01.416 1.888c0 .779-.171 1.472-.512 2.08a3.657 3.657 0 01-1.408 1.44c-.598.352-1.291.528-2.08.528z"
        fill="#4F0696"
      />
      <g filter="url(#filter13_d_770_7691)">
        <path
          d="M893.013 47.376c0-2.788.667-5.358 1.999-7.708a14.883 14.883 0 015.478-5.576A14.857 14.857 0 01908.007 32c2.692-.028 5.197.67 7.516 2.09 2.319 1.422 4.145 3.28 5.478 5.577 1.333 2.296 1.999 4.865 1.999 7.708 0 1.175-.28 2.541-.84 4.1a34.694 34.694 0 01-2.119 4.714 60.3 60.3 0 01-2.918 4.838 127.967 127.967 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.778 121.778 0 00-2.159 2.665l-.839.943a13.399 13.399 0 01-.84-.984 181.388 181.388 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 923.8 923.8 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.979 78.979 0 01-2.119-4.633c-.64-1.53-.92-2.91-.84-4.14zm4.998 0c0 2.842.973 5.261 2.919 7.256 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.256 0-2.843-.973-5.248-2.918-7.216-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.216z"
          fill="#fff"
        />
      </g>
      <rect x={894} y={32.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M902.304 52.501v-8.864l-1.84.432v-1.568l2.704-1.2h1.264v11.2h-2.128zm8.508.192c-.843 0-1.552-.155-2.128-.464a3.837 3.837 0 01-1.392-1.248 5.47 5.47 0 01-.768-1.744 8.595 8.595 0 01-.224-1.968c0-1.248.176-2.33.528-3.248.352-.928.858-1.643 1.52-2.144.661-.512 1.461-.768 2.4-.768.768 0 1.418.155 1.952.464.544.299.965.693 1.264 1.184a3.69 3.69 0 01.528 1.584h-1.904c-.096-.49-.304-.87-.624-1.136-.32-.267-.731-.4-1.232-.4-.715 0-1.291.33-1.728.992-.438.65-.672 1.648-.704 2.992.256-.437.64-.8 1.152-1.088a3.43 3.43 0 011.712-.432c.618 0 1.2.15 1.744.448.544.299.981.72 1.312 1.264.341.544.512 1.184.512 1.92 0 .672-.166 1.296-.496 1.872a3.668 3.668 0 01-1.376 1.392c-.587.352-1.27.528-2.048.528zm-.112-1.76c.597 0 1.088-.181 1.472-.544.394-.373.592-.843.592-1.408 0-.597-.198-1.077-.592-1.44-.395-.363-.896-.544-1.504-.544-.395 0-.752.09-1.072.272-.31.17-.555.405-.736.704a1.788 1.788 0 00-.272.976c0 .576.197 1.05.592 1.424.405.373.912.56 1.52.56z"
        fill="#4F0696"
      />
      <g filter="url(#filter14_d_770_7691)">
        <path
          d="M968.013 32.376c0-2.788.667-5.358 1.999-7.708a14.883 14.883 0 015.478-5.576A14.857 14.857 0 01983.007 17c2.692-.028 5.197.67 7.516 2.09 2.319 1.422 4.145 3.28 5.478 5.577 1.333 2.296 1.999 4.865 1.999 7.708 0 1.175-.28 2.541-.84 4.1a34.694 34.694 0 01-2.119 4.714 60.3 60.3 0 01-2.918 4.838 127.967 127.967 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.778 121.778 0 00-2.159 2.665l-.839.943a13.399 13.399 0 01-.84-.984 181.388 181.388 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 923.8 923.8 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.979 78.979 0 01-2.119-4.633c-.64-1.53-.92-2.91-.84-4.14zm4.998 0c0 2.842.973 5.261 2.919 7.256 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.256 0-2.843-.973-5.248-2.918-7.216-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.216z"
          fill="#fff"
        />
      </g>
      <rect x={969} y={17.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M973.316 37.501v-1.488c.683-.565 1.344-1.125 1.984-1.68a19.835 19.835 0 001.728-1.648 8.891 8.891 0 001.216-1.584c.309-.523.464-1.03.464-1.52 0-.459-.128-.864-.384-1.216-.245-.352-.656-.528-1.232-.528-.587 0-1.029.192-1.328.576-.299.384-.448.848-.448 1.392h-1.984c.021-.821.203-1.504.544-2.048a3.152 3.152 0 011.36-1.232c.565-.277 1.2-.416 1.904-.416 1.141 0 2.027.315 2.656.944.64.619.96 1.424.96 2.416a4.06 4.06 0 01-.432 1.808 7.795 7.795 0 01-1.104 1.696 15.625 15.625 0 01-1.488 1.536c-.533.47-1.051.907-1.552 1.312h4.848v1.68h-7.712zm13.927.192c-1.035 0-1.92-.24-2.656-.72-.736-.49-1.307-1.168-1.712-2.032-.395-.875-.592-1.888-.592-3.04 0-1.141.197-2.15.592-3.024.405-.875.976-1.552 1.712-2.032.736-.49 1.621-.736 2.656-.736 1.034 0 1.92.245 2.656.736.736.48 1.301 1.157 1.696 2.032.405.875.608 1.883.608 3.024 0 1.152-.203 2.165-.608 3.04-.395.864-.96 1.541-1.696 2.032-.736.48-1.622.72-2.656.72zm0-1.84c.842 0 1.53-.352 2.064-1.056.533-.704.8-1.67.8-2.896 0-1.227-.267-2.192-.8-2.896-.534-.704-1.222-1.056-2.064-1.056-.854 0-1.547.352-2.08 1.056-.523.704-.784 1.67-.784 2.896 0 1.227.261 2.192.784 2.896.533.704 1.226 1.056 2.08 1.056z"
        fill="#4F0696"
      />
      <g filter="url(#filter15_d_770_7691)">
        <path
          d="M1091.01 21.375c0-2.788.67-5.357 2-7.707 1.34-2.351 3.16-4.21 5.48-5.576A14.848 14.848 0 011106.01 6c2.69-.028 5.19.67 7.51 2.09 2.32 1.422 4.15 3.28 5.48 5.577 1.33 2.296 2 4.865 2 7.707 0 1.176-.28 2.542-.84 4.1a35.201 35.201 0 01-2.12 4.715 57.284 57.284 0 01-2.92 4.838 124.2 124.2 0 01-3.2 4.592 84.293 84.293 0 01-2.91 3.772c-.91 1.093-1.63 1.982-2.16 2.665l-.84.943c-.22-.219-.5-.547-.84-.984a310.55 310.55 0 00-2.12-2.583 62.043 62.043 0 01-2.96-3.854c-.91-1.285-1.96-2.788-3.16-4.51-1.2-1.722-2.18-3.362-2.96-4.92a81.02 81.02 0 01-2.12-4.633c-.64-1.53-.92-2.91-.84-4.14zm5 0c0 2.843.97 5.262 2.92 7.257 1.95 1.996 4.3 2.993 7.08 2.993 2.77 0 5.13-.997 7.07-2.993 1.95-1.995 2.92-4.414 2.92-7.257 0-2.842-.97-5.247-2.92-7.215-1.94-1.968-4.3-2.98-7.07-3.034-2.78-.055-5.13.956-7.08 3.034-1.95 2.077-2.92 4.482-2.92 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={1092} y={6.00098} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M1100.8 26.501v-8.864l-1.84.432v-1.568l2.71-1.2h1.26v11.2h-2.13zm4.94 0l4.23-9.456h-5.52v-1.744h7.63v1.456l-4.21 9.744h-2.13z"
        fill="#4F0696"
      />
      <g filter="url(#filter16_d_770_7691)">
        <path
          d="M1162.01 47.376c0-2.788.67-5.358 2-7.708 1.34-2.351 3.16-4.21 5.48-5.576a14.848 14.848 0 017.52-2.091c2.69-.028 5.19.67 7.51 2.09 2.32 1.422 4.15 3.28 5.48 5.577 1.33 2.296 2 4.865 2 7.708 0 1.175-.28 2.541-.84 4.1a35.201 35.201 0 01-2.12 4.714 57.284 57.284 0 01-2.92 4.838 124.2 124.2 0 01-3.2 4.592 84.293 84.293 0 01-2.91 3.772c-.91 1.093-1.63 1.982-2.16 2.665l-.84.943c-.22-.219-.5-.547-.84-.984a310.55 310.55 0 00-2.12-2.583 62.043 62.043 0 01-2.96-3.854c-.91-1.284-1.96-2.788-3.16-4.51-1.2-1.722-2.18-3.362-2.96-4.92a81.02 81.02 0 01-2.12-4.633c-.64-1.53-.92-2.91-.84-4.14zm5 0c0 2.842.97 5.261 2.92 7.256 1.95 1.996 4.3 2.993 7.08 2.993 2.77 0 5.13-.997 7.07-2.993 1.95-1.995 2.92-4.414 2.92-7.256 0-2.843-.97-5.248-2.92-7.216-1.94-1.968-4.3-2.98-7.07-3.034-2.78-.055-5.13.956-7.08 3.034-1.95 2.077-2.92 4.482-2.92 7.216z"
          fill="#fff"
        />
      </g>
      <rect x={1163} y={32.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M1171.3 52.501v-8.864l-1.84.432v-1.568l2.71-1.2h1.26v11.2h-2.13zm8.26.192c-.77 0-1.47-.133-2.08-.4-.62-.277-1.12-.667-1.49-1.168-.37-.501-.55-1.083-.55-1.744 0-.683.18-1.28.53-1.792.36-.512.84-.88 1.43-1.104a2.852 2.852 0 01-1.22-.96 2.42 2.42 0 01-.45-1.424c0-.533.15-1.024.43-1.472.29-.459.72-.827 1.28-1.104.58-.277 1.28-.416 2.12-.416.83 0 1.53.139 2.09.416.57.277.99.645 1.28 1.104.29.448.43.939.43 1.472 0 .512-.15.987-.46 1.424-.3.427-.7.747-1.2.96.6.224 1.07.592 1.42 1.104.36.512.53 1.11.53 1.792 0 .661-.18 1.243-.54 1.744-.37.501-.86.89-1.49 1.168-.62.267-1.31.4-2.06.4zm0-6.816c.55 0 .99-.144 1.31-.432.32-.288.48-.656.48-1.104 0-.49-.16-.875-.48-1.152-.31-.277-.75-.416-1.31-.416-.58 0-1.02.139-1.33.416-.31.277-.47.661-.47 1.152 0 .459.16.832.47 1.12.32.277.76.416 1.33.416zm0 5.104c.68 0 1.2-.165 1.55-.496.35-.341.53-.768.53-1.28 0-.565-.19-1.008-.56-1.328-.37-.32-.87-.48-1.52-.48-.66 0-1.17.16-1.56.48-.37.32-.56.763-.56 1.328 0 .512.18.939.53 1.28.36.33.89.496 1.59.496z"
        fill="#4F0696"
      />
      <g filter="url(#filter17_d_770_7691)">
        <path
          d="M1004.01 112.375c0-2.787.67-5.357 2-7.707 1.34-2.351 3.16-4.21 5.48-5.576a14.848 14.848 0 017.52-2.091c2.69-.028 5.19.67 7.51 2.09 2.32 1.422 4.15 3.281 5.48 5.577s2 4.865 2 7.707c0 1.176-.28 2.542-.84 4.1a35.185 35.185 0 01-2.12 4.715 57.19 57.19 0 01-2.92 4.838c-1.09 1.64-2.16 3.171-3.2 4.592a84.293 84.293 0 01-2.91 3.772c-.91 1.093-1.63 1.982-2.16 2.665l-.84.943c-.22-.219-.5-.547-.84-.984-.35-.437-1.06-1.298-2.12-2.583a62.043 62.043 0 01-2.96-3.854 726.99 726.99 0 01-3.16-4.51c-1.2-1.722-2.18-3.362-2.96-4.92a80.896 80.896 0 01-2.12-4.633c-.64-1.53-.92-2.911-.84-4.141zm5 0c0 2.843.97 5.262 2.92 7.257 1.95 1.996 4.3 2.993 7.08 2.993 2.77 0 5.13-.997 7.07-2.993 1.95-1.995 2.92-4.414 2.92-7.257 0-2.842-.97-5.247-2.92-7.215-1.94-1.968-4.3-2.98-7.07-3.034-2.78-.055-5.13.956-7.08 3.034-1.95 2.077-2.92 4.482-2.92 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={1005} y={97.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M1013.3 117.501v-8.864l-1.84.432v-1.568l2.71-1.2h1.26v11.2h-2.13zm8.13.192c-.78 0-1.44-.149-1.97-.448a3.498 3.498 0 01-1.25-1.2 3.751 3.751 0 01-.53-1.584h1.91c.08.491.29.869.61 1.136.33.267.74.4 1.24.4.72 0 1.29-.325 1.73-.976.44-.661.67-1.664.71-3.008-.26.437-.65.8-1.17 1.088-.51.288-1.08.432-1.7.432-.62 0-1.2-.149-1.74-.448-.55-.299-.99-.72-1.33-1.264-.34-.544-.51-1.184-.51-1.92 0-.672.16-1.296.49-1.872.33-.576.79-1.04 1.38-1.392.59-.352 1.27-.528 2.06-.528.85 0 1.56.155 2.13.464.58.309 1.04.725 1.38 1.248.35.523.6 1.104.75 1.744.16.64.24 1.296.24 1.968 0 1.248-.18 2.336-.53 3.264-.34.917-.85 1.632-1.52 2.144-.66.501-1.45.752-2.38.752zm.06-5.888c.4 0 .75-.085 1.07-.256.32-.181.57-.416.76-.704.18-.299.27-.629.27-.992a1.87 1.87 0 00-.61-1.424c-.4-.373-.9-.56-1.5-.56s-1.1.187-1.49.56c-.4.363-.59.827-.59 1.392 0 .597.19 1.077.59 1.44.39.363.89.544 1.5.544z"
        fill="#4F0696"
      />
      <g filter="url(#filter18_d_770_7691)">
        <path
          d="M939.013 78.376c0-2.788.667-5.358 1.999-7.708a14.883 14.883 0 015.478-5.576A14.857 14.857 0 01954.007 63c2.692-.028 5.197.67 7.516 2.09 2.319 1.422 4.145 3.28 5.478 5.577 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.694 34.694 0 01-2.119 4.715 60.3 60.3 0 01-2.918 4.838 127.967 127.967 0 01-3.199 4.592 79.089 79.089 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.378 62.378 0 01-2.959-3.854 923.8 923.8 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.979 78.979 0 01-2.119-4.633c-.64-1.53-.92-2.91-.84-4.14zm4.998 0c0 2.842.973 5.261 2.919 7.256 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.216z"
          fill="#fff"
        />
      </g>
      <rect x={940} y={63.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M953.752 83.693c-.736 0-1.403-.128-2-.384a3.331 3.331 0 01-1.44-1.216c-.363-.544-.555-1.227-.576-2.048h2.016c.011.544.187 1.003.528 1.376.352.363.843.544 1.472.544.597 0 1.056-.165 1.376-.496.32-.33.48-.747.48-1.248 0-.587-.213-1.03-.64-1.328-.416-.31-.955-.464-1.616-.464h-.832v-1.68h.848c.544 0 .997-.128 1.36-.384.363-.256.544-.635.544-1.136 0-.416-.139-.747-.416-.992-.267-.256-.64-.384-1.12-.384-.523 0-.933.155-1.232.464-.288.31-.448.688-.48 1.136h-2c.043-1.035.4-1.85 1.072-2.448.683-.597 1.563-.896 2.64-.896.768 0 1.413.139 1.936.416.533.267.933.624 1.2 1.072.277.448.416.944.416 1.488 0 .63-.176 1.163-.528 1.6a2.504 2.504 0 01-1.28.864 2.569 2.569 0 011.536.928c.395.48.592 1.088.592 1.824 0 .619-.149 1.184-.448 1.696-.299.512-.736.923-1.312 1.232-.565.31-1.264.464-2.096.464z"
        fill="#EF4444"
      />
      <g filter="url(#filter19_d_770_7691)">
        <path
          d="M1144.01 204.375c0-2.787.67-5.357 2-7.707 1.34-2.351 3.16-4.21 5.48-5.576a14.841 14.841 0 017.52-2.091c2.69-.028 5.19.669 7.51 2.091 2.32 1.421 4.15 3.28 5.48 5.576s2 4.865 2 7.707c0 1.176-.28 2.542-.84 4.1a35.185 35.185 0 01-2.12 4.715 57.19 57.19 0 01-2.92 4.838c-1.09 1.64-2.16 3.171-3.2 4.592a84.293 84.293 0 01-2.91 3.772c-.91 1.093-1.63 1.982-2.16 2.665l-.84.943c-.22-.219-.5-.547-.84-.984-.35-.437-1.06-1.298-2.12-2.583a62.043 62.043 0 01-2.96-3.854 726.99 726.99 0 01-3.16-4.51c-1.2-1.722-2.18-3.362-2.96-4.92a80.896 80.896 0 01-2.12-4.633c-.64-1.53-.92-2.911-.84-4.141zm5 0c0 2.843.97 5.262 2.92 7.257 1.95 1.996 4.3 2.993 7.08 2.993 2.77 0 5.13-.997 7.07-2.993 1.95-1.995 2.92-4.414 2.92-7.257 0-2.842-.97-5.247-2.92-7.215-1.94-1.968-4.3-2.98-7.07-3.034-2.78-.055-5.13.956-7.08 3.034-1.95 2.077-2.92 4.482-2.92 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={1145} y={189.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M1156.23 209.501l4.22-9.456h-5.52v-1.744h7.63v1.456l-4.2 9.744h-2.13z"
        fill="#0FBB9B"
      />
      <g filter="url(#filter20_d_770_7691)">
        <path
          d="M1176.01 241.375c0-2.787.67-5.357 2-7.707 1.34-2.351 3.16-4.21 5.48-5.576a14.841 14.841 0 017.52-2.091c2.69-.028 5.19.669 7.51 2.091 2.32 1.421 4.15 3.28 5.48 5.576s2 4.865 2 7.707c0 1.176-.28 2.542-.84 4.1a35.185 35.185 0 01-2.12 4.715 57.19 57.19 0 01-2.92 4.838c-1.09 1.64-2.16 3.171-3.2 4.592a84.293 84.293 0 01-2.91 3.772c-.91 1.093-1.63 1.982-2.16 2.665l-.84.943c-.22-.219-.5-.547-.84-.984-.35-.437-1.06-1.298-2.12-2.583a62.043 62.043 0 01-2.96-3.854 726.99 726.99 0 01-3.16-4.51c-1.2-1.722-2.18-3.362-2.96-4.92a80.896 80.896 0 01-2.12-4.633c-.64-1.53-.92-2.911-.84-4.141zm5 0c0 2.843.97 5.262 2.92 7.257 1.95 1.996 4.3 2.993 7.08 2.993 2.77 0 5.13-.997 7.07-2.993 1.95-1.995 2.92-4.414 2.92-7.257 0-2.842-.97-5.247-2.92-7.215-1.94-1.968-4.3-2.98-7.07-3.034-2.78-.055-5.13.956-7.08 3.034-1.95 2.077-2.92 4.482-2.92 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={1177} y={226.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M1187.3 246.501v-8.864l-1.84.432v-1.568l2.71-1.2h1.26v11.2h-2.13zm5.52 0v-8.864l-1.84.432v-1.568l2.7-1.2h1.27v11.2h-2.13z"
        fill="#0FBB9B"
      />
      <g filter="url(#filter21_d_770_7691)">
        <path
          d="M489.013 379.375c0-2.787.667-5.357 1.999-7.707a14.875 14.875 0 015.478-5.576 14.85 14.85 0 017.517-2.091c2.692-.028 5.197.669 7.516 2.091 2.319 1.421 4.145 3.28 5.478 5.576 1.333 2.296 1.999 4.865 1.999 7.707 0 1.176-.28 2.542-.84 4.1a34.678 34.678 0 01-2.119 4.715 60.197 60.197 0 01-2.918 4.838 127.706 127.706 0 01-3.199 4.592 79.146 79.146 0 01-2.919 3.772 121.745 121.745 0 00-2.159 2.665l-.839.943a13.529 13.529 0 01-.84-.984 185.462 185.462 0 00-2.119-2.583 62.41 62.41 0 01-2.959-3.854 895.934 895.934 0 01-3.158-4.51c-1.2-1.722-2.186-3.362-2.959-4.92a78.862 78.862 0 01-2.119-4.633c-.64-1.53-.92-2.911-.84-4.141zm4.998 0c0 2.843.973 5.262 2.919 7.257 1.946 1.996 4.305 2.993 7.077 2.993 2.772 0 5.131-.997 7.077-2.993 1.945-1.995 2.918-4.414 2.918-7.257 0-2.842-.973-5.247-2.918-7.215-1.946-1.968-4.305-2.98-7.077-3.034-2.772-.055-5.131.956-7.077 3.034-1.946 2.077-2.919 4.482-2.919 7.215z"
          fill="#fff"
        />
      </g>
      <rect x={490} y={364.001} width={28} height={30} rx={14} fill="#fff" />
      <path
        d="M498.304 384.501v-8.864l-1.84.432v-1.568l2.704-1.2h1.264v11.2h-2.128zm7.964.192c-.736 0-1.403-.128-2-.384a3.322 3.322 0 01-1.44-1.216c-.363-.544-.555-1.227-.576-2.048h2.016c.01.544.186 1.003.528 1.376.352.363.842.544 1.472.544.597 0 1.056-.165 1.376-.496.32-.331.48-.747.48-1.248 0-.587-.214-1.029-.64-1.328-.416-.309-.955-.464-1.616-.464h-.832v-1.68h.848c.544 0 .997-.128 1.36-.384.362-.256.544-.635.544-1.136 0-.416-.139-.747-.416-.992-.267-.256-.64-.384-1.12-.384-.523 0-.934.155-1.232.464a1.803 1.803 0 00-.48 1.136h-2c.042-1.035.4-1.851 1.072-2.448.682-.597 1.562-.896 2.64-.896.768 0 1.413.139 1.936.416.533.267.933.624 1.2 1.072.277.448.416.944.416 1.488 0 .629-.176 1.163-.528 1.6a2.503 2.503 0 01-1.28.864 2.568 2.568 0 011.536.928c.394.48.592 1.088.592 1.824a3.31 3.31 0 01-.448 1.696c-.299.512-.736.923-1.312 1.232-.566.309-1.264.464-2.096.464z"
        fill="#4F0696"
      />
      <defs>
        <filter
          id="filter0_d_770_7691"
          x={111.2}
          y={100.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_770_7691"
          x={261.2}
          y={215.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_770_7691"
          x={346.2}
          y={94.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_770_7691"
          x={336.2}
          y={35.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_770_7691"
          x={450.2}
          y={35.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_770_7691"
          x={620.2}
          y={62.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_770_7691"
          x={720.2}
          y={435.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_770_7691"
          x={1179.2}
          y={377.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_770_7691"
          x={590.2}
          y={195.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_d_770_7691"
          x={883.2}
          y={191.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_d_770_7691"
          x={1228.2}
          y={298.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_d_770_7691"
          x={1097.2}
          y={211.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter12_d_770_7691"
          x={830.2}
          y={21.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter13_d_770_7691"
          x={884.2}
          y={30.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter14_d_770_7691"
          x={959.2}
          y={15.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter15_d_770_7691"
          x={1082.2}
          y={4.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter16_d_770_7691"
          x={1153.2}
          y={30.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter17_d_770_7691"
          x={995.2}
          y={95.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter18_d_770_7691"
          x={930.2}
          y={61.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter19_d_770_7691"
          x={1135.2}
          y={187.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter20_d_770_7691"
          x={1167.2}
          y={224.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
        <filter
          id="filter21_d_770_7691"
          x={480.2}
          y={362.2}
          width={47.6}
          height={58.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_770_7691"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_770_7691"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
   </div>
    {/* <div style={{ position: 'relative' }}>
      <img
        src="https://res.cloudinary.com/ahbranding/image/upload/v1710241165/AHbranding/Campus_May_2022_resized_to_1600_x_770_pkidk1.jpg"
        alt="Campus Map"
        className='w-full rounded-xl'
      />

      <MapPin
        position={{ x: 36, y: 46 }} // Adjust coordinates as per your map
        onClick={() => handlePinClick({ x: 36, y: 46 }, 'Rugby/Football/Cricket fields', 'Our field has first class rugby, football and cricket pitches along with cricket nets and spectator seating.', 'https://resources.finalsite.net/images/f_auto,q_auto/v1654778667/dubaicollegeorg/p5i5yu5zcpx7k09ywrpf/RugbyFootballCricketfields.png')}
      />


<MapPin
        position={{ x: 205, y: 46 }} // Adjust coordinates as per your map
        onClick={() => handlePinClick({ x: 205, y: 46 }, 'English Block', 'Our English Departments has its own stand-alone building, with an excellent range of written and audio-visual resources.  It also houses our Harkness Room.', 'https://resources.finalsite.net/images/f_auto,q_auto/v1654778437/dubaicollegeorg/bw3msxfmuydfyt0a0n4l/EnglishBlock.png')}
      />

      {popoverContent && (
        <MapPopover
          onClose={handleClosePopover}
          content={popoverContent}
          cat={popoverContentCat}
          img={popoverContentImg}
          style={{
            position: 'absolute',
            top: popoverPosition.y,
            left: popoverPosition.x,
          }}
        />
      )}
    </div> */}

    </>
  );
};

export default CampusMap;
