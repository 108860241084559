import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Tab } from "@headlessui/react";
import AuthContext from "../context/AuthContext";

import { toast, Toaster } from "react-hot-toast";
import moment from "moment";

import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MobileBottom from "../components/MobileBottom";
import { useLanguage } from "../components/language";

const Notifications = () => {
  const [loading, setLoading] = useState(true);
  let { user } = useContext(AuthContext);
  const navigate = useNavigate();
  let userID = user["user_id"];
  const [profile, setProfile] = useState();
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const { t, changeLanguage, lanArabic } = useLanguage();
  // Function to filter notifications based on their type

  useEffect(() => {
    if (!user && !userID) {
      console.log("Navigating to login due to lack of user or userID");
      window.location.replace("/login");
    }
  }, [user, userID, navigate]);

  const filterNotificationsByType = (type) => {
    const filtered = notifications.filter(
      (notification) => notification.type === type
    );
    setFilteredNotifications(filtered);
  };
  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${userID}`
        );

        // parse the data in json
        let data = await response.json();

        setProfile(data);
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };
    getProfile();
  }, []);

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Fetch notifications data from your Django backend
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_notifications/`)
      .then((response) => {
        setNotifications(response.data);
        setFilteredNotifications(response.data); // Initially set to all notifications
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, []);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let { logoutUser } = useContext(AuthContext);

  const markAsRead = (notificationId) => {
    // Send a POST request to mark the notification as read
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/mark_notification_as_read/`, {
        id: notificationId,
      })
      .then((response) => {
        // Update the notification state to reflect the change
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === notificationId
              ? { ...notification, is_read: 1 }
              : notification
          )
        );
      })
      .catch((error) => {
        console.error("Error marking notification as read:", error);
      });
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

            <Sidebar />

            {isSidebarVisible && <SideInnerSM />}

            <main
              className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${
                lanArabic ? "xl:mr-68" : "md:ml-[17rem]"
              } rounded-xl`}
            >
              {/* Navbar */}
              <Navbar toggleSidebar={toggleSidebar} />

              {/* end Navbar */}
              {/* cards */}
              <div className="w-full px-6 py-6 mx-auto">
                <div className="bg-white rounded-lg">
                  <div className="flex flex-col md:flex-row items-start">
                    <Tab.Group>
                      <Tab.List className="flex justify-start w-full md:w-max md:flex-col gap-4 px-8 py-4 NotifTabs">
                        <Tab onClick={() => filterNotificationsByType("")}>
                          <span className="btn btn-sm bg-transparent border-0 hover:bg-transparent">Show All</span>
                        </Tab>
                        <Tab onClick={() => filterNotificationsByType("Quiz")}>
                        <span className="btn btn-sm bg-transparent border-0 hover:bg-transparent">📝 Quizzes</span>
                        </Tab>
                        <Tab onClick={() => filterNotificationsByType("Video")}>
                        <span className="btn btn-sm bg-transparent border-0 hover:bg-transparent">▶️ Videos</span>
                        </Tab>
                        <Tab
                          onClick={() =>
                            filterNotificationsByType("External Link")
                          }
                        >
                          <span className="btn btn-sm bg-transparent border-0 hover:bg-transparent">🌐 Resources</span>
                        </Tab>
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel>
                          <div className="w-full flex justify-center">
                            {notifications.length === 0 ? (
                              <div className="flex flex-col items-center justify-center h-full">
                                <p className="mt-24">No notifications found</p>
                              </div>
                            ) : (
                              <div className="grid grid-cols-1 md:grid-cols-2">
                                {notifications
                                  .slice()
                                  .reverse()
                                  .map((notification) => (
                                    <Link
                                      to={
                                        notification.type === "Quiz"
                                          ? `/challenge/${notification.quiz_id}`
                                          : `/notification_detail/${notification.id}`
                                      }
                                    >
                                      <div
                                        key={notification.id}
                                        className="mx-4 my-2"
                                        onClick={() =>
                                          markAsRead(notification.id)
                                        }
                                      >
                                        <div
                                          className={`flex items-start justify-between max-w-sm md:w-full p-5 space-x-4 rounded-md ${
                                            notification.is_read
                                              ? "bg-gray-50"
                                              : "bg-red-50"
                                          } text-gray-800 items-center`}
                                        >
                                          {(() => {
                                            if (notification.type === "Quiz") {
                                              return (
                                                <button className="btn btn-circle bg-amber-100 border-none btn-sm">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="flex-shrink-0 w-5 h-5 my-1 text-amber-500"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M5.166 2.621v.858c-1.035.148-2.059.33-3.071.543a.75.75 0 00-.584.859 6.753 6.753 0 006.138 5.6 6.73 6.73 0 002.743 1.346A6.707 6.707 0 019.279 15H8.54c-1.036 0-1.875.84-1.875 1.875V19.5h-.75a2.25 2.25 0 00-2.25 2.25c0 .414.336.75.75.75h15a.75.75 0 00.75-.75 2.25 2.25 0 00-2.25-2.25h-.75v-2.625c0-1.036-.84-1.875-1.875-1.875h-.739a6.706 6.706 0 01-1.112-3.173 6.73 6.73 0 002.743-1.347 6.753 6.753 0 006.139-5.6.75.75 0 00-.585-.858 47.077 47.077 0 00-3.07-.543V2.62a.75.75 0 00-.658-.744 49.22 49.22 0 00-6.093-.377c-2.063 0-4.096.128-6.093.377a.75.75 0 00-.657.744zm0 2.629c0 1.196.312 2.32.857 3.294A5.266 5.266 0 013.16 5.337a45.6 45.6 0 012.006-.343v.256zm13.5 0v-.256c.674.1 1.343.214 2.006.343a5.265 5.265 0 01-2.863 3.207a6.72 6.72 0 00.857-3.294z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>
                                                </button>
                                              );
                                            } else if (
                                              notification.type ===
                                              "External Link"
                                            ) {
                                              return (
                                                <button className="btn btn-circle bg-blue-100 border-none btn-sm">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="flex-shrink-0 w-5 h-5 my-1 text-blue-500"
                                                  >
                                                    <path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003a12.754 12.754 0 01-4.339 2.708a18.991 18.991 0 01-.214 4.772a17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347c-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647a17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774a12.753 12.753 0 01-4.34-2.708a9.711 9.711 0 00-.944 5.004a17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817a18.64 18.64 0 001.988-4.718a18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718a9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986a11.267 11.267 0 01-3.746 2.504a18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353c-.968 0-1.908-.122-2.805-.353a17.151 17.151 0 012.805-7.121zM10.122 2.43a18.629 18.629 0 00-2.37 6.49a11.266 11.266 0 01-3.746-2.504a9.754 9.754 0 016.116-3.985z" />
                                                  </svg>
                                                </button>
                                              );
                                            } else if (
                                              notification.type === "Video"
                                            ) {
                                              return (
                                                <button className="btn btn-circle bg-indigo-100 border-none btn-sm">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="currentColor"
                                                    className="flex-shrink-0 w-5 h-5 my-1 text-indigo-500"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>
                                                </button>
                                              );
                                            }
                                          })()}

                                          <div className="flex flex-col flex-1 px-2 space-y-1">
                                            <p className="text-base font-semibold m-0">
                                              {notification.title}
                                            </p>
                                            <span className="text-sm text-gray-600">
                                              {notification.description.length >
                                              15
                                                ? `${notification.description.substring(
                                                    0,
                                                    15
                                                  )}...`
                                                : notification.description}
                                            </span>
                                          </div>

                                          <div className="text-xs md:text-sm">
                                            {moment(
                                              notification.post_date,
                                              "YYYY-MM-DD"
                                            ).fromNow()}
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  ))}
                              </div>
                            )}
                          </div>
                        </Tab.Panel>
                        <Tab.Panel>
                          {/* Render filtered quizzes notifications */}
                          <div className="grid grid-cols-1 md:grid-cols-2">
                            {filteredNotifications.length === 0 ? (
                              <div className="flex flex-col items-center justify-center h-full">
                                <p className="mt-24">
                                  No quizzes notifications found
                                </p>
                              </div>
                            ) : (
                              <div>
                                {filteredNotifications
                                  .slice()
                                  .reverse()
                                  .map((notification) => {
                                    if (notification.type === "Quiz") {
                                      return (
                                        <Link
                                          to={`/notification_detail/${notification.id}`}
                                        >
                                          <div
                                            key={notification.id}
                                            className="mx-4 my-2"
                                            onClick={() =>
                                              markAsRead(notification.id)
                                            }
                                          >
                                            <div
                                              className={`flex items-start justify-between max-w-sm p-5 space-x-4 rounded-md ${
                                                notification.is_read
                                                  ? "bg-gray-50"
                                                  : "bg-red-50"
                                              } text-gray-800 items-center`}
                                            >
                                              <button className="btn btn-circle bg-amber-100 border-none btn-sm">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="currentColor"
                                                  className="flex-shrink-0 w-5 h-5 my-1 text-amber-500"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M5.166 2.621v.858c-1.035.148-2.059.33-3.071.543a.75.75 0 00-.584.859 6.753 6.753 0 006.138 5.6 6.73 6.73 0 002.743 1.346A6.707 6.707 0 019.279 15H8.54c-1.036 0-1.875.84-1.875 1.875V19.5h-.75a2.25 2.25 0 00-2.25 2.25c0 .414.336.75.75.75h15a.75.75 0 00.75-.75 2.25 2.25 0 00-2.25-2.25h-.75v-2.625c0-1.036-.84-1.875-1.875-1.875h-.739a6.706 6.706 0 01-1.112-3.173 6.73 6.73 0 002.743-1.347 6.753 6.753 0 006.139-5.6.75.75 0 00-.585-.858 47.077 47.077 0 00-3.07-.543V2.62a.75.75 0 00-.658-.744 49.22 49.22 0 00-6.093-.377c-2.063 0-4.096.128-6.093.377a.75.75 0 00-.657.744zm0 2.629c0 1.196.312 2.32.857 3.294A5.266 5.266 0 013.16 5.337a45.6 45.6 0 012.006-.343v.256zm13.5 0v-.256c.674.1 1.343.214 2.006.343a5.265 5.265 0 01-2.863 3.207a6.72 6.72 0 00.857-3.294z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </button>

                                              <div className="flex flex-col flex-1 px-2 space-y-1">
                                                <p className="text-base font-semibold m-0">
                                                  {notification.title}
                                                </p>
                                                <span className="text-sm text-gray-600">
                                                  {notification.description
                                                    .length > 15
                                                    ? `${notification.description.substring(
                                                        0,
                                                        15
                                                      )}...`
                                                    : notification.description}
                                                </span>
                                              </div>

                                              <div>
                                                {moment(
                                                  notification.post_date,
                                                  "YYYY-MM-DD"
                                                ).fromNow()}
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      );
                                    }
                                    return null;
                                  })}
                              </div>
                            )}
                          </div>
                        </Tab.Panel>
                        <Tab.Panel>
                          {/* Render filtered video notifications */}
                          <div className="grid grid-cols-1 md:grid-cols-2">
                            {filteredNotifications.length === 0 ? (
                              <div className="flex flex-col items-center justify-center h-full">
                                <p className="mt-24">
                                  No Video notifications found
                                </p>
                              </div>
                            ) : (
                              <div>
                                {filteredNotifications
                                  .slice()
                                  .reverse()
                                  .map((notification) => {
                                    if (notification.type === "Video") {
                                      return (
                                        <Link
                                          to={`/notification_detail/${notification.id}`}
                                        >
                                          <div
                                            key={notification.id}
                                            className="mx-4 my-2"
                                            onClick={() =>
                                              markAsRead(notification.id)
                                            }
                                          >
                                            <div
                                              className={`flex items-start justify-between max-w-sm p-5 space-x-4 rounded-md ${
                                                notification.is_read
                                                  ? "bg-gray-50"
                                                  : "bg-red-50"
                                              } text-gray-800 items-center`}
                                            >
                                              <button className="btn btn-circle bg-indigo-100 border-none btn-sm">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="currentColor"
                                                  className="flex-shrink-0 w-5 h-5 my-1 text-indigo-500"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </button>

                                              <div className="flex flex-col flex-1 px-2 space-y-1">
                                                <p className="text-base font-semibold m-0">
                                                  {notification.title}
                                                </p>
                                                <span className="text-sm text-gray-600">
                                                  {notification.description
                                                    .length > 15
                                                    ? `${notification.description.substring(
                                                        0,
                                                        15
                                                      )}...`
                                                    : notification.description}
                                                </span>
                                              </div>

                                              <div>
                                                {moment(
                                                  notification.post_date,
                                                  "YYYY-MM-DD"
                                                ).fromNow()}
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      );
                                    }
                                    return null;
                                  })}
                              </div>
                            )}
                          </div>
                        </Tab.Panel>

                        <Tab.Panel>
                          {/* Render filtered video notifications */}
                          <div className="grid grid-cols-1 md:grid-cols-2">
                            {filteredNotifications.length === 0 ? (
                              <div className="flex flex-col items-center justify-center h-full">
                                <p className="mt-24">
                                  No external resource notifications found
                                </p>
                              </div>
                            ) : (
                              <div>
                                {filteredNotifications
                                  .slice()
                                  .reverse()
                                  .map((notification) => {
                                    if (notification.type === "External Link") {
                                      return (
                                        <Link
                                          to={`/notification_detail/${notification.id}`}
                                        >
                                          <div
                                            key={notification.id}
                                            className="mx-4 my-2"
                                            onClick={() =>
                                              markAsRead(notification.id)
                                            }
                                          >
                                            <div
                                              className={`flex items-start justify-between max-w-sm p-5 space-x-4 rounded-md ${
                                                notification.is_read
                                                  ? "bg-gray-50"
                                                  : "bg-red-50"
                                              } text-gray-800 items-center`}
                                            >
                                              <button className="btn btn-circle bg-blue-100 border-none btn-sm">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="currentColor"
                                                  className="flex-shrink-0 w-5 h-5 my-1 text-blue-500"
                                                >
                                                  <path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003a12.754 12.754 0 01-4.339 2.708a18.991 18.991 0 01-.214 4.772a17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347c-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647a17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774a12.753 12.753 0 01-4.34-2.708a9.711 9.711 0 00-.944 5.004a17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817a18.64 18.64 0 001.988-4.718a18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718a9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986a11.267 11.267 0 01-3.746 2.504a18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353c-.968 0-1.908-.122-2.805-.353a17.151 17.151 0 012.805-7.121zM10.122 2.43a18.629 18.629 0 00-2.37 6.49a11.266 11.266 0 01-3.746-2.504a9.754 9.754 0 016.116-3.985z" />
                                                </svg>
                                              </button>

                                              <div className="flex flex-col flex-1 px-2 space-y-1">
                                                <p className="text-base font-semibold m-0">
                                                  {notification.title}
                                                </p>
                                                <span className="text-sm text-gray-600">
                                                  {notification.description
                                                    .length > 15
                                                    ? `${notification.description.substring(
                                                        0,
                                                        15
                                                      )}...`
                                                    : notification.description}
                                                </span>
                                              </div>

                                              <div>
                                                {moment(
                                                  notification.post_date,
                                                  "YYYY-MM-DD"
                                                ).fromNow()}
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      );
                                    }
                                    return null;
                                  })}
                              </div>
                            )}
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      <MobileBottom />
    </div>
  );
};

export default Notifications;
