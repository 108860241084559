import React, { useState, useEffect, useContext } from "react";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";
import axios from "axios";

import randomColor from "randomcolor";
// import toast from "react-hot-toast";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import AuthContext from "../context/AuthContext";
import AddEventForm from "../pages/AddEventForm";
import { Link } from "react-router-dom";
function AddEventBar() {
  const localizer = globalizeLocalizer(globalize);
  const id = 2;
  const [list, setList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showEdit, setShowEdit] = useState(false);



  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const storedColors = localStorage.getItem("clubColors");
    if (storedColors) {
      setClubColors(JSON.parse(storedColors));
    }
  }, []);

  let { user } = useContext(AuthContext);
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);

  let url = '';
  if(user['role']==='Student'){
    url = 'profile';
  } else if(user['role']==='Network Admin'){
    url = 'network_profile';
  } else if(user['role']==='School Admin'){
    url = 'school_profile';
  } else if(user['role']==='Staff'){
    url = 'staff';
  }


  let urlCalendar = '';
  if(user['role']==='Network Admin'){
    urlCalendar = 'get_network_schedules';
  } else if(user['role']==='School Admin'){
    urlCalendar = 'get_school_schedules';
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
        // Fetch schedule data after fetching network profile
        if(user['role']==='Network Admin'){
          get_all_schedules(data.network_id); // Pass network ID to fetch schedule
        } else if(user['role']==='School Admin'){
          get_all_schedules(data.school_id); // Pass network ID to fetch schedule
        }
        
      } catch (err) {
        //console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };
  
    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);
  
    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  
  const get_all_schedules = async (networkId) => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${urlCalendar}/${networkId}/`)
      .then((res) => {
        setList(res.data);
        console.warn("SCHEDULE: ", res.data);
      })
      .catch((err) => {
        console.error("ERROR SCHEDULE: ", err);
        console.log(err);
      });
  };
  

  const generateClubColors = (events) => {
    // Get existing colors from localStorage
    const storedColors = localStorage.getItem("clubColors");
    let colors = storedColors ? JSON.parse(storedColors) : {};

    events.forEach((event) => {
      if (!colors[event.club]) {
        colors[event.club] = randomColor();
      }
    });

    // Save updated colors to localStorage
    localStorage.setItem("clubColors", JSON.stringify(colors));

    return colors;
  };

  useEffect(() => {
    if (list.length > 0) {
      const colors = generateClubColors(list);
      setClubColors(colors);
    }
  }, [list]);

  const [clubColors, setClubColors] = useState({});

  function mergeAdjacentEvents(events) {
    // Sort events based on start_date
    events.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    let mergedEvents = [];
    let currentEvent = null;

    for (let event of events) {
      if (!currentEvent) {
        currentEvent = { ...event };
      } else {
        const currentEndDate = new Date(currentEvent.end_date);
        const eventStartDate = new Date(event.start_date);

        if (currentEndDate >= eventStartDate) {
          // Merge events
          currentEvent.end_date = event.end_date;
        } else {
          // Push current merged event
          mergedEvents.push(currentEvent);
          // Start a new merged event
          currentEvent = { ...event };
        }
      }
    }

    // Push the last merged event
    if (currentEvent) {
      mergedEvents.push(currentEvent);
    }

    // Format date strings in ISO format
    mergedEvents.forEach((event) => {
      event.start_date = event.start_date.slice(0, 19);
      event.end_date = event.end_date.slice(0, 19);
    });

    return mergedEvents;
  }

  const mergedEvents = mergeAdjacentEvents(list);

  // Generate random colors for each club
  const newData = mergedEvents?.map((item) => {
    const id = item?.id;
    const title = item?.name;
    const start = new Date(item?.start_date);
    const end = new Date(item?.end_date);
    const club = item.club;
    return { id, title, start, end, club };
  });

  const handleDeleteEvent = (id) => {
    // Update the events array after deleting a schedule
    setList(list.filter((event) => event.id !== id));
  };

  const handleEventClick = (event) => {
    // Set the selected event when an event is clicked
    setSelectedEvent(event);
    console.warn("EVENT", event);
  };

  const handleClosePopup = () => {
    // Close the popup by resetting the selected event
    setSelectedEvent(null);
  };

  const formatDateString = (date) => {
    // Format the date string to "Wed. Feb 21, 2024 (6:00 PM)" format
    return date.toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  // const handleDelete = (id) => {
  //   axios
  //     .delete(`${process.env.REACT_APP_API_KEY}/api/delete_schedule/${id}/`)
  //     .then((res) => {
  //       toast.success("Schedule Deleted Successfully!");
  //       get_all_schedules();
  //       handleClosePopup();
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong!");
  //       handleClosePopup();
  //       console.log(err);
  //     });
  // };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this schedule?"
    );
    if (confirmDelete) {
      axios
        .delete(`${process.env.REACT_APP_API_KEY}/api/delete_schedule/${id}/`)
        .then((res) => {
          toast.success("Schedule Deleted Successfully!");
          get_all_schedules();
          handleClosePopup();
        })
        .catch((err) => {
          toast.error("Something went wrong!");
          handleClosePopup();
          console.log(err);
        });
    }
  };

  const handleEdit = (id) => {
    axios
      .put(`${process.env.REACT_APP_API_KEY}/api/update_schedule/${id}/`, {
        start_date: startDate,
        end_date: endDate,
      })
      .then((res) => {
        toast.success("Schedule Updated Successfully!");
        setStartDate(null);
        setEndDate(null);
        setShowEdit(false);
        handleClosePopup();
      })
      .catch((err) => {
        toast.error("Something went wrong!");

        console.log(err);
      });
  };

  const toggleEdit = () => {
    setShowEdit((prevState) => !prevState);
  };

  const formatDateWithOffset = (dateString) => {
    const date = new Date(dateString);
    const offset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(offset / 60);
    const offsetMinutes = offset % 60;
    const offsetString = `GMT${offsetHours < 0 ? "-" : "+"}${Math.abs(
      offsetHours
    )
      .toString()
      .padStart(2, "0")}${Math.abs(offsetMinutes).toString().padStart(2, "0")}`;
    return date.toISOString().slice(0, 16) + offsetString;
  };

  return (
    <>
      {/* <div
          role="alert"
          className="alert flex mb-8 justify-between bg-white shadow-xl mt-8"
        >
          <p className="m-0">Add an event / session to your calendar</p>
          <button className="btn btn-sm btn-primary" onClick={openModal}>
            Add Event
          </button>
        </div> */}


        <Link to={`/add_event`} className="btn btn-primary" >
            Add Event
          </Link>


      <Toaster position="bottom-center" />
    {/* // </AdminLayout> */}
    </>
  );
}

export default AddEventBar;
