import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import axios from "axios";
import StudentCouncilDetails from "./StudentCouncilDetails";

function StudentCouncils() {
  const [councils, setCouncils] = useState([]);

  useEffect(() => {
    fetchCouncils();
  }, []);

  const fetchCouncils = async () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_student_council/`)
      .then((response) => {
        const data = response.data;
        setCouncils(data);
      })
      .catch((error) => {});
  };

  return (
    <AdminLayout>


    <StudentCouncilDetails councils={councils}/>
     
    </AdminLayout>
  );
}

export default StudentCouncils;
