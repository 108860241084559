import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function EventList() {


    const clubs = [
       
        {
            "id": 21,
            "school_name": "TCSI Dubai",
            "faculty_advisors": [
                "Ms. Zakir",
                "Ms. Lina",
                "Dr.",
                "Ms"
            ],
            "name": "Big Write",
            "description": null,
            "start_date": "2024-05-19T11:15:00Z",
            "end_date": "2024-05-26T11:15:00Z",
            "venue": "Main Auditorium",
            "external_event": false,
            "status": null,
            "eventType": null,
            "club": null,
            "network": 4,
            "school": 5
        },
        {
            "id": 22,
            "school_name": "TCSI Dubai",
            "faculty_advisors": [
                "Ms. Zakir",
                "Ms. Lina",
                "Dr.",
                "Ms"
            ],
            "name": "Spelling Bee",
            "description": null,
            "start_date": "2024-05-27T11:18:00Z",
            "end_date": "2024-06-03T15:18:00Z",
            "venue": "TBD",
            "external_event": true,
            "status": null,
            "eventType": null,
            "club": null,
            "network": 4,
            "school": 5
        },
        {
            "id": 23,
            "school_name": "TCSI Dubai",
            "faculty_advisors": [
                "Ms. Zakir",
                "Ms. Lina",
                "Dr.",
                "Ms"
            ],
            "name": "Mental Math",
            "description": null,
            "start_date": "2024-06-03T12:18:00Z",
            "end_date": "2024-06-06T16:19:00Z",
            "venue": "TBD",
            "external_event": true,
            "status": null,
            "eventType": null,
            "club": null,
            "network": 4,
            "school": 5
        }
    ]


  return (
    <div className="overflow-y-scroll max-h-[20rem] p-3 mt-8">
    {clubs.map((item, index) => (
        <div className="flex justify-between p-5 my-2 gap-3 items-center shadow-xl w-full rounded-md" key={item.id}>

          <h2 className="text-black mb-0 font-semibold text-xl">{item.name}</h2>
          <Link to={`/event_details/${item.id}`} className="btn btn-sm btn-ghost ">
            View details
          </Link>

      </div>
      ))}
      
    </div>
  );
}

export default EventList;
