import React from "react";

const ScheduleDisplay = ({ schedule, setSchedule }) => {
  const convertTo12HourFormat = (time) => {
    let [hours, minutes] = time.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
  };

  const handleDeleteSlot = (day, index) => {
    const updatedDaySlots = schedule[day].filter((_, i) => i !== index);
    setSchedule({
      ...schedule,
      [day]: updatedDaySlots,
    });
  };

  return (
    <div className="w-8/12">
      <h3 className="text-base font-semibold">Schedule</h3>
      <div>
        <div className="bg-secondary p-4 rounded-xl max-h-[20rem] overflow-y-scroll">
          {Object.keys(schedule).map((day) => (
            <div key={day} className="bg-white rounded-xl p-4 my-4">
              <h4 className="text-base font-semibold">{day}</h4>
              <ul className="flex flex-row flex-wrap gap-3 items-center max-h-32 overflow-y-auto">
                {schedule[day].map((slot, index) => (
                  <li key={index} className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs bg-gray-100 text-gray-800 rounded-md darki:bg-neutral-500/20 darki:text-neutral-400">
                    {convertTo12HourFormat(slot.startTime)} to{" "}
                    {convertTo12HourFormat(slot.endTime)}
                    <button
                      onClick={() => handleDeleteSlot(day, index)}
                      className="btn btn-xs hover:btn-error ml-2"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
  <path fill-rule="evenodd" d="M5 3.25V4H2.75a.75.75 0 0 0 0 1.5h.3l.815 8.15A1.5 1.5 0 0 0 5.357 15h5.285a1.5 1.5 0 0 0 1.493-1.35l.815-8.15h.3a.75.75 0 0 0 0-1.5H11v-.75A2.25 2.25 0 0 0 8.75 1h-1.5A2.25 2.25 0 0 0 5 3.25Zm2.25-.75a.75.75 0 0 0-.75.75V4h3v-.75a.75.75 0 0 0-.75-.75h-1.5ZM6.05 6a.75.75 0 0 1 .787.713l.275 5.5a.75.75 0 0 1-1.498.075l-.275-5.5A.75.75 0 0 1 6.05 6Zm3.9 0a.75.75 0 0 1 .712.787l-.275 5.5a.75.75 0 0 1-1.498-.075l.275-5.5a.75.75 0 0 1 .786-.711Z" clip-rule="evenodd" />
</svg>

                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleDisplay;
