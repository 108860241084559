import React, { useState, useEffect } from 'react';
import AdminLayout from './AdminLayout';
import SchoolCalendar from './SchoolCalendar';

function AdminCalendar() {

    

    return (
       <AdminLayout>
         <SchoolCalendar />
       </AdminLayout>
    )
}

export default AdminCalendar;