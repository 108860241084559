import React, { useState, useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import SideInner from "../components/SideInner";
import Navbar from "../components/Navbar";
import MobileBottom from "../components/MobileBottom";
import { useLanguage } from "../components/language";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useRef } from "react";
import SideInnerSM from "../components/SideInnerSM";


const BadgesList = () => {
  const [studentBadges, setStudentBadges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageURLs, setImageURLs] = useState([]);

  const { user } = useContext(AuthContext);
  const userID = user["user_id"];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  const fetchStudentBadges = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/api/get_badges/`
      );
      const filteredBadges = filterLatestUserBadges(response.data, userID);
      setStudentBadges(filteredBadges);

      // const badgeImageURLs = filteredBadges.map(badge => badge.technology_badgeURL);
      // setImageURLs(badgeImageURLs);

      const badgeImageURLs = filteredBadges.reduce((urls, badge) => {
        if (badge.technology_badgeURL) urls.push(badge.technology_badgeURL);
        if (badge.leadership_badgeURL) urls.push(badge.leadership_badgeURL);
        if (badge.social_responsibility_badgeURL) urls.push(badge.social_responsibility_badgeURL);
        if (badge.interpersonal_skills_badgeURL) urls.push(badge.interpersonal_skills_badgeURL);
        if (badge.critical_thinking_badgeURL) urls.push(badge.critical_thinking_badgeURL);
        if (badge.physical_fitness_badgeURL) urls.push(badge.physical_fitness_badgeURL);

        return urls;
      }, []);

      setImageURLs(badgeImageURLs);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchStudentBadges();
  }, [userID]);

  const filterLatestUserBadges = (data, userID) => {
    const latestBadges = {};

    data.forEach((badge) => {
      if (badge.userid === userID) {
        if (!latestBadges[badge.technology_tier]) {
          latestBadges[badge.technology_tier] = badge;
        } else if (
          badge.date_created > latestBadges[badge.technology_tier].date_created
        ) {
          latestBadges[badge.technology_tier] = badge;
        }
      }
    });

    const latestBadgesArray = Object.values(latestBadges);

    return latestBadgesArray;
  };

  console.log("DATA: ", studentBadges);
  console.log("Image URLs: ", imageURLs);
  const { t, changeLanguage, lanArabic } = useLanguage();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };


  const sliderRef = useRef(null);
  const [startX, setStartX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the multiplier based on your preference
    sliderRef.current.scrollLeft -= walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div>
    <div>
      <div>
        <>
          <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

          <Sidebar />

          {isSidebarVisible && <SideInnerSM />}

          <main
            className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${
              lanArabic ? "xl:mr-68" : "md:ml-[17rem]"
            } rounded-xl`}
          >
            {/* Navbar */}
            <Navbar toggleSidebar={toggleSidebar} />

            {/* end Navbar */}
            {/* cards */}
            <div className="w-full px-6 py-6 mx-auto">
              <div className="bg-white rounded-lg">
                {loading ? (
                  <div className="flex items-center justify-center h-screen space-x-2">
	<div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
	<div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
	<div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
</div>
                ) : (
                  <div>
                    
                  {/* <div
                          className={`p-4 pb-0 rounded-t-4 ${
                            lanArabic ? "text-end" : ""
                          }`}
                        >
                          <h6 className="mb-2 ">
                            {t("My Badges")}
                          </h6>
                          <p className="text-sm capitalize leading-normal ">
                            {t(
                              "Explore the badges you've earned! Swipe right to view more and celebrate your achievements. "
                            )}
                          </p>
                        </div>

                      
                      <div className="p-6 ">
        <div className="carousel carousel-center rounded-xl bg-secondary p-4 space-x-4 w-full">
      {imageURLs.map((imageUrl, index) => (
        <div key={index} className="carousel-item justify-center m-0">
          <img src={imageUrl} className="w-8/12" alt={`Carousel Item ${index + 1}`} />
        </div>
      ))}
    </div>
        </div> */}


<div className="p-4 m-4">

<div
                          className={`rounded-t-4 ${
                            lanArabic ? "text-end" : ""
                          }`}
                        >
                          <h6 className="mb-2 ">
                            {t("My Badges")}
                          </h6>
                          <p className="text-sm capitalize leading-normal ">
                            {t(
                              "Explore the badges you've earned! Swipe right to view more and celebrate your achievements. "
                            )}
                          </p>
                        </div>

        <div
  ref={sliderRef}
  className="p-6 overflow-x-scroll bg-secondary-dim rounded-2xl relative"
  onMouseDown={handleMouseDown}
  onMouseMove={handleMouseMove}
  onMouseUp={handleMouseUp}
  onMouseLeave={handleMouseUp}
>
  <div className="flex space-x-4 justify-between">
    {/* Example array of image URLs */}
    {imageURLs.map((imageUrl, index) => (
      <img
        key={index}
        src={imageUrl}
        draggable="false"
        style={{ width: 'auto', height: '11rem', pointerEvents: 'none'}}
        className="badge-lilac p-2 rounded-xl border-4 border-primary"
        alt={`Badge ${index + 1}`}
      />
    ))}
  </div>
</div>


</div>
                      {/* All Badges Tab - Placeholder Content */}

                      
                     
                        <div
                          className={`p-4 pb-0 rounded-t-4 ${
                            lanArabic ? "text-end" : ""
                          }`}
                        >

<div
                          className={`rounded-t-4 ml-6 ${
                            lanArabic ? "text-end" : ""
                          }`}
                        >
                          <h6 className="mb-2 ">
                            {t("Explore all Badges")}
                          </h6>
                          <p className="text-sm capitalize leading-normal ">
                            {t(
                              "Discover all the badges that have yet to be earned."
                            )}
                          </p>
                        </div>


                          {studentBadges.map((studentBadge) => (
                            <div
                              key={studentBadge.id}
                              className="container grid grid-cols-2 sm:grid-cols-2 md:grid-cols-5 py-6 gap-6"
                            >
                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12"
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863806/AHbranding/Badges/cct%20bADGES/Group_34784405_nmsutb.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863805/AHbranding/Badges/cct%20bADGES/Group_34784404_e2p6kj.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863805/AHbranding/Badges/cct%20bADGES/Group_34784403_pbl3h4.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863804/AHbranding/Badges/cct%20bADGES/Group_34784402_whqbhg.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863803/AHbranding/Badges/cct%20bADGES/Group_34784400_f6asly.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863803/AHbranding/Badges/cct%20bADGES/Group_34784399_xnqmy6.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863802/AHbranding/Badges/cct%20bADGES/Group_34784398_aijv2s.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863802/AHbranding/Badges/cct%20bADGES/Group_34784397_ete5lo.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863802/AHbranding/Badges/cct%20bADGES/Group_34784395_hyk0yh.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863802/AHbranding/Badges/cct%20bADGES/Group_34784396_bxyrgm.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863801/AHbranding/Badges/cct%20bADGES/Group_34784394_kudof8.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863800/AHbranding/Badges/cct%20bADGES/Group_34784393_fo57zq.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863800/AHbranding/Badges/cct%20bADGES/Group_34784392_xb420b.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863800/AHbranding/Badges/cct%20bADGES/Group_34784391_vffumu.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863801/AHbranding/Badges/cct%20bADGES/Group_34784394_kudof8.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863800/AHbranding/Badges/cct%20bADGES/Group_34784390_hgub1r.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863799/AHbranding/Badges/cct%20bADGES/Group_34784389_u16iyf.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863799/AHbranding/Badges/cct%20bADGES/Group_34784383_gyvc3w.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863797/AHbranding/Badges/cct%20bADGES/Group_34784382_vvthpa.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863797/AHbranding/Badges/cct%20bADGES/Group_34784378_txtlew.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863797/AHbranding/Badges/cct%20bADGES/Group_34784377_puf9xy.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863797/AHbranding/Badges/cct%20bADGES/Group_34784376_ibmzgc.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863797/AHbranding/Badges/cct%20bADGES/Group_34784376_ibmzgc.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863797/AHbranding/Badges/cct%20bADGES/Group_34784372_pqhmio.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863797/AHbranding/Badges/cct%20bADGES/Group_34784375_tvjzon.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863796/AHbranding/Badges/cct%20bADGES/Group_34784357_uoz09d.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863796/AHbranding/Badges/cct%20bADGES/Group_34784357_uoz09d.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863796/AHbranding/Badges/cct%20bADGES/Group_34784406_poxhnc.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863796/AHbranding/Badges/cct%20bADGES/Group_34784373_gmmufl.png"
                                alt="Interpersonal Skills Badge"
                              />

                              <img
                                className="mx-auto block shadow-lg p-4 rounded-xl w-9/12 "
                                src="https://res.cloudinary.com/ahbranding/image/upload/v1698863796/AHbranding/Badges/cct%20bADGES/Group_34784348_yvhfvn.png"
                                alt="Interpersonal Skills Badge"
                              />
                            </div>
                          ))}
                        </div>
                 
              
                  </div>
                )}
              </div>
            </div>
          </main>
        </>
      </div>
    </div>

    <MobileBottom />
  </div>
  );
};

export default BadgesList;
