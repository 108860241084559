import React, { useState, useEffect, useContext } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import Modal from 'react-modal';

function InventoryWrapper() {
  const [profile, setProfile] = useState();
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);

  const userID = user["user_id"];

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  let role = user["role"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  const [events, setEvents] = useState([]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  const musicInventory = [
    {
      "instrumentName": "Electric Guitar",
      "quantity": 5,
      "quantityAllocated": 2,
      "quantityRemaining": 3,
      "image": "https://www.freyaguitars.ie/wp-content/uploads/2022/02/SG-Front-scaled.jpg.webp"
    },
    {
      "instrumentName": "Acoustic Guitar",
      "quantity": 8,
      "quantityAllocated": 3,
      "quantityRemaining": 5,
      "image": "https://m1.com.pk/wp-content/uploads/2021/11/SX-SD704E-Electric-Acoustic-Guitar.jpg"
    },
    {
      "instrumentName": "Violin",
      "quantity": 10,
      "quantityAllocated": 1,
      "quantityRemaining": 9,
      "image": "https://upload.wikimedia.org/wikipedia/commons/1/1b/Violin_VL100.png"
    },
    {
      "instrumentName": "Keyboard",
      "quantity": 6,
      "quantityAllocated": 4,
      "quantityRemaining": 2,
      "image": "https://i5.walmartimages.com/asr/fbc46d69-11ae-47fa-a2d5-3d4b5875404a.3345e1e59b1f810005cabc52f2df5ce4.jpeg"
    },
    {
      "instrumentName": "Drum Set",
      "quantity": 4,
      "quantityAllocated": 2,
      "quantityRemaining": 2,
      "image": "https://m1.com.pk/wp-content/uploads/2021/09/Maxtone-Drum-Kit-5pcs-Black.jpg"
    },
    {
      "instrumentName": "Microphone",
      "quantity": 12,
      "quantityAllocated": 0,
      "quantityRemaining": 12,
      "image": "https://products.shureweb.eu/shure_product_db/product_images/files/38e/6dd/d1-/original/9e053a36c73d696abcfe54c98d6cb463.webp"
    },
    {
      "instrumentName": "Amplifier",
      "quantity": 3,
      "quantityAllocated": 1,
      "quantityRemaining": 2,
      "image": "https://www.spacetv.co.za/wp-content/uploads/2021/02/SPA-30-Mixer-Amplifier-front.jpg"
    },
    {
      "instrumentName": "Trumpet",
      "quantity": 7,
      "quantityAllocated": 3,
      "quantityRemaining": 4,
      "image": "https://upload.wikimedia.org/wikipedia/commons/1/12/Yamaha_Trumpet_YTR-8335LA_crop.jpg"
    },
    {
      "instrumentName": "Saxophone",
      "quantity": 5,
      "quantityAllocated": 2,
      "quantityRemaining": 3,
      "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRasY9Wdyyyv70ES_TcGgpB2d7I-_ssddCHMg&usqp=CAU"
    },
    {
      "instrumentName": "Flute",
      "quantity": 9,
      "quantityAllocated": 0,
      "quantityRemaining": 9,
      "image": "https://m1.com.pk/wp-content/uploads/2016/11/Bamboo-flute.jpg"
    },
    {
      "instrumentName": "Cello",
      "quantity": 6,
      "quantityAllocated": 1,
      "quantityRemaining": 5,
      "image": "https://upload.wikimedia.org/wikipedia/commons/5/5f/Cello_front_side.png"
    },
    {
      "instrumentName": "Trombone",
      "quantity": 3,
      "quantityAllocated": 0,
      "quantityRemaining": 3,
      "image": "https://upload.wikimedia.org/wikipedia/commons/a/a2/Jean_Baptiste_soprano_trombone_%28white_bg%29.png"
    },
    {
      "instrumentName": "Harp",
      "quantity": 2,
      "quantityAllocated": 1,
      "quantityRemaining": 1,
      "image": "https://www.kaufmanmusiccenter.org/images/uploads/news/1LH-PRINCE-WILLIAM-CG-SPECIAL-INSTRUMENT-600x750.jpg"
    },
    {
      "instrumentName": "Mixer",
      "quantity": 4,
      "quantityAllocated": 2,
      "quantityRemaining": 2,
      "image": "https://assets-global.website-files.com/634e7aa49f5b025e1fd9e87b/65203ae7367d3fa4a81cbfe2_podcast-audio-mixer.jpeg"
    },
    {
      "instrumentName": "Turntable",
      "quantity": 3,
      "quantityAllocated": 0,
      "quantityRemaining": 3,
      "image": "https://www.tejar.pk/media/catalog/product/cache/3/image/9df78eab33525d08d6e5fb8d27136e95/a/u/audio-technica_at-lp140xp_direct-drive_professional_dj_turntable_-_1tejar_1.jpg"
    }
  ];
  

  const allocatedItemsCount = musicInventory.filter(item => !item.quantityAllocated).length;
  const remainingItemsCount = musicInventory.filter(item => !item.quantityRemaining).length;


  const ModalContent = ({ inventory }) => (
    <div>
      <h2>{inventory.instrumentName}</h2>
      <p>Quantity: {inventory.quantity}</p>
      <p>Allocated: {inventory.quantityAllocated}</p>
      <p>Remaining: {inventory.quantityRemaining}</p>
      {/* Add more details as needed */}
    </div>
  );

  return (
    <>
    <AdminLayout>

    
      <div>
        <>
          {/* Card Section */}
          <div className="max-w-[85rem] p-4 mx-auto">
            {/* Grid */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
              {/* Card */}
              <div className="flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 h-max">
                <div className="p-4 md:p-5 flex flex-row justify-between items-center">
                  <div className="flex items-center gap-x-2">
                    <p className="text-xs uppercase tracking-wide text-gray-500 m-0">
                      Total Items
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2">
                    <h3 className="text-xl sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                    {musicInventory.length}
                    </h3>
                  </div>
                </div>
              </div>
              {/* End Card */}
              {/* Card */}
              <div className="flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 h-max">
                <div className="p-4 md:p-5 flex flex-row justify-between items-center">
                  <div className="flex items-center gap-x-2">
                    <p className="text-xs uppercase tracking-wide text-gray-500 m-0">
                      Total Available
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2">
                    <h3 className="text-xl sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                    {remainingItemsCount}
                    </h3>
                  </div>
                </div>
              </div>
              {/* End Card */}
              {/* Card */}
              <div className="flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 h-max">
                <div className="p-4 md:p-5 flex flex-row justify-between items-center">
                  <div className="flex items-center gap-x-2">
                    <p className="text-xs uppercase tracking-wide text-gray-500 m-0">
                    Total Allocates
                    </p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-2">
                    <h3 className="text-xl sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
                    {allocatedItemsCount}
                    </h3>
                  </div>
                </div>
              </div>
              {/* End Card */}
              {/* Card */}

              {/* End Card */}
            </div>
            {/* End Grid */}
          </div>
          {/* End Card Section */}
        </>
      </div>

      <div>
        <>
          {/* Table Section */}
          <div className="max-w-[85rem] p-4 mx-auto">
            {/* Card */}
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden darki:bg-slate-900 darki:border-gray-700">
                    <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
                      <thead className="bg-gray-50 darki:bg-slate-800">
                        <tr>
                        <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                                Image
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                                Event Name
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                                Quantity
                              </span>
                            </div>
                          </th>
                    
                          <th scope="col" className="px-6 py-3 text-end" />
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
                        {musicInventory.map((inventory, index) => (
                          <tr>
                          <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-2">
                               <img src={inventory?.image} className="rounded-xl w-[5rem] h-[5rem] img-fit"/>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-2">
                                <a
                                  className="text-sm text-blue-600 decoration-2 hover:underline darki:text-blue-500"
                                  href="#"
                                >
                                  {inventory?.instrumentName}
                                </a>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-2">
                                <span className="text-sm text-gray-600 darki:text-gray-400 font-medium">
                                  {inventory.quantity} items
                                </span> <br/>

                                <span className="text-sm text-success darki:text-gray-400 ">
                                  {inventory.quantityAllocated} allocated
                                </span> <span className="text-gray-300 text-xs">|</span>  <span className="text-sm text-error darki:text-gray-400">
                                  {inventory.quantityRemaining} available
                                </span>
                              </div>
                            </td>

                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-1.5 flex justify-center">
                                <div
                                  className="btn btn-ghost text-primary"
                                  onClick={() => {
              setSelectedInventory(inventory);
              setModalIsOpen(true);
            }}
                                >
                                  <FaEye />
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* End Card */}
          </div>
          {/* End Table Section */}
        </>
      </div>


      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        {selectedInventory && <ModalContent inventory={selectedInventory} />}


        <span>Assign to: </span>
        <select className="select select-bordered">
          <option value={`Ali`}>Ali</option>
          <option value={`Fahad`}>Fahad</option>
        </select>

        <button className="btn btn-primary">Save</button>
      </Modal>
      </AdminLayout>

      
    </>
  );
}

export default InventoryWrapper;
