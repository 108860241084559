import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";
import { ImMail4 } from "react-icons/im";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { ImLinkedin } from "react-icons/im";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-primary w-full px-4">
      <div className="w-full max-w-[75rem] py-10 px-0 sm:px-0 lg:px-0 lg:pt-20 mx-auto">
        {/* Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
          <div className="col-span-full lg:col-span-1">
            <Link to="/">
              <img
                className="w-[6rem] h-auto"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708415696/AHbranding/logo_white_r1bqtu.png"
                alt="Logo"
              />
            </Link>
          </div>
          {/* End Col */}
          <div className="col-span-1">
            <h4 className="font-semibold text-xl text-gray-100">{t('Company')}</h4>
            <div className="mt-3 grid space-y-3">
              <p className="m-0">
                <Link
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={""}
                >
                  {t('About Us')}
                </Link>
              </p>
              <p className="m-0">
                <Link
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={"/book_demo"}
                >
                  {t('BookDemo')}
                </Link>
              </p>
              <p className="m-0">
                <Link
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={"/login"}
                >
                  {t('Login')}
                </Link>
              </p>
            </div>
          </div>
          {/* End Col */}
          <div className="col-span-1">
            <h4 className="font-semibold text-xl text-gray-100">
              {t('Key Features')}
            </h4>
            <div className="mt-3 grid space-y-3">
              <p className="m-0">
                <Link
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={"/features/ems"}
                >
                  {t('Extracurricular Management System')}
                </Link>
              </p>
              <p className="m-0">
                <Link
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={"/features/cct"}
                >
                  {t('Extracurricular Report')}
                </Link>
              </p>
              <p className="m-0">
                <Link
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={"/features/gp"}
                >
                  {t('Guided Pathways')}
                </Link>
              </p>
              <p className="m-0">
                <Link
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={"/features/leap"}
                >
                  {t('WEB / Mobile App')}
                </Link>
              </p>
            </div>
          </div>
          {/* End Col */}
          <div className="col-span-1">
            <h4 className="font-semibold text-xl text-gray-100">{t('Resources')}</h4>
            <div className="mt-3 grid space-y-3">
              <p className="m-0">
                <a
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={""}
                >
                  {t('Case Studies')}
                </a>
              </p>
              <p className="m-0">
                <Link
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={"/blogs"}
                >
                  {t('Blogs')}
                </Link>
              </p>

              <p className="m-0">
                <a
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={""}
                >
                  {t('Knowledge Base')}
                </a>
              </p>
            </div>
          </div>
          {/* End Col */}

          <div className="col-span-1">
            <h4 className="font-semibold text-xl text-gray-100">{t('Legal')}</h4>
            <div className="mt-3 grid space-y-3">
              <p className="m-0">
                <a
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={""}
                >
                  {t('Security')}
                </a>
              </p>
              <p className="m-0">
                <a
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={""}
                >
                  {t('Terms & Condition')}
                </a>
              </p>
              <p className="m-0">
                <a
                  className="inline-flex gap-x-2 text-white opacity-80 hover:text-gray-200 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  to={""}
                >
                  {t('Privacy Policy')}
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* End Grid */}
        <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
          <div className="flex justify-between items-center">
            <p className="text-sm text-white opacity-80">
              © {currentYear} {t('Start Up Early. All rights reserved.')}
            </p>
          </div>
          {/* End Col */}
          {/* Social Brands */}

          <div className="flex flex-row items-center gap-2">
            <Link
              className="btn btn-circle btn-ghost"
              target="_blank"
              to={"https://www.facebook.com/StartupEarly.Co/"}
            >
              <FaFacebook size={25} />
            </Link>
            <Link
              className="btn btn-circle btn-ghost"
              target="_blank"
              to={"mailto:hello@startupearly.com"}
            >
              <ImMail4 size={25} />
            </Link>
            <Link
              className="btn btn-circle btn-ghost"
              target="_blank"
              to={"https://twitter.com/early_startup?lang=en"}
            >
              <AiFillTwitterCircle size={28} />
            </Link>
            <Link
              className="btn btn-circle btn-ghost"
              target="_blank"
              to={"https://www.instagram.com/startupearly/?hl=en"}
            >
              <AiFillInstagram size={29} />
            </Link>
            <Link
              className="btn btn-circle btn-ghost"
              target="_blank"
              to={"https://pk.linkedin.com/company/startupearly"}
            >
              <ImLinkedin size={25} />
            </Link>
          </div>
          {/* End Social Brands */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
