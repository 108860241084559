import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import RoleDropdown from "./RoleDropdown";
import ClubSidebar from "./ClubSidebar";
import ClubListList from "./ClubListList";
import ClubPagination from "./ClubPagination";
// import clubs from "./clubs";
import SchoolNews from "./SchoolNews";
import PublicNav from "./PublicNav";

function AllClubsStd({ profile }) {
  const [state, setState] = useState("");

  useEffect(() => {
    return () => {};
  }, []);

  const GlobalStyle = createGlobalStyle`
    html {
      background: #F9F3FF !important;
      /* Add other global styles here if needed */
    }
  `;

  const [clubs, setClubs] = useState([]);
  let filteredClubs=[]
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/clubs/`
        );
        let data = await response.json();
        filteredClubs = data.filter(club => club.network === profile.network_in);

        setClubs(filteredClubs);
        console.log("CLUBS ", data);
      } catch (err) {
        console.log("ERROR");
      }
    };
    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);
    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;

  // Filter blogs based on selected category
  const filteredBlogs = selectedCategory
    ? clubs.filter((blog) => blog.category === selectedCategory)
    : clubs;

  // Pagination logic
  const totalBlogs = filteredBlogs.length;
  const totalPages = Math.ceil(totalBlogs / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;

  const currentBlogs = filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const handleCategorySelect = (category) => {
    setCurrentPage(1);
    setSelectedCategory(category);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleShowAll = () => {
    setCurrentPage(1);
    setSelectedCategory(null);
  };

  const uniqueCategories = [
    ...new Set(clubs.map((blog) => blog.category_name)),
  ];

  return (
    <div>
      {/* <GlobalStyle />
<PublicNav/>
  */}
      <div className="container mx-auto mt-8">
        {/* <RoleDropdown  /> */}
        <div className="mt-4">
          {" "}
          {/* Increase or decrease the number of columns as needed */}
          {/* <div className="hidden  md:flex md:col-span-2 w-full">
            <ClubSidebar
              categories={uniqueCategories}
              onSelectCategory={handleCategorySelect}
              onShowAll={handleShowAll}
            />
          </div> */}
          <div className="">
            {" "}
            {/* Adjust the number of columns spanned by the content */}
            <ClubListList blogs={clubs} />
            {/* <ClubPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}
          </div>
        </div>
      </div>

      {/* <SchoolNews/> */}
    </div>
  );
}

export default AllClubsStd;
