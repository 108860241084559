import React, { useState, useEffect, useContext } from "react";
// import profileImg from "../assets/images/profile.png"
import axios from "axios";
import ReactPaginate from "react-paginate";
import { toast } from "react-hot-toast";
import AuthContext from "../context/AuthContext";
import Modal from "react-modal";
import { Link } from "react-router-dom";

const NewModerator = () => {
  const [activitiesData, setActivitesData] = useState([]);
  const [leadFeadBack, setLeadFeedBack] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [searchField, setSearchField] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [scope, setScope] = useState("");
  const [grade, setGrade] = useState("");
  const [month, setMonth] = useState("");
  const [serviceHours, setServiceHours] = useState("");
  const [category, setCategory] = useState("");
  const [userId, setUserId] = useState("");
  const [activityName, setActivityName] = useState("");
  const [feedback, setFeeback] = useState("");
  const [postedDate, setPostedDate] = useState("");
  const [postedBy, setPostedBy] = useState("");
  const [schoolIn, setSchoolIn] = useState("");
  const [score, setScore] = useState("");
  const [startDate, setStartDate] = useState("");
  const [status, setStatus] = useState();
  const activitiesPerPage = 4;
  // const pageCount = Math.ceil(activitiesData?.length / activitiesPerPage);
  const [searchQuery, setSearchQuery] = useState("");
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      maxWidth: "400px", // Adjust the maximum width as needed
      width: "100%", // Ensure the modal takes full width on smaller screens
      padding: "20px",
      background: "#fff",
      zIndex: "9999999999999",
    },
  };

  const handleEditClick = (data) => {
    console.warn(data);
    setUserId(data?.id);
    setCategory(data?.category);
    setScope(data?.scope);
    setGrade(data.grade);
    setMonth(data?.duration);
    setServiceHours(data?.service_hours);
    setActivityName(data?.activity_name);
    setFeeback(data?.feedback);
    setPostedDate(data?.post_date);
    setPostedBy(data?.posted_by);
    setSchoolIn(data?.school_in);
    setScore(data?.score);
    setStartDate(data?.start_date);
    setStatus(data?.status);
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    if (category === "LeadershipActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_lead/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "SocialActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_social/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "InternActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_intern/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "EventsActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_eventStd/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "SportsActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_sports/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "AwardActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_awards/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "SocietyActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_society/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "CertActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_cert/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "LangActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_lang/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (category === "TalentActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_talent/${userId}/`,
          {
            scope,
            duration: month,
            grade,
            service_hours: serviceHours,
            activity_name: activityName,
            feedback,
            post_date: postedDate,
            posted_by: postedBy,
            school_in: schoolIn,
            score,
            start_date: startDate,
            status,
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Good Job! Activity Saved", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        fetchActivities();
        setCategory("");
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }
    setIsEditing(false);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleApproveActivity = async (activity, index) => {
    toast.success("Please wait. Saving Feedback!", {
      icon: "🕒",
      style: {
        borderRadius: "55px",
        marginBottom: "15px",
      },
    });

    if (activity?.category === "LeadershipActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_lead/${activity?.id}/`,
          {
            status: "1",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "SportsActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_sports/${activity?.id}/`,
          {
            status: "1",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } 
    
    else if (activity?.category === "SocialActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_social/${activity?.id}/`,
          {
            status: "1",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
            description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }
    
    else if (activity?.category === "SocietyActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_society/${activity?.id}/`,
          {
            status: "1",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "EventsActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_eventStd/${activity?.id}/`,
          {
            status: "1",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }


    else if (activity?.category === "AwardActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_awards/${activity?.id}/`,
          {
            status: "1",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            rank: activity.rank,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }


    else if (activity?.category === "LangActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_lang/${activity?.id}/`,
          {
            status: "1",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            rank: activity.rank,
            prof: activity.prof,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }
  };

  const handleProofActivity = async (activity, index) => {
    toast.success("Please wait. Saving Feedback!", {
      icon: "🕒",
      style: {
        borderRadius: "55px",
        marginBottom: "15px",
      },
    });

    if (activity?.category === "LeadershipActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_lead/${activity?.id}/`,
          {
            status: "3",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "SocialActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_social/${activity?.id}/`,
          {
            status: "3",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "SportsActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_sports/${activity?.id}/`,
          {
            status: "3",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "SocietyActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_society/${activity?.id}/`,
          {
            status: "3",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "EventsActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_eventStd/${activity?.id}/`,
          {
            status: "3",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }


    else if (activity?.category === "AwardActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_awards/${activity?.id}/`,
          {
            status: "3",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            rank: activity.rank,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }

    else if (activity?.category === "LangActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_lang/${activity?.id}/`,
          {
            status: "3",
            feedback: leadFeadBack?.[index] || 0,

            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            prof: activity.prof,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }


  };

  const handleRejectActivity = async (activity, index) => {
    toast.success("Please wait. Saving Feedback!", {
      icon: "🕒",
      style: {
        borderRadius: "55px",
        marginBottom: "15px",
      },
    });

    if (activity?.category === "LeadershipActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_lead/${activity?.id}/`,
          {
            status: "2",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "SportsActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_sports/${activity?.id}/`,
          {
            status: "2",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "SocietyActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_society/${activity?.id}/`,
          {
            status: "2",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } else if (activity?.category === "EventsActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_eventStd/${activity?.id}/`,
          {
            status: "2",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    }




    else if (activity?.category === "SocialActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_social/${activity?.id}/`,
          {
            status: "2",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } 


    
    else if (activity?.category === "AwardActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_awards/${activity?.id}/`,
          {
            status: "2",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            rank: activity.rank,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } 



    else if (activity?.category === "LangActivities") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_KEY}/api/update_lang/${activity?.id}/`,
          {
            status: "2",
            feedback: leadFeadBack?.[index] || 0,
            score: 0,
            activity_name: activity.activity_name,
description: activity.description,
            duration: activity.duration,
            grade: activity.grade,
            role: activity.role,
            prof: activity.prof,
            scope: activity.scope,
            service_hours: activity.service_hours,
            posted_by: activity.posted_by,
            post_date: activity.post_date,
            school_in: activity.school_in,
            start_date: activity.start_date,
            category: activity.category,
            // Include other fields here, but don't modify them
          }
        );
        console.log("Activity updated successfully:", response.data);
        toast.success("Feedback Saved!", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        setLeadFeedBack("");
        fetchActivities();
      } catch (error) {
        console.log("Error updating activity:", error);
      }
    } 

  };

  function getPositionLabel(position) {
    const positionMapping = {
      1: "Participation",
      1.1: "3rd",
      1.2: "2nd",
      1.5: "1st",
    };

    return positionMapping[position] || "-";
  }

  function getLangProfLabel(prof) {
    const langProfMapping = {
      1: "Beginner",
      1.2: "Early Intermediate",
      1.23: "Intermediate",
      1.24: "Fluent",
      1.5: "Native",
    };

    return langProfMapping[prof] || "-";
  }

  function getScopeLabel(scope) {
    const scopeMapping = {
      1: "School",
      1.2: "Local",
      1.5: "International",
    };

    return scopeMapping[scope] || "-";
  }

  function getDurationLabel(duration) {
    const durationMapping = {
      0.1: "1 month",
      0.3: "3 months",
      0.6: "6 months",
      0.9: "9 months",
      1.2: "12 months",
    };

    return durationMapping[duration] || "-";
  }

  function getSocialRoleLabel(role) {
    const roleMapping = {
      1: "Volunteer",
      1.2: "Organized",
      1.5: "Donated",
    };

    return roleMapping[role] || "-";
  }

  function getLeadershipScopeLabel(scope) {
    const scopeMapping = {
      1: "Class",
      1.2: "Section",
      1.51: "House",
      1.52: "School",
    };

    return scopeMapping[scope] || "-";
  }

  function getSocietyRoleLabel(role) {
    const roleMapping = {
      1: "President",
      1.2: "Vice President",
      1.5: "Member",
    };

    return roleMapping[role] || "-";
  }

  function getEngagementLabel(engagement) {
    const engagementMapping = {
      1: "Light",
      1.2: "Moderate",
      1.4: "Heavy",
      1.5: "Extra",
    };

    return engagementMapping[engagement] || "-";
  }

  function getLeadershipActivityLabel(name) {
    const labelMap = {
      9: "Student Council General Secretary",
      8.01: "Deputy Head Student",
      8.02: "House Captain",
      8.03: "Class Prefect",
      8.04: "Captain Sports Team",
      7: "Class Monitor",
      10: "Head Student",
    };

    return labelMap[name] || "-";
  }

  function getSocietyNameLabel(name) {
    const labelMap = {
      8: "STEM/STEAM Society",
      8.02: "Art Society",
      8.01: "Music Society",
      8.03: "Debates Society",
      8.04: "Drama/Theater Society",
      8.05: "Math Society",
      8.06: "Literature Society",
      8.07: "Environment Society",
      8.08: "Community Service Society",
      8.09: "Media & Publications Society",
      7: "Astronomy Society",
      8.1: "Sports Society",
      7.02: "Choir Society",
      7.03: "Cooking Society",
      7.04: "Yoga Club",
      7.05: "Book Club",
      7.06: "School Media Club",
      7.07: "Cardio Club",
      7.01: "Film Society",
      8.2: "School Magazine",
    };
    return labelMap[name] || "-";
  }

  function getSocialActivityLabel(name) {
    const labelMap = {
      8: "Clothes drive",
      8.01: "Charity collection",
      9: "Blood donation drive",
      9.01: "Hospital volunteer",
      8.02: "Trash clean-up",
      8.03: "Charity bake sale",
      8.04: "Food drive",
    };
    return labelMap[name] || "-";
  }

  function getEventManagementLabels(name) {
    const labelMap = {
      9: "Executive Council",
      8: "Logistics Team",
      8.1: "Registrations Team",
      8.2: "Socials Team",
      8.3: "Publications Team",
      8.4: "Finance Team",
      8.5: "Promotions Team",
      8.6: "Communications Team",
      8.7: "IT Team",
      8.8: "Security Team",
      8.9: "Operations Team",
    };
    return labelMap[name] || "-";
  }

  function getTalentActivityLabel(name) {
    const labelMap = {
      7: "Videography",
      7: "Gaming",
      7: "SEO",
      7: "GFX",
      7: "Kni,tting/Crochet",
      7: "Resin art",
      7: "Embroidery",
      7: "Photography",
      7: "Painting",
      7: "Drawing",
      7: "Sculpture",
      7: "Graphic Design",
      7: "Writing",
      7: "Reading",
      7: "Makeup",
      7: "Cooking",
      7: "Photography",
      7: "Piano",

      7: "Ukulele",
      7: "Violin",
      7: "Singing",
      7: "Flute",
      7: "Guitar",
      7: "Cycling",
      7: "Editing",
      7: "Jewellery Making",
      7: "Dancing",
      7: "Cardio",
      7: "Weightlifting",
      7: "Football",
      7: "Cricket",
      7: "Swimming",
      7: "Basketball",

      7: "Poetry",
      7: "Hockey",
      7: "Tennis",
      7: "Ballet",
      7: "Football",
      7: "Table Tennis",
      7: "Chess",
      7: "Horseriding",

      7: "Skating",
      7: "Social Media Influencer",

      7: "Badminton",
    };
    return labelMap[name] || "-";
  }

  function getAwardsLabel(name) {
    const awardsMapping = {
      10: "Full attendance",
      10.01: "Academic excellence",
      10.02: "Sports scholarship",
      10.03: "Merit scholarship",
      10.04: "Research grant",
      7: "Poem Recitation",
      7.01: "Spoken Word Contest",
      8: "Play/Musical",
      7.02: "Choir Performance",
      7.03: "Aerobics Competition",
      7.04: "Bake Sale",
      7.05: "Artbeat",
      8.01: "Debates Competition",
      8.02: "School/college MUN",
      8.03: "Math Competition",
      7.06: "Trivia Competition",
      8.04: "Science Competition",
      8.05: "Science Fair",
      7.07: "Dance Competition",
      8.04: "Short Film Competition",
      8.05: "Robotics Competition",
      7.08: "Poster Making Competition",
      7.09: "Gaming Competition",
      7.1: "Fashion Design Competition",
      7.11: "Cooking Competition",
      7.12: "Modeling Competition",
      7.13: "Talent Show",
      8.06: "Handwriting Competition",
      8.07: "Essay Competition",
      7.14: "Acting Competition",
      8.08: "Drawing Competition",
      8.09: "Photography Competition",
      8.1: "Painting Competition",
      8.11: "Composition Competition",
      6: "Lucky Draw",
      7.15: "Spelling Competition",
      10.05: "Certificate of Achievement",
    };

    return awardsMapping[name] || "-";
  }

  function getSportsLabel(name) {
    const sportsMapping = {
      7: "Basketball",
      7.01: "Cricket",
      7.02: "Hockey",
      7.03: "Badminton",
      7.04: "Tennis",
      7.05: "Throwball",
      7.06: "Gymnastics",
      7.07: "Futsal",
      7.08: "Archery",
      7.09: "Cycling",
      7.1: "Sprint",
      7.2: "Football",
      7.3: "Relay race",
      7.4: "Baseball",
      7.5: "Fencing",
      7.6: "Golf",
      7.7: "Handball",
      7.8: "Netball",
      7.9: "Rowing",
      7.1: "Skateboarding",
      7.11: "Road bicycle racing",
      7.411: "Ballet",
      7.12: "Kabaddi",
      7.13: "Kickboxing",
      7.14: "Boxing",
      7.15: "Strongman",
      7.16: "Lacrosse",
      7.17: "Polo",
      7.18: "Water Polo",
      7.19: "Long Jump",
      7.2: "High Jump",
      7.21: "Shot put",
      7.22: "Pole Vault",
      7.23: "Pickleball",
      8: "Chess",
      7.24: "Track & field",
      7.25: "Curling",
      7.26: "Endurance running",
      7.27: "Hurdle races",
      7.28: "Ice skating",
      7.29: "Surfing",
      7.3: "Skiing",
      7.31: "Snowboarding",
      7.32: "Dodgeballt",
      7.33: "Climbing",
      7.34: "Snooker",
      7.35: "Squash",
      7.36: "Swimming",
      7.37: "Volleyball",
      7.38: "Weightlifting",
      7.39: "Rugby",
    };

    return sportsMapping[name] || "-";
  }

  function getInternshipTypeLabel(name) {
    const internshipTypeMapping = {
      1: "Part Time Internship",
      1.2: "Virtual Internship",
      1.5: "On-site Internship",
    };
    return internshipTypeMapping[name] || "-";
  }

  function getCertificationLabel(type) {
    const certificationMapping = {
      10: "First aid certification",
      5: "Driving license",
      8: "Google certification",
      8.01: "Coursera course",
      8.02: "edX course",
      8.03: "Udemy course",
      8.04: "Masterclass course",
      8.05: "Duolingo course",
      8.06: "Khan Academy course",
      8.07: "Codecademy course",
      8.08: "Domestika course",
      8.09: "Udacity course",
    };

    return certificationMapping[type] || "-";
  }

  function getLanguageLabel(name) {
    const languageMapping = {
      af: "Afrikaans",
      sq: "Albanian - shqip",
      am: "Amharic - አማርኛ",
      ar: "Arabic - العربية",
      an: "Aragonese - aragonés",
      hy: "Armenian - հայերեն",
      ast: "Asturian - asturianu",
      az: "Azerbaijani - azərbaycan dili",
      eu: "Basque - euskara",
      be: "Belarusian - беларуская",
      bn: "Bengali - বাংলা",
      bs: "Bosnian - bosanski",
      br: "Breton - brezhoneg",
      bg: "Bulgarian - български",
      ca: "Catalan - català",
      ckb: "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
      zh: "Chinese - 中文",
      "zh-HK": "Chinese (Hong Kong) - 中文（香港）",
      "zh-CN": "Chinese (Simplified) - 中文（简体）",
      "zh-TW": "Chinese (Traditional) - 中文（繁體）",
      co: "Corsican",
      hr: "Croatian - hrvatski",
      cs: "Czech - čeština",
      da: "Danish - dansk",
      nl: "Dutch - Nederlands",
      eo: "Esperanto - esperanto",
      et: "Estonian - eesti",
      fo: "Faroese - føroyskt",
      fil: "Filipino",
      fi: "Finnish - suomi",
      fr: "French - français",
      "fr-CA": "French (Canada) - français (Canada)",
      "fr-FR": "French (France) - français (France)",
      "fr-CH": "French (Switzerland) - français (Suisse)",
      gl: "Galician - galego",
      ka: "Georgian - ქართული",
      de: "German - Deutsch",
      "de-AT": "German (Austria) - Deutsch (Österreich)",
      "de-DE": "German (Germany) - Deutsch (Deutschland)",
      "de-LI": "German (Liechtenstein) - Deutsch (Liechtenstein)",
      "de-CH": "German (Switzerland) - Deutsch (Schweiz)",
      el: "Greek - Ελληνικά",
      gn: "Guarani",
      gu: "Gujarati - ગુજરાતી",
      ha: "Hausa",
      haw: "Hawaiian - ʻŌlelo Hawaiʻi",
      he: "Hebrew - עברית",
      hi: "Hindi - हिन्दी",
      hu: "Hungarian - magyar",
      is: "Icelandic - íslenska",
      id: "Indonesian - Indonesia",
      ia: "Interlingua",
      ga: "Irish - Gaeilge",
      it: "Italian - italiano",
      "it-IT": "Italian (Italy) - italiano (Italia)",
      "it-CH": "Italian (Switzerland) - italiano (Svizzera)",
      ja: "Japanese - 日本語",
      kn: "Kannada - ಕನ್ನಡ",
      kk: "Kazakh - қазақ тілі",
      km: "Khmer - ខ្មែរ",
      ko: "Korean - 한국어",
      ku: "Kurdish - Kurdî",
      ky: "Kyrgyz - кыргызча",
      lo: "Lao - ລາວ",
      la: "Latin",
      lv: "Latvian - latviešu",
      ln: "Lingala - lingála",
      lt: "Lithuanian - lietuvių",
      mk: "Macedonian - македонски",
      ms: "Malay - Bahasa Melayu",
      ml: "Malayalam - മലയാളം",
      mt: "Maltese - Malti",
      mr: "Marathi - मराठी",
      mn: "Mongolian - монгол",
      ne: "Nepali - नेपाली",
      no: "Norwegian - norsk",
      nb: "Norwegian Bokmål - norsk bokmål",
      nn: "Norwegian Nynorsk - nynorsk",
      oc: "Occitan",
      or: "Oriya - ଓଡ଼ିଆ",
      om: "Oromo - Oromoo",
      ps: "Pashto - پښتو",
      fa: "Persian - فارسی",
      pl: "Polish - polski",
      pt: "Portuguese - português",
      "pt-BR": "Portuguese (Brazil) - português (Brasil)",
      "pt-PT": "Portuguese (Portugal) - português (Portugal)",
      pa: "Punjabi - ਪੰਜਾਬੀ",
      qu: "Quechua",
      ro: "Romanian - română",
      mo: "Romanian (Moldova) - română (Moldova)",
      rm: "Romansh - rumantsch",
      ru: "Russian - русский",
      gd: "Scottish Gaelic",
      sr: "Serbian - српски",
      sh: "Serbo-Croatian - Srpskohrvatski",
      sn: "Shona - chiShona",
      sd: "Sindhi",
      si: "Sinhala - සිංහල",
      sk: "Slovak - slovenčina",
      sl: "Slovenian - slovenščina",
      so: "Somali - Soomaali",
      st: "Southern Sotho",
      es: "Spanish - español",
      "es-AR": "Spanish (Argentina) - español (Argentina)",
      "es-419": "Spanish (Latin America) - español (Latinoamérica)",
      "es-MX": "Spanish (Mexico) - español (México)",
      "es-ES": "Spanish (Spain) - español (España)",
      "es-US": "Spanish (United States) - español (Estados Unidos)",
      su: "Sundanese",
      sw: "Swahili - Kiswahili",
      sv: "Swedish - svenska",
      tg: "Tajik - тоҷикӣ",
      ta: "Tamil - தமிழ்",
      tt: "Tatar",
      te: "Telugu - తెలుగు",
      th: "Thai - ไทย",
      ti: "Tigrinya - ትግርኛ",
      to: "Tongan - lea fakatonga",
      tr: "Turkish - Türkçe",
      tk: "Turkmen",
      tw: "Twi",
      uk: "Ukrainian - українська",
      ug: "Uyghur",
      uz: "Uzbek - o‘zbek",
      vi: "Vietnamese - Tiếng Việt",
      wa: "Walloon - wa",
      cy: "Welsh - Cymraeg",
      fy: "Western Frisian",
      xh: "Xhosa",
      yi: "Yiddish",
      yo: "Yoruba - Èdè Yorùbá",
      zu: "Zulu - isiZulu",
    };
    return languageMapping[name] || "-";
  }

  let { user } = useContext(AuthContext);
  let userID = user["user_id"];
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);

  console.log("SCHOOL: " + profile?.school_in);

  // const fetchData = async () => {
  //     try {
  //         let response = '';

  //         if(user['role'] === 'Network Admin'){
  //             response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${userID}`);
  //             setProfile(data);
  //             setLoading(false);

  //             //alert("N ID: ",profile)
  //         }

  //         else{

  //         response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${userID}`);
  //         setProfile(data);
  //         setLoading(false);

  //         //alert("N ID: ",profile)

  //         }
  //         let data = await response.json();

  //     } catch (err) {
  //         console.log("The requested profile does not exist.");
  //         setLoading(false); // Set loading to false even on error to stop showing the loader
  //     }
  // };


  let url = '';
  if(user['role']==='Student'){
    url = 'profile';
  } else if(user['role']==='Network Admin'){
    url = 'network_profile';
  } else if(user['role']==='School Admin'){
    url = 'school_profile';
  }

  const fetchData = async () => {
    try {
      let response = "";
      let data = ""; // Define data variable outside the if-else block
      let role = "";
      response = await fetch(
        `${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`
      );

      // Move setProfile and setLoading outside the if-else block
      data = await response.json();
      setProfile(data);
      setLoading(false);

      // Now you can use the profile after setting it
      //alert("Profile ID: " + profile.id); // Replace "id" with the actual property you want to display
    } catch (err) {
      console.log("The requested profile does not exist.");
      setLoading(false); // Set loading to false even on error to stop showing the loader
    }
  };

  // const fetchActivities = async () => {
  //   try {
  //     // const res = await axios.get(`${process.env.REACT_APP_API_KEY}/api/join_activities/`);
  //     let res = 0;
  //     // const filteredData = res?.data.filter(activity => activity.school_in === 8);
  //     if (user["role"] === "Network Admin") {
  //       res = await axios.get(
  //         `${process.env.REACT_APP_API_KEY}/api/school_activities_timeline/${profile?.network_id}/0/`
  //       );
  //       setActivitesData(res?.data);
  //     } else {
  //       res = await axios.get(
  //         `${process.env.REACT_APP_API_KEY}/api/school_activities_timeline/${profile?.network_in}/${profile?.school_in}/`
  //       );
  //       setActivitesData(res?.data);
  //     }

  //     // setActivitesData(filteredData);
  //   } catch (err) {
  //     console.log("Error fetching data for tabs:", err);
  //   }
  // };


  const fetchActivities = async () => {
    try {
      // const res = await axios.get(`${process.env.REACT_APP_API_KEY}/api/join_activities/`);
      let res = 0;
      // const filteredData = res?.data.filter(activity => activity.school_in === 8);
      if (user["role"] === "Network Admin") {
        res = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/school_activities_timeline/${profile?.network_id}/0/`
        );
        setActivitesData(res?.data);
      } if (user["role"] === "School Admin") {
        res = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/school_activities_timeline/${profile?.network}/${profile?.school_id}/`
        );
        setActivitesData(res?.data);
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/school_activities_timeline/${profile?.network_in}/${profile?.school_in}/`
        );
        setActivitesData(res?.data);
      }

      // setActivitesData(filteredData);
    } catch (err) {
      console.log("Error fetching data for tabs:", err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   fetchActivities();
  // }, [profile?.network_id || profile?.network_in]);

  useEffect(() => {
    fetchActivities();
  }, [profile?.network_id || profile?.network_in || profile?.network || profile?.school_id]);


  const handleSelectChange = (e) => {
    const selectedField = e.target.value;
    setSearchField(selectedField);
  };

  const filterActivities = (data, query, field) => {
    if (!query && !field) {
      return data;
    }

    if (query) {
      const normalizedQuery = query.toLowerCase();
      return data?.filter((item) => {
        return Object?.values(item)?.some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase()?.includes(normalizedQuery)
        );
      });
    } else if (field !== "") {
      return data?.filter((item) => {
        return item?.grade && item.grade?.toString() === field;
      });
    }

    return data;
  };

  for (let i = activitiesData.length - 1; i >= 0; i--) {
    if (activitiesData[i]?.activities.length === 0) {
      // Remove the item from the array
      activitiesData.splice(i, 1);
    }
  }
  const newData = activitiesData?.map((item) => {
    const filteredActivities = item.activities.filter((activity) =>
      [
        "LeadershipActivities",
        "SportsActivities",
        "SocietyActivities",
        "SocialActivities",
        "EventsActivities",
        "AwardActivities",
        "LangActivities",
      ]?.includes(activity?.category)
    );

    return {
      ...item,
      activities: filteredActivities,
    };
  });

  const filteredData = filterActivities(newData, searchQuery, searchField);

  const offset = pageNumber * activitiesPerPage;
  const pageCount = Math.ceil(filteredData?.length / activitiesPerPage);
  const displayActivities = filteredData
    ?.slice(offset, offset + activitiesPerPage)
    ?.map((item, index) => {
      if (item?.activities?.length > 0) {
        return (
          <div className="">


          <div
            key={index}
            className="w-full bg-white p-6 rounded-3xl flex flex-col gap-10 "
          >
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div className="flex items-center justify-center ">
                  {/* <img className='w-16 rounded-3xl  '  src={item && item?.avatar !== "" ? item?.avatar : profileImg} alt="profileImg"  /> */}
                  <span class="inline-block h-[3.875rem] w-[3.875rem] bg-gray-100 rounded-full overflow-hidden">
                    <svg
                      class="h-full w-full text-gray-300"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.62854"
                        y="0.359985"
                        width="15"
                        height="15"
                        rx="7.5"
                        fill="white"
                      />
                      <path
                        d="M8.12421 7.20374C9.21151 7.20374 10.093 6.32229 10.093 5.23499C10.093 4.14767 9.21151 3.26624 8.12421 3.26624C7.0369 3.26624 6.15546 4.14767 6.15546 5.23499C6.15546 6.32229 7.0369 7.20374 8.12421 7.20374Z"
                        fill="currentColor"
                      />
                      <path
                        d="M11.818 10.5975C10.2992 12.6412 7.42106 13.0631 5.37731 11.5537C5.01171 11.2818 4.69296 10.9631 4.42107 10.5975C4.28982 10.4006 4.27107 10.1475 4.37419 9.94123L4.51482 9.65059C4.84296 8.95684 5.53671 8.51624 6.30546 8.51624H9.95231C10.7023 8.51624 11.3867 8.94749 11.7242 9.62249L11.8742 9.93184C11.968 10.1475 11.9586 10.4006 11.818 10.5975Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>

                <div className="flex flex-col">
                  <h3 className="m-0 text-xl">
                    {item?.firstname + item?.lastname}
                  </h3>
                  <h6 className="m-0 text-[#00000075]">
                    {"@" + item?.name + " • Grade " + item?.grade}
                  </h6>
                </div>
              </div>
              {/* <div>
                        <button className='bg-[#FF884D] text-white  font-semibold px-6 py-2 rounded-2xl uppercase'>Pending</button>
                    </div> */}
            </div>

            <details className="w-full  rounded-lg">
              <summary className="px-4 py-6 focus:outline-none focus-visible:ri text-center text-primary">
                Expand Activitis
              </summary>

              {item?.activities?.length > 0 ? (
                item?.activities
                  .filter((i) => i.status === "0") // Filter activities where status is 0
                  .map((i, iIndex) => (
                    <div
                      key={iIndex}
                      className="gap-6 border-2 my-4 border-gray-100 p-7 flex flex-col md:flex-row w-full rounded-3xl items-center"
                    >
                      <div className="w-full md:w-2/4 flex flex-col gap-5">
                        <div className="w-1/3 ">
                          <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darko:bg-white/10 darko:text-white">
                            {i?.category && (
                              <span>
                                {i.category.replace(/Activities$/, "")}
                                {i.category.endsWith("LeadershipActivities") &&
                                  " 🏆"}
                                {i.category.endsWith("SportsActivities") &&
                                  " ⚽"}
                                {i.category.endsWith("SocietyActivities") &&
                                  " ♠️"}
                                {i.category.endsWith("EventsActivities") &&
                                  " 📆"}
                                {i.category.endsWith("AwardActivities") && "🎖️"}
                                {i.category.endsWith("LangActivities") && "🔠"}
                                {i.category.endsWith("SocialActivities") &&
                                  "🎗️"}
                              </span>
                            )}
                          </span>
                        </div>
                        {/* <h2 className='font-semibold text-3xl text-black m-0'>{i?.activity_name}</h2> */}
                        {(() => {
                          if (i?.category === "LeadershipActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getLeadershipActivityLabel(
                                      i?.activity_name
                                    )}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "SocietyActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getSocietyNameLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "SportsActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getSportsLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "EventsActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getEventManagementLabels(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "SocialActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getSocialActivityLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "LangActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getLanguageLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                  {getLanguageLabel(i?.activity_name)}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "AwardActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getAwardsLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          }
                        })()}
                        <div className="w-full flex flex-col justify-between">
                          <div className="w-full flex items-center flex-wrap gap-3">
                            <div className="border-2 border-gray-100 flex items-center justify-center rounded-lg px-2.5 py-1">
                              <span className="font-medium text-sm">
                                Performed in Grade {i?.grade}
                              </span>
                            </div>
                            {
                              (i?.duration > 0 && (
                                <div className="border-2 border-gray-100 flex items-center justify-center rounded-lg px-2.5 py-1">
                                  <span className="font-medium text-sm">
                                    {i.duration} month's
                                  </span>
                                </div>
                              ))}


                              

                              { i.category === 'LangActivities' && (
  <div className="border-2 border-gray-100 flex items-center justify-center rounded-lg px-2.5 py-1">
    <span className="font-medium text-sm">
      {getLangProfLabel(i?.prof)} proficiency
    </span>
  </div>
)}

                            

                            { i.category !== 'LangActivities' && (
                              <div className="border-2 border-gray-100 flex items-center justify-center rounded-lg px-2.5 py-1">
                              <span className="font-medium text-sm">
                                {getEngagementLabel(i?.service_hours)}{" "}
                                dedication
                              </span>
                            </div>
)}
                          </div>

                          {i?.description && i?.description !== "0" && (
                            <span class="mt-4 inline-flex items-center gap-x-1.5 py-3 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">
                              <b>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  class="w-5 h-5 mr-2"
                                >
                                  <path d="M15.98 1.804a1 1 0 0 0-1.96 0l-.24 1.192a1 1 0 0 1-.784.785l-1.192.238a1 1 0 0 0 0 1.962l1.192.238a1 1 0 0 1 .785.785l.238 1.192a1 1 0 0 0 1.962 0l.238-1.192a1 1 0 0 1 .785-.785l1.192-.238a1 1 0 0 0 0-1.962l-1.192-.238a1 1 0 0 1-.785-.785l-.238-1.192ZM6.949 5.684a1 1 0 0 0-1.898 0l-.683 2.051a1 1 0 0 1-.633.633l-2.051.683a1 1 0 0 0 0 1.898l2.051.684a1 1 0 0 1 .633.632l.683 2.051a1 1 0 0 0 1.898 0l.683-2.051a1 1 0 0 1 .633-.633l2.051-.683a1 1 0 0 0 0-1.898l-2.051-.683a1 1 0 0 1-.633-.633L6.95 5.684ZM13.949 13.684a1 1 0 0 0-1.898 0l-.184.551a1 1 0 0 1-.632.633l-.551.183a1 1 0 0 0 0 1.898l.551.183a1 1 0 0 1 .633.633l.183.551a1 1 0 0 0 1.898 0l.184-.551a1 1 0 0 1 .632-.633l.551-.183a1 1 0 0 0 0-1.898l-.551-.184a1 1 0 0 1-.633-.632l-.183-.551Z" />
                                </svg>
                                {item?.firstname}'s experience
                                <br />
                                <br />
                                <span className="font-normal">
                                  {i?.description}
                                </span>
                              </b>
                            </span>
                          )}

                          {i?.proof && i?.proof !== null && i?.proof !== 0 && (
                            <div className="mt-4">
                              <>
                                {/* List Group */}
                                <ul className="flex flex-col justify-end text-start -space-y-px">
                                  <li
                                    style={{ background: "#f3fbf2" }}
                                    className="flex items-center gap-x-2 p-3 bg-[#f3fbf2 !important] text-sm bg-white border text-gray-800 first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-gray-200"
                                  >
                                    <div className="w-full flex justify-between truncate">
                                      <span className="me-3 flex items-center gap-4">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          class="w-5 h-5 text-green"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                                          />
                                        </svg>{" "}
                                        Student Sent Proof
                                      </span>
                                      <Link
                                        type="button"
                                        className="flex items-center gap-x-2 text-gray-500 hover:text-blue-500 whitespace-nowrap darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                                        to={i?.proof}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <svg
                                          className="flex-shrink-0 w-4 h-4"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                          <polyline points="7 10 12 15 17 10" />
                                          <line
                                            x1={12}
                                            x2={12}
                                            y1={15}
                                            y2={3}
                                          />
                                        </svg>
                                        Download
                                      </Link>
                                    </div>
                                  </li>
                                </ul>
                                {/* End List Group */}
                              </>
                            </div>
                          )}
                          <div className="mt-4">
                            <button
                              class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-blue-600 hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-blue-500 darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                              onClick={() => handleEditClick(i)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                                />
                              </svg>
                              Edit Activity
                            </button>
                          </div>
                        </div>

                        <Modal
                          isOpen={isEditing}
                          onRequestClose={() => setIsEditing(false)}
                          style={modalStyles}
                        >
                          {/* Your editing form */}
                          <div>
                            <h4>Edit {category?.replace("Activities", "")} </h4>
                            <div className="mb-2">
                              <label className="block text-gray-700 text-sm font-bold mb-1">
                                Grade
                              </label>
                              <input
                                value={grade}
                                onChange={(e) => setGrade(e.target.value)}
                                className="input input-bordered w-full"
                                id="username"
                                type="text"
                                placeholder="Grade"
                              />
                            </div>
                            <div className="mb-2">
                              <label className="block text-gray-700 text-sm font-bold mb-1">
                                Month(s)
                              </label>
                              <input
                                value={month}
                                onChange={(e) => setMonth(e.target.value)}
                                className="input input-bordered w-full"
                                id="username"
                                type="text"
                                placeholder="Month"
                              />
                            </div>

                            {(() => {
                              if (
                                category?.replace("Activities", "") !==
                                "Society"
                              ) {
                                return (
                                  <div className="mb-4">
                                    {/* <label className="block text-gray-700 text-sm font-bold mb-1">
                                Scope
                              </label>
                              <input
                                value={scope}
                                onChange={(e) => setScope(e.target.value)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="username"
                                type="text"
                                placeholder="Scope"
                              /> */}
                                    <label className="block text-gray-700 text-sm font-bold mb-1">
                                      Scope
                                    </label>

                                    <select
                                      className="select w-full select-bordered"
                                      name="scope"
                                      value={scope}
                                      onChange={(e) => setScope(e.target.value)}
                                    >
                                      <option selected>{"Pick one"}</option>
                                      <option value="1">{"Class"}</option>
                                      <option value="1.2">{"Section"}</option>
                                      <option value="1.51">{"House"}</option>
                                      <option value="1.52">{"School"}</option>
                                    </select>
                                  </div>
                                );
                              }
                            })()}

                            <div className="flex items-center gap-2 ">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={handleSave}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-error btn-sm"
                                onClick={() => setIsEditing(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Modal>
                      </div>
                      <div className="w-full md:w-2/4 flex flex-col gap-4">
                        <div className="w-full grid grid-cols-1 md:flex md:flex-items-center md:justify-end gap-3">
                          <button
                            onClick={() => handleApproveActivity(i, iIndex)}
                            className="btn btn-success text-white "
                          >
                            Approve{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              data-slot="icon"
                              class="w-5 h-5"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleProofActivity(i, iIndex)}
                            className="btn btn-warning text-white"
                          >
                            Need Proof{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              data-slot="icon"
                              class="w-5 h-5"
                            >
                              <path d="M6.75 8.25a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z" />
                              <path
                                fill-rule="evenodd"
                                d="M9 2a7 7 0 1 0 4.391 12.452l3.329 3.328a.75.75 0 1 0 1.06-1.06l-3.328-3.329A7 7 0 0 0 9 2ZM3.5 9a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleRejectActivity(i, iIndex)}
                            className="btn btn-error text-white"
                          >
                            Reject{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              data-slot="icon"
                              class="w-5 h-5"
                            >
                              <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                            </svg>
                          </button>
                        </div>
                        <div className="flex justify-end">
                          {/* <textarea value={leadFeadBack[iIndex] || ''} onChange={(e) => {
        const newLeadFeedbacks = [...leadFeadBack];
        newLeadFeedbacks[iIndex] = e.target.value;
        setLeadFeedBack(newLeadFeedbacks);
      }} placeholder='Share comments' className='w-3/4 h-32 border-2 border-gray-100 p-4 rounded-2xl text-black' />
 */}

                          <>
                            {/* Floating Textarea */}
                            <div className="relative w-full md:w-11/12">
                              <textarea
                                id="hs-floating-textarea"
                                className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600
  focus:pt-6
  focus:pb-2
  [&:not(:placeholder-shown)]:pt-6
  [&:not(:placeholder-shown)]:pb-2
  autofill:pt-6
  autofill:pb-2"
                                placeholder="Share feedback"
                                value={leadFeadBack[iIndex] || ""}
                                onChange={(e) => {
                                  const newLeadFeedbacks = [...leadFeadBack];
                                  newLeadFeedbacks[iIndex] = e.target.value;
                                  setLeadFeedBack(newLeadFeedbacks);
                                }}
                              />
                              <label
                                htmlFor="hs-floating-textarea"
                                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent darki:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
    peer-focus:text-xs
    peer-focus:-translate-y-1.5
    peer-focus:text-gray-500
    peer-[:not(:placeholder-shown)]:text-xs
    peer-[:not(:placeholder-shown)]:-translate-y-1.5
    peer-[:not(:placeholder-shown)]:text-gray-500"
                              >
                                Comment
                              </label>
                            </div>

                            {/* End Underline Floating Textarea */}
                          </>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <h4 className="text-center  text-gray-500">
                  No activities yet.
                </h4>
              )}

              {item?.activities?.length > 0 ? (
                item?.activities
                  .filter((i) => i.status !== "0") // Filter activities where status is 0
                  .map((i, iIndex) => (
                    <div
                      key={iIndex}
                      className="gap-6 border-2 my-4 border-gray-100 p-7 flex flex-col md:flex-row w-full rounded-3xl items-center"
                    >
                      <div className="w-full md:w-2/4 flex flex-col gap-5">
                        <div className="w-1/3 ">
                          <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darko:bg-white/10 darko:text-white">
                            {i?.category && (
                              <span>
                                {i.category.replace(/Activities$/, "")}
                                {i.category.endsWith("LeadershipActivities") &&
                                  " 🏆"}
                                {i.category.endsWith("SportsActivities") &&
                                  " ⚽"}
                                {i.category.endsWith("SocietyActivities") &&
                                  " ♠️"}
                                {i.category.endsWith("EventsActivities") &&
                                  " 📆"}
                                {i.category.endsWith("AwardActivities") && "🎖️"}
                                {i.category.endsWith("LangActivities") && "🔠"}
                                {i.category.endsWith("SocialActivities") &&
                                  "🎗️"}
                              </span>
                            )}
                          </span>
                        </div>
                        {/* <h2 className='font-semibold text-3xl text-black m-0'>{i?.activity_name}</h2> */}
                        {(() => {
                          if (i?.category === "LeadershipActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getLeadershipActivityLabel(
                                      i?.activity_name
                                    )}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "SocietyActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getSocietyNameLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "SportsActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getSportsLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "EventsActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getEventManagementLabels(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "SocialActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getSocialActivityLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "LangActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getLanguageLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                  {getLanguageLabel(i?.activity_name)}
                                  </div>
                                )}
                              </div>
                            );
                          } else if (i?.category === "AwardActivities") {
                            return (
                              <div>
                                {/^\d+\.\d+$/.test(i?.activity_name) ? (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {getAwardsLabel(i?.activity_name)}
                                  </div>
                                ) : (
                                  <div className="font-semibold text-xl text-black m-0">
                                    {i?.activity_name}
                                  </div>
                                )}
                              </div>
                            );
                          }
                        })()}
                        <div className="w-full flex items-center flex-wrap gap-3">
                        <div className="w-full flex items-center flex-wrap gap-3">
                            <div className="border-2 border-gray-100 flex items-center justify-center rounded-lg px-2.5 py-1">
                              <span className="font-medium text-sm">
                                Performed in Grade {i?.grade}
                              </span>
                            </div>
                            {
                              (i?.duration > 0 && (
                                <div className="border-2 border-gray-100 flex items-center justify-center rounded-lg px-2.5 py-1">
                                  <span className="font-medium text-sm">
                                    {i.duration} month's
                                  </span>
                                </div>
                              ))}


                              

                              { i.category === 'LangActivities' && (
  <div className="border-2 border-gray-100 flex items-center justify-center rounded-lg px-2.5 py-1">
    <span className="font-medium text-sm">
      {getLangProfLabel(i?.prof)} proficiency
    </span>
  </div>
)}

                            

                            { i.category !== 'LangActivities' && (
                              <div className="border-2 border-gray-100 flex items-center justify-center rounded-lg px-2.5 py-1">
                              <span className="font-medium text-sm">
                                {getEngagementLabel(i?.service_hours)}{" "}
                                dedication
                              </span>
                            </div>
)}
                          </div>

                          {i?.description && i?.description !== "0" && (
                            <span class="mt-4 inline-flex items-center gap-x-1.5 py-3 px-3 rounded-lg text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500">
                              <b>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  class="w-5 h-5 mr-2"
                                >
                                  <path d="M15.98 1.804a1 1 0 0 0-1.96 0l-.24 1.192a1 1 0 0 1-.784.785l-1.192.238a1 1 0 0 0 0 1.962l1.192.238a1 1 0 0 1 .785.785l.238 1.192a1 1 0 0 0 1.962 0l.238-1.192a1 1 0 0 1 .785-.785l1.192-.238a1 1 0 0 0 0-1.962l-1.192-.238a1 1 0 0 1-.785-.785l-.238-1.192ZM6.949 5.684a1 1 0 0 0-1.898 0l-.683 2.051a1 1 0 0 1-.633.633l-2.051.683a1 1 0 0 0 0 1.898l2.051.684a1 1 0 0 1 .633.632l.683 2.051a1 1 0 0 0 1.898 0l.683-2.051a1 1 0 0 1 .633-.633l2.051-.683a1 1 0 0 0 0-1.898l-2.051-.683a1 1 0 0 1-.633-.633L6.95 5.684ZM13.949 13.684a1 1 0 0 0-1.898 0l-.184.551a1 1 0 0 1-.632.633l-.551.183a1 1 0 0 0 0 1.898l.551.183a1 1 0 0 1 .633.633l.183.551a1 1 0 0 0 1.898 0l.184-.551a1 1 0 0 1 .632-.633l.551-.183a1 1 0 0 0 0-1.898l-.551-.184a1 1 0 0 1-.633-.632l-.183-.551Z" />
                                </svg>
                                {item?.firstname}'s experience
                                <br />
                                <br />
                                <span className="font-normal">
                                  {i?.description}
                                </span>
                              </b>
                            </span>
                          )}


                        </div>

                        {i?.proof && i?.proof !== null && i?.proof !== 0 && (
                          <div className="mt-4">
                            <>
                              {/* List Group */}
                              <ul className="flex flex-col justify-end text-start -space-y-px">
                                <li
                                  style={{ background: "#f3fbf2" }}
                                  className="flex items-center gap-x-2 p-3 bg-[#f3fbf2 !important] text-sm bg-white border text-gray-800 first:rounded-t-lg first:mt-0 last:rounded-b-lg darki:bg-slate-900 darki:border-gray-700 darki:text-gray-200"
                                >
                                  <div className="w-full flex justify-between truncate">
                                    <span className="me-3 flex items-center gap-4">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5 text-green"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                                        />
                                      </svg>{" "}
                                      Student Sent Proof
                                    </span>
                                    <Link
                                      type="button"
                                      className="flex items-center gap-x-2 text-gray-500 hover:text-blue-500 whitespace-nowrap darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                                      to={i?.proof}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <svg
                                        className="flex-shrink-0 w-4 h-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                        <polyline points="7 10 12 15 17 10" />
                                        <line x1={12} x2={12} y1={15} y2={3} />
                                      </svg>
                                      Download
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                              {/* End List Group */}
                            </>
                          </div>
                        )}
                      </div>
                      <div className="w-full md:w-2/4 flex flex-col gap-4">
                        {(() => {
                          if (i?.status === "1") {
                            return (
                              <div className="flex justify-end">
                                <span class="inline-flex w-max items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-teal-100 text-teal-800 darki:bg-teal-800/30 darki:text-teal-500">
                                  Approved
                                </span>
                              </div>
                            );
                          } else if (i?.status === "2") {
                            return (
                              <div className="flex justify-end">
                                <span class="inline-flex w-max items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-red-100 text-red-800 darki:bg-red-800/30 darki:text-red-500">
                                  Rejected
                                </span>
                              </div>
                            );
                          } else if (i?.status === "3") {
                            return (
                              <div className="flex justify-end">
                                <span class="inline-flex w-max items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 darki:bg-yellow-800/30 darki:text-yellow-500">
                                  Need Proof
                                </span>
                              </div>
                            );
                          }
                        })()}

                        <div className="w-full grid grid-cols-1 md:flex md:flex-items-center md:justify-end gap-3">
                          <button
                            onClick={() => handleApproveActivity(i, iIndex)}
                            className="btn btn-success text-white "
                          >
                            Approve{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              data-slot="icon"
                              class="w-5 h-5"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleProofActivity(i, iIndex)}
                            className="btn btn-warning text-white"
                          >
                            Need Proof{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              data-slot="icon"
                              class="w-5 h-5"
                            >
                              <path d="M6.75 8.25a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Z" />
                              <path
                                fill-rule="evenodd"
                                d="M9 2a7 7 0 1 0 4.391 12.452l3.329 3.328a.75.75 0 1 0 1.06-1.06l-3.328-3.329A7 7 0 0 0 9 2ZM3.5 9a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleRejectActivity(i, iIndex)}
                            className="btn btn-error text-white"
                          >
                            Reject{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              data-slot="icon"
                              class="w-5 h-5"
                            >
                              <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                            </svg>
                          </button>
                        </div>
                        <div className="flex justify-end">
                          {/* <textarea value={leadFeadBack[iIndex] || ''} onChange={(e) => {
        const newLeadFeedbacks = [...leadFeadBack];
        newLeadFeedbacks[iIndex] = e.target.value;
        setLeadFeedBack(newLeadFeedbacks);
      }} placeholder='Share comments' className='w-3/4 h-32 border-2 border-gray-100 p-4 rounded-2xl text-black' />
 */}

                          <>
                            {/* Floating Textarea */}
                            <div className="relative w-full md:w-11/12">
                              <textarea
                                id="hs-floating-textarea"
                                className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600
  focus:pt-6
  focus:pb-2
  [&:not(:placeholder-shown)]:pt-6
  [&:not(:placeholder-shown)]:pb-2
  autofill:pt-6
  autofill:pb-2"
                                placeholder="Share feedback"
                                defaultValue={
                                  i?.feedback === "0" ? "" : i?.feedback
                                }
                                onChange={(e) => {
                                  const newLeadFeedbacks = [...leadFeadBack];
                                  newLeadFeedbacks[iIndex] = e.target.value;
                                  setLeadFeedBack(newLeadFeedbacks);
                                }}
                              />
                              <label
                                htmlFor="hs-floating-textarea"
                                className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent darki:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
    peer-focus:text-xs
    peer-focus:-translate-y-1.5
    peer-focus:text-gray-500
    peer-[:not(:placeholder-shown)]:text-xs
    peer-[:not(:placeholder-shown)]:-translate-y-1.5
    peer-[:not(:placeholder-shown)]:text-gray-500"
                              >
                                Comment
                              </label>
                            </div>

                            {/* End Underline Floating Textarea */}
                          </>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <h4 className="text-center  text-gray-500">
                  No activities yet.
                </h4>
              )}
            </details>
          </div> </div>
        );
      }
    });

  return (
    <div className=" rounded-2xl w-full p-6 flex flex-col gap-8 max-w-[85rem] mx-auto">
      <div className="w-full flex items-center gap-4 ">
        {/* <input
          type="text"
          placeholder="Search by name"
          
          className="rounded-xl w-1/4"
         
        /> */}


        <div className="relative">
  <input
    type="text"
    id="hs-floating-input-email"
    className="peer p-4 block w-full border-gray-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600
  focus:pt-6
  focus:pb-2
  [&:not(:placeholder-shown)]:pt-6
  [&:not(:placeholder-shown)]:pb-2
  autofill:pt-6
  autofill:pb-2"
    placeholder="Search by Name"
    value={searchQuery}
    onChange={handleSearch}
  />
  <label
    htmlFor="hs-floating-input-email"
    className="absolute top-0 start-0 p-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent darki:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
    peer-focus:text-xs
    peer-focus:-translate-y-1.5
    peer-focus:text-gray-500
    peer-[:not(:placeholder-shown)]:text-xs
    peer-[:not(:placeholder-shown)]:-translate-y-1.5
    peer-[:not(:placeholder-shown)]:text-gray-500"
  >
    Seach Student
  </label>
</div>


        <select
          className="w-32 hs-dropdown-toggle py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
          value={searchField}
          onChange={handleSelectChange}
        >
          <option value="">All grades</option>
          <option value="6">6 Grade</option>
          <option value="7">7 Grade</option>
          <option value="8">8 Grade</option>
          <option value="9">9 Grade</option>
          <option value="10">10 Grade</option>
          <option value="11">11 Grade </option>
          <option value="12">12 Grade </option>
          {/* Add other fields you want to search by */}
        </select>
      </div>
      {displayActivities}
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

export default NewModerator;
