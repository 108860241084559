import React, { useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import Joyride, { STATUS } from "react-joyride";
import Navbar from "../components/Navbar";
import BarChart from "../components/Barchart";
import RadarChart from "../components/RadarChart";
import BarStacked from "../components/BarStacked";
import CoCurricularEventsTable from "../components/CoCurricularEventsTable";
import Barchart from "../components/Barchart";
import { useSwipeable } from "react-swipeable";
import MobileBottom from "../components/MobileBottom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import TimelineStack from "../components/TimelineStack";
import EngagementHeader from "../components/EngagementHeader";
import Popup from "reactjs-popup";
import Draggable from "react-draggable";
import EventCalendar from "../components/EventCalendar";
export default function StudentDashboard() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  let { user } = useContext(AuthContext);
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        //console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  const [activeIndex, setActiveIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeIndex < 1) {
        setActiveIndex(activeIndex + 1);
      }
    },
    onSwipedRight: () => {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
      }
    },
  });

  const dotStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  const dotClass = (index) => {
    return activeIndex === index ? "active-dot" : "inactive-dot";
  };

  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const lanArabic = i18n.language === "ar";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [tourSteps, setTourSteps] = useState([]);
  const [runTour, setRunTour] = useState(false);
  const [joyrideKey, setJoyrideKey] = useState(0); // Add a key to force remount

  useEffect(() => {

    // Define the tour steps here

    if(!lanArabic){
      const newTourSteps = [
        {
          target: '[data-tour="step1"]',
          content: `This is your Stats Section! This area highlights your progress, including your current level, total experience, completed activities, and earned badges. Dive into your achievements and track your educational journey!`,
        },
        {
          target: '[data-tour="step2"]',
          content: `This is your graphs section. Activities graph indicates number of activities in each category. Competency map shows your skillset based on your extracurricular journey`,
        },

        {
          target: '[data-tour="step3"]',
          content: `Realtime Timeline Of Your Extracurricular Activities. All activities are hooked in this timeline`,
        },

        {
          target: '[data-tour="step4"]',
          content: `Based on your progress, system suggests how much you can improve in this academic year`,
        },

        {
          target: '[data-tour="step5"]',
          content: `This is the next step, Roadmap tells you that hwo can you improve your extracurricular profile followed by the activity suggestions in the next tab.`,
        },
        // Add more steps as needed
      ]; setTourSteps(newTourSteps);
    }

    else{
      const newTourSteps = [
        {
          target: '[data-tour="step1"]',
          content: `هذا هو قسم الإحصائيات الخاص بك! يسلط هذا القسم الضوء على تقدمك، بما في ذلك مستواك الحالي، وإجمالي الخبرة، والأنشطة المكتملة، والشارات التي حصلت عليها. استمتع باستعراض إنجازاتك وتتبع رحلتك التعليمية!`,
        },
        {
          target: '[data-tour="step2"]',
          content: `هذا هو قسم الرسوم البيانية الخاص بك. تُظهر رسم بياني للأنشطة عدد الأنشطة في كل فئة. يظهر الخريطة الوظيفية مجموعة مهاراتك استنادًا إلى رحلتك اللاصفية`,
        },

        {
          target: '[data-tour="step3"]',
          content: `الجدول الزمني الفوري لأنشطتك اللاصفية. تتصل جميع الأنشطة بهذا الجدول الزمني`,
        },

        {
          target: '[data-tour="step4"]',
          content: `استنادًا إلى تقدمك، يقترح النظام كم يمكنك تحسين أدائك في هذا العام الأكاديمي`,
        },

        {
          target: '[data-tour="step5"]',
          content: `هذه هي الخطوة التالية، يخبرك خطة الطريق كيف يمكنك تحسين ملفك اللاصفي تبعًا لاقتراحات الأنشطة في التبويب التالي.`,
        },
      ]; setTourSteps(newTourSteps);
    }



  }, [profile]);

  const startTour = () => {
    setRunTour(true);
    setJoyrideKey((prevKey) => prevKey + 1); // Increment the key to force remount
  };




  // useEffect(() => {
  //   if (!user && !profileID) {
  //     console.log("Navigating to login due to lack of user or profileID");
  //     window.location.replace("/login");
  //   }
  // }, [user, profileID, navigate]);

  const [events, setEvents] = useState([]);
  const [networkUser, setNetworkName] = useState([]);
let networkId = profile?.network_in;

useEffect(() => {
    // Fetch all networks
    fetch('https://dev.api.startupearly.com/api/networks/')
      .then((response) => response.json())
      .then((allNetworks) => {
        if (networkId) {
          // Filter the network based on networkId
          const filteredNetwork = allNetworks.find((network) => network.network_id === networkId);

          if (filteredNetwork) {
            // Access the network name from the filtered data
            const networkName = filteredNetwork.name;
            // Do something with the network name, e.g., set it in state
            setNetworkName(networkName);
          } else {
            console.error('Network not found with networkId:', networkId);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching network data:', error);
      });
  }, [networkId]); // Include networkId as a dependency to re-fetch when it changes





useEffect(() => {
  // Fetch data from the '/get_events/' endpoint
  fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
    .then((response) => response.json())
    .then((data) => {
      // Filter events where campus matches the networkUser
      const filteredEvents = data.filter((event) => event.campus === networkUser);
      setEvents(filteredEvents);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
}, [networkUser]); // Include networkUser as a dependency to re-fetch when it changes



  return (
    <div>




      <div>
        <>
          <div class="hidden md:block absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

          <Sidebar />

          {isSidebarVisible && <SideInnerSM />}

          <main
            className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${
              lanArabic ? "xl:mr-72" : "md:ml-[17rem]"
            } rounded-xl`}
          >
            <Navbar toggleSidebar={toggleSidebar} />

            <div className="hidden md:block"><EngagementHeader /></div>

            {/* Navbar */}

            {/* end Navbar */}
            {/* cards */}
            <div className="w-full px-6 py-6 mx-auto">
              <div data-tour="step1">
                <Stats />
              </div>
              {/* cards row 2 */}

              {/* cards row 3 */}
              <div
                className="hidden md:flex flex-wrap mt-6 -mx-3"
                data-tour="step2"
              >

<Draggable>

                <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
                  <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
                    <div className="p-4 pb-0 mb-0 rounded-t-4">
                      <div
                        class={`flex flex-col justify-start ${
                          lanArabic ? "items-end" : ""
                        }`}
                      >
                        <h6 className="mb-2 ">
                          {t("Activity Breakdown by Category")}
                        </h6>

                        <div className={`flex justify-between items-start ${lanArabic ? 'rtl' : ''}`}>

                          <p className="text-sm capitalize leading-normal ">
                            {" "}
                            {t(
                              "Visual summary of activities per category, highlighting your Co-Curricular scope"
                            )}
                            .
                          </p>

                          <Popup
                            trigger={(open) => (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                              </div>
                            )}
                            position="right top"
                            on={["hover", "focus"]}
                          >
                            <div className="bg-black text-white shadow-md p-4 rounded-lg w-8/12">
                              <span>
                                  {t(`Displayed is a graphical summary of your Co-Curricular activities categorized to give you a snapshot of where you're excelling and where you could expand your involvement. The height of each bar correlates with the number of activities you've engaged in within each category, offering a quick view of your comprehensive involvement across the Co-Curricular spectrum.`)}
                              </span>
                            </div>
                          </Popup>
                        </div>
                      </div>
                    </div>
                    <BarChart source="Self" />
                  </div>
                </div>
</Draggable>


<Draggable>
                <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
                  <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                    <div
                      className={`p-4 pb-0 rounded-t-4 ${
                        lanArabic ? "text-end" : ""
                      }`}
                    >
                      <h6 className="mb-2 ">{t("Your Competency Profile")}</h6>

                      <div className={`flex items-start ${lanArabic ? 'rtl flex-row' : ''}`}>
                        <p className="text-sm capitalize leading-normal ">
                          {" "}
                          {t(
                            "Interactive map displaying proficiency across key competency areas."
                          )}
                        </p>

                        <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="left center"
                          on={["hover", "focus"]}
                        >
                          <div className="bg-black text-white max-auto shadow-md p-4 rounded-lg w-8/12">
                            <span>
                            {t(`The competency profile represents the student's skills and strengths across various domains. Each axis signifies a specific area of competency, with the plotted points reflecting the level of proficiency achieved. The encompassed area visualizes the student's overall skill set, providing insights into both their current capabilities and potential areas for development.`)}

                            </span>
                          </div>
                        </Popup>
                      </div>
                    </div>
                    <div className="flex-auto p-4">
                      <RadarChart source="Self" />
                    </div>
                  </div>
                </div>

</Draggable>
              </div>









              <div>
                <div
                  className="flex md:hidden flex-wrap mt-6 -mx-3"
                  {...handlers}
                >
                  {activeIndex === 0 && (
                    <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
                      <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
                        <div className="p-4 pb-0 mb-0 rounded-t-4">
                          <div
                            class={`flex flex-col justify-start ${
                              lanArabic ? "items-end" : ""
                            }`}
                          >
                            <h6 className="mb-2 ">Activities</h6>
                            <p className="text-sm capitalize leading-normal ">
                              {" "}
                              Activity since your last update.
                            </p>

                            <div className="flex justify-between items-start">
                              <p className="text-sm capitalize leading-normal ">
                                {" "}
                                {t(
                                  "Interactive map displaying proficiency across key competency areas."
                                )}
                              </p>

                              <Popup
                                trigger={(open) => (
                                  <div>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                      />
                                    </svg>
                                  </div>
                                )}
                                position="left center"
                                on={["hover", "focus"]}
                              >
                                <div className="bg-black text-white max-auto shadow-md p-4 rounded-lg w-8/12">
                                  <span>

{t(`The competency profile represents the student's skills and strengths across various domains. Each axis signifies a specific area of competency, with the plotted points reflecting the level of proficiency achieved. The encompassed area visualizes the student's overall skill set, providing insights into both their current capabilities and potential areas for development.`)}
                                  </span>
                                </div>
                              </Popup>
                            </div>
                          </div>
                        </div>
                        <BarChart source="Self" />
                      </div>

                      <div style={dotStyle}>
                        <div className={dotClass(0)}></div>
                        <div className={dotClass(1)}></div>
                      </div>
                    </div>
                  )}
                  {activeIndex === 1 && (
                    <div
                      className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none"
                      data-tour="step4"
                    >
                      <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                        <div
                          className={`p-4 pb-0 rounded-t-4 ${
                            lanArabic ? "text-end" : ""
                          }`}
                        >
                          <h6 className="mb-2 ">
                            {t("Your Competency Profile")}
                          </h6>
                          <p className="text-sm capitalize leading-normal ">
                            {t(
                              "Interactive map displaying proficiency across key competency areas."
                            )}
                          </p>
                        </div>
                        <div className="flex-auto p-4">
                          <RadarChart source="Self" />
                        </div>
                      </div>

                      <div style={dotStyle}>
                        <div className={dotClass(0)}></div>
                        <div className={dotClass(1)}></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>













              <div className="flex flex-wrap mt-6 -mx-3 relative ">
                <div
                  className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none"
                  data-tour="step4"
                >
                  <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
                    <div className="p-4 pb-0 mb-0 rounded-t-4">
                      <div
                        class={`flex flex-col justify-start ${
                          lanArabic ? "items-end" : ""
                        }`}
                      >
                        <h6 className="mb-2 ">
                          {t("Growth Forecast in Co-Curricular Activities")}
                        </h6>

                        <div className="flex justify-between items-start">
                          <p className="text-sm capitalize leading-normal ">
                            {" "}
                            {t(
                              "Anticipated trajectory of Co-Curricular engagement"
                            )}
                          </p>

                          <Popup
                            trigger={(open) => (
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6 hidden"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                  />
                                </svg>
                              </div>
                            )}
                            position="top center"
                            on={["hover", "focus"]}
                          >
                            <div className="bg-black text-white max-auto shadow-md p-4 rounded-lg w-8/12">
                              <span>
                                This competency profile represents the student's
                                skills and strengths across various domains.
                                Each axis signifies a specific area of
                                competency, with the plotted points reflecting
                                the level of proficiency achieved. The
                                encompassed area visualizes the student's
                                overall skill set, providing insights into both
                                their current capabilities and potential areas
                                for development.
                              </span>
                            </div>
                          </Popup>
                        </div>
                      </div>
                    </div>
                    <BarStacked />
                  </div>
                </div>
                <div
                  className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none"
                  data-tour="step5"
                >
                  <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                    <div
                      className={`p-4 pb-0 rounded-t-4 ${
                        lanArabic ? "text-end" : ""
                      }`}
                    >
                      <h6 className="mb-2 ">
                        {t("Co-Curricular Journey Timeline")}
                      </h6>

                      <div className={`flex  items-start ${lanArabic ? 'rtl text-end justify-' : 'justify-between gap-2'}`}>
                        <p className="text-sm capitalize leading-normal ">
                          {" "}
                          {t(
                            "Chronological display of your extracurricular engagements and milestones"
                          )}
                        </p>

                        <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="top center"
                          on={["hover", "focus"]}
                        >
                          <div className="bg-black text-white max-auto shadow-md p-4 rounded-lg w-8/12">
                            <span>
                            {t("Dive into the story of your extracurricular growth. This interactive timeline chronicles your journey, capturing the roles you've embraced and the skills you've cultivated. Utilize the filters to reflect on specific activities or expand each entry to reminisce and celebrate your achievements.")}{" "}
                            </span>
                          </div>
                        </Popup>
                      </div>

                      <div>
                        <TimelineStack className="bg-white" source="Self" />
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>




              <div className="flex flex-wrap mt-6 -mx-3 relative ">
                <div
                  className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none"
                  data-tour="step4"
                >
                  <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
                    <div className="p-4 pb-0 mb-0 rounded-t-4">
                      <div
                        class={`flex flex-col justify-start ${
                          lanArabic ? "items-end" : ""
                        }`}
                      >
                        <h6 className="mb-2 ">


                          {t("Personal Growth Pathway")}
                        </h6>

                        <div className="flex justify-between items-start">
                          <p className="text-sm capitalize leading-normal ">
                            {" "}
                            {t(
                            "Tailored actions to enhance your Co-Curricular portfolio"
                          )}
                          </p>

                          <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="top center"
                          on={["hover", "focus"]}
                        >
                            <div className="bg-black text-white max-auto shadow-md p-4 rounded-lg w-8/12">
                              <span>
                              Unlock your potential with customized recommendations designed to expand your Co-Curricular engagement. Each suggestion here is crafted to build on your existing activities and interests, guiding you towards new experiences and opportunities for personal and professional growth. Click on a suggestion to see detailed steps and resources for realization.
                              </span>
                            </div>
                          </Popup>
                        </div>
                      </div>
                    </div>
                    <Tabs className="mt-4">
                        {/* <TabList className="tab-list hidden">
                          <Tab className="tab">{t("Roadmap")}</Tab>
                          <Tab className="tab">{t("Suggestions")}</Tab>
                        </TabList> */}

                        <TabPanel className="tab-panel">
                          <div
                            className={`p-0 pb-0 rounded-t-4 ${
                              lanArabic ? "text-end" : ""
                            }`}
                          ></div>
                          <div className="flex-auto p-4">
                            {(() => {
                              if (
                                profile?.roadmap === null ||
                                profile?.roadmap === 0 ||
                                profile?.roadmap === "" ||
                                profile?.roadmap === "0"
                              ) {
                                return (
                                  <p className="bg-gray-100 p-8 rounded-xl">
                                    Your roadmap is being calculated{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                  </p>
                                );
                              } else {
                                return (
                                  <p className="bg-gray-100 p-8 rounded-xl">
                                    Dear {profile?.firstname},
                                    {lanArabic ? (
                                      <div>
                                        {" "}
                                        عزيزي إيزابيل، قد تفكرين في قيادة تنفيذ
                                        مشاريع فنية تعاونية في مناطق نورثفيل،
                                        تشمل مشاركة آخرين مهتمين بالفن. استضافة
                                        معارض فنية أو حضور استراحات فنية يمكن أن
                                        يعززان مهاراتك الفنية ويوفران فرصًا
                                        للتواصل والتواصل مع محبي الفن الآخرين.
                                      </div>
                                    ) : (
                                      <div> {profile?.roadmap}</div>
                                    )}
                                  </p>
                                );
                              }
                            })()}

                            <CoCurricularEventsTable />
                          </div>
                        </TabPanel>
                      </Tabs>
                  </div>
                </div>
                <div
                  className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none mb-32 md:mb-0"
                  data-tour="step5"
                >
                  <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                    <div
                      className={`p-4 pb-0 rounded-t-4 ${
                        lanArabic ? "text-end" : ""
                      }`}
                    >
                      <h6 className="mb-2 ">
                        {t("Events Calendar")}
                      </h6>

                      <div className={`flex  items-start ${lanArabic ? 'rtl text-end justify-' : 'justify-between gap-2'}`}>
                        <p className="text-sm capitalize leading-normal ">
                          {" "}
                          {t(
                            "Events scheduled by your school will be list down here"
                          )}
                        </p>

                        <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="top center"
                          on={["hover", "focus"]}
                        >
                          <div className="bg-black text-white max-auto shadow-md p-4 rounded-lg w-8/12">
                            <span>
                            {t("Your scheduled events will list down here")}{" "}
                            </span>
                          </div>
                        </Popup>
                      </div>

                      <div>
                      <EventCalendar events={events}/>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>


              {/* <div className="flex flex-wrap mt-6 -mx-3 relative ">

                <div
                  className="w-full max-w-full px-3 mt-0 lg:w-12/12 lg:flex-none"
                  data-tour="step5" >
                  <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                    <div
                      className={`p-4 pb-0 rounded-t-4 ${
                        lanArabic ? "text-end" : ""
                      }`}
                    >
                      <h6 className="mb-2 ">{t("Personal Growth Pathway")}</h6>

                      <div className={`flex justify-between items-start ${lanArabic ? 'rtl' : ''}`}>

                        <p className="text-sm capitalize leading-normal ">
                          {" "}
                          {t(
                            "Tailored actions to enhance your Co-Curricular portfolio"
                          )}
                        </p>

                        <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="top center"
                          on={["hover", "focus"]}
                        >
                          <div className="bg-black text-white max-auto shadow-md p-4 rounded-lg w-8/12">
                            <span>
                              {t('Unlock your potential with customized recommendations designed to expand your Co-Curricular engagement. Each suggestion here is crafted to build on your existing activities and interests, guiding you towards new experiences and opportunities for personal and professional growth. Click on a suggestion to see detailed steps and resources for realization.')} {" "}
                            </span>
                          </div>
                        </Popup>
                      </div>

                      <Tabs className="mt-4">
                        <TabList className="tab-list">
                          <Tab className="tab">{t("Roadmap")}</Tab>
                          <Tab className="tab">{t("Suggestions")}</Tab>
                        </TabList>

                        <TabPanel className="tab-panel">
                          <div
                            className={`p-4 pb-0 rounded-t-4 ${
                              lanArabic ? "text-end" : ""
                            }`}
                          ></div>
                          <div className="flex-auto p-4">
                            {(() => {
                              if (
                                profile?.roadmap === null ||
                                profile?.roadmap === 0 ||
                                profile?.roadmap === "" ||
                                profile?.roadmap === "0"
                              ) {
                                return (
                                  <p className="bg-gray-100 p-8 rounded-xl">
                                    Your roadmap is being calculated{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                      />
                                    </svg>
                                  </p>
                                );
                              } else {
                                return (
                                  <p className="bg-gray-100 p-8 rounded-xl">
                                    Dear {profile?.firstname},
                                    {lanArabic ? (
                                      <div>
                                        {" "}
                                        عزيزي إيزابيل، قد تفكرين في قيادة تنفيذ
                                        مشاريع فنية تعاونية في مناطق نورثفيل،
                                        تشمل مشاركة آخرين مهتمين بالفن. استضافة
                                        معارض فنية أو حضور استراحات فنية يمكن أن
                                        يعززان مهاراتك الفنية ويوفران فرصًا
                                        للتواصل والتواصل مع محبي الفن الآخرين.
                                      </div>
                                    ) : (
                                      <div> {profile?.roadmap}</div>
                                    )}
                                  </p>
                                );
                              }
                            })()}

                            <CoCurricularEventsTable />
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>{" "}
                  </div>
                </div>
              </div> */}



            </div>












            {/* <button
              onClick={startTour}
              style={{ zIndex: "9999999999" }}
              className="btn hidden md:block btn-primary rounded-full fixed bottom-4 right-4 bg-blue-500 text-white py-2 px-4 rounded-full shadow-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
            >
              {t('Need Help')} 🌟
            </button> */}
          </main>
        </>
      </div>

      {/* <Joyride
        steps={tourSteps}
        run
        continuous
        scrollToFirstStep
        showProgress
        disableBeacon // Start the tour automatically without highlighting

        styles={{
            options: {
              arrowColor: '#4F0696',
              backgroundColor: '#fff',
              overlayColor: 'rgba(19, 10, 32, 0.46)',
              primaryColor: '#4F0696',
              textColor: '#000',
              width: 900,
              zIndex: 1000,
            }
          }}
      /> */}

      {runTour && (
        <Joyride
          key={joyrideKey}
          steps={tourSteps}
          run={runTour}
          continuous
          scrollToFirstStep
          showProgress
          disableBeacon
          styles={{
            options: {
              arrowColor: "#4F0696",
              backgroundColor: "#fff",
              overlayColor: "rgba(19, 10, 32, 0.46)",
              primaryColor: "#4F0696",
              textColor: "#000",
              width: 900,
              zIndex: 1000,
            },
            beacon: {
              backgroundColor: "#fff", // Change to the desired gold color
              borderRadius: "100px",
              inner: "#4F0696", // Use the original color for the inner part of the beacon
            },
          }}
        />
      )}

      <MobileBottom />
    </div>
  );
}
