import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";



const leaders = [
  { Name: "Sana Faisal", role: "LEADER" },
  { Name: "Kimberly Savia Fernandes", role: "LEADER" },
  { Name: "Heba Qaiser", role: "LEADER" },
  { Name: "Wania Khalid", role: "LEADER" },
  { Name: "Hassaan Ahmed", role: "LEADER" },
  { Name: "Adam Noor", role: "LEADER" },
  { Name: "Mohammed Kashan", role: "LEADER" },
  { Name: "Ali Affan", role: "LEADER" },
  { Name: "Hamdiya Ahmed", role: "LEADER" },
  { Name: "Abdur Rehman", role: "LEADER" },
];

const students = [
  { Name: "Student 1", role: "Member" },
  { Name: "Student 2", role: "Member" },
  { Name: "Student 3", role: "Member" },
  { Name: "Student 4", role: "Member" },
  { Name: "Student 5", role: "Member" },
  { Name: "Student 6", role: "Member" },
  { Name: "Student 7", role: "Member" },
  { Name: "Student 8", role: "Member" },
  { Name: "Student 9", role: "Member" },
  { Name: "Student 10", role: "Member" },
];

function ClubMembersListSample() {
  // const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  // const [members, setmembers] = useState([]);
  // const { id } = useParams();
  // const getMembers = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_KEY}/api/club_members/${id}/`
  //     );
  //     let data = await response.json();
  //     setmembers(data);
  //   } catch (err) {
  //     //console.log("The requested profile does not exist.");
  //   }
  // };
  // useEffect(() => {
  //   getMembers();
  // }, []);


  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [members, setMembers] = useState([]);
  const { id } = useParams();

  const getMembers = () => {
    // Combine leaders and students, but only include the first two leaders
    const combinedMembers = [
      ...leaders.slice(0, 2), // Show max 2 leaders
      ...students,            // Show all 10 students
    ];
    setMembers(combinedMembers);
  };

  useEffect(() => {
    getMembers();
  }, []);


  console.warn('members',members)
  
  return (
    <div>
      <>
        {/* Table Section */}
        <div className="mt-8">
          {/* Card */}
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden darki:bg-slate-900 darki:border-gray-700">
                  {/* Header */}
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 darki:border-gray-700">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 darki:text-gray-200">
                        Users
                      </h2>
                    </div>
                    <div>
                      <div className="inline-flex gap-x-2">
                        <a
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                          href="#"
                        >
                          Export Data
                        </a>
                        <a
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                          href="#"
                        >
                          <svg
                            className="flex-shrink-0 w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                            />
                          </svg>
                          Add user
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Header */}
                  {/* Table */}
                  <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
                    <thead className="bg-gray-50 darki:bg-slate-800">
                      <tr>
                   
                        <th
                          scope="col"
                          className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3 text-start"
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200"></span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Member
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Status
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Participation
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Joined
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-end" />
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
                    {members?.map((member) => (
                        <tr>

                          <td className="h-px w-px whitespace-nowrap">
                            <div className="mx-4">
                              <div className="flex items-center justify-center gap-x-3">
                                <div className="avatar online placeholder">
                                  <div className="bg-secondary text-primary rounded-full w-10">
                                    <span className="text-xl">
                                      {member?.Name
                                        ? member.Name
                                            .split("@")[0]
                                            .substring(0, 2)
                                            .toUpperCase()
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="h-px w-72 whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                              {member?.Name} 
                              </span>
                              <span className="block text-sm text-gray-500">
                              {member?.role}
                              </span>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                          <div className="px-6 py-3">
                                <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                                  <svg
                                    className="w-2.5 h-2.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                  Active
                                </span>
                              </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-xs text-gray-500">
                                  10%
                                </span>
                                <div className="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden darki:bg-gray-700">
                                  <div
                                    className="flex flex-col justify-center overflow-hidden bg-gray-800 darki:bg-gray-200"
                                    role="progressbar"
                                    style={{ width: "25%" }}
                                    aria-valuenow={25}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                May 14, 2024
                              </span>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-1.5">
                              <a
                                className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                                href="#"
                              >
                                Edit
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* End Table */}
                  {/* Footer */}
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 darki:border-gray-700">
                    <div>
                      <p className="text-sm text-gray-600 darki:text-gray-400">
                        <span className="font-semibold text-gray-800 darki:text-gray-200">
                          6
                        </span>{" "}
                        results
                      </p>
                    </div>
                    <div>
                      <div className="inline-flex gap-x-2">
                        <button
                          type="button"
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                        >
                          <svg
                            className="flex-shrink-0 w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m15 18-6-6 6-6" />
                          </svg>
                          Prev
                        </button>
                        <button
                          type="button"
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                        >
                          Next
                          <svg
                            className="flex-shrink-0 w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Footer */}
                </div>
              </div>
            </div>
          </div>
          {/* End Card */}
        </div>
        {/* End Table Section */}
      </>
    </div>
  );
}

export default ClubMembersListSample;