import React, { useState } from "react";

const TimeSlots = ({ selectedDays, schedule, setSchedule }) => {
  const [timeSlot, setTimeSlot] = useState({
    day: "",
    startTime: "",
    endTime: "",
  });

  const handleAddSlot = () => {
    if (timeSlot.day && timeSlot.startTime && timeSlot.endTime) {
      setSchedule({
        ...schedule,
        [timeSlot.day]: [
          ...(schedule[timeSlot.day] || []),
          { startTime: timeSlot.startTime, endTime: timeSlot.endTime },
        ],
      });
      setTimeSlot({ day: "", startTime: "", endTime: "" });
    }
  };

  return (
    <div>
      <h3 className="text-base font-semibold mt-6">Create Time Slots</h3>
      <div className="flex flex-row items-center gap-2 flex-wrap	">
        <select
          value={timeSlot.day}
          className="select select-bordered"
          onChange={(e) => setTimeSlot({ ...timeSlot, day: e.target.value })}
        >
          <option value="">Select Day</option>
          {selectedDays.map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
        <input
          type="time"
          value={timeSlot.startTime}
          className="input input-bordered"
          onChange={(e) =>
            setTimeSlot({ ...timeSlot, startTime: e.target.value })
          }
        />
        <input
          type="time"
          value={timeSlot.endTime}
          className="input input-bordered"
          onChange={(e) =>
            setTimeSlot({ ...timeSlot, endTime: e.target.value })
          }
        />
        <button
          className="btn btn-outline btn-secondary"
          onClick={handleAddSlot}
        >
          {" "}
          + Add Slot
        </button>
      </div>
    </div>
  );
};

export default TimeSlots;
