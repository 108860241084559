import React, { useState, useEffect, useContext, useRef } from 'react';
import AuthContext from "../context/AuthContext";

function CCAStats() {

    let { user } = useContext(AuthContext);
    const scrollContainerRef = useRef(null);
    // Function to scroll the content left
    const scrollLeft = () => {
      if (scrollContainerRef.current) {
        const scrollAmount = 250; // Adjust the scroll amount as needed
        scrollContainerRef.current.scrollLeft -= scrollAmount;
      }
    };
    
    const scrollRight = () => {
      if (scrollContainerRef.current) {
        const scrollAmount = 250; // Adjust the scroll amount as needed
        scrollContainerRef.current.scrollLeft += scrollAmount;
      }
    };

    return (

        <div>
        {/* <div className="flex pb-4" style={{overflowX:'scroll'}}> */}
        
        <div className={`pb-4 gap-2 block ${user.role === 'ccaAdmin' || user.role === 'Network Admin' ? '' : ''}`}>
        
        
  
        
        
                  {/* card1 */}
                  {/* <div className="flex" style={{ overflowX: 'scroll' }} ref={scrollContainerRef}> */}
                  <div className="flex scrollable-container gap-3 hide-scrollbar pb-4" ref={scrollContainerRef} >
                  <div className="w-full max-w-full px-0 mb-2 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
                      <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                          <div className="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                                Total Students
                              </p>
                              <h5 className="mb-2 font-bold ">{20}</h5>
                              <p className="mb-0  hidden">
                                <span className="text-sm font-bold leading-normal text-emerald-500">
                                  +55%
                                </span>
                                since yesterday
                              </p>
                            </div>
                          </div>
                          <div className="px-3 text-right basis-1/3">
                            <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                              <div className=" text-lg relative text-white"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clip-rule="evenodd" />
  <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
</svg>

</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* card2 */}
        
        
                  {(() => {
                    if (user['role']==='Network Admin') {
                        return (
                          <div className="w-full max-w-full px-0 mb-2 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 " >
                      <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                          <div className="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                              Staff Members
                              </p>
                              <h5 className="mb-2 font-bold ">05</h5>
                              <p className="mb-0  hidden">
                                <span className="text-sm font-bold leading-normal text-emerald-500">
                                  +3%
                                </span>
                                since last week
                              </p>
                            </div>
                          </div>
                          <div className="px-3 text-right basis-1/3">
                            <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                            <div className=" text-lg relative text-white" >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M4.5 6.375a4.125 4.125 0 1 1 8.25 0 4.125 4.125 0 0 1-8.25 0ZM14.25 8.625a3.375 3.375 0 1 1 6.75 0 3.375 3.375 0 0 1-6.75 0ZM1.5 19.125a7.125 7.125 0 0 1 14.25 0v.003l-.001.119a.75.75 0 0 1-.363.63 13.067 13.067 0 0 1-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 0 1-.364-.63l-.001-.122ZM17.25 19.128l-.001.144a2.25 2.25 0 0 1-.233.96 10.088 10.088 0 0 0 5.06-1.01.75.75 0 0 0 .42-.643 4.875 4.875 0 0 0-6.957-4.611 8.586 8.586 0 0 1 1.71 5.157v.003Z" />
</svg></div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                        )
                      }
                    })()}
        
        
                  {(() => {
                    if (user['role']==='Staff' || user['role']==='School' || user['role']==='Network Admin') {
                        return (
                          <div className="w-full max-w-full px-0 mb-2 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 " >
                      <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                          <div className="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                              All Societies
                              </p>
                              <h5 className="mb-2 font-bold ">04</h5>
                              <p className="mb-0  hidden">
                                <span className="text-sm font-bold leading-normal text-emerald-500">
                                  +3%
                                </span>
                                since last week
                              </p>
                            </div>
                          </div>
                          <div className="px-3 text-right basis-1/3">
                            <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                              <div className=" text-lg relative text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M6 3a3 3 0 0 0-3 3v2.25a3 3 0 0 0 3 3h2.25a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6ZM15.75 3a3 3 0 0 0-3 3v2.25a3 3 0 0 0 3 3H18a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3h-2.25ZM6 12.75a3 3 0 0 0-3 3V18a3 3 0 0 0 3 3h2.25a3 3 0 0 0 3-3v-2.25a3 3 0 0 0-3-3H6ZM17.625 13.5a.75.75 0 0 0-1.5 0v2.625H13.5a.75.75 0 0 0 0 1.5h2.625v2.625a.75.75 0 0 0 1.5 0v-2.625h2.625a.75.75 0 0 0 0-1.5h-2.625V13.5Z" />
</svg>
</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                        )
                      }
                    })()}
        
        
        
                
        
        
        
                    {(() => {
                    if (user['role']==='society') {
                        return (
                          <div className="w-full max-w-full px-0 mb-2 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 " >
                      <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                          <div className="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                              Total Events
                              </p>
                              <h5 className="mb-2 font-bold ">0</h5>
                              <p className="mb-0  hidden">
                                <span className="text-sm font-bold leading-normal text-emerald-500">
                                  +3%
                                </span>
                                since last week
                              </p>
                            </div>
                          </div>
                          <div className="px-3 text-right basis-1/3">
                            <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                              <i className="ni leading-none ni-app text-lg relative top-3.5 text-white" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                        )
                      }
                    })()}
        
        
        
                  <div className="w-full max-w-full px-0 sm:w-1/2 sm:flex-none xl:w-1/4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
                      <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                          <div className="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                                Requests
                              </p>
                              <h5 className="mb-2 font-bold ">03</h5>
                              <p className="mb-0  hidden">
                                <span className="text-sm font-bold leading-normal text-emerald-500">
                                  +5%
                                </span>
                                than last month
                              </p>
                            </div>
                          </div>
                          <div className="px-3 text-right basis-1/3">
                            <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                              <div className="text-lg relative text-white" >

                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M5.478 5.559A1.5 1.5 0 0 1 6.912 4.5H9A.75.75 0 0 0 9 3H6.912a3 3 0 0 0-2.868 2.118l-2.411 7.838a3 3 0 0 0-.133.882V18a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0 0 17.088 3H15a.75.75 0 0 0 0 1.5h2.088a1.5 1.5 0 0 1 1.434 1.059l2.213 7.191H17.89a3 3 0 0 0-2.684 1.658l-.256.513a1.5 1.5 0 0 1-1.342.829h-3.218a1.5 1.5 0 0 1-1.342-.83l-.256-.512a3 3 0 0 0-2.684-1.658H3.265l2.213-7.191Z" clip-rule="evenodd" />
  <path fill-rule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v6.44l1.72-1.72a.75.75 0 1 1 1.06 1.06l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 1.06-1.06l1.72 1.72V3a.75.75 0 0 1 .75-.75Z" clip-rule="evenodd" />
</svg>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        
        
                 
        
                  {/* card3 */}
        
                  {/* card4 */}
        
        
                  {(() => {
                    if (user['role']==='Staff' || user['role']==='School' || user['role']==='Network Admin') {
                        return (
                          <div className="w-full max-w-full px-0 sm:w-1/2 sm:flex-none xl:w-1/4">
                    <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
                      <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                          <div className="flex-none w-2/3 max-w-full px-3">
                            <div>
                              <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                                All Events
                              </p>
                              <h5 className="mb-2 font-bold ">03</h5>
                              <p className="mb-0  hidden">
                                <span className="text-sm font-bold leading-normal text-emerald-500">
                                  +5%
                                </span>
                                than last month
                              </p>
                            </div>
                          </div>
                          <div className="px-3 text-right basis-1/3">
                            <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                              <div className="text-lg relative text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
  <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clip-rule="evenodd" />
</svg>
</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                        )
                      }
                    })()}
        
        </div>
        
        <div className='flex flex-row gap-3 justify-end'>
        {(() => {
                if (user['role']==='Staff' || user['role']==='School' || user['role']==='Network Admin') {
                  return (
                    <button className="btn btn-sm btn-circle" onClick={scrollLeft}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
        </svg>
        </button>
                  )
                }
              })()}
        
        {(() => {
                if (user['role']==='Staff' || user['role']==='School' || user['role']==='Network Admin') {
                  return (
                    <button className="btn btn-sm btn-circle" onClick={scrollRight}>
        
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
        </svg>
        
        </button>
                  )
                }
              })()}</div>
                </div>
        
            </div>
    )
}

export default CCAStats;