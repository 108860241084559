import React from 'react'
import ContentLoader from 'react-content-loader'

const ResponsiveArticle = props => {
  return (
    <ContentLoader
    speed={2}
    width={'100%'}
    height={'100%'}
    viewBox="0 0 300 615"
    backgroundColor="#f5f5f5"
    foregroundColor="#ebebeb"
    {...props}
  >

    <rect x="152" y="106" rx="0" ry="0" width="145" height="1" />
    <rect x="28" y="127" rx="4" ry="4" width="243" height="31" />
    <rect x="62" y="188" rx="4" ry="4" width="148" height="19" />
    <circle cx="39" cy="197" r="10" />
    <circle cx="39" cy="247" r="10" />
    <circle cx="39" cy="297" r="10" />
    <circle cx="39" cy="347" r="10" />
    <rect x="64" y="237" rx="4" ry="4" width="148" height="19" />
    <rect x="65" y="287" rx="4" ry="4" width="148" height="19" />
    <rect x="64" y="337" rx="4" ry="4" width="148" height="19" />

  </ContentLoader>
)
}

ResponsiveArticle.metadata = {
name: 'Arfath Tade', // My name
github: 'arfath77', // Github username
description:
  'Global sidebar with tabs containig search bar and list of options', // Little tagline
filename: 'GlobalSidebar', // filename of your loader
}
export default ResponsiveArticle