import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./pages/Home";

import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import ChangePasswordForm from "./pages/ChangePasswordForm";
import UpdatePasswordPage from "./pages/UpdatePasswordPage";
import Error from "./pages/Error";
// import LandingPage from "./pages/LandingPage";
import LandingPage from "./pages/Static/LandingPage";
import StudentDasbboard from "./pages/StudentDashboard";
import Category from "./pages/Category";
import Lead from "./pages/Lead";
import LeadStage from "./pages/LeadStage";
import SocietyStage from "./pages/SocietyStage";
import Society from "./pages/Society";
import AwardStage from "./pages/AwardStage";
import Award from "./pages/Award";
import BadgesList from "./pages/BadgesList";
import Cats from "./pages/Cats";
import CategorySM from "./pages/CategorySM";
import StudentProfile from "./components/StudentProfile";
import Network from "./pages/Network";
import NetworkStudents from "./pages/AllNetworkStudents";
import NetworkSchools from "./pages/NetworkSchools";
import CreateClubForm from "./components/CreateClubForm";
import NetworkClubs from "./pages/NetworkClubs";
import NetworkStudentDetails from "./components/NetworkStudentDetails";
import GetInTouch from "./pages/GetInTouch";
import CSVPush from "./pages/CSVPush";
import EmailForm from "./pages/EmailForm";
import Notifications from "./pages/Notifications";
import Challenge from "./pages/Challenge";
import StdChallenges from "./pages/StdChallenges";
import MobileSideNav from "./pages/MobileSideNav";
import Social from "./pages/Social";
import Internship from "./pages/Internship";
import Certification from "./pages/Certification";
import Talent from "./pages/Talent";
import EventMgmt from "./pages/EventMgmt";
import Lang from "./pages/Lang";
import AllNetworkStudents from "./pages/AllNetworkStudents";

import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import PilotForm from "./pages/PilotForm";
import NotificationDetails from "./pages/NotificationDetails";
import Network_StudentList from "./components/Network_StudentList";
import EventsCalendar from "./pages/EventsCalendar";
import AverageApp from "./pages/AverageApp";
import PilotPage from "./pages/PilotPage";
import SideInner from "./components/SideInner";
import PilotPageRadial from "./components/PilotPageRadial";
import StudentDashboardPilot from "./pages/StudentDashboardPilot";
import Moderator from "./pages/Moderator";
import FeedbackLead from "./pages/FeedbackLead";
import FeedbackSociety from "./pages/FeedbackSociety";
import FeedbackEvents from "./pages/FeedbackEvents";
import ModNetwork from "./pages/ModNetwork";
import ModeratorAdmin from "./pages/ModeratorAdmin";
import Report from "./pages/Report";
import ClubDetailsInternal from "./pages/ClubDetailsInternal";
import Tweet from "./pages/Tweet";
import Policy from "./pages/Policy";
import ClubDetails from "./pages/ClubDetails";
import AllClubsPublic from "./pages/AllClubsPublic";
import MyClubs from "./pages/MyClubs";
import AddClub from "./pages/AddClub";
import AddEvent from "./pages/AddEvent";
import Network_StudentTable from "./components/Network_StudentTable";
import AddClubTalha from "./pages/AddClubTalha";
import NetworkClubsTalha from "./pages/NetworkClubsTalha";
import SchoolOnboardForm from "./pages/SchoolOnboardForm";

import EventDetailsPage from "./pages/EventDetailsPage";
import EventDetailsPage_APPRVD from "./pages/EventDetailsPage_APPRVD";
import AttendancePage from "./pages/AttendancePage";
import AttendanceWrapper from "./pages/AttendanceWrapper";
import AddStaff from "./pages/AddStaff";
import AddSchool from "./pages/AddSchool";
import AddClubCategory from "./pages/AddClubCategory";
import NetworkStaff from "./components/NetworkStaff";
import StdClubs from "./pages/StdClubs";
import { useEffect } from "react";
import NetworkStudentsUpload from "./components/NetworkStudentsUpload";
import OnboarLanding from "./pages/OnboarLanding";
import LoginNew from "./pages/LoginNew";
import NewAdmin from "./pages/NewAdmin";
import GCalendar from "./pages/GCalendar";
import AdminDashboard from "./pages/AdminDashboard";
import AdminLayout from "./pages/AdminLayout";
import AdminAttendance from "./pages/AdminAttendance";
import GetDemo from "./pages/Static/GetDemo";
import Features_EMS from "./pages/Static/Features_EMS";
import Features_CCT from "./pages/Static/Features_CCT";
import Features_Leap from "./pages/Static/Features_Leap";
import SchoolCalendar from "./pages/SchoolCalendar";
import SchoolDetails from "./pages/SchoolDetails";
import AboutUs from "./pages/Static/AboutUs";
import SchoolCalendarStd from "./pages/SchoolCalendarStd";
import StudentAttendance from "./pages/StudentAttendance";
import ChatBotForm from "./pages/ChatBotForm";
import SchoolEvents from "./pages/SchoolEvents";
import StudentCouncils from "./pages/StudentCouncils";
import StudentCouncilForm from "./pages/StudentCouncilForm";
import SchoolVenues from "./pages/SchoolVenues";
import SchoolHouses from "./pages/SchoolHouses";
import SchoolHousesDash from "./pages/SchoolHousesDash";
import HousePointSheet from "./pages/HousePointSheet";
import HouseStudentList from "./pages/HouseStudentList";
import BlogArticle from "./pages/Static/BlogArticle";
import AllBlogs from "./pages/Static/AllBlogs";
import SchoolDashboard from "./pages/SchoolDashboard";
import NetworkDepartments from "./pages/NetworkDepartments";
import InventoryWrapper from "./pages/InventoryWrapper";
import ParentView from "./pages/ParentView";

import AllCaseStudies from "./pages/Static/AllCaseStudies";
import CaseStudyArticle from "./pages/Static/CaseStudyArticle";
import Features_GP from "./pages/Static/Features_GP";
import ScheduleAdmin from "./pages/ScheduleAdmin";
import EventReqForm from "./components/EventReqForm";
import Chats from "./pages/Chats";
import Resources from "./pages/Resources";
import EventForm from "./pages/EventForm";
import Pathway from "./pages/Pathway";
import PostDetails from "./pages/Feeds/PostDetails";
import StudentCouncilDetails from "./pages/StudentCouncilDetails";
import AddClubExtra from "./pages/AddClubExtra";
import NetworkStaffUpload from "./components/NetworkStaffUpload";
import AdminCalendar from "./pages/AdminCalendar";
import AllSchoolCampaigns from "./pages/AllSchoolCampaigns";
import CampDetailsPage from "./pages/CampDetailsPage";
import Sports from './pages/Sports'
import SportsPage from "./components/Sports";
import StdEvents from "./pages/StdEvents";
import EventDetailsStd from "./pages/EventDetailsStd";
import EventDetailsInternal from "./pages/EventDetailsInternal";
import AddEventForm from "./pages/AddEventForm";
import TrackerApp from "./components/TrackerApp";
import SearchUser from "./SearchUser";
import Chat from "./pages/chats/Chat";

function App() {
  
  function languageDetector(options) {
    const savedLanguage = localStorage.getItem('i18nextLng');
    if (savedLanguage) {
      return savedLanguage;
    }
    return options.fallbackLng;
  }
  
  if (!i18n.isInitialized) 
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      // Configuration options go here
      fallbackLng: 'en', // Default language
      supportedLngs: ['en', 'ar'],
      debug: true, // Enable debugging
      interpolation: {
        escapeValue: false, // React already does escaping
      },
      detection: {
        order: ['localStorage','ar', 'en'],
        caches: ['localStorage'], // Use 'localStorage' as a cache for language
        lookupLocalStorage: 'i18nextLng', 
      }, // Use the language detection options
    });


  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/pass_reset" element={<ChangePasswordForm />} exact />
            <Route
              path="/new_pass/user/2959094878/:id"
              element={<UpdatePasswordPage />}
              exact
            />
             <Route path="/features/ems" element={<Features_EMS />} />
             <Route path="/features/cct" element={<Features_CCT />} />
             <Route path="/features/leap" element={<Features_Leap />} />

             <Route path="/book_demo" element={<GetDemo />} />
             <Route path="/blogs" element={<AllBlogs />} />
             <Route path="/blog/:id" element={<BlogArticle />} />
            <Route path="*" element={<Error />} />
            
            <Route path="/sign-in" element={<LoginNew />} />

            <Route path="/case_studies" element={<AllCaseStudies />} />
            <Route path="/case_study/learning-hub" element={<CaseStudyArticle />} />

            <Route path="/query" element={<GetInTouch />} exact />
            <Route path="/onboard_new" element={<OnboarLanding />} exact />
            <Route element={<PrivateRoute />}>
              <Route path="/signup" element={<Signup />} />
              <Route path="/home" element={<Home />} />
              
              <Route path="/student" element={<StudentDasbboard />} />
              <Route path="/network" element={<Network />} />
              <Route path="/network2" element={<NewAdmin />} />
              <Route path="/cats" element={<Category />} />
              <Route path="/categories" element={<Cats />} />
              <Route path="/catsm" element={<CategorySM />} />

              <Route path="/leads" element={<LeadStage />} exact />
              <Route path="/add_lead" element={<Lead />} exact />

              <Route path="/societies" element={<SocietyStage />} exact />
              <Route path="/add_society" element={<Society />} exact />

              <Route path="/awards" element={<AwardStage />} exact />
              <Route path="/add_award" element={<Award />} exact />

              <Route path="/add_social" element={<Social />} exact />
              <Route path="/add_society" element={<Society />} exact />
              <Route path="/add_intern" element={<Internship />} exact />
              <Route path="/add_sports" element={<Sports />} exact />
              <Route path="/add_cert" element={<Certification />} exact />
              <Route path="/add_talent" element={<Talent />} exact />
              <Route path="/add_lang" element={<Lang />} exact />
              <Route path="/add_eventstd" element={<EventMgmt />} exact />

              <Route path="/badges" element={<BadgesList />} exact />
              <Route
                path="/student_profile"
                element={<StudentProfile />}
                exact
              />

              <Route
                path="/network_students"
                element={<NetworkStudents />}
                exact
              />

<Route
                path="/addclub_advanced"
                element={<AddClubExtra />}
                exact
              />
              <Route
                path="/network_students_old"
                element={<Network_StudentList />}
                exact
              />
              <Route
                path="/network_schools"
                element={<NetworkSchools />}
                exact
              />

<Route
                path="/network_departments"
                element={<NetworkDepartments />}
                exact
              />

              
<Route
                path="/search"
                element={<SearchUser />}
                exact
              />


              <Route path="/clubs" element={<NetworkClubs />} exact />
              <Route
                path="/student_nw/:id"
                element={<NetworkStudentDetails />}
                exact
              />
              <Route path="/sidenav" element={<MobileSideNav />} exact />
              <Route path="/pilot" element={<PilotForm />} exact />
              <Route path="/csvpush" element={<CSVPush />} exact />
              <Route path="/csvpush/4dyhx6fvie" element={<CSVPush />} exact />
              <Route path="/email/za58ebpib3" element={<EmailForm />} exact />
              <Route path="/notifications" element={<Notifications />} exact />
              <Route path="/challenges" element={<StdChallenges />} exact />
              <Route
                path="/notification_detail/:id"
                element={<NotificationDetails />}
                exact
              />
              <Route path="/challenge/:id" element={<Challenge />} exact />
              <Route path="/averageapp" element={<AverageApp />} exact />

              <Route path="/calendar" element={<EventsCalendar />} exact />
              <Route path="/school_calendar" element={<SchoolCalendar />} exact />

              <Route path="/bisr" element={<PilotPage />} exact />
              <Route path="/demo" element={<PilotPage />} exact />
              <Route path="/doe" element={<PilotPage />} exact />
              <Route path="/rowad" element={<PilotPage />} exact />

              <Route path="/testradial" element={<PilotPageRadial />} exact />
              <Route path="/moderate_network" element={<ModNetwork />} exact />
              <Route path="/moderator" element={<ModeratorAdmin />} exact />
              <Route
                path="/preview"
                element={<StudentDashboardPilot />}
                exact
              />

              <Route
                path="/feedback_lead/:id"
                element={<FeedbackLead />}
                exact
              />
              <Route
                path="/feedback_society/:id"
                element={<FeedbackSociety />}
                exact
              />
              <Route
                path="/feedback_sports/:id"
                element={<FeedbackSociety />}
                exact
              />
              <Route
                path="/feedback_events/:id"
                element={<FeedbackEvents />}
                exact
              />
              <Route
                path="/club_details/:id"
                element={<ClubDetailsInternal />}
                exact
              />
              {/* <Route path="/report" element={<Report />} exact /> */}
              <Route path="/report/:id" element={<Report />} />
              <Route path="/club_timeline/:id" element={<Tweet />} />
              <Route path="/event_timeline/:id" element={<EventDetailsStd />} />
              <Route path="/allclubpublic" element={<AllClubsPublic />} />
              <Route path="/myclub" element={<MyClubs />} />
              <Route path="/policy" element={<Policy />} />
              <Route path="/addclub" element={<AddClub />} />
              <Route path="/addevent" element={<AddEvent />} />
              <Route path="/onboard_school" element={<SchoolOnboardForm />} />
              <Route path="/staff" element={<AddStaff />} />
              {/* <Route path="/event_details/:id" element={<EventDetailsPage />} /> */}
              <Route path="/event_details/:id" element={<EventDetailsInternal />} />
              <Route path="/camp_details/:id" element={<CampDetailsPage />} />
              <Route
                path="/event_details_green"
                element={<EventDetailsPage_APPRVD />}
              />
              <Route path="/event_attendance" element={<AttendanceWrapper />} />
              <Route path="/add_school" element={<AddSchool />} />
              <Route path="/add_club_category" element={<AddClubCategory />} />
              <Route path="/network_staff" element={<NetworkStaff />} />
              <Route
                path="/bulk_students"
                element={<NetworkStudentsUpload />}
              /> <Route
                path="/bulk_staff"
                element={<NetworkStaffUpload />}
              />
              <Route path="/student_clubs" element={<StdClubs />} />
              
              <Route path="/gcalendar2" element={<GCalendar />} />
              {/* <Route path="/admin" element={<AdminDashboard />} /> */}
              <Route path="admin" element={<AdminDashboard />} />
              <Route path="school" element={<SchoolDashboard />} />
              <Route path="attendance" element={<AdminAttendance />} />
              <Route path="/school_data/:school_id" element={<SchoolDetails />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/student_calendar" element={<SchoolCalendarStd />} />
              <Route path="/student_attendance" element={<StudentAttendance />} />
              <Route path="/explorer" element={<ChatBotForm />} />
              <Route path="/school_events" element={<SchoolEvents />} />
              <Route path="/inventory" element={<InventoryWrapper />} />
              <Route path="/student_councils" element={<StudentCouncils />} />
              <Route path="/create_council" element={<StudentCouncilForm />} />
              <Route path="/school_venues" element={<SchoolVenues />} />
              <Route path="/house_program" element={<SchoolHousesDash />} />
              <Route path="/school_houses" element={<SchoolHouses />} />
              <Route path="/house_students" element={<HouseStudentList />} />
              <Route path="/pointsheet/:id" element={<HousePointSheet />} />
              <Route path="/admin_schedule" element={<ScheduleAdmin />} />

              <Route path="/sports" element={<SportsPage />} />

              <Route path="/req_form" element={<EventForm />} />
              <Route path="/communicate" element={<Chats />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/guardian" element={<ParentView />} />
              <Route path="/pathway" element={<Pathway />} />
              <Route path="/post_details/:id" element={<PostDetails/>} exact />
              <Route path="/council-details/:name" element={<StudentCouncilDetails />} />

              <Route path="/admin-calendar" element={<AdminCalendar />} />
              <Route path="/campaigns" element={<AllSchoolCampaigns />} />
              <Route path="/student_events" element={<StdEvents />} />
              <Route path="/add_event" element={<AddEventForm />} />
              <Route path="/trackerApp" element={<TrackerApp />} />

              <Route path="/comms" element={<Chat />} />

            </Route>
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
