import React, { useContext, useEffect, useState } from "react";

import AuthContext from "../context/AuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import { MultiSelect } from "react-multi-select-component";

const AddSingleStaff = () => {
  const [school, setSchool] = useState(null);

  const { user } = useContext(AuthContext);
  const userID = user["user_id"];
  const profileID = userID;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("1");
  const [schools, setSchools] = useState([]);
  const [profile, setprofile] = useState("");

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  const [currentNetwork, setCurrentNetwork] = useState(null); // Initialize the currentNetwork state
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        setprofile(response?.data);
        // setNetwork(response?.data?.network);
        if (url === "network_profile") {
          setCurrentNetwork(response?.data?.network_id); // Ensure data is not null before accessing the property
        } else if (url === "school_profile") {
          setCurrentNetwork(response?.data?.school_id); // Ensure data is not null before accessing the property
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleGradeChange = (e) => {
    setSelectedGrade(e.target.value);
  };

  useEffect(() => {
    if (currentNetwork) {
      // Fetch schools from the Django API
      fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const filteredSchools = data.filter(
            (school) =>
              parseInt(school.network) === parseInt(profile?.network_id)
          );
          setSchools(filteredSchools);
        })
        .catch((error) => {
          console.error("Error fetching schools:", error);
        });
    }
  }, [currentNetwork]);

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  let campus = 0;
  let network = 0;

  if (user["role"] === "Network Admin") {
    campus = selectedOption;
    network = currentNetwork;
  } else if (user["role"] === "School Admin") {
    campus = profile?.school_id;
    network = profile?.network;
  }

  const handleSubmit = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/single_register/`, {
        name: name,
        email: email,
        grade: selectedGrade,
        school_id: campus,
        network_id: network,
      })
      .then((response) => {
        console.log(response);
        toast.success("Staff Added");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Staff Not Added");
      });
  };


  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedks, setselectedks] = useState([]);

  const roles = [
    { label: "Network Admin", value: "network_admin" },
    { label: "Campus Admin", value: "campus_admin" },
    { label: "Staff Member", value: "staff_member" }
  ];

  const keystages = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
  ];

  const permissions = [
    { label: "Can manage staff", value: "can_manage_staff" },
    { label: "Can manage clubs", value: "can_manage_clubs" },
    { label: "Can manage students", value: "can_manage_students" },
    { label: "Moderate activities", value: "moderate_activities" },
    { label: "Manage schedule", value: "manage_schedule" },
    { label: "Manage roster", value: "manage_roster" }
  ];
  const isStaffSelected = selectedRoles.some(role => role.value === 'staff_member');

  return (
    <div className="bg-white rounded-xl p-6">
      <h3 className="text-lg">Add single staff</h3>
      <p>Add single user instead of uploading file</p>

      <div className="flex flex-row gap-x-4  my-2">
        <div>
          <label>
            Name:
            <input
              type="text"
              className="input input-bordered w-full max-w-xs"
              value={name}
              onChange={handleNameChange}
              placeholder="Staff Full Name"
            />
          </label>
        </div>
        <div>
          <label>
            Email:
            <input
              type="text"
              className="input input-bordered w-full max-w-xs"
              value={email}
              onChange={handleEmailChange}
              placeholder="Staff Email Address"
            />
          </label>
        </div>
      </div>

      

      <div className="flex flex-row gap-x-4 my-2">

      <div className="w-full">
          <label className="w-full">
            Key stage:
           
          <MultiSelect
            options={keystages}
            value={selectedks}
            onChange={setSelectedRoles}
            labelledBy="Allot Key Stage"
          />
          </label>
        </div>

        <div className="w-full">
          <label className="w-full">
            Role:
            <MultiSelect
            options={roles}
            value={selectedRoles}
            onChange={setSelectedRoles}
            labelledBy="Select Roles"
          />
          </label>
        </div>

       
      </div>

      <div className="w-full">
          <label className="w-full">
            Permissions:
            <MultiSelect
              options={permissions}
              value={selectedPermissions}
              onChange={setSelectedPermissions}
              labelledBy="Select Permissions"
            />
          </label>
        </div>

      <div>
        <button className="btn btn-primary mt-6" >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddSingleStaff;
