import React, { createContext, useState, useEffect } from "react"
import jwt_decode from "jwt-decode"
import { useLocation, useNavigate } from "react-router-dom"
import { toast, Toaster } from "react-hot-toast"
import { GoogleLogin } from "@react-oauth/google"
import jwtDecode from "jwt-decode"
import { signInWithCustomToken } from "firebase/auth" // Import Firebase function
import axios from "axios"
import { auth } from "../pages/chats/firebaseConfig"

const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {
    // Add auth as a prop
    let authTokenInitState = localStorage.getItem("authTokens")
        ? JSON.parse(localStorage.getItem("authTokens"))
        : null

    let userState = localStorage.getItem("authTokens")
        ? jwt_decode(localStorage.getItem("authTokens"))
        : null

    let [user, setUser] = useState(() => userState)
    let [authTokens, setAuthTokens] = useState(() => authTokenInitState)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000 // 24 hours in milliseconds

        const deleteAuthTokenTimer = setTimeout(() => {
            localStorage.removeItem("authTokens")
            setAuthTokens(null) // Ensure state reflects removal
        }, oneDayInMilliseconds)

        // Cleanup function to clear the timer when component unmounts or token changes
        return () => clearTimeout(deleteAuthTokenTimer)
    }, [authTokens])

    // Updated loginUser function to include Firebase sign-in
    let loginUser = async (e) => {
        e.preventDefault()
        const credentials = new FormData(e.currentTarget)
        const apiUrl = `https://dev.api.startupearly.com/api/token/`

        let response
        try {
            response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: credentials.get("username"),
                    password: credentials.get("password")
                })
            })

            if (response.status === 401) {
                toast.error("Invalid credentials. Please try again.", {
                    icon: "😓",
                    style: {
                        borderRadius: "55px",
                        marginBottom: "15px"
                    }
                })
                return
            }

            if (!response.ok) {
                throw new Error("Login failed. Server error.")
            }

            let data = await response.json()
            let token = jwt_decode(data.django_token.access)

            if (response.status === 200) {
                setAuthTokens(data)
                setUser(jwt_decode(data.django_token.access))
                localStorage.setItem("authTokens", JSON.stringify(data))
                // Firebase sign-in with custom token
                signInWithCustomToken(auth, data.firebase_token)
                    .then(async (userCredential) => {
                        const user = userCredential.user
                        user.role = jwt_decode(data.django_token.access).role
                        try {
                            const userInfo = await axios.get(
                                `${process.env.REACT_APP_API_KEY}/api/profile/${user.uid}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${data.django_token.access}`
                                    }
                                }
                            )
                            console.log("User signed in:", user)
                            sessionStorage.setItem("uid", user.uid)
                            sessionStorage.setItem(
                                "photoURL",
                                userInfo.data.avatar
                            )
                            sessionStorage.setItem(
                                "displayName",
                                userInfo.data.name
                            )
                            setUser(user)
                            navigate("/home/")
                        } catch (error) {
                            console.error("Error retrieving user info:", error)
                        }
                    })
                    .catch((error) => {
                        console.error("Error signing in with Firebase:", error)
                        toast.error(
                            "Firebase sign-in failed. Please try again."
                        )
                    })
            } else {
                toast.error(
                    "There's an internet issue. Please check your connection.",
                    {
                        icon: "🔌",
                        style: {
                            borderRadius: "55px",
                            marginBottom: "15px"
                        }
                    }
                )
            }
        } catch (error) {
            toast.error("An error occurred during login. Please try again.", {
                icon: "😓",
                style: {
                    borderRadius: "55px",
                    marginBottom: "15px"
                }
            })
        }
    }

    let googleLoginUser = async (responseData) => {
        try {
            console.log("Response Data:", responseData)

            const tokenId = jwtDecode(responseData.credential)
            console.log("Decoded Token:", tokenId)
            console.warn(tokenId)

            const apiUrl = `${process.env.REACT_APP_API_KEY}/api/google-login/`

            const apiResponse = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ tokenId })
            })

            if (!apiResponse.ok) {
                throw new Error("Login failed. Server error.")
            }

            const data = await apiResponse.json()
            console.log("API Response Data:", data)

            try {
                const decodedToken = jwt_decode(data.token)
                console.log("Decoded Access Token:", decodedToken)

                // Update user state with decoded token
                setUser(decodedToken)
                setAuthTokens(data)
                localStorage.setItem("authTokens", JSON.stringify(data))
                toast.success("Login Successful!")
                navigate("/home")
            } catch (error) {
                console.error("Error:", error.message)
                toast.error("Login failed. Please try again.")
            }
        } catch (error) {
            console.error("Error:", error.message)
            toast.error(
                "This account does not exist. Contact your school administration."
            )
        }
    }

    let googleLoginError = (error) => {
        console.error("Google Login Failed:", error)
        toast.error("Google Login Failed. Please try again.")
    }

    let registerUser = async (e) => {
        e.preventDefault()
        const credentials = new FormData(e.currentTarget)

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/register/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: credentials.get("username"),
                    email: credentials.get("email"),
                    password: credentials.get("password"),
                    password2: credentials.get("password2"),
                    role: "Student"
                })
            }
        )

        let data = await response.json()

        if (response.status === 201) {
            navigate("/login")
        } else {
            console.log("Something went wrong!")
        }
    }

    let logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem("authTokens")
        navigate("/login")
    }

    let contextData = {
        user: user,
        loginUser: loginUser,
        logoutUser: logoutUser,
        registerUser: registerUser,
        googleLoginUser: googleLoginUser
    }

    return (
        <div>
            <AuthContext.Provider value={contextData}>
                {children}
                <div style={{ zIndex: 999999999999999 }}>
                    <Toaster position="top-center" />
                </div>
            </AuthContext.Provider>
        </div>
    )
}
