import React, { useState } from 'react';
import axios from 'axios';

const SearchUser = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const handleSearch = async (event) => {
        const value = event.target.value;
        setQuery(value);

        if (value.length > 2) {
            try {
                const response = await axios.get('https://dev.api.startupearly.com/api/search-user/', {
                    params: { query: value }
                });
                setResults(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Error fetching profiles:', error);
                setResults([]);
            }
        } else {
            setResults([]);
        }
    };

    return (
        <div>
            <input
                type="text"
                value={query}
                onChange={handleSearch}
                placeholder="Search profiles..."
            />
            <ul>
                {results.map(profile => (
                    <li key={profile.id}>{profile.name}</li>
                ))}
            </ul>
        </div>
    );
};

export default SearchUser
