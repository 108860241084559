import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Tab } from "@headlessui/react";
import AuthContext from "../context/AuthContext";

import { toast, Toaster } from "react-hot-toast";
import moment from "moment";

import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MobileBottom from "../components/MobileBottom";
import { useLanguage } from "../components/language";
import EventCalendar from "../components/EventCalendar";

const EventsCalendar = () => {
  const [loading, setLoading] = useState(true);
  let { user } = useContext(AuthContext);
  const navigate = useNavigate();
  let userID = user["user_id"];
  const [profile, setProfile] = useState();
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const { t, changeLanguage, lanArabic } = useLanguage();
  // Function to filter notifications based on their type


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${userID}`
        );
        let data = await response.json();
        setProfile(data);
        
      } catch (err) {
        // console.log("The requested profile does not exist.");
        
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [userID]);


  useEffect(() => {
    if (!user && !userID) {
      console.log("Navigating to login due to lack of user or userID");
      window.location.replace("/login");
    }
  }, [user, userID, navigate]);

  const [events, setEvents] = useState([]);
  const [networkUser, setNetworkName] = useState([]);
let networkId = profile?.network_in;
  
useEffect(() => {
    // Fetch all networks
    fetch('https://dev.api.startupearly.com/api/networks/')
      .then((response) => response.json())
      .then((allNetworks) => {
        if (networkId) {
          // Filter the network based on networkId
          const filteredNetwork = allNetworks.find((network) => network.network_id === networkId);
  
          if (filteredNetwork) {
            // Access the network name from the filtered data
            const networkName = filteredNetwork.name;
            // Do something with the network name, e.g., set it in state
            setNetworkName(networkName);
          } else {
            console.error('Network not found with networkId:', networkId);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching network data:', error);
      });
  }, [networkId]); // Include networkId as a dependency to re-fetch when it changes
  


  let { logoutUser } = useContext(AuthContext);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };



//   const [events, setEvents] = useState([]);
// const [networkUser, setNetworkUser] = useState(''); // Assuming you have a state for networkUser

useEffect(() => {
  // Fetch data from the '/get_events/' endpoint
  fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
    .then((response) => response.json())
    .then((data) => {
      // Filter events where campus matches the networkUser
      const filteredEvents = data.filter((event) => event.campus === networkUser);
      setEvents(filteredEvents);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
}, [networkUser]); // Include networkUser as a dependency to re-fetch when it changes




  return (
    <div>
      <div>
        <div>
          <>
            <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

            <Sidebar />

            {isSidebarVisible && <SideInnerSM />}

            <main
              className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${
                lanArabic ? "xl:mr-68" : "md:ml-[17rem]"
              } rounded-xl`}
            >
              {/* Navbar */}
              <Navbar toggleSidebar={toggleSidebar} />

              {/* end Navbar */}
              {/* cards */}
              <div className="w-full px-6 py-6 mx-auto">
                <div className="bg-white rounded-2xl">
                 <EventCalendar events={events}/>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      <MobileBottom />
    </div>
  );
};

export default EventsCalendar;
