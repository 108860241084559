import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import TimeLine from "./TimeLine";
import "moment-timezone"; // If needed, to manage timezones
import { useLanguage } from "./language";

// import TimeAgo from "./TimeAgo";
const TimelineStack = ({ source }) => {
  const [timelineData, setTimelineData] = useState([]);
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);

  const { user } = useContext(AuthContext);
  const params = useParams();

  let profileID;
  if (source === 'Param') {
    profileID = params.id;
  } else if (source === 'Self') {
    profileID = user['user_id'];
  } else if (source === 'Parent') {
    profileID = 12;
  } 

  if (!profileID) {
    console.error("Invalid source provided to TimelineStack");
    profileID = 0;
  }

  const userID = parseInt(profileID);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        
      } catch (err) {
        // console.log("The requested profile does not exist.");
        
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  //// console.log(profile);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requests = [
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lead/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_society/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_social/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_awards/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_sports/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_intern/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_cert/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_talent/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lang/`),
          axios.get(`${process.env.REACT_APP_API_KEY}/api/get_eventStd/`),
        ];

        // Execute all the requests in parallel
        const responses = await Promise.all(requests);

        // Merge the data from all endpoints into one array
        let mergedData = [];
        responses.forEach((response) => {
          if (Array.isArray(response.data)) {
            mergedData = mergedData.concat(response.data);
          }
        });

        // Filter the data based on the userID
        const filteredData = mergedData.filter(
          (item) => parseInt(item.posted_by) === userID
        );

        // Sort the filtered data based on the start_date
        filteredData.sort(
          (b, a) => new Date(a.start_date) - new Date(b.start_date)
        );

        setLoading(false);
        setTimelineData(filteredData);
      } catch (error) {
        console.error("Error fetching timeline data:", error);
      }
    };

    fetchData();
  }, [userID]);

  const groupedData = {};
  timelineData.forEach((item) => {
    const startDate = item.start_date;
    if (groupedData[startDate]) {
      groupedData[startDate].push(item);
    } else {
      groupedData[startDate] = [item];
    }
  });

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  
  const { t, lanArabic } = useLanguage(); // Use the useLanguage hook to access translations and lanArabic

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_API_KEY}/api/get_activities/${profileID}/`
  //         );

  //         let responseData = response.data;

  //         setData(responseData);
  //         setLoading(false);
  //         // console.log("data: " + JSON.stringify(responseData));
  //       } catch (err) {
  //         // console.log("The requested profile does not exist.");
  //         setLoading(false); // Set loading to false even on error to stop showing the loader
  //       }
  //     };

  //     // Simulate the loader by setting a timer for 3 seconds
  //     const timer = setTimeout(() => {
  //       fetchData();
  //     }, 900);

  //     // Clean up the timer when the component unmounts
  //     return () => clearTimeout(timer);
  //   }, [profileID]);

  const [isempty, setisempty] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_activities/${profileID}/`
        );

        let responseData = response.data;

        setData(responseData);
        if(responseData.length===0){
          setisempty(true)
        }
        else{
          setisempty(false)
        }


        // console.log("data: " + JSON.stringify(responseData));
      } catch (err) {
        // console.log("The requested profile does not exist.");
      }
    };

    // Fetch data immediately
    fetchData();

    // Set an interval to fetch data every 2 seconds
    const interval = setInterval(() => {
      fetchData();
    }, 2000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [profileID]);

  // console.log("activities:" + data);

  return (
    <div>
    {loading ? ( // Conditional rendering based on loading state
    <div className="flex items-center gap-4 justify-center h-screen">
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          </div>
    ) : (
      <div className="rounded-lg" style={{ height: "32rem", overflowY: "scroll" }}>

      {data.length < 1 ? (
        <div>
  
  {/* Heading */}
  <div className="ps-2 my-2 first:mt-0">
    <h5 className="text-xs font-medium uppercase text-gray-500 darkI:text-gray-400">

    </h5>
  </div>
  {/* End Heading */}
  {/* Item */}
  <div className="flex gap-x-3">
    {/* Icon */}
    <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 darkI:after:bg-gray-700">
      <div className="relative z-10 w-7 h-7 flex justify-center items-center">
        <div className="w-2 h-2 rounded-full bg-gray-400 darkI:bg-gray-600" />
      </div>
    </div>
    {/* End Icon */}
    {/* Right Content */}
    <div className="grow flex flex-col gap-4 pt-0.5 pb-2">


<h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
<span class="w-max inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">🏆 {t('Leadership')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>


      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">♣️ {t('Student Club')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>


      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">♣️ {t('Award')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>


      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">🎗️ {t('Social Work')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>


      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">⚽ {t('Sports')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>


      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">💼 {t('Internship')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>


      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">📜 {t('Certification')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>



      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">🔠 {t('Languages')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>


      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">📜 {t('Certification')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>


      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">🎨 {t('Talents')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>
      <h5 className="flex flex-col gap-x-1.5 font-semibold text-gray-800 darkI:text-white">
      <span class="w-max  inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-gray-100 text-gray-800 darkI:bg-white/10 darkI:text-white mb-4">📆 {t('Event Management')}</span>

     <span className="text-xs opacity-40">No Activity Reported</span> 
      </h5>




    </div>
    {/* End Right Content */}
  </div>


  
  
  {/* End Item */}
</div>
      ) : (


        <ul className="mt-4 p-6 " style={{ height: "42rem" }}>
          {data
            .filter((userdata) => userdata.activity_name)
            .sort((a, b) => {
              // Check if the start_date is '0'
              if (a.start_date === "0" && b.start_date !== "0") {
                return 1; // Move 'a' to the end
              } else if (a.start_date !== "0" && b.start_date === "0") {
                return -1; // Move 'b' to the end
              } else {
                // Sort by start_date in descending order
                return new Date(b.start_date) - new Date(a.start_date);
              }
            })
            .map((userdata) => (
              <>
                <TimeLine
                  key={userdata.id}
                  startDate={userdata.start_date}
                  title={userdata.activity_name}
                  grade={userdata.grade}
                  months={userdata.duration}
                  role={userdata.role}
                  rank={userdata.rank}
                  service_hours={userdata.service_hours}
                  scope={userdata.scope}
                  type={userdata.type}
                  postedDate={userdata.post_date}
                  category={userdata.category}
                  status={userdata.status}
                  feedback={userdata.feedback}
                  activityId={userdata.id}
                />
              </>
            ))}
        </ul>

      )}
  
        
      </div>
    )}
  </div>
  );
};

export default TimelineStack;