import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { FaSortAlphaDown, FaSortAlphaUp, FaSearch } from "react-icons/fa"; // Icons
import { MultiSelect } from "react-multi-select-component";

import AuthContext from "../context/AuthContext";
import StudentTable from "../components/Network_StudentTable";
import SelectDropdown from "../components/SelectDropdown";
import { useLanguage } from "./language";
import AddSingleStudent from "../pages/AddSingleStudent";
import ReactModal from "react-modal";

import Clubs from './Clubs.json'
import CustomOption from "./CustomOption";

function Network_StudentList({ default_itemsPerPage }) {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [schools, setSchools] = useState([]);
  const { user } = useContext(AuthContext);
  const userID = user["user_id"];
  const params = useParams();
  const profileID = userID;

  const [isOpen2, setIsOpen2] = useState(false);

  const openModal2 = () => {
    setIsOpen2(true);
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  const [loadingStudents, setLoadingStudents] = useState(true);
  const [studentActivityCount, setStudentActivityCount] = useState({});

  const [profile, setProfile] = useState();
  const [error, setError] = useState();
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [filterStudent, setFilterStudent] = useState([]);
  const [activityNot, setActivityNot] = useState([]);
  const [activityPresent, setActivityPresent] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All");

  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState({
    key: "firstname",
    order: "asc",
  });

  const [selectedGrades, setSelectedGrades] = useState([]);
  const gradeOptions = Array.from(
    new Set(schools.map((student) => student.grade))
  ).map((grade) => ({ label: grade, value: grade }));

  const sortedStudents = [...schools].sort((a, b) => {
    const { key, order } = sortOrder;
    if (order === "asc") {
      return a[key].localeCompare(b[key]);
    } else if (order === "desc") {
      return b[key].localeCompare(a[key]);
    }
    return 0;
  });


  const [selectedClub, setselectedClub] = useState([]);

  const clubOptions = Clubs.map(club => ({
    value: club.id,
    label: club.name,
      logo: club.logo // Assuming each club object has a logo property

  }));

  const [selectedClubs, setSelectedClubs] = useState([]);

  const handleChange = selectedOptions => {
    setselectedClub(selectedOptions);
  };


  const filteredStudents = sortedStudents.filter((student) => {
    const matchesSearch = student.firstname
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesGrade =
      selectedGrades.length === 0 ||
      selectedGrades.some((grade) => grade.value === student.grade);
    return matchesSearch && matchesGrade;
  });

  const [totalStudents, setTotalStudents] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(default_itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value));
    setCurrentPage(1); // Reset to the first page whenever items per page change
  };

  const indexOfLastStudent = currentPage * itemsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - itemsPerPage;
  const currentStudents = filteredStudents.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  let url = "";
  if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  }

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`
        );
        const data = await response.json();
        setProfile(data);
        if (url === "network_profile") {
          setCurrentNetwork(data?.network_id);
        } else if (url === "school_profile") {
          setCurrentNetwork(data?.school_id);
        }
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };

    getProfile();
  }, [profileID]);

  useEffect(() => {
    if (currentNetwork) {
      fetch(`${process.env.REACT_APP_API_KEY}/api/student_count/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const allStudents = data.filter((student) => {
            if (user["role"] === "Network Admin") {
              return parseInt(student.network_in) === parseInt(currentNetwork);
            } else if (user["role"] === "School Admin") {
              return parseInt(student.school_in) === parseInt(currentNetwork);
            }
            return false;
          });

          const filteredSchools = allStudents.filter((student) =>
            filterStudent.some(
              (activity) =>
                parseInt(activity.posted_by) === parseInt(student.user)
            )
          );

          const filterActivityNot = allStudents.filter((student) =>
            filterStudent.every(
              (activity) =>
                parseInt(activity.posted_by) !== parseInt(student.user)
            )
          );

          setActivityNot(filterActivityNot);
          setSchools(allStudents);
          setActivityPresent(filteredSchools);
        })
        .catch((error) => {
          console.error("Error fetching schools:", error);
          setError(error.message);
        });
    }
  }, [user,currentNetwork]);

  useEffect(() => {
    const getActivities = async () => {
      fetch(`${process.env.REACT_APP_API_KEY}/api/get_all_activities/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Student Activities was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setFilterStudent(data);

          const activityCount = {};

          schools.forEach((student) => {
            activityCount[student.user] = data.filter(
              (activity) =>
                parseInt(activity.posted_by) === parseInt(student.user)
            ).length;
          });

          setStudentActivityCount(activityCount);
        });
    };

    getActivities();
  }, [profileID, schools]);

  useEffect(() => {
    let studentsToShow;
    if (selectedOption === "Activity Present") {
      studentsToShow = activityPresent.length;
    } else if (selectedOption === "Activity Not Present") {
      studentsToShow = activityNot.length;
    } else {
      studentsToShow = filteredStudents.length;
    }
    setTotalStudents(studentsToShow);
  }, [selectedOption, activityPresent, activityNot, filteredStudents]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleSelect = (selected) => {
    setSelectedOption(selected);
  };

  const handleSort = (key) => {
    setSortOrder((prevSortOrder) => {
      const order =
        prevSortOrder.key === key && prevSortOrder.order === "asc"
          ? "desc"
          : "asc";
      return { key, order };
    });
  };

  return (
    <div className="p-4">
      <div className="grid xl:grid-cols-12 md:grid-cols-6 lg:grid-cols-10  gap-4">
        <div className="col-span-4">
          <div>
            <label className="text-sm text-gray-400"> Search Students </label>

            <input
              className="input input-bordered join-item w-full"
              placeholder="Search by name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        {/* <SelectDropdown
          selectedOption={selectedOption}
          onSelect={handleSelect}
        /> */}
        <div className="flex flex-col col-span-2 ">
          <label className="text-sm text-gray-400"> Filter by year </label>
          <MultiSelect
            options={gradeOptions}
            value={selectedGrades}
            onChange={setSelectedGrades}
            labelledBy="Select Grades"
          />
        </div>

        {/* <div className="flex flex-col col-span-2 ">
          <label className="text-sm text-gray-400"> Filter by club </label>
          <MultiSelect
            options={clubOptions}
            value={selectedClub}
            onChange={handleChange}
            labelledBy="Select Grades"
          />
        </div> */}


        <div className="flex flex-col col-span-2">
      <label className="text-sm text-gray-400">Filter by club</label>
      <MultiSelect
        options={clubOptions}
        value={selectedClub}
        onChange={handleChange}
        labelledBy="Select Clubs"
        className="w-full"
        overrideStrings={{
          selectSomeItems: "Select Clubs",
          allItemsAreSelected: "All Clubs Selected",
          selectAll: "Select All",
          search: "Search",
        }}
        ItemRenderer={({ option, checked, onClick }) => (
          <CustomOption
            innerRef={option.innerRef}
            innerProps={option.innerProps}
            data={option}
            onClick={onClick}
            style={{ backgroundColor: checked ? '#e0e0e0' : 'transparent' }}
          />
        )}
      />
    </div>


        <div className="flex flex-col col-span-2 ">
          <label className="text-sm text-gray-400"> Items per page </label>

          <select
            value={itemsPerPage}
            className="select select-bordered rounded-l-0 justify-end mb-4"
            onChange={handleItemsPerPageChange}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>

        <div className="dropdown dropdown-end col-span-2">
        <label className="text-sm text-gray-400 opacity-0 hidden md:block"> Items per page </label>
          <div tabIndex={0} role="button" className="btn btn-primary">
            + Student
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <button
                onClick={openModal2}
                className="btn btn-ghost justify-start p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                  />
                </svg>
                Add New
              </button>
            </li>
            <li>
              <Link
                to={"/bulk_students"}
                className="btn btn-ghost justify-start p-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                  />
                </svg>
                Bulk Upload
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="total-students">
        {/* Total Students: {totalStudents} */}
      </div>

      <div></div>
      <StudentTable
        schools={currentStudents}
        selectActivity={selectedOption}
        activityPresent={activityPresent}
        activityNot={activityNot}
        filterStudent={filterStudent}
        activityCount={studentActivityCount} // Passing the count to StudentTable
        handleSort={handleSort}
        sortOrder={sortOrder}
      />
      <div className="pagination-controls">
        <div className="flex flex-row items-center gap-2 flex-wrap justify-center mt-4 border-0">
          {Array.from(
            { length: Math.ceil(totalStudents / itemsPerPage) },
            (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`btn btn-ghost PageActive border-0 ${
                  currentPage === index + 1 ? "PageActive bg-secondary" : ""
                }`}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
      </div>
      <ReactModal
          className="w-5/12 h-min m-auto mt-8 "
          isOpen={isOpen2}
          onClose={closeModal2}
        >
          <div className="flex justify-end">
            <button
              className="btn btn-error btn-circle mb-4"
              onClick={closeModal2}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <AddSingleStudent />
          </div>
        </ReactModal>
    </div>
  );
}

export default Network_StudentList;
