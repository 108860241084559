import React, { useState, useEffect } from "react";
import StdEventCard from "./StdEventCard";

const StdEventList = ({ blogs, myList }) => {
  const [filters, setFilters] = useState({
    category: "",
    eventType: "", // Updated from 'price' to 'eventType'
  });

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const [randomizedClubData, setRandomizedClubData] = useState([]);

  useEffect(() => {
    // Assuming that 'blogs' is the initial data you want to use
    setRandomizedClubData(blogs);
  }, [blogs]);

  const filteredClubs = randomizedClubData.filter((club) => {
    const categoryMatch = filters.category
      ? club.category_name === filters.category
      : true;
    const eventTypeMatch =
      filters.eventType === "internal"
        ? !club.external_event
        : filters.eventType === "external"
        ? club.external_event
        : true;
    return categoryMatch && eventTypeMatch;
  });

  const allClubsCount = randomizedClubData.length;
  const internalClubsCount = randomizedClubData.filter(
    (club) => !club.external_event
  ).length;
  const externalClubsCount = randomizedClubData.filter(
    (club) => club.external_event
  ).length;

  return (
    <div>
      <div className="max-w-[85rem] py-2 rounded-xl mx-auto">
        <div className="flex flex-wrap gap-4 justify-end mb-8">
          <button
            className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
              filters.eventType === ""
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => handleFilterChange("eventType", "")}
          >
            All{" "}
            {filters.eventType === "" ? (
              <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                {allClubsCount}
              </small>
            ) : null}
          </button>
          <button
            className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
              filters.eventType === "internal"
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => handleFilterChange("eventType", "internal")}
          >
            Internal Events{" "}
            {filters.eventType === "internal" ? (
              <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                {internalClubsCount}
              </small>
            ) : null}
          </button>
          <button
            className={`flex flex-row items-center gap-2 px-4 py-2 rounded-full ${
              filters.eventType === "external"
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-800"
            }`}
            onClick={() => handleFilterChange("eventType", "external")}
          >
            External Events{" "}
            {filters.eventType === "external" ? (
              <small className="bg-[#8a2be2] opacity-80 border-0 badge badge-sm text-white">
                {externalClubsCount}
              </small>
            ) : null}
          </button>
        </div>
        <div className="grid grid-cols-3 lg:gap-y-8 gap-10">
          {myList ? (
            <>
              {filteredClubs.slice(0, 2).map((club) => (
                <StdEventCard key={club.id} {...club} />
              ))}
            </>
          ) : (
            <>
              {filteredClubs.map((club) => (
                <StdEventCard key={club.id} {...club} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StdEventList;
