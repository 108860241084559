import React, { useState } from "react";

const DaysSelection = ({ selectedDays, setSelectedDays }) => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const toggleDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <div>
      <div className="flex flex-row gap-2 items-center flex-wrap	">
        {days.map((day) => (
          <button
            key={day}
            onClick={() => toggleDay(day)}
            style={{
              backgroundColor: selectedDays.includes(day)
                ? "#4f0696 "
                : "#d5d5d5",
              color: selectedDays.includes(day) ? "#fff " : "#80808096",
            }}
            className="btn btn-sm rounded-full text-white"
          >
            {day}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DaysSelection;
