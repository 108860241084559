import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../context/AuthContext";

import ProfileEditForm from "../components/ProfileEditForm";

import { getRoles } from "@testing-library/react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
//competency data
import Achievements_Data from "./CompetencyData/AchievementsCompetency";
import Certifications_Data from "./CompetencyData/CertificationsCompetency";
import Clubs_Data from "./CompetencyData/ClubsCompetency";
import Event_Management_Data from "./CompetencyData/EventManagementData";
import Languages_Data from "./CompetencyData/LanguagesCompetency";
import Leadership_Data from "./CompetencyData/LeadershipCompetency";
import Social_Work_Data from "./CompetencyData/SocialWorkCompetency";
import Special_Talents_Data from "./CompetencyData/SpecialTalentsCompetency";
import Sports_Data from "./CompetencyData/SportsCompetency";
import Barchart from "../components/Barchart";
import RadarChart from "../components/RadarChart";
import MobileBottom from "../components/MobileBottom";
import BarStacked from "../components/BarStacked";
import Calendar from "react-calendar";
import axios from "axios";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SideInner from "../components/SideInner";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import AllClubsStd from "./AllClubsStd";
import MyClubsStd from "./MyClubsStd";
import EventReqForm from "../components/EventReqForm";
import SideInnerSM from "../components/SideInnerSM";

const Pathway = () => {
  let { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const lanArabic = i18n.language === "ar";
  // extract profile id
  let params = useParams();
  let profileID = params.id;

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  //console.log(profile);
  let { logoutUser } = useContext(AuthContext);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

            <Sidebar />

            {isSidebarVisible && <SideInnerSM />}

            <main
              className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? "xl:mr-68" : "md:ml-[17rem]"} rounded-xl`}
            >
              {/* Navbar */}
              <Navbar toggleSidebar={toggleSidebar} />

              {/* end Navbar */}
              {/* cards */}
              <div className="w-full px-6 py-6 mx-auto rounded-lg">
              <iframe
        title="Embedded Website"
        src="https://dev.pathways.startupearly.com/"
        width="100%"
        height="500px"
        frameBorder="0"
        allowFullScreen
      ></iframe>
              </div>
            </main>
          </>
        </div>
      </div>

      <MobileBottom />
    </div>
  );
};

export default Pathway;
