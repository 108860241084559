import React, { useEffect, useState } from "react";
import CouncilRosterStats from "./CouncilRosterStats";
import StudentList from './StudentList.json'

const StudentCouncilTracker = ({ singleEvent, type, needWeeks }) => {
  // Sample student data
  const [students, setStudents] = useState(StudentList);

  const handleInputChange = (index, fieldName, value) => {
    const updatedStudents = [...students];
    updatedStudents[index][fieldName] = value;
    setStudents(updatedStudents);
  };

  const [selectedMonth, setSelectedMonth] = useState("");

  const [weeks, setWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState("");

  function updateWeeks(date) {
    const weeksInMonth = [];
    const month = date.getMonth();
    const year = date.getFullYear();
    let currentWeek = new Date(year, month, 1);
    while (currentWeek.getMonth() === month) {
      const startOfWeek = new Date(currentWeek);
      currentWeek.setDate(currentWeek.getDate() + 6);
      const endOfWeek = new Date(currentWeek);
      if (endOfWeek.getMonth() !== month) {
        endOfWeek.setDate(0);
      }
      weeksInMonth.push(
        `${startOfWeek.toDateString()} - ${endOfWeek.toDateString()}`
      );
      currentWeek.setDate(currentWeek.getDate() + 1);
    }
    setWeeks(weeksInMonth);
    setSelectedWeek(weeksInMonth[0]);
  }
  useEffect(() => {
    const currentDate = new Date();
    const nextMonthDate = new Date(
      currentDate.setMonth(currentDate.getMonth() + 3)
    );
    const nextMonth = nextMonthDate.toLocaleString("default", {
      month: "long",
    });
    setSelectedMonth(nextMonth);
    updateWeeks(nextMonthDate);
  }, []);

  useEffect(() => {
    if (selectedMonth) {
      const monthIndex = months.indexOf(selectedMonth);
      const year = new Date().getFullYear();
      const monthDate = new Date(year, monthIndex, 1);
      updateWeeks(monthDate);
    }
  }, [selectedMonth]);
  const handleWeekChange = (e) => {
    setSelectedWeek(e.target.value);
  };


  useEffect(() => {
    const currentDate = new Date();
    const nextMonthDate = new Date(
      currentDate.setMonth(currentDate.getMonth() + 3)
    );
    const nextMonth = nextMonthDate.toLocaleString("default", {
      month: "long",
    });
    setSelectedMonth(nextMonth);
  }, []);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    console.log("Selected month:", e.target.value);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const scores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const attendanceStatus = [
    {
      title: "Present",
      value: 2,
    },

    {
      title: "Excused Absence",
      value: 1,
    },

    {
      title: "Absent",
      value: 0,
    },
  ];

  return (
    <div>
      {!singleEvent ? <CouncilRosterStats type={type} /> : null}

      <div className="flex flex-row justify-between items-center mt-4 px-4">
        <h2 className="text-lg font-bold ">{selectedMonth} 2024 Tracking</h2>

        <div className="flex flex-row gap-x-3">
          {!singleEvent ? (
            <select
              id="monthSelect"
              className="select select-bordered "
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>
          ) : null}

          {needWeeks ? (
            <select
              id="weekSelect"
              className="select select-bordered "
              value={selectedWeek}
              onChange={handleWeekChange}
            >
              {weeks.map((week, index) => (
                <option key={index} value={week}>
                  {week}
                </option>
              ))}
            </select>
          ) : null}
        </div>
      </div>

      <div className="overflow-x-scroll w-full">
        <table className="student-council-table table bg-white p-4 rounded-xl shadow-xl mt-6">
          <thead>
            <tr>
              <th>No</th>
              <th>Student Name</th>
              <th>Grade</th>
              <th>Designation</th>
              <th>Attendance</th>
              <th>Involvement</th>
              <th>Participation</th>
              <th>Performance</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{student.name}</td>
                <td>{student.grade}</td>
                <td>{student.position}</td>
                <td>
                  <select
                    class="select select-bordered select-sm max-w-xs w-full  w-full"
                    value={student.attendance}
                    onChange={(e) =>
                      handleInputChange(index, "attendance", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {attendanceStatus.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.title}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    class="select select-bordered select-sm max-w-xs w-full  w-full"
                    value={student.involvement}
                    onChange={(e) =>
                      handleInputChange(index, "involvement", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {scores.map((score, index) => (
                      <option key={index} value={score}>
                        {score}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    class="select select-bordered select-sm max-w-xs w-full  w-full"
                    value={student.participation}
                    onChange={(e) =>
                      handleInputChange(index, "participation", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {scores.map((score, index) => (
                      <option key={index} value={score}>
                        {score}
                      </option>
                    ))}
                  </select>
                </td>

                <td>
                  <select
                    class="select select-bordered select-sm max-w-xs w-full  w-full"
                    value={student.performance}
                    onChange={(e) =>
                      handleInputChange(index, "performance", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {scores.map((score, index) => (
                      <option key={index} value={score}>
                        {score}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StudentCouncilTracker;
