import React, { useState, useEffect, useContext } from "react";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import globalize from "globalize";
import EventList from "./EventList";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import AdminLayout from "./AdminLayout";
import randomColor from "randomcolor";
import toast from "react-hot-toast";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import SideInner from "../components/SideInner";
import AuthContext from "../context/AuthContext";
import SideInnerSM from "../components/SideInnerSM";

function SchoolCalendarStd() {
  const localizer = globalizeLocalizer(globalize);
  const id = 2;
  const [list, setList] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // State to track the selected event
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  let { user } = useContext(AuthContext);

  let userID = 0;

  if(user[`role`]===`Parent`){
    userID = 12;
  } else{
    userID = user["user_id"];
  }

  const get_all_schedules = async () => {
    axios
      .get(
        `${process.env.REACT_APP_API_KEY}/api/get_student_schedules/${userID}/`
      )
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.error("ERROR SCHEDULE: ", err);
        console.log(err);
      });
  };
  useEffect(() => {
    get_all_schedules();
  }, [userID]);

  function mergeAdjacentEvents(events) {
    // Sort events based on start_date
    events.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

    let mergedEvents = [];
    let currentEvent = null;

    for (let event of events) {
      if (!currentEvent) {
        currentEvent = { ...event };
      } else {
        const currentEndDate = new Date(currentEvent.end_date);
        const eventStartDate = new Date(event.start_date);

        if (currentEndDate >= eventStartDate) {
          // Merge events
          currentEvent.end_date = event.end_date;
        } else {
          // Push current merged event
          mergedEvents.push(currentEvent);
          // Start a new merged event
          currentEvent = { ...event };
        }
      }
    }

    // Push the last merged event
    if (currentEvent) {
      mergedEvents.push(currentEvent);
    }

    // Format date strings in ISO format
    mergedEvents.forEach((event) => {
      event.start_date = event.start_date.slice(0, 19);
      event.end_date = event.end_date.slice(0, 19);
    });

    return mergedEvents;
  }

  const mergedEvents = mergeAdjacentEvents(list);

  // Generate random colors for each club
  const clubColors = {};
  mergedEvents.forEach((event) => {
    if (!clubColors[event.club]) {
      clubColors[event.club] = randomColor();
    }
  });

  const newData = mergedEvents?.map((item) => {
    const id = item?.id;
    const title = item?.name;
    const start = new Date(item?.start_date);
    const end = new Date(item?.end_date);
    const club = item.club;
    const bgColor = clubColors[club]; // Retrieve color based on club

    return { id, title, start, end, club, bgColor };
  });

  const handleDeleteEvent = (id) => {
    // Update the events array after deleting a schedule
    setList(list.filter((event) => event.id !== id));
  };

  const handleEventClick = (event) => {
    // Set the selected event when an event is clicked
    setSelectedEvent(event);
  };

  const handleClosePopup = () => {
    // Close the popup by resetting the selected event
    setSelectedEvent(null);
  };

  const formatDateString = (date) => {
    // Format the date string to "Wed. Feb 21, 2024 (6:00 PM)" format
    return date.toLocaleString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const toggleEdit = () => {
    setShowEdit((prevState) => !prevState);
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  return (
    <>
      <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

      <Sidebar />

      {isSidebarVisible && <SideInnerSM />}

      <main
        className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${"md:ml-[17rem]"} rounded-xl`}
      >
        {/* Navbar */}
        <Navbar toggleSidebar={toggleSidebar} />
        <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
          <div className="bg-white rounded-xl p-2 shadow-xl h-screen">
            <Calendar
              localizer={localizer}
              events={newData}
              startAccessor="start"
              endAccessor="end"
              popup={true}
              onSelectEvent={handleEventClick} // Call handleEventClick when an event is clicked
              onShowMore={(events, date) => console.log(date)}
            />
          </div>
        </div>

        {selectedEvent && (
          <div>
            <div
              className="fixed inset-0 flex items-center justify-center bg-[#00000080] bg-opacity-999 w-full"
              style={{ zIndex: 999 }}
            >
              <div className="bg-white p-6 rounded-lg min-w-[30rem]">
                <div className="flex flex-row items-center justify-between">
                  <div>
                    <h2 className="text-xl font-bold m-0">
                      {selectedEvent.title}
                    </h2>
                    <p className="text-gray-500 text-sm m-0">Session details</p>
                  </div>
                  {/* <button
          
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Edit <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
  <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
</svg>

        </button> */}
                </div>

                <div className="flex flex-row items-start gap-4 mt-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                    />
                  </svg>

                  <div>
                    <p className="text-gray-500 text-sm m-0">Starts at</p>
                    <p className="text-gray-700">
                      {formatDateString(selectedEvent.start)}
                    </p>
                  </div>
                </div>

                <div className="flex flex-row items-start gap-4 mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                    />
                  </svg>

                  <div>
                    <p className="text-gray-500 text-sm m-0">Ends at</p>
                    <p className="text-gray-700">
                      {formatDateString(selectedEvent.end)}
                    </p>
                  </div>
                </div>

                <button
                  onClick={handleClosePopup}
                  className="mt-4 bg-white hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}

export default SchoolCalendarStd;