import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const FeaturedNavs = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const tabs = [
    {
      title: "ECR",
      subTitle: "Extracurricular Report",
      description: "An extracurricular report is an official set of record, past and present, of a student's extracurricular activities (clubs, organizations, activities), awards, accomplishments, and honors received during a student's schooling years.",
      imageSrc: "https://res.cloudinary.com/ahbranding/image/upload/v1711390465/AHbranding/Group_34784388_otycao.png",
      featurePage: '/features/cct'
    },
    {
      title: "EMS",
      subTitle: "Extracurricular Management System",
      description: "An EMS is a purpose-built platform designed to help schools manage the administration of their extracurricular activities. It is a one-stop-shop for extracurricular data and processes, allowing schools to access reporting and analytics that they would otherwise be unable to utilise.",
      imageSrc: "https://res.cloudinary.com/ahbranding/image/upload/v1711390521/AHbranding/Group_34784389_1_ndyw8g.png",
      featurePage: '/features/ems'
    },

    {
      title: "",
      subTitle: "WEB / Mobile App",
      description: "Our mobile application extends the power of the Extracurricular Management System (EMS) directly into the hands of students, parents, and staff, enabling seamless management and engagement with extracurricular activities anytime, anywhere. Designed with a user-friendly, mobile-first approach, our app ensures that the full spectrum of EMS capabilities is just a tap away.",
      imageSrc: "https://res.cloudinary.com/ahbranding/image/upload/v1711294759/AHbranding/Group_34784356_fwuikk.png",
      featurePage: '/features/leap'
    },
    {
      title: "GP",
      subTitle: "Guided Pathways",
      description: "In today's educational landscape, the gap between in-classroom knowledge and real-world skills is a growing concern. Our Guided Pathways are designed to bridge this gap, offering personalized learning journeys that enhance key competencies critical for student success. Rooted in the insights gained from our Extracurricular report assessment, each pathway is a strategic step towards comprehensive skill development, tailored to meet the unique needs of each student.",
      imageSrc: "https://res.cloudinary.com/ahbranding/image/upload/v1711453121/AHbranding/Group_34784391_q8f0jg.png",
      featurePage: '/features/gp'
    },
  ];
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // Change active tab after 6 seconds
  //     const nextTab = (activeTab % tabs.length) + 1;
  //     setActiveTab(nextTab);
  //     setIsLoading(false);
  //     console.log("Active Tab:", nextTab);
  //   }, 6000);

  //   return () => clearTimeout(timer);
  // }, [activeTab, tabs.length]);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    setIsLoading(true);
    console.log("Active Tab:", tabNumber);
  };
  const { i18n } = useTranslation();
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className={`flex gap-4 md:gap-0 flex-col ${i18n.language==='en'? 'md:flex-row':'md:flex-row-reverse'}  items-center`}>
        <h2 className="font-bold text-3xl lg:text-4xl text-gray-800 darki:text-gray-200">
          {t('Key Offerings')}
        </h2>
        <nav
          className="max-w-6xl mx-auto flex gap-4 flex-wrap overflow-x-auto md:overflow-hidden pb-4"
          aria-label="Tabs"
          role="tablist"
        >
          {tabs.map((tab, index) => (
            <button
              key={index}
              type="button"
              className={`btn capitalize ${
                activeTab === index + 1 ? "bg-primary text-white" : ""
              }`}
              id={`tabs-with-card-item-${index + 1}`}
              data-hs-tab={`#tabs-with-card-${index + 1}`}
              aria-controls={`tabs-with-card-${index + 1}`}
              role="tab"
              onClick={() => handleTabClick(index + 1)}
            >
              <span className="loader"></span>
              <span className="loader-text">{t(tab.subTitle)}</span>
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-12 md:mt-16">
        {/* Map over your tab content and render it based on activeTab */}
        {tabs.map((tab, index) => (
          <>
            {/* Features */}
            <div
              key={index}
              id={`tabs-with-card-${index + 1}`}
              className={
                activeTab === index + 1
                  ? ""
                  : "hidden max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
              }
              role="tabpanel"
              aria-labelledby={`tabs-with-card-item-${index + 1}`}
            >
              {/* Grid */}
              <div className="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
                <div>
                  <img
                    className="rounded-xl mx-auto w-7/12 md:w-full"
                    src={tab.imageSrc}
                    alt="Image Description"
                  />
                </div>
                {/* End Col */}
                <div className="mt-5 sm:mt-10 lg:mt-0">
                  <div className="space-y-6 sm:space-y-8">
                    {/* Title */}
                    <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
                      <h3 className={`text-2xl flex flex-row ${i18n.language==='en'? 'justify-start':'justify-end'} items-center gap-2`}>
                        {t(tab.subTitle)} 

      {(() => {
        if (tab.title !=='') {
          return (
           <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}> ({t(tab.title)})</div>
          )
        } 
      })()}
                      </h3>
                      <p className="text-gray-500 mt-2">{t(tab.description)}</p>


                      {/* <Link to={tab.featurePage} className="link link-primary mt-4">
                        {'Learn more >'}
                      </Link> */}

                      {tab.featurePage !== '' && (
  <Link to={tab.featurePage} className="link link-primary mt-4">
    {t('Learn more')+' '+'>'}
  </Link>
)}

                    </div>

                  </div>
                  
                </div>
                {/* End Col */}
              </div>
              {/* End Grid */}
            </div>
            
          </>
        ))}
      </div>
    </div>
  );
};

export default FeaturedNavs;
