import axios from "axios";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";





const leaders = [
    { Name: "Sana Faisal", Grade: 13 , role: "LEADER" },
    { Name: "Kimberly Savia Fernandes", role: "LEADER" , Grade: 13},
    { Name: "Heba Qaiser", role: "LEADER", Grade: 13 },
    { Name: "Wania Khalid", role: "LEADER" , Grade: 13},
    { Name: "Hassaan Ahmed", role: "LEADER" , Grade: 13},
    { Name: "Adam Noor", role: "LEADER" },
    { Name: "Mohammed Kashan", role: "LEADER" , Grade: 13},
    { Name: "Ali Affan", role: "LEADER" , Grade: 13},
    { Name: "Hamdiya Ahmed", role: "LEADER" , Grade: 13},
    { Name: "Abdur Rehman", role: "LEADER" , Grade: 13},
  ];
  
  const students = [
    { Name: "Student 1", role: "Member" , Grade: 10},
    { Name: "Student 2", role: "Member", Grade: 10 },
    { Name: "Student 3", role: "Member", Grade: 10 },
    { Name: "Student 4", role: "Member" , Grade: 10},
    { Name: "Student 5", role: "Member" , Grade: 10},
    { Name: "Student 6", role: "Member" , Grade: 10},
    { Name: "Student 7", role: "Member" , Grade: 10},
    { Name: "Student 8", role: "Member" , Grade: 10},
    { Name: "Student 9", role: "Member" , Grade: 10},
    { Name: "Student 10", role: "Member" , Grade: 10},
  ];
  

function ClubEnrolmentsSample(props) {

    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
    const [members, setMembers] = useState([]);
    const { id } = useParams();
  
    const getMembers = () => {
      // Combine leaders and students, but only include the first two leaders
      const combinedMembers = [
        ...leaders.slice(0, 2), // Show max 2 leaders
        ...students,            // Show all 10 students
      ];
      setMembers(combinedMembers);
    };
  
    useEffect(() => {
      getMembers();
    }, []);
  



  return (
    <div>
      <>
        {/* Table Section */}
        <div className="mt-8">
          {/* Card */}
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden darki:bg-slate-900 darki:border-gray-700">
                  {/* Header */}
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 darki:border-gray-700">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 darki:text-gray-200">
                        Enrollments
                      </h2>
                    </div>
                    <div>
                      <div className="inline-flex gap-x-2">
                        <a
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                          href="#"
                        >
                          Export Data
                        </a>
                        <a
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                          href="#"
                        >
                          <svg
                            className="flex-shrink-0 w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                            />
                          </svg>
                          Add user
                        </a>

                        {checkedCheckboxes.length > 0 && (
                          <a
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-amber-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                            href="#"
                          >
                            Nominate for Club Leaders
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* End Header */}
                  {/* Table */}
                  <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
                    <thead className="bg-gray-50 darki:bg-slate-800">
                      <tr>
                        <th scope="col" className="ps-6 py-3 text-start">
                          <label
                            htmlFor="hs-at-with-checkboxes-main"
                            className="flex"
                          >
                            <input
                              type="checkbox"
                              className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                              id="hs-at-with-checkboxes-main"
                            />
                            <span className="sr-only">Checkbox</span>
                          </label>
                        </th>
                        <th
                          scope="col"
                          className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3 text-start"
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Name
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Grade
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Clubs Joined
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Applied
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Status
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-end" />
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
                      {members.map((item, index) => (
                        <tr>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="ps-6 py-3">
                              <label
                                htmlFor="hs-at-with-checkboxes-1"
                                className="flex"
                              >
                                <input
                                  type="checkbox"
                                //   onChange={() =>
                                //     handleCheckboxChange(
                                //       "hs-at-with-checkboxes-1"
                                //     )
                                //   }
                                  className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                                  id="hs-at-with-checkboxes-1"
                                />
                                <span className="sr-only">Checkbox</span>
                              </label>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                              <div className="flex items-center gap-x-3">
                              <div className="avatar online placeholder">
                                  <div className="bg-secondary text-primary rounded-full w-10">
                                    <span className="text-xl">
                                      {item?.Name
                                        ? item.Name
                                            .split("@")[0]
                                            .substring(0, 2)
                                            .toUpperCase()
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                                    {item?.Name}
                                  </span>
                                  <span className="block text-sm text-gray-500">
                                    {item?.role}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                                Grade {item?.Grade}
                              </span>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="">1</span>
                            </div>
                          </td>

                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                               May 14, 2024
                              </span>
                            </div>
                          </td>

                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                                    Approved
                                  </span>
                              </span>
                            </div>
                          </td>

                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-1.5">
                              <div className="flex flex-col sm:inline-flex sm:flex-row rounded-sm">
                                <button
                                  type="button"
                                  className="btn mx-2 btn-success"
                                //   onClick={() =>
                                //     handleEnrollement(item?.id, true)
                                //   }
                                >
                                  Approve
                                </button>
                                <button
                                  type="button"
                                  className="btn mx-2 btn-error"
                                //   onClick={() =>
                                //     handleEnrollement(item?.id, false)
                                //   }
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* End Table */}
                  {/* Footer */}
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 darki:border-gray-700">
                    <div>
                      <p className="text-sm text-gray-600 darki:text-gray-400">
                        <span className="font-semibold text-gray-800 darki:text-gray-200">
                          6
                        </span>{" "}
                        results
                      </p>
                    </div>
                    <div>
                      <div className="inline-flex gap-x-2">
                        <button
                          type="button"
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                        >
                          <svg
                            className="flex-shrink-0 w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m15 18-6-6 6-6" />
                          </svg>
                          Prev
                        </button>
                        <button
                          type="button"
                          className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                        >
                          Next
                          <svg
                            className="flex-shrink-0 w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* End Footer */}
                </div>
              </div>
            </div>
          </div>
          {/* End Card */}
        </div>
        {/* End Table Section */}
      </>
    </div>
  );
}

export default ClubEnrolmentsSample;
