// import React from 'react';
import AdminLayout from "./AdminLayout";
import AdminStats from "./AdminStats";
import TopClubs from "./TopClubs";
import EventCalendar from "../components/EventCalendar";

import React, { useContext, useEffect, useState } from "react";
import AdminDashSideBar from "./AdminDashSideBar";
import AdminDashNavbar from "./AdminDashNavbar";
import { createGlobalStyle } from "styled-components";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import CampusTable from "./CampusTable";
import ScheduleTrend from "../components/ScheduleTrend";
import ClubSegregation from "./ClubSegregation";
import ClubBudgetBreakdown from "./ClubBudgetBreakdown";
import ClubMemberSegregation from "./ClubMemberSegregation";
import RetentionChart from "./RetentionChart";
import ReportDownloadCard from "./ReportDownloadCard";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AttendanceBreakdown from "./AttendanceBreakdown";
import AttendanceSummaryTable from "./AttendanceSummaryTable";
import AttendanceWrapper from "./AttendanceWrapper";
import AttendancePage from "./AttendancePage";
import Modal from "react-modal";
import AttendancePopUp from "./AttendancePopUp";
import AttendanceHeatChart from "./AttendanceHeatChart";

function AdminAttendance() {
  const [profile, setProfile] = useState();
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const [network, setNetwork] = useState(null);

  const userID = user["user_id"];

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }



  let Attendanceurl = "";
  if (user["role"] === "Network Admin") {
    Attendanceurl = "get_network_attendence_stats";
  } else if (user["role"] === "School Admin") {
    Attendanceurl = "get_school_attendance_stats";
  } 

  let role = user["role"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        if (user["role"] === "Network Admin") {
          setNetwork(data?.network_id);
        } else if (user["role"] === "School Admin") {
          setNetwork(data?.school_id);
        }
        
        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);


  const campusesData = [
    {
      name: "Strikers FC",
      datetime: "Feb 15, 08:00 - 11:00",
      presents: 120,
      absents: 15,
      leaves: 13,
      participation: "23%",
    },

    {
      name: "Strikers FC",
      datetime: "Feb 13, 02:00 - 05:00",
      presents: 100,
      absents: 15,
      leaves: 23,
      participation: "38%",
    },

    {
      name: "Strikers FC",
      datetime: "Feb 12, 08:00 - 11:00",
      presents: 120,
      absents: 15,
      leaves: 13,
      participation: "23%",
    },

    {
      name: "Strikers FC",
      datetime: "Feb 11, 02:00 - 05:00",
      presents: 100,
      absents: 15,
      leaves: 23,
      participation: "38%",
    },
  ];

  const clubsData = [
    {
      name: "Strikers FC",
      campus: " Northern",
      members: 22,
      staffMembers: 5,
      events: 5,
      budget: "$500",
      participation: "69%",
    },
    {
      name: "Active8",
      campus: " South",
      members: 36,
      staffMembers: 15,
      events: 4,
      budget: "$1,300",
      participation: "65%",
    },
    {
      name: "Swimming Club",
      campus: " Guardian",
      members: 11,
      staffMembers: 25,
      events: 1,
      budget: "$3,500",
      participation: "65%",
    },
  ];

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter(
          (event) => event.campus === user.username
        );
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${day} ${monthNames[monthIndex]} `;
  };




  
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    axios
      .get( 
        `${process.env.REACT_APP_API_KEY}/api/${Attendanceurl}/${network}/`
      )
      .then((response) => {
        const data = response.data;
        setAttendanceData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [network, Attendanceurl]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedScheduleID, setselectedScheduleID] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  // Function to open the modal and set selected club and date
  const openModal = (clubName, scheduleID, scheduleDate) => {
    setSelectedClub(clubName);
    setselectedScheduleID(scheduleID);
    setSelectedDate(scheduleDate);
    setIsModalOpen(true);
    console.warn("SELECTED: "+selectedClub+selectedDate)
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };



  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredAttendances = attendanceData?.unmarked_attendance
    ?.filter(attendance =>
      attendance.club_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    ?.sort((a, b) => new Date(b.schedule_name) - new Date(a.schedule_name));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAttendances = filteredAttendances?.slice(indexOfFirstItem, indexOfLastItem);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  return (
    <AdminLayout>
      <Tabs>
        <div className="">
          <div className="border-b border-gray-200 ">
            <TabList className="-mb-px flex gap-6" aria-label="Tabs">
            {user['role']!=="Staff"? (
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Overview
              </Tab>
            ):null}
              
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Attendance Register
              </Tab>
            </TabList>
          </div>
        </div>

        {user['role']!=="Staff"? (
          <TabPanel>
          <div>
            <div className="">
              {/* <TopClubs/> */}

              <div className="flex flex-wrap mt-2 -mx-3">
                <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-7/12 lg:flex-none">
                  <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid  border-black-125 rounded-2xl bg-clip-border">
                    {/* <ScheduleTrend/> */}

                    <div className="overflow-hidden">
                      {/* Card */}
                      <div className="flex flex-col">
                        <div className="overflow-x-auto">
                          <div className="min-w-full inline-block align-middle">
                            <div className="bg-white border border-gray-200 rounded-xl h-[50vh]  overflow-hidden darki:bg-slate-900 darki:border-gray-700">
                              <div className="p-4 pb-0 mb-0 rounded-t-4">
                                <div className="flex flex-col justify-start">
                                  <h6 className="mb-2 ">
                                    Breakdown for last 30 days
                                  </h6>
                                  <p className="text-sm  leading-normal ">
                                    ECA attendance simplified
                                  </p>
                                </div>
                              </div>
                              <AttendanceBreakdown
                                present={attendanceData?.present}
                                absent={attendanceData?.absent}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Card */}
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-full px-3 mt-0 lg:w-5/12 lg:flex-none">
                  <div className="">
                    <div className="">
                      {/* Card */}
                      <div className="flex flex-col">
                        <div className="-m-1.5 overflow-x-auto">
                          <div className="p-1.5 min-w-full inline-block align-middle">
                            <div className="bg-white border border-gray-200 rounded-xl h-[50vh] overflow-hidden darki:border-gray-700">
                              <div className="p-4 pb-0 mb-0 rounded-t-4">
                                <div className="flex flex-col justify-start">
                                  <h6 className="mb-2">Unmarked Attendances</h6>
                                  <p className="text-sm leading-normal">
                                    These attendances haven't been marked yet
                                  </p>
                                </div>
                              </div>
{/* 
                              <ul className="w-full flex flex-col gap-y-1 overflow-y-auto max-h-[45vh]">
                                {attendanceData?.unmarked_attendance?.map(
                                  (attendance, index) => (
                                    <li
                                      key={index}
                                      className="flex hover:bg-gray-100 justify-between items-center gap-x-2 py-3 px-4 text-sm font-medium text-gray-800 darki:text-white cursor-pointer"
                                      onClick={() =>
                                        openModal(
                                          attendance.club_id,
                                          attendance.schedule_id,
                                          attendance.schedule_name
                                        )
                                      }
                                    >
                                      <span>{attendance?.club_name}</span>{" "}
                                      <span className="text-gray-400">
                                        {formatDate(attendance?.schedule_name)}
                                      </span>
                                    </li>
                                  )
                                )}
                              </ul> */}
{/* 
                              <ul className="w-full flex flex-col gap-y-1 overflow-y-auto max-h-[45vh]">
  {attendanceData?.unmarked_attendance
    // Sort the unmarked attendances based on date in descending order
    ?.sort((a, b) => new Date(b.schedule_name) - new Date(a.schedule_name))
    .map((attendance, index) => (
      <li
        key={index}
        className="flex hover:bg-gray-100 justify-between items-center gap-x-2 py-3 px-4 text-sm font-medium text-gray-800 darki:text-white cursor-pointer"
        onClick={() =>
          openModal(
            attendance.club_id,
            attendance.schedule_id,
            attendance.schedule_name
          )
        }
      >
        <span>{attendance?.club_name}</span>{" "}
        <span className="text-gray-400">
          {formatDate(attendance?.schedule_name)}
        </span>
      </li>
    ))}
</ul> */}



<div>

<div className="flex flex-row items-center justify-between p-4">
<input
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Search by club name..."
        className="input input-bordered"
      />
{filteredAttendances && filteredAttendances.length > itemsPerPage && (
        <div className="flex flex-row items-center gap-2">
          <button className="btn btn-sm btn-ghost" disabled={currentPage<2} onClick={() => handlePageChange(currentPage - 1)}>Back</button>
          <span>{currentPage}</span>
          <button className="btn btn-sm btn-ghost" disabled={currentPage >= Math.ceil(filteredAttendances.length / itemsPerPage)} onClick={() => handlePageChange(currentPage + 1)}>Next</button>
        </div>
      )}
</div>
      

      <ul className="w-full flex flex-col gap-y-1 overflow-y-auto max-h-[45vh]">
        {currentAttendances?.map((attendance, index) => (
          <li
            key={index}
            className="flex hover:bg-gray-100 justify-between items-center gap-x-2 py-3 px-4 text-sm font-medium text-gray-800 darki:text-white cursor-pointer"
            onClick={() =>
              openModal(
                attendance.club_id,
                attendance.schedule_id,
                attendance.schedule_name
              )
            }
          >
            <span>{attendance?.club_name}</span>{" "}
            <span className="text-gray-400">
              {formatDate(attendance?.schedule_name)}
            </span>
          </li>
        ))}
      </ul>

      
    </div>


                              {isModalOpen && (
                                <Modal
                                  isOpen={isModalOpen}
                                  onClose={closeModal}
                                >
                                <div className="flex justify-end ">
                                <button className="btn btn-circle btn-ghost" onClick={closeModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>
</button>
                                </div>
                                  <AttendancePopUp
                                    club={selectedClub} scheduleID={selectedScheduleID} date={selectedDate} />
                                  
                                </Modal>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Card */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <AttendanceHeatChart summary={attendanceData.summary} /> */}

            <div className="">
              <div className="flex flex-wrap mt-2 -mx-3">
                <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:flex-none">
                  <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid border-black-125 rounded-2xl bg-clip-border overflow-hidden">
                    <div className="overflow-y-auto max-h-[50vh]">
                      {/* Card */}
                      <div className="flex flex-col">
                        <div className="overflow-x-auto">
                          <div className="min-w-full inline-block align-middle">
                            <div className="bg-white border border-gray-200 rounded-xl overflow-hidden darki:bg-slate-900 darki:border-gray-700">
                              <div className="p-4 pb-0 mb-0 rounded-t-4">
                                <div className="flex flex-col justify-start">
                                  <h6 className="mb-2 ">Attendance Summary</h6>
                                  <p className="text-sm leading-normal">
                                    Summary of marked attendances
                                  </p>
                                </div>
                              </div>
                              <AttendanceSummaryTable
                                attendance={attendanceData?.summary}
                                attendanceUnmarked={attendanceData?.unmarked_attendance}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Card */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
            ):null}
      
        <TabPanel>
          <AttendancePage />
        </TabPanel>
      </Tabs>
    </AdminLayout>
  );
}

export default AdminAttendance;
