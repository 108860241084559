import React, { useState } from "react";
import ThreadWrapper from "./ThreadWrapper";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { axiosInstance } from "../../Axios";
import toast from "react-hot-toast";

const AddCommentForm = ({ getFeed, getComments }) => {
  const [commentText, setCommentText] = useState("");
  const [authorName, setAuthorName] = useState("ALI");
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    axiosInstance
      .post("post_comment/", {
        feed: id,
        user: user.user_id,
        text: commentText,
      })
      .then((response) => {
        toast.success("Comment posted successfully!");
        setCommentText("");
        getFeed();
        getComments();
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
      });
    // e.preventDefault();
    // if (!commentText.trim() || !authorName.trim()) return;

    // const newComment = {
    //   text: commentText,
    //   author: authorName,
    // };

    // try {
    //   const response = await fetch("YOUR_API_ENDPOINT", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(newComment),
    //   });

    //   if (!response.ok) {
    //     throw new Error("Failed to add comment");
    //   }

    //   // Assuming the API returns the newly created comment data
    //   const data = await response.json();
    //   onAddComment(data); // Update UI with the new comment
    //   setCommentText("");
    //   setAuthorName("");
    // } catch (error) {
    //   console.error("Error adding comment:", error);
    // }
  };

  return (
    <div>
      <form className="px-4">
        <div className="relative">
          <textarea
            id="comment-text"
            className="peer py-4 px-0 block w-full bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm placeholder:text-transparent focus:border-blue-500 focus:border-t-transparent focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none darki:border-b-neutral-700 darki:text-neutral-400 darki:focus:ring-neutral-600 darki:focus:border-b-neutral-600
 focus:pt-6
 focus:pb-2
 [&:not(:placeholder-shown)]:pt-6
 [&:not(:placeholder-shown)]:pb-2
 autofill:pt-6
 autofill:pb-2"
            placeholder="Add your comment..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          ></textarea>
          <label
            htmlFor="comment-text"
            className="absolute top-0 start-0 py-4 h-full text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent origin-[0_0] darki:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none
 peer-focus:text-xs
 peer-focus:-translate-y-1.5
 peer-focus:text-gray-500 darki:peer-focus:text-neutral-500
 peer-[:not(:placeholder-shown)]:text-xs
 peer-[:not(:placeholder-shown)]:-translate-y-1.5
 peer-[:not(:placeholder-shown)]:text-gray-500 darki:peer-[:not(:placeholder-shown)]:text-neutral-500 darki:text-neutral-500"
          >
            Comment
          </label>
        </div>
        <button
          className="btn btn-primary my-6 "
          type="button"
          onClick={handleSubmit}
        >
          Add Comment
        </button>
      </form>
    </div>
  );
};

export default AddCommentForm;
