import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";

import Network_StudentList from "../components/Network_StudentList";
import { useLanguage } from "../components/language";
import AddSchool from "./AddSchool";
import AdminLayout from "./AdminLayout";
export default function NetworkSchools() {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [schools, setSchools] = useState([]);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const userID = user["user_id"];
  const profileID = userID;

  const [loadingStudents, setLoadingStudents] = useState(true);

  const [profile, setProfile] = useState();
  const [currentNetwork, setCurrentNetwork] = useState(null); // Initialize the currentNetwork state

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`
        );
        const data = await response.json();
        setProfile(data);

        // Set the currentNetwork state once the profile data is fetched
        setCurrentNetwork(data?.network_id); // Ensure data is not null before accessing the property
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };

    getProfile();
  }, [profileID]);

  useEffect(() => {
    if (currentNetwork) {
      // Fetch schools from the Django API
      fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Filter schools with network matching the currentNetwork
          const filteredSchools = data.filter(
            (school) => school.network === currentNetwork && !school.is_department
          );
          setSchools(filteredSchools);
        })
        .catch((error) => {
          console.error("Error fetching schools:", error);
          setError(error.message);
        });
    }
  }, [currentNetwork]);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AdminLayout>
    <div className="">
      <Tabs>
        <div className="sm:hidden">
          <label htmlFor="Tab" className="sr-only">
            Tab
          </label>
          <select id="Tab" className="w-full rounded-md border-gray-200">
            <option>Settings</option>
            <option>Messages</option>
            <option>Archive</option>
            <option select="">Notifications</option>
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <TabList className="-mb-px flex gap-6" aria-label="Tabs">
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                All Campuses
              </Tab>
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Add New Campus
              </Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
          <div className="mt-8 rounded-xl overflow-hidden darki:bg-slate-900 darki:border-gray-700">
            {schools.length === 0 ? (
              <div className="flex justify-center mt-20 items-center gap-2 flex-col">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="text-gray-400">No school found</p>
              </div>
            ) : (
              <ul className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 grids gap-4">
                {schools.map((school) => (
                  <li key={school.school_id} className="card bg-base-100 ">
                    <figure>
                      <img
                        src={
                          school.card_photo ||
                          "https://edexec.co.uk/wp-content/uploads/2021/01/school-building-modern-thin-line-design-style-vector-illustration-vector-id1030995160.jpg"
                        }
                        className="w-auto h-[11rem] max-h-64"
                        alt="School"
                      />
                    </figure>

                    <div className="card-body">
                      <h2 className="card-title">{school.name}</h2>

                      <div className="card-actions justify-start">
                        {/* <div className="badge badge-outline">{school.city} City</div>  */}
                        <div className="badge badge-outline">
                          {school.number_of_students} Students
                        </div>
                      </div>

                      <div className="card-actions justify-start mt-4">
                        <Link to={`/school_data/${school.school_id}`}>
                          <button className="btn btn-ghost text-primary">
                            View Details
                          </button>
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <AddSchool />
        </TabPanel>
      </Tabs></div>
    </AdminLayout>
  );
}
