// firebaseConfig.js or firebase.js

import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyBDYMJ3uhdyjeQegQDZnXVV-mCV91vH5uM",
    authDomain: "leapstartupearly-82067.firebaseapp.com",
    projectId: "leapstartupearly-82067",
    storageBucket: "leapstartupearly-82067.appspot.com",
    messagingSenderId: "412283018548",
    appId: "1:412283018548:web:c13264dbb01d23bce9d961",
    measurementId: "G-QZM1HXBCR5"
}

let auth
let firestore

try {
    // Initialize Firebase
    initializeApp(firebaseConfig)
    auth = getAuth()
    firestore = getFirestore()
    console.log("firebase initialized", auth, firestore)
} catch (err) {
    console.error("error initializig firebase", err)
}

export { auth, firestore }
