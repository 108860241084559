import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import CampusMap from "./CampusMap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SchoolHouseRubric from "./SchoolHouseRubric";

function SchoolHouses() {

  return (
    <AdminLayout>

<div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    {/* Grid */}
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
      {/* Card */}
      <div className="flex flex-col gap-y-3 bg-[#f4e9ff] lg:gap-y-5 p-4 md:p-5 border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800">
        <div className="inline-flex justify-center items-center">
          <span className="size-2 inline-block bg-gray-500 rounded-full me-2" />
          <span className="text-xs font-semibold uppercase text-gray-600 darki:text-gray-400">
          Sir Francis Chichester
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            15,000
          </h3>
        </div>
        <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-green-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">1.7%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              3
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl>
      </div>
      {/* End Card */}
      {/* Card */}
      <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-[#e0f9f4] border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800">
        <div className="inline-flex justify-center items-center">
          <span className="size-2 inline-block bg-green-500 rounded-full me-2" />
          <span className="text-xs font-semibold uppercase text-gray-600 darki:text-gray-400">
          Thor Heyerdahl
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            6,500
          </h3>
        </div>
        <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-green-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">5.6%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              2
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl>
      </div>
      {/* End Card */}
      {/* Card */}
      <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-[#ffecb1] border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800">
        <div className="inline-flex justify-center items-center">
          <span className="size-2 inline-block bg-red-500 rounded-full me-2" />
          <span className="text-xs font-semibold uppercase text-gray-600 darki:text-gray-400">
          Jacques Cousteau
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            3,890
          </h3>
        </div>
        <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-red-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">5.6%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              2
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl>
      </div>



      <div className="flex flex-col gap-y-3 lg:gap-y-5 p-4 md:p-5 bg-[#ffe0e0] border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800">
        <div className="inline-flex justify-center items-center">
          <span className="size-2 inline-block bg-red-500 rounded-full me-2" />
          <span className="text-xs font-semibold uppercase text-gray-600 darki:text-gray-400">
          Barbarossa
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            2,500
          </h3>
        </div>
        <dl className="flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-red-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">5.6%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              1
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl>
      </div>





      <div className="flex flex-col gap-y-3 justify-center cursor-pointer lg:gap-y-5 p-4 md:p-5 bg-[#fff] border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800" style={{border:'4px dashed #0000007d'}}>

        <div className="text-center">
          <h3 className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-gray-800 darki:text-gray-200">
            +
          </h3>
        </div>
        {/* <dl className="opacity-0 flex justify-center items-center divide-x divide-gray-200 darki:divide-gray-700">
          <dt className="pe-3">
            <span className="text-red-600">
              <svg
                className="inline-block size-4 self-center"
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                />
              </svg>
              <span className="inline-block text-sm">5.6%</span>
            </span>
            <span className="block text-sm text-gray-500">progress</span>
          </dt>
          <dd className="text-start ps-3">
            <span className="text-sm font-semibold text-gray-800 darki:text-gray-200">
              1
            </span>
            <span className="block text-sm text-gray-500">members</span>
          </dd>
        </dl> */}
      </div>



      {/* End Card */}
    </div>
    {/* End Grid */}
  </div>
    {/* <div>
      <div>
        <div className="max-w-[85rem] p-4 mx-auto">


        <Tabs>

        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <TabList className="flex gap-6 max-w-[85rem] mx-auto" aria-label="Tabs">
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                All Houses
              </Tab>
              <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Custom Rubric
              </Tab>
            </TabList>
          </div>
        </div>

        <TabPanel>
        <>

</>

        </TabPanel>
        <TabPanel>
          <SchoolHouseRubric />
        </TabPanel>
      </Tabs>
         
        </div>
      </div>
    </div> */}
    </AdminLayout>
  );
}

export default SchoolHouses;
