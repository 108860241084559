import React from 'react';

function TopClubs({ data }) {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right">
          <tr>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Name
            </th>

            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Members
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Staff Members
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Events
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Budget
            </th>
            <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Participation
            </th>
            <th className="px-4 py-2" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-center">
          {data.map((club, index) => (
            <tr key={index} className='mb-2'>
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                {club.name}
              </td>

              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {club.members}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {club.staffMembers}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {club.events}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {club.budget}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                {club.participation}
              </td>
              <td className="whitespace-nowrap px-4 py-2">
                <a
                  href="#"
                  className="inline-block rounded bg-primary px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"
                >
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    
  );
}

export default TopClubs;
