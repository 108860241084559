
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "react-modal";
import AuthContext from "../context/AuthContext";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
const AddCampaignForm = ({onModalClose}) => {
  const [eventName, setEventName] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [venue, setVenue] = useState("");
  const [isExternalEvent, setIsExternalEvent] = useState(false);

  let { user } = useContext(AuthContext);

  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [schools, setSchools] = useState([]);
  const [network, setNetwork] = useState("");
  const [schoolID, setschoolID] = useState(null);
  const [schoolnetworkID, setschoolnetworkID] = useState(null);

  const [selectedSchool, setselectedSchool] = useState("");

  const handleSelectChange = (event) => {
    setselectedSchool(event.target.value);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  let url = '';
  if(user['role']==='Student'){
    url = 'profile';
  } else if(user['role']==='Network Admin'){
    url = 'network_profile';
  } else if(user['role']==='School Admin'){
    url = 'school_profile';
  } else if(user['role']==='Staff'){
    url = 'staff';
  }
  const userID = user["user_id"];



  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((res) => {
        setNetwork(res?.data?.network_id);
        setschoolID(res?.data?.school_id)
        setschoolnetworkID(res?.data?.network)
        //console.warn("Data:", error);
      });
  }, [userID,url]);



  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const filteredSchools = data.filter(
          (school) => school.network === network
        );
        setSchools(filteredSchools);
      })
      .catch((error) => {
        console.error("Error fetching schools:", error);
      });
  }, [network]);


  let schoolValue = 0
  let networkValue = 0

  if(user['role']==='Network Admin'){
    schoolValue = selectedSchool
  } else if(user['role']==='School Admin'){
    schoolValue = schoolID
  }

  if(user['role']==='Network Admin'){
    networkValue = network
  } else if(user['role']==='School Admin'){
    networkValue = schoolnetworkID
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/add_event/`, {
        name: eventName,
        start_date: startDateTime,
        end_date: endDateTime,
        external_event: isExternalEvent,
        venue: venue,
        //school: selectedSchool,
        school: schoolValue,
        //network: network,
        network: networkValue,
      })
      .then((res) => {
        // console.log(res);
        closeModal();
        toast.success("Event added successfully!");
      })
      .catch((err) => {
        toast.error("Something went wrong! Please try again later.");
        closeModal();
        console.log(err);
      });

    console.log({
      eventName,
      startDateTime,
      endDateTime,
      venue,
      isExternalEvent,
    });
    // Reset form fields after submission
    setEventName("");
    setStartDateTime("");
    setEndDateTime("");
    setVenue("");
    setIsExternalEvent(false);
    closeModal();
  };
  const [constraints, setConstrainsts] = useState({});


  return (
    <div>
       <div className="bg-white p-6 rounded ">
        <h2 className="text-xl mb-4">Add Campaign</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Campaign Name:</label>
            <input
              type="text"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              className="input input-bordered w-full"
              
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4 w-full">
            <label className="block text-gray-700">Start Date and Time:</label>
            <input
              type="datetime-local"
              value={startDateTime}
              onChange={(e) => setStartDateTime(e.target.value)}
              className="input input-bordered w-full"
              
            />
          </div>
          <div className="mb-4  w-full">
            <label className="block text-gray-700">End Date and Time:</label>
            <input
              type="datetime-local"
              value={endDateTime}
              onChange={(e) => setEndDateTime(e.target.value)}
              className="input input-bordered w-full"
              
            />
          </div>

          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">



          {user['role']==='Network Admin' && (
  <div className="mb-4">
    <label className="flex justify-between text-sm font-medium text-gray-800 darki:text-gray-200">
      <span>Campus</span>
    </label>

    <select
      className="select select-bordered w-full"
      value={selectedSchool}
      onChange={handleSelectChange}
    >
      <option disabled value="">
        Select a campus
      </option>

      {schools.map((option, index) => (
        <option key={index} value={option.school_id}>
          {option.name}
        </option>
      ))}
    </select>
  </div>
)}



<div className="mb-4">
    <label className="flex justify-between text-sm font-medium text-gray-800 darki:text-gray-200">
      <span>Activity</span>
    </label>

    <select
      className="select select-bordered w-full"
      value={selectedSchool}
      onChange={handleSelectChange}
    >
      <option disabled value="">
        Select a activity
      </option>

      <option >
          Leadership
        </option>

        <option >
          Social Work
        </option>


        <option >
          Sports
        </option>
    </select>
  </div>

          </div>
         


<textarea placeholder="Campaign Description" className="w-full mb-4 textarea textarea-bordered"></textarea>



          <div className="text-right">
            <button type="submit" className="btn btn-primary">
              Add Campaign
            </button>
            <button type="button" onClick={onModalClose} className="btn btn-ghost">
              Cancel
            </button>
          </div>
        </form>
      </div> 







    </div>
  );
};

export default AddCampaignForm;