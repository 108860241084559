import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import "react-calendar/dist/Calendar.css";
import { axiosInstance } from "../Axios";
import { Link } from "react-router-dom";
import axios from "axios";
import { HiMiniBell, HiOutlineBell, HiBell } from "react-icons/hi2";
import MobileNav from "./MobileNav";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
function ParentHeader() {
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const [profile, setProfile] = useState();
  let userID = 0;

  if (user["role"] === "Parent") {
    userID = 12;
  } else {
    userID = user["user_id"];
  }

  let url = "";
  if (user["role"] === "Student" || user["role"] === "Parent") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  let role = user["role"];

  if (profile?.status == "2") {
    role = "Student";
  }

  const [loading, setLoading] = useState(true);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
        setLoading(false);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  return (
    <div className="px-4 py-1 ">
      <div class=" inset-x-0 top-0 p-4">
        <div class="relative flex items-center justify-between gap-4 rounded-lg bg-indigo-600 px-4 py-3 text-white shadow-lg">
          <p class="text-sm font-medium m-0">
            Rachel Bennett <badge className="badge ml-2">Parent</badge>
          </p>

          {/* <li class="flex items-center dropdown">
            <a
              tabindex="0"
              class=" border-0 m-1 flex items-center gap-2 p-0 text-sm font-semibold text-drk transition-all ease-nav-brand "
            >
              <div>
                <img
                  class="w-10 h-10 rounded-full"
                  src="https://icons.iconarchive.com/icons/papirus-team/papirus-status/512/avatar-default-icon.png"
                />
              </div>
              <span class=" sm:inline">
              <span className="text-sm text-white opacity-50">Current viewing child:</span>
                <div class="flex flex-row gap-2 text-white items-center dropdown dropdown-end">
                  Lucas Ramirez
                </div>

              </span>
            </a>
          </li> */}

          <div className="flex items-center gap-4"> 
  <label for="HeadlineAct" class="block text-sm font-medium text-white opacity-50"> Currently viewing child </label>

  <div class="relative mt-1.5">
    <input
      type="text"
      list="HeadlineActArtist"
      id="HeadlineAct"
      class="w-full rounded-lg border-gray-300 pe-10 text-gray-700 sm:text-sm [&::-webkit-calendar-picker-indicator]:opacity-0"
      placeholder="Please select"
      value={`Lucas Ramirez`}
    />

    <span class="absolute inset-y-0 end-0 flex w-8 items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-5 text-gray-500"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
        />
      </svg>
    </span>
  </div>

  <datalist name="HeadlineAct" id="HeadlineActArtist" >
    <option value="Lucas Ramirez" selected>Lucas Ramirez</option>
  </datalist>
</div>

        </div>
      </div>
    </div>
  );
}

export default ParentHeader;
