import React, { useState, useEffect, useContext } from "react";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import EventsStats from "../components/EventsStats";
import CampaignsList from "./CampaignsList";

function AllSchoolCampaigns() {
  const [profile, setProfile] = useState();
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);

  const userID = user["user_id"];

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  } else if (user["role"] === "School Admin") {
    url = "school_profile";
  } else if (user["role"] === "Staff") {
    url = "staff";
  }

  let role = user["role"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  const [campaigns, setcampaigns] = useState(
    [
        {
          "id": 1,
          "name": "Sustainability Campaign",
          "status": "active",
          "start_date": "May 1, 2024",
          "end_date": "May 31, 2024",
          banner: "https://academy.europa.eu/pluginfile.php/66761/course/overviewfiles//sustainability_course_featuring_visual.png"
        },
        {
          "id": 2,
          "name": "Ramzan Drive Campaign",
          "status": "active",
          "start_date": "May 1, 2024",
          "end_date": "May 31, 2024",
          banner:"https://www.propertyfinder.ae/blog/wp-content/uploads/2024/01/5-29.jpg"
        },
        {
          "id": 3,
          "name": "Entrepreneurship Campaign",
          "status": "active",
          "start_date": "May 1, 2024",
          "end_date": "May 31, 2024",
          banner:"https://assets.entrepreneur.com/content/3x2/2000/20150413213308-lemonade-stand-entrepreneur-mindset-selling-startup-small-kids-children.jpeg"
        }
      ]
      
  );

  

  return (
    <>
     <AdminLayout>
{/* 
      <div>
        <>

          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden darki:bg-slate-900 darki:border-gray-700">
                  <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
                    <thead className="bg-gray-50 darki:bg-slate-800">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Event Name
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Date
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Time
                            </span>
                          </div>
                        </th>
                       
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                              Status
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-end" />
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
                      {campaigns.map((event, index) => (
                        <tr>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <a
                                className="text-sm text-blue-600 decoration-2 hover:underline darki:text-blue-500"
                                href="#"
                              >
                                {event?.name}
                              </a>
                            </div>
                          </td>
                         
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              <span className="text-sm text-gray-600 darki:text-gray-400">
                                {(event.start_date)} -{" "}
                                {(event.end_date)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-2">
                              {event?.status === `active` ? (
                                <span className="badge badge-success text-gray-600">
                                  Active
                                </span>
                              ) : event?.status === false ? (
                                <span className="badge badge-error text-gray-600">
                                  Rejected
                                </span>
                              ) : (
                                <span className="badge badge-warning text-gray-600">
                                  Pending
                                </span>
                              )}
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex justify-center">
                              <Link
                                className="btn btn-ghost text-primary"
                                to={`/camp_details/${event?.id}`}
                              >
                                <FaEye />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </>
      </div>


       */}

       <CampaignsList campaigns={campaigns} />
      </AdminLayout>
    </>
  );
}

export default AllSchoolCampaigns;
