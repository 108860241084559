import React, { useState, useEffect } from 'react';

function EventsStats({events}) {

    return (
        <div className="my-8">
<>
  {/* Card Section */}
  {/* <div className="max-w-[85rem] p-4 mx-auto"> */}
  {/* Grid */}
  <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
    {/* Card */}
    <div className="flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 h-max">
      <div className="p-4 md:p-5 flex flex-row justify-between items-center">
        <div className="flex items-center gap-x-2">
          <p className="text-xs uppercase tracking-wide text-gray-500 m-0">
            Total Events
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2">
          <h3 className="text-xl sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
            {events.length}
          </h3>
        </div>
      </div>
    </div>
    {/* End Card */}
    {/* Card */}
    <div className="flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 h-max">
      <div className="p-4 md:p-5 flex flex-row justify-between items-center">
        <div className="flex items-center gap-x-2">
          <p className="text-xs uppercase tracking-wide text-gray-500 m-0">
            Total Pending
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2">
          <h3 className="text-xl sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
            {events.filter((event) => !event?.status).length}
          </h3>
        </div>
      </div>
    </div>
    {/* End Card */}
    {/* Card */}
    <div className="flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 h-max">
      <div className="p-4 md:p-5 flex flex-row justify-between items-center">
        <div className="flex items-center gap-x-2">
          <p className="text-xs uppercase tracking-wide text-gray-500 m-0">
            Internal Events
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2">
          <h3 className="text-xl sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
            {events.filter((event) => !event?.external_event).length}
          </h3>
        </div>
      </div>
    </div>
    {/* End Card */}
    {/* Card */}
    <div className="flex flex-col bg-white border shadow-sm rounded-xl darki:bg-slate-900 darki:border-gray-800 h-max">
      <div className="p-4 md:p-5 flex flex-row justify-between items-center">
        <div className="flex items-center gap-x-2">
          <p className="text-xs uppercase tracking-wide text-gray-500 m-0">
            External Events
          </p>
        </div>
        <div className="mt-1 flex items-center gap-x-2">
          <h3 className="text-xl sm:text-2xl font-medium text-gray-800 darki:text-gray-200">
            {/* Count the number of events where event?.external_event is true */}
            {events.filter((event) => event?.external_event).length}
          </h3>
        </div>
      </div>
    </div>

    {/* End Card */}
  </div>
  {/* End Grid */}
  {/* </div> */}
  {/* End Card Section */}
</>
</div>
    )
}

export default EventsStats;