import React, { useState, useEffect } from "react";
import { LuTrendingUp } from "react-icons/lu";

function CouncilRosterStats({needTypeFilter,type,clublist}) {
  const stats = [
    {
      title: "Attendance",
      score: "98%",
    },
    {
      title: "Involvement",
      score: "High",
    },
    {
      title: "Participation",
      score: "Active",
    },
    {
      title: "Performance",
      score: "High",
    },
  ];

  return (
    <>
      {/* Card Section */}
      <div className="mt-8 p-4 bg-purple-50 rounded-xl">
        {/* Grid */}

<div className="grid grid-cols-2 md:grid-cols-12 gap-6">


<div className="flex flex-col col-span-4 justify-between">

<div className="">
<h3 className="text-lg font-bold mb-0"> Overview </h3>
<span className="text-base font-normal ">Your {type} at a glance</span>
</div>


  <select className="select select-bordered select-sm max-w-[12rem]">
    <option>Last 7 days</option>
    <option>Last month</option>
    <option>Last 3 months</option>
    <option>Last 6 months</option>
    <option>This year</option>
  </select>

{needTypeFilter? (
  <select className="select select-bordered select-sm max-w-[12rem]">
  <option selected>Select {type}</option>
    {clublist.map((club, index)=>(
      <>
      {club.name!==null || club.name!==''?(
        <option key={index}> {club.name} </option>
      ):null}
      </>
    ))}
   
  </select>
):null}

</div>

<div className="col-span-8">



<div className="grid sm:grid-cols-2 md:grid-cols-4 gap-4 ">
        {stats.map((stat, index) => (
            <div className="flex flex-col border rounded-xl bg-white " key={index}> 
            <div className="py-2 px-3 flex flex-col-reverse justify-start items-start  h-[8rem]">
              <div className="flex items-center gap-x-2">
                <p className="text-sm  m-0 font-semibold text-gray-500">
                  {stat?.title}
                </p>
              </div>
              <h3 className="text-2xl text-gray-800 mb-0">
                <span className="font-semibold mb-0">{stat.score}</span>{" "}
              </h3>

              <LuTrendingUp className="text-success" size={20} />

            </div>
          </div>
        ))}
        </div>
</div>
</div>


        


        {/* End Grid */}
      </div>
      {/* End Card Section */}
    </>
  );
}

export default CouncilRosterStats;
