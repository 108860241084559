import React, { useState, useEffect } from 'react';

const StudentPerformanceTable = ({ shortLength }) => {
  const [dates, setDates] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getDates = (startDate, numDays) => {
      const dates = [];
      const date = new Date(startDate);
      for (let i = 0; i < numDays; i++) {
        dates.push(new Date(date).toDateString());
        date.setDate(date.getDate() + 1);
      }
      return dates;
    };

    let showLength = 0;
    if (shortLength) {
      showLength = 3;
    } else {
      showLength = 15;
    }

    const now = new Date();
    const days = getDates(now, showLength);
    setDates(days);

    const generateData = (numDays) => {
      const getRandomScore = () => Math.floor(Math.random() * 11);
      return days.map((date, index) => ({
        no: index + 1,
        date: date,
        presence: 'Present',  // or 'Absent', can be randomized if needed
        involvement: getRandomScore(),
        participation: getRandomScore(),
        performance: getRandomScore(),
      }));
    };

    setData(generateData(showLength));
  }, [shortLength]);

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th>No.</th>
        <th>Date</th>
        <th>Presence</th>
        <th>Involvement</th>
        <th>Participation</th>
        <th>Performance</th>
      </tr>
    </thead>
  );

  const renderTableBody = () => (
    <tbody>
      {data.map((row, rowIndex) => (
        <tr key={rowIndex}>
          <td>{row.no}</td>
          <td>{row.date}</td>
          <td className='text-success'>{row.presence}</td>
          <td>{row.involvement}</td>
          <td>{row.participation}</td>
          <td>{row.performance}</td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div>
      <table className='table' border="1">
        {renderTableHeader()}
        {renderTableBody()}
      </table>
    </div>
  );
};

export default StudentPerformanceTable;
