import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ProfileEditForm from "./ProfileEditForm";
import { axiosInstance } from "../Axios";
import axios from "axios";
import { FaSignal, FaTrophy, FaRegCheckCircle } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Tooltip } from "react-tooltip";
import Popup from "reactjs-popup";

const PStdStats = () => {
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const lanArabic = i18n.language === "ar";

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  let [totalCompletions, setTotalCompletions] = useState(0);
  let [totalExperiencePoints, setTotalExperiencePoints] = useState(0);
  let [currentLevel, setCurrentLevel] = useState("Level 1");
  let [profile, setProfile] = useState();
  let UserID = 12;

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${UserID}`
        );
        // parse the data in json
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        // console.log('The requested profile does not exist.');
      }
    };

    const calculateCompletions = async () => {
      // API endpoints to check how many entries match in each endpoint
      const endpoints = [
        `${process.env.REACT_APP_API_KEY}/api/get_lead/`,
        `${process.env.REACT_APP_API_KEY}/api/get_society/`,
        `${process.env.REACT_APP_API_KEY}/api/get_social/`,
        `${process.env.REACT_APP_API_KEY}/api/get_awards/`,
        `${process.env.REACT_APP_API_KEY}/api/get_sports/`,
        `${process.env.REACT_APP_API_KEY}/api/get_intern/`,
        `${process.env.REACT_APP_API_KEY}/api/get_cert/`,
        `${process.env.REACT_APP_API_KEY}/api/get_talent/`,
        `${process.env.REACT_APP_API_KEY}/api/get_lang/`,
        `${process.env.REACT_APP_API_KEY}/api/get_eventStd/`,
      ];

      // Sum all completions from each endpoint
      let completions = 0;
      for (const endpoint of endpoints) {
        try {
          const response = await axios.get(endpoint);
          completions += response.data.filter(
            (entry) => parseInt(entry.posted_by) === UserID
          ).length;
        } catch (error) {
          // console.log(`Error fetching data from endpoint: ${endpoint}`);
        }
      }

      setTotalCompletions(completions);
    };

    const calculateExperiencePoints = async () => {
      try {
        // Fetch data from relevant endpoints to calculate experience points
        const leadResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_lead/`
        );
        const societyResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_society/`
        );
        const socialResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_social/`
        );
        const awardsResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_awards/`
        );
        const sportsResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_sports/`
        );
        const internResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_intern/`
        );
        const certResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_cert/`
        );
        const talentResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_talent/`
        );
        const langResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_lang/`
        );
        const eventResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_eventStd/`
        );

        // Filter data to include only entries where posted_by is equal to UserID
        const filterByUserID = (data) =>
          data.filter(
            (entry) => parseInt(entry.posted_by) === parseInt(UserID)
          );

        const leadData = filterByUserID(leadResponse.data);
        const societyData = filterByUserID(societyResponse.data);
        const socialData = filterByUserID(socialResponse.data);
        const awardsData = filterByUserID(awardsResponse.data);
        const sportsData = filterByUserID(sportsResponse.data);
        const internData = filterByUserID(internResponse.data);
        const certData = filterByUserID(certResponse.data);
        const talentData = filterByUserID(talentResponse.data);
        const langData = filterByUserID(langResponse.data);
        const eventData = filterByUserID(eventResponse.data);

        function getLeadershipActivityLabel(activity_name) {
          const labelMap = {
            "Student Council General Secretary": 9,
            "Deputy Head Student": 8.01,
            "House Captain": 8.02,
            "Class Prefect": 8.03,
            "Captain Sports Team": 8.04,
            "Class Monitor": 7,
            "Head Student": 10,
          };
          // console.log("Pushed Activity: "+labelMap[activity_name]);
          return labelMap[activity_name] || 1;
        }

        function getAwardsLabel(activity_name) {
          const awardsMapping = {
            "Full attendance": 10,
            "Academic excellence": 10,
            "Sports scholarship": 10,
            "Merit scholarship": 10,
            "Research grant": 10,
            "Poem Recitation": 6,
            "Spoken Word Contest": 6,
            "Play/Musical": 7,
            "Choir Performance": 7,
            "Aerobics Competition": 7,
            "Bake Sale": 6,
            Artbeat: 7,
            "Debates Competition": 8,
            "School/college MUN": 8,
            "Math Competition": 8,
            "Trivia Competition": 7,
            "Science Competition": 8,
            "Science Fair": 8,
            "Dance Competition": 7,
            "Short Film Competition": 8,
            "Robotics Competition": 8,
            "Poster Making Competition": 7,
            "Gaming Competition": 7,
            "Fashion Design Competition": 7,
            "Cooking Competition": 7,
            "Modeling Competition": 7,
            "Talent Show": 7,
            "Handwriting Competition": 5,
            "Essay Competition": 8,
            "Acting Competition": 7,
            "Drawing Competition": 8,
            "Photography Competition": 8,
            "Painting Competition": 8,
            "Composition Competition": 8,
            "Lucky Draw": 6,
            "Spelling Competition": 7,
            "Certificate of Achievement": 10,
          };

          return awardsMapping[activity_name] || 1;
        }

        function getCertificationLabel(cert_name) {
          const certificationMapping = {
            "First aid certification": 10,
            "Driving license": 7,
            "Google certification": 8,
            "Coursera course": 8,
            "edX course": 8,
            "Udemy course": 8,
            "Masterclass course": 8,
            "Duolingo course": 8,
            "Khan Academy course": 8,
            "Codecademy course": 8,
            "Domestika course": 8,
            "Udacity course": 8,
          };

          return certificationMapping[cert_name] || 1;
        }

        function getEventManagementLabels2(activity_name) {
          const labelMap = {
            "Executive Council": 9,
            "Logistics Team": 8,
            "Registrations Team": 8,
            "Socials Team": 8,
            "Publications Team": 8,
            "Finance Team": 8,
            "Promotions Team": 8,
            "Communications Team": 8,
            "IT Team": 8,
            "Security Team": 8,
            "Operations Team": 8,
          };
          return labelMap[activity_name] || 1;
        }

        function getInternIndustry(activity_name) {
          const labelMap = {
            Research: 8,
            Engineering: 8,
            "Marketing/Advertising": 8,
            "Engineering/Technical": 8,
            "Finance/Accounting": 8,
            "Media/Entertainment": 8,
            "Nonprofit/NGO": 8,
            Medical: 8,
            Legal: 8,
            "Graphic Design/Visual Arts": 8,
            "Software Development/IT": 8,
            "Startup/Entrepreneurship": 8,
          };

          return labelMap[activity_name] || 1;
        }

        function getSocialActivityLabel(activity_name) {
          const labelMap = {
            "Food drive": 8,
            "Clothes drive": 8,
            "Charity collection": 8,
            "Blood donation drive": 9,
            "Hospital volunteer": 9,
            "Trash clean-up": 8,
            "Charity bake sale": 8,
          };
          return labelMap[activity_name] || 1;
        }

        function getSocietyNameLabel(activity_name) {
          const labelMap = {
            "STEM/STEAM Society": 8,
            "Music Society": 8,
            "Art Society": 8,
            "Debates Society": 8,
            "MUN Society": 8,
            "Drama/Theater Society": 8,
            "Math Society": 8,
            "Astronomy Society": 7,
            "Literature Society": 8,
            "Film Society": 7,
            "Environment Society": 8,
            "Community Service Society": 8,
            "Media & Publications Society": 8,
            "Sports Society": 8,
            "Choir Society": 7,
            "Cooking Society": 7,
            "Cardio Society": 7,
            "Yoga Society": 7,
            "Book Club": 7,
            "Cooking Club": 7,
            "School Magazine": 8,
            "School Social Media": 7,
          };
          return labelMap[activity_name] || 1;
        }

        function getSportsLabel(activity_name) {
          const sportsMapping = {
            Football: 7,
            Basketball: 7,
            Cricket: 7,
            Hockey: 7,
            Badminton: 7,
            Tennis: 7,
            Gymnastics: 7,
            Throwball: 7,
            Futsal: 7,
            Archery: 7,
            Cycling: 7,
            Sprint: 7,
            "Relay race": 7,
            Baseball: 7,
            Fencing: 7,
            Golf: 7,
            Handball: 7,
            Netball: 7,
            Rowing: 7,
            Skateboarding: 7,
            "Road bicycle racing": 7,
            Ballet: 7,
            Kabaddi: 7,
            Kickboxing: 7,
            Boxing: 7,
            Strongman: 7,
            Lacrosse: 7,
            Polo: 7,
            "Water Polo": 7,
            "Long Jump": 7,
            "High Jump": 7,
            "Shot put": 7,
            "Pole Vault": 7,
            Pickleball: 7,
            Chess: 8,
            "Track & field": 7,
            Curling: 7,
            "Endurance running": 7,
            "Hurdle races": 7,
            "Ice skating": 7,
            Surfing: 7,
            Skiing: 7,
            Snowboarding: 7,
            Dodgeball: 7,
            Climbing: 7,
            Snooker: 7,
            Squash: 7,
            Swimming: 7,
            "Table Tennis": 7,
            Volleyball: 7,
            Weightlifting: 7,
            Rugby: 7,
          };

          return sportsMapping[activity_name] || 1;
        }

        // Calculate experience points for each endpoint
        let lead_result = leadData.reduce(
          (total, entry) =>
            total +
            getLeadershipActivityLabel(entry.activity_name) *
              parseInt(entry.scope) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );
        let society_result = societyData.reduce(
          (total, entry) =>
            total +
            getSocietyNameLabel(entry.activity_name) *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );
        let social_result = socialData.reduce(
          (total, entry) =>
            total +
            getSocialActivityLabel(entry.activity_name) *
              parseInt(entry.scope) *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );
        let awards_result = awardsData.reduce(
          (total, entry) =>
            total +
            getAwardsLabel(entry.activity_name) *
              parseInt(entry.scope) *
              entry.rank *
              parseInt(entry.service_hours),
          0
        );
        let sports_result = sportsData.reduce(
          (total, entry) =>
            total +
            getSportsLabel(entry.activity_name) *
              parseInt(entry.scope) *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );
        let intern_result = internData.reduce(
          (total, entry) =>
            total +
            getInternIndustry(entry.activity_name) *
              entry.type *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1),
          0
        );
        let cert_result = certData.reduce(
          (total, entry) => total + getCertificationLabel(entry.cert_name),
          0
        );
        let talent_result = talentData.reduce(
          (total, entry) => total + 8 * parseInt(entry.service_hours),
          0
        );
        let lang_result = langData.reduce(
          (total, entry) => total + parseInt(entry.prof) * 7,
          0
        );
        let event_result = eventData.reduce(
          (total, entry) =>
            total +
            getEventManagementLabels2(entry.activity_name) *
              parseInt(entry.scope) *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );

        // Sum all results from each endpoint and multiply by 15 to get total experience points
        let experiencePoints =
          (lead_result +
            society_result +
            social_result +
            awards_result +
            sports_result +
            intern_result +
            cert_result +
            talent_result +
            lang_result +
            event_result) *
          15;

        let roundedExperiencePoints = Math.round(experiencePoints);
        setTotalExperiencePoints(roundedExperiencePoints);

        // console.log("EXP: " + totalExperiencePoints);
      } catch (error) {
        // console.log('Error fetching data for experience points calculation');
      }
    };

    getProfile();
    calculateCompletions();
    calculateExperiencePoints();
  }, [UserID]);

  // console.log("EXP: " + totalExperiencePoints);

  // let currentLevel = 'Level 1';

  const level = Math.floor(totalExperiencePoints / 500) + 1;

  //  currentLevel = level;

  //  if(totalExperiencePoints<=500){currentLevel = {t('Novice')};}

  if (totalExperiencePoints <= 500) currentLevel = `${t("Novice")}`;
  if (totalExperiencePoints > 500 && totalExperiencePoints <= 1000)
    currentLevel = `${t("Rising")}`;
  if (totalExperiencePoints > 1000 && totalExperiencePoints <= 1500)
    currentLevel = `${t("Explorer")}`;
  if (totalExperiencePoints > 1500 && totalExperiencePoints <= 2000)
    currentLevel = `${t("Enthusiast ")}`;
  if (totalExperiencePoints > 2000 && totalExperiencePoints <= 2500)
    currentLevel = `${t("Achiever")}`;
  if (totalExperiencePoints > 2500 && totalExperiencePoints <= 3000)
    currentLevel = `${t("Collaborator ")}`;
  if (totalExperiencePoints > 3000 && totalExperiencePoints <= 3500)
    currentLevel = `${t("Veteran")}`;
  if (totalExperiencePoints > 3500 && totalExperiencePoints <= 4000)
    currentLevel = `${t("Influencer ")}`;
  if (totalExperiencePoints > 4000 && totalExperiencePoints <= 4500)
    currentLevel = `${t("Master")}`;
  if (totalExperiencePoints > 4500) currentLevel = `${t("Legend")}`;

  
  //  if(lanArabic){
  //   if (totalExperiencePoints < 500) currentLevel = `${t('')} 1`;
  //   if (totalExperiencePoints > 500 C) currentLevel = `${t('')} 2`;
  //   if (totalExperiencePoints > 1000 ) currentLevel = `${t('')} 3`;
  //   if (totalExperiencePoints >= 700) currentLevel = `${t('')} 4`;
  //   if (totalExperiencePoints >= 1000) currentLevel = `${t('')} 5`;
  //  }

  //  else{
  //   if (totalExperiencePoints < 200) currentLevel = `${t('Level')} 1`;
  //   if (totalExperiencePoints >= 200) currentLevel = `${t('Level')} 2`;
  //   if (totalExperiencePoints >= 400) currentLevel = `${t('Level')} 3`;
  //   if (totalExperiencePoints >= 700) currentLevel = `${t('Level')} 4`;
  //   if (totalExperiencePoints >= 1000) currentLevel = `${t('Level')} 5`;
  //  }

  //// console.log(role);

  const scrollContainerRef = useRef(null);
  // Function to scroll the content left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const scrollAmount = 250; // Adjust the scroll amount as needed
      scrollContainerRef.current.scrollLeft -= scrollAmount;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const scrollAmount = 250; // Adjust the scroll amount as needed
      scrollContainerRef.current.scrollLeft += scrollAmount;
    }
  };
  return (
    <div>
      {/* <div className="flex pb-4" style={{overflowX:'scroll'}}> */}

      <div
        className={`pb-4 ${
          user.role === "ccaAdmin" ? "flex items-center" : ""
        }`}
      >
        {(() => {
          if (user["role"] === "ccaAdmin") {
            return (
              <button className="btn btn-circle" onClick={scrollLeft}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
              </button>
            );
          }
        })()}

        {/* card1 */}
        {/* <div className="flex" style={{ overflowX: 'scroll' }} ref={scrollContainerRef}> */}
        <div
          className="grid grid-cols-2 gap-2 mb-0 md:gap-0 md:flex scrollable-container md:pb-0 "
          ref={scrollContainerRef}
        >
          <div className={`w-full md:max-w-full md:px-3 xl:w-1/4 ${lanArabic ? 'rtl' : ''}`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div
                  className={`lg:flex block px-2 -mx-3 ${
                    lanArabic ? "flex-row-reverse" : "flex-row"
                  }`}
                >
                  <div
                    className={`flex-none w-full lg:w-2/3 lg:max-w-full px-3 ${
                      lanArabic ? "text-end" : ""
                    }`}
                  >
                    <div>
                      <div className="flex justify-between lg:justify-start lg:gap-2 items-center">
                        <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                          {t("LEVEL")}
                        </p>

                        <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="top center"
                          on={["hover", "focus"]}
                        >
                          <div className="bg-black text-white shadow-md p-4 rounded-lg w-8/12">
                            <span>
                              Your Progress Level captures the breadth of your
                              Co-Curricular involvement. Each new level
                              signifies a broader range of experiences and
                              skills you've developed outside the classroom.
                              Engage in diverse activities to enrich your
                              transcript and ascend to higher levels of
                              recognition.
                            </span>
                          </div>
                        </Popup>
                      </div>

                      <h5 className={`mb-2 font-bold ${lanArabic ? 'rtl text-justify' : ''}`}>{currentLevel} </h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="md:px-3 text-right basis-1/3 hidden lg:flex justify-center">
                    <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-amber-400">
                      <FaSignal size={25} className="text-white " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`w-full md:max-w-full md:px-3 xl:w-1/4 ${lanArabic ? 'rtl' : ''}`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div
                  className={`lg:flex block px-2 -mx-3 ${
                    lanArabic ? "flex-row-reverse" : "flex-row"
                  }`}
                >
                  <div
                    className={`flex-none w-full lg:w-2/3 lg:max-w-full px-3${
                      lanArabic ? "text-end" : ""
                    }`}
                  >
                    <div>
                      <div className="flex justify-between lg:justify-start lg:gap-2 items-center">
                        <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                          {t("Experience")}
                        </p>

                        <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="top center"
                          on={["hover", "focus"]}
                        >
                          <div className="bg-black text-white shadow-md p-4 rounded-lg w-8/12">
                            <span>
                              Experience Points tally your active participation
                              in Co-Curricular activities. Whether it's sports,
                              arts, or community service, every event adds to
                              your experience tally, reflecting your commitment
                              and growth. Collect points to highlight your
                              holistic development in your Co-Curricular
                              transcript.”
                            </span>
                          </div>
                        </Popup>
                      </div>

                      <h5 className={`mb-2 font-bold ${lanArabic ? 'rtl text-justify' : ''}`}>
                        {totalExperiencePoints}{" "}
                      </h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="md:px-3 text-right basis-1/3 hidden lg:flex justify-center">
                    <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-primary">
                      <SlGraph size={25} className="text-white " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`w-full md:max-w-full md:px-3 xl:w-1/4 ${lanArabic ? 'rtl' : ''}`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div
                  className={`lg:flex block px-2 -mx-3 ${
                    lanArabic ? "flex-row-reverse" : "flex-row"
                  }`}
                >
                  <div
                    className={`flex-none w-full lg:w-2/3 lg:max-w-full px-3 ${
                      lanArabic ? "text-end" : ""
                    }`}
                  >
                    <div>
                      <div className="flex justify-between lg:justify-start lg:gap-2 items-center">
                        <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                          {t("Activities")}
                        </p>

                        <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="top center"
                          on={["hover", "focus"]}
                        >
                          <div className="bg-black text-white shadow-md p-4 rounded-lg w-8/12">
                            <span>
                              The Activity Tracker showcases the total number of
                              Co-Curricular activities you've participated in.
                              It represents your active engagement in a variety
                              of experiences beyond academics. Use this tracker
                              to monitor your involvement level, set
                              participation goals, and reflect your well-rounded
                              engagement in your Co-Curricular transcript
                            </span>
                          </div>
                        </Popup>
                      </div>

                      <h5 className={`mb-2 font-bold ${lanArabic ? 'rtl text-justify' : ''}`}>{totalCompletions} </h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="md:px-3 text-right basis-1/3 hidden lg:flex justify-center">
                    <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-teal">
                      <FaRegCheckCircle size={25} className="text-white " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`w-full md:max-w-full md:px-3 xl:w-1/4 ${lanArabic ? 'rtl' : ''}`}>
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div
                  className={`lg:flex block px-2 -mx-3 ${
                    lanArabic ? "flex-row-reverse" : "flex-row"
                  }`}
                >
                  <div
                    className={`flex-none w-full lg:w-2/3 lg:max-w-full px-3${
                      lanArabic ? "text-end" : ""
                    }`}
                  >
                    <div>
                      <div className="flex justify-between lg:justify-start lg:gap-2 items-center">
                        <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                          {t("Badges")}
                        </p>

                        <Popup
                          trigger={(open) => (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                class="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                />
                              </svg>
                            </div>
                          )}
                          position="top center"
                          on={["hover", "focus"]}
                        >
                          <div className="bg-black text-white shadow-md p-4 rounded-lg w-8/12">
                            <span>
                              <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                                {t(
                                  "Earn badges by participating in and contributing to various co-curricular activities. Each badge showcases a unique skill or experience, adding depth to your co-curricular transcript. Celebrate your achievements and build a portfolio of badges that tell your story."
                                )}
                              </p>
                            </span>
                          </div>
                        </Popup>
                      </div>

                      <h5 className="mb-2 font-bold">
                        {totalCompletions > 0 ? <span>5</span> : <span>0</span>}
                      </h5>

                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="md:px-3 text-right basis-1/3 hidden lg:flex justify-center">
                    <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-purple-400">
                      <FaTrophy size={25} className="text-white " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {(() => {
          if (user["role"] === "ccaAdmin") {
            return (
              <button className="btn btn-circle" onClick={scrollRight}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                  />
                </svg>
              </button>
            );
          }
        })()}
      </div>
    </div>
  );
};

export default PStdStats;
