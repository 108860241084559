// src/components/Accordion.js

import React, { useState } from 'react';

const faqData = [
  {
    question: "Who can participate in this workshop?",
    answer: "Anyone interested in the topic of the workshop can participate."
  },
  {
    question: "What are the prerequisites for joining this workshop?",
    answer: "There are no prerequisites for joining this workshop."
  },
  {
    question: "Can I interact with the host at the workshop?",
    answer: "Yes, participants can interact with the host during the workshop."
  },
  {
    question: "Is it a pre-recorded or live workshop?",
    answer: "The workshop is live, providing real-time interaction opportunities."
  }
];

const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`rounded-xl ${isOpen ? 'shadow-2xl my-2 '  : ''}`}>
      <button
        className="w-full text-left bg-white p-4 rounded-xl focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex justify-between items-center">
          <span className='font-bold'>{title}</span>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
      </button>
      {isOpen && (
        <div className="px-4 py-2 bg-white">
          {children}
        </div>
      )}
    </div>
  );
};

const FAQs = () => {
  return (
    <div className="w-full mx-auto my-4">
      {faqData.map((item, index) => (
        <AccordionItem key={index} title={item.question}>
          {item.answer}
        </AccordionItem>
      ))}
    </div>
  );
};

export default FAQs;
