import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import { createGlobalStyle } from "styled-components";
import Drawer from "../components/Drawer";
import LanguageDropdown from "../components/LanguageDropdown";
import { useTranslation } from 'react-i18next';

function AdminDashNavbar({ profile, page, contentTitle }) {
  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  //     const GlobalStyle = createGlobalStyle`
  //     .mainNav {
  //         background: ${profile?.primaryColor ? profile.primaryColor : '#4f0696'};
  //     }
  // `;

  // Function to check if a color is dark
  const isDarkColor = (color) => {
    // Convert hex color to RGB
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);
    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    // Check if the luminance is less than 0.5
    return luminance < 0.5;
  };

  // Determine the text color dynamically based on the background color
  const textColor =
    profile && profile?.primaryColor
      ? isDarkColor(profile?.primaryColor)
        ? "#ffffff"
        : "#000000"
      : "#000000";

  const GlobalStyle = createGlobalStyle`
    .mainNav {
        background: ${profile?.primaryColor};
        color: ${
          profile?.primaryColor !== 0 && profile?.primaryColor
            ? isDarkColor(profile.primaryColor)
              ? "#ffffff"
              : "#000000"
            : "#000000"
        };
    }
`;

  let fullname = user["first_name"] + " " + user["last_name"];

  const [isEditing, setIsEditing] = useState(false);

  const [loading, setLoading] = useState(true);

  return (
    <>
      <GlobalStyle />
      <header
        style={{ zIndex: 99999999 }}
        className={`${
          user.role === "Network Admin" ? "mainNav" : "bg-[#4f0696]"
        } sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48] w-full border-b text-sm py-2 sm:py-2 darki:bg-gray-800 darki:border-gray-700`}
      >
        <nav
          className="flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8 "
          aria-label="Global"
        >
          <div className="me-5 font-semibold lg:me-0 flex flex-row items-center gap-4">
            <>
              {/* <div className="drawer">
                <input
                  id="my-drawer"
                  type="checkbox"
                  className="drawer-toggle"
                />
                <div className="drawer-content">
                  <label
                    htmlFor="my-drawer"
                    className="btn btn-primary drawer-button"
                  >
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5" />
</svg>


                  </label>
                </div>
                <div className="drawer-side">
                  <label
                    htmlFor="my-drawer"
                    aria-label="close sidebar"
                    className="drawer-overlay"
                  ></label>
                  <ul className="menu p-4 w-80 min-h-full bg-white text-base-content">


                  <Drawer profile={profile} page={page} contentTitle={contentTitle}/>

                    
                  </ul>
                </div>
              </div> */}

              {user["role"] === "Network Admin" ? (
                <img src={profile?.avatar} className="h-10 w-auto" />
              ) : user["role"] === "School Admin"  ? (
                <img src={profile?.logo} className="h-12 w-auto" />
              ) : user["role"] === "Staff"  ? (<img src={`https://res.cloudinary.com/ahbranding/image/upload/v1715176666/AHbranding/logo_white_t4smlv.png`} className="h-10 w-auto" />): null}
            </>
          </div>

          {user["role"] === "Network Admin" ? (
            <div
              className="w-full text-center pageTitle font-semibold hidden lg:block"
              style={{ color: textColor }}
            >
              {t(contentTitle)}
            </div>
          ) : user["role"] === "School Admin" || user["role"] === "Staff" ? (
            <div className="w-full text-center pageTitle font-semibold hidden lg:block text-white">
            {t(contentTitle)}
            </div>
          ) : null}

          <div className="w-min flex items-center justify-end ms-auto sm:justify-end sm:gap-x-3 sm:order-3">
            <div className="flex flex-row items-center justify-end gap-2">
            <LanguageDropdown darkC={true} />
              <div className="dropdown dropdown-end">
                <div
                  tabIndex={0}
                  role="button"
                  className="btn btn-ghost btn-circle "
                >
                  <div className="avatar placeholder">
                    <div className="bg-secondary text-primary rounded-full w-10">
                      <span>{user['role'] !== "Staff" ? (profile?.name?.charAt(0)?.toUpperCase()) : 'S'} </span>
                    </div>
                  </div>
                </div>
                <ul
                  tabIndex={0}
                  className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 mt-4"
                >
                  {/* <li className='font-medium text-gray-500'><Link className="px-2 py-4">Profile</Link></li> */}
                  <li className="font-medium text-red-400" onClick={logoutUser}>
                    <Link className="px-2 py-3">{t('Logout')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default AdminDashNavbar;
