import React from 'react';
import ReactApexChart from 'react-apexcharts';

class AttendanceBreakdown extends React.Component {
  constructor(props) {
    super(props);
    const { present, absent } = props;
    this.state = {
      series: [present, absent], // Example data for male and female diversity percentages
      options: {
        stroke: {
            width: 1,
            colors: undefined
          },
          yaxis: {
            show: false
          },
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0
              },
              spokes: {
                strokeWidth: 0
              },
            }
          },
        labels: ['Presents', 'Absents'], // Labels for the data
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  }

  render() {
    return (
      <ReactApexChart width={400} options={this.state.options} series={this.state.series} type="polarArea" />
    );
  }
}

export default AttendanceBreakdown;
