import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import { useLanguage } from "./language";
import Club from "./Clubs.json";
import { IoMdMore } from "react-icons/io";
import { Tooltip } from "react-tippy";
import KeyStageChecker from "./KeyStageChecker";

function Network_StudentTable({
  schools,
  selectActivity,
  activityPresent,
  activityNot,
  activityCount,
  filterStudent,
  handleSort,
  sortOrder,
}) {
  const { t, changeLanguage, lanArabic } = useLanguage();

  const navigate = useNavigate();

  const getInitials = (firstname, lastname) => {
    return `${firstname.charAt(0)}${lastname.charAt(0)}`;
  };

  const renderSortIcon = (key) => {
    if (sortOrder.key === key) {
      return sortOrder.order === "asc" ? (
        <FaSortAlphaUp />
      ) : (
        <FaSortAlphaDown />
      );
    }
    return null;
  };

  const getRandomClubs = () => {
    const shuffled = Club.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };

  const getRandomParentName = () => {
    const pakistaniNames = [
      "Ahmed Khan",
      "Sana Malik",
      "Ayesha Ahmed",
      "Farhan Ali",
      "Zainab Baloch",
      "Hassan Saeed",
      "Rabia Khan",
      "Tariq Mehmood",
      "Sadia Aslam",
      "Bilal Ahmad"
    ];
    return pakistaniNames[Math.floor(Math.random() * pakistaniNames.length)];
  };

  const getRandomLevel = () => {
    return Math.floor(Math.random() * 5) + 1;
  };

  const getRandomActivities = () => {
    return Math.floor(Math.random() * 14);
  };

  if (Club.length === 0) {
    return null;
  }

  return (
    <div className="overflow-hidden">
      <div className="student-count"></div>
      <table className="table w-full">
        <thead>
          <tr>
            <th onClick={() => handleSort("firstname")}>
              Name {renderSortIcon("firstname")}
            </th>
            <th>Student ID {renderSortIcon("id")}</th>
            <th>Parent</th>
            <th>Grade {renderSortIcon("grade")}</th>
            <th>Key Stage {renderSortIcon("keystage")}</th>
            <th>Level</th>
            <th>Activities</th>
            <th>Clubs</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {selectActivity === "All" &&
            schools.map((student) => {
              const clubs = getRandomClubs();
              const primaryClub = clubs[0];
              const otherClubs = clubs.slice(1);
              return (
                <tr key={student.id}>
                  <td>
                    <div className="flex items-center space-x-3">
                      <div className="avatar placeholder">
                        <div className="bg-secondary text-primary rounded-full w-12">
                          <span className="text-lg">
                            {getInitials(student.firstname, student.lastname)}
                          </span>
                        </div>
                      </div>
                      <div>
                        <Link to={`/student_nw/${student.user}`} className="font-bold hover:link-primary">
                          {student.firstname} {student.lastname}
                        </Link>
                        <div className="text-sm opacity-50">
                          @{student.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>TCSI{student.id}</td>
                  <td>{getRandomParentName()}</td>
                  <td>{student.grade}</td>
                  <td> <KeyStageChecker grade={parseInt(student.grade)} /> </td>
                  <td>{getRandomLevel()}</td>
                  <td>{getRandomActivities()}</td>
                  <td>
                    <Link to={`/club_details/${primaryClub.id}`} className="inline-flex flex-nowrap items-center bg-white border border-gray-200 rounded-full p-1.5 pe-3 darki:bg-neutral-900 darki:border-neutral-700">
                      <img
                        className="me-1.5 inline-block w-5 h-5 rounded-full"
                        src={primaryClub.logo}
                        alt="Primary Club"
                      />
                      <div className="whitespace-nowrap text-sm font-medium text-gray-800 darki:text-white">
                        {primaryClub.name}
                      </div>
                    </Link>
                    {otherClubs.length > 0 && (
                      <Tooltip
                        trigger="click"
                        html={(
                          <div className="bg-white p-4 rounded-2xl shadow-xl">
                            {otherClubs.map((club, index) => (
                              <div
                               onClick={() => navigate(`/club_details/${club.id}`)}
                                key={index}
                                className=" cursor-pointer flex items-center space-x-2 "
                              >
                                <img
                                  className="w-5 h-5 rounded-full my-2"
                                  src={club.logo}
                                  alt={club.name}
                                />
                                <span>{club.name}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      >
                        <span className="ml-2 text-sm cursor-pointer text-gray-600 darki:text-gray-400">
                          +{otherClubs.length} more
                        </span>
                      </Tooltip>
                    )}
                  </td>
                  <th>
                    <div className="dropdown dropdown-end">
                      <div tabIndex={0} role="button" className="btn m-1">
                        <IoMdMore />
                      </div>
                      <ul
                        tabIndex={0}
                        className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                      >
                        <Link
                          to={`/student_nw/${student.user}`}
                          className="btn btn-ghost"
                        >
                          Details
                        </Link>
                        <button className="btn btn-ghost">Edit</button>
                        <button className="btn btn-ghost text-error">
                          Delete
                        </button>
                      </ul>
                    </div>
                  </th>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default Network_StudentTable;
