import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";

function Resources(props) {

    const resourcesData = [
        {
          id: 1,
          title: "Student Handbook",
          description: "Guide for university policies and procedures",
          file: "handbook.pdf",
          created: "2024-04-30T10:00:00Z",
        },
        {
          id: 2,
          title: "Academic Calendar",
          description: "Schedule for academic events and holidays",
          file: "academic_calendar.pdf",
          created: "2024-03-15T09:30:00Z",
        },
        // Add more resources as needed
      ];


      function formatDate(timestamp) {
        const date = new Date(timestamp);
        const options = {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        return date.toLocaleDateString("en-US", options);
      }
      
      const [showMenu, setshowMenu] = useState(false);

      useEffect(() => {
        function handleClickOutside(event) {
          if (showMenu && !event.target.closest(".hs-dropdown")) {
            setshowMenu(false);
          }
        }
    
        document.addEventListener("click", handleClickOutside);
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, [showMenu]);

  return (
    <AdminLayout>
      <>
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
              {/* Header */}
              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200">
                <div>
                  <h2 className="text-xl font-semibold text-gray-800">
                    Resources
                  </h2>
                  <p className="text-sm text-gray-600">
                    Important guideline documents from the administration
                  </p>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                      <svg
                        className="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5v14" />
                      </svg>
                      Add a new resource
                    </button>
                  </div>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                          Title
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                          Description
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                          File
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-start">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800">
                          Created
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-end" />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                {resourcesData.map((resource) => (
                  <tr>
                    <td className="size-px whitespace-nowrap">
                      <div className="px-6 py-3">
                        <span className="text-sm text-gray-600">{resource?.title}</span>
                      </div>
                    </td>
                    <td className="size-px whitespace-nowrap">
                      <div className="px-6 py-3">
                        <div className="flex items-center gap-x-2">{resource?.description}</div>
                      </div>
                    </td>
                    <td className="size-px whitespace-nowrap">
                      <div className="px-6 py-3">
                        <button
                          type="button"
                          className="py-2 px-3 uppercase inline-flex items-center gap-x-2 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                        >
                          Download
                        </button>
                      </div>
                    </td>

                    <td className="size-px whitespace-nowrap">
                      <div className="px-6 py-3">
                        <span className="text-sm text-gray-600">
                        {formatDate(resource.created)}
                        </span>
                      </div>
                    </td>
                    <td className="size-px whitespace-nowrap">
                      <div className="px-6 py-1.5">
                        <div className="hs-dropdown [--placement:bottom-right] relative inline-block">
                          <button
                            id={`hs-table-dropdown-${resource.id}`}
                            type="button"
                            onClick={() => setshowMenu(resource.id)}
                            className="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-lg text-gray-700 align-middle disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                          >
                            <svg
                              className="flex-shrink-0 size-4"
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <circle cx={12} cy={12} r={1} />
                              <circle cx={19} cy={12} r={1} />
                              <circle cx={5} cy={12} r={1} />
                            </svg>
                          </button>

                          {showMenu === resource.id && (
                          <div
                            className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100  divide-y divide-gray-200 min-w-40 z-10 bg-white shadow-2xl rounded-lg p-2 mt-2"
                            aria-labelledby={`hs-table-dropdown-${resource.id}`}
                          >
                            <div className="py-2 first:pt-0 last:pb-0">
                            
                              <button
                                className="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
                                
                              >
                                Edit
                              </button>

                              <button
                                className="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
                                
                              >
                                Make private
                              </button>
                            </div>
                            <div className="py-2 first:pt-0 last:pb-0">
                              <button
                                className="flex items-center gap-x-3 py-2 px-3 rounded-lg text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
                                
                              >
                                Delete
                              </button>
                            </div>
                          </div>)}
                        </div>
                      </div>
                    </td>
                  </tr>  ))}
                </tbody>
              </table>
              {/* End Table */}
              {/* Footer */}
              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200">
                <div>
                  <p className="text-sm text-gray-600">
                    <span className="font-semibold text-gray-800">02</span>{" "}
                    results
                  </p>
                </div>
              
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </>
    </AdminLayout>
  );
}

export default Resources;
