import React, { useContext, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import {
  FaHouseChimney,
  FaStarOfLife,
  FaFileCircleCheck,
  FaBoxArchive,
  FaLocationDot,
  FaHouseFlag,
  FaBuildingUser,
  FaTable,
  FaCircleCheck,
  FaGraduationCap,
  FaCalendarCheck,
  FaUsers,
  FaBuildingColumns,
  FaUserTie,
  FaUserGraduate,
  FaCalendarDays,
  FaBox,
  FaFootball,
} from "react-icons/fa6";
import { IoChatbubbleEllipsesSharp, IoDocumentText } from "react-icons/io5";
import { MdScheduleSend } from "react-icons/md";
import { FiInbox } from "react-icons/fi";
import { useTranslation } from "react-i18next";

function AdminDashSideBar({ contentTitle, profile }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  let { user, logoutUser } = useContext(AuthContext);
  let matchDashboard = useMatch("/admin");
  let matchDashboardSchool = useMatch("/school");
  let matchTeams = useMatch("/network_schools");
  let matchStaff = useMatch("/network_staff");
  let matchStd = useMatch("/network_students");
  let matchattendance = useMatch("/attendance");
  let matchCalendar = useMatch("/admin_schedule");
  let campaigns = useMatch("/campaigns");
  let matchmoderation = useMatch("/moderate_network");
  let matchclubs = useMatch("/clubs");
  let matchEvents = useMatch("/school_events");
  let matchstudent_councils = useMatch("/student_councils");
  let matchVenues = useMatch("/school_venues");
  let matchHouse = useMatch("/house_program");
  let matchFixtures = useMatch("/school_fixtures");
  let matchDep = useMatch("/network_departments");
  let matchInv = useMatch("/inventory");
  let matchchats = useMatch("/communicate");
  let matchresources = useMatch("/resources");
  let adminCalendar = useMatch("/admin-calendar");
  let sports = useMatch("/sports");

  const [showDrawer, setshowDrawer] = useState(false);

  const handleClick = (event) => {
    setshowDrawer((current) => !current);
  };

  const getLinkClassName = (match) => {
    return `flex items-center gap-2 rounded-lg px-4 py-2 text-sm font-normal text-gray-500 hover:bg-gray-100 hover:text-gray-700 ${
      match ? "bg-[#ede7f6a1] text-primary" : ""
    }`;
  };

  return (
    <div className="">
      <div className="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden darki:bg-gray-800 darki:border-gray-700">
        <div className="flex items-center py-4">
          <ol
            className="ms-3 flex items-center whitespace-nowrap"
            aria-label="Breadcrumb"
          >
            <li
              className="text-sm font-semibold text-gray-800 truncate darki:text-gray-400 mr-4 "
              aria-current="page"
            >
              {/* <button className='btn btn-sm btn-ghost btn-circle hover:btn-primary' onClick={handleClick}> */}
              <button
                className={`btn btn-sm btn-ghost btn-circle ${
                  showDrawer ? "btn-primary" : "hover:btn-primary"
                }`}
                onClick={handleClick}
              >
                {!showDrawer ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </li>
            {/* <li className="flex items-center text-sm text-gray-800 darki:text-gray-400">
              {user['role']}
              <svg
                className="flex-shrink-0 mx-3 overflow-visible size-2.5 text-gray-400 darki:text-gray-600"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
            </li>
            <li className="text-sm font-semibold text-gray-800 truncate darki:text-gray-400" aria-current="page">
              {contentTitle}
            </li> */}
          </ol>
        </div>
      </div>

      {showDrawer && (
        <div
          id="application-sidebar"
          className={`bg-white rounded-xl py-6 lg:hidden `}
        >
          <div className="">
            <div className="px-4">
              <div className="">
                <div className="">
                  {/* {user["role"] === "Network Admin" ? (
          <img src={profile?.avatar} className="h-10 w-auto mx-auto" />
        ) : user["role"] === "School Admin" || user["role"] === "Staff" ? (
          <img src={profile?.logo} className="h-10 w-auto mx-auto" />
        ) : null} */}
                </div>

                <div id="application-sidebar" className="mt-[4rem]">
                  <div className="">
                    <div className="">
                      <ul className="mt-2 space-y-1 flex flex-col gap-4">
                        {(() => {
                          if (user["role"] === "Network Admin") {
                            return (
                              <li>
                                <Link
                                  to="/admin"
                                  className={getLinkClassName(matchDashboard)}
                                >
                                  <FaHouseChimney />
                                  {t("Dashboard")}
                                </Link>
                              </li>
                            );
                          } else if (
                            user["role"] === "School Admin" ||
                            user["role"] === "Staff"
                          ) {
                            return (
                              <li>
                                <Link
                                  to="/school"
                                  className={getLinkClassName(
                                    matchDashboardSchool
                                  )}
                                >
                                  <FaHouseChimney />
                                  {t("Dashboard")}
                                </Link>
                              </li>
                            );
                          } else {
                            return null;
                          }
                        })()}

                        {/* 
              {(() => {
                      if (user["role"] === "Network Admin") {
                        return (
                          <>
                            <li>
                              <Link
                                to="/network_schools"
                                className={getLinkClassName(matchTeams)}
                              >
                                <FaBuildingColumns />
                                Campuses
                              </Link>
                            </li>

                          </>
                        );
                      }
                    })()} */}

                        {user["role"] === "Network Admin" ||
                        user["role"] === "School Admin" ? (
                          <li>
                            <details className="group [&_summary::-webkit-details-marker]:hidden">
                              <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                <span className="text-sm font-normal flex items-center gap-2">
                                  <FaUsers /> {t("Users")}{" "}
                                </span>
                                <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </span>
                              </summary>
                              <ul className="mt-2 space-y-1 px-4">
                                <li>
                                  <Link
                                    to="/network_staff"
                                    className={getLinkClassName(matchStaff)}
                                  >
                                    <FaUserTie />
                                    {t("Staff Members")}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/network_students"
                                    className={getLinkClassName(matchStd)}
                                  >
                                    <FaUserGraduate />
                                    {t("Students")}
                                  </Link>
                                </li>
                              </ul>
                            </details>
                          </li>
                        ) : null}

                        {(() => {
                          if (user["role"] === "Network Admin") {
                            return (
                              <>
                                <li>
                                  <Link
                                    to="/student_councils"
                                    className={getLinkClassName(
                                      matchstudent_councils
                                    )}
                                  >
                                    <FaGraduationCap />
                                    {t("Council")}
                                  </Link>
                                </li>
                              </>
                            );
                          }
                        })()}

                        <li>
                          <Link
                            to="/clubs"
                            className={getLinkClassName(matchclubs)}
                          >
                            <FaStarOfLife />
                            {t("Clubs")}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/admin_schedule"
                            className={getLinkClassName(matchCalendar)}
                          >
                            <FaCalendarDays />
                            {t("Events")}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/campaigns"
                            className={getLinkClassName(campaigns)}
                          >
                            <MdScheduleSend />
                            {t("Campaigns")}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/sports"
                            className={getLinkClassName(sports)}
                          >
                            <FaFootball />
                            {t("Sports")}
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/communicate"
                            className={getLinkClassName(matchchats)}
                          >
                            <IoChatbubbleEllipsesSharp />
                            {t("Chats")}
                          </Link>
                        </li>

                        {/* {user['role']!=="Staff"? (
                  <li>
                <Link
                  to="/attendance"
                  className={getLinkClassName(matchattendance)}
                >
                  <FaFileCircleCheck />
                  Attendance
                </Link>
              </li>
                 ) :null}
               */}

                        <li>
                          <Link
                            to="/moderate_network"
                            className={getLinkClassName(matchmoderation)}
                          >
                            <FaCircleCheck />
                            {t("Moderation")}
                          </Link>
                        </li>

                        {/* 
              <div className='divider my-2'></div>


                                  {(() => {
                                      if (user["role"] === "Network Admin") {
                                        return (
                                          <>
                                          
                                            <li>
                                              <Link
                                                to="/house_program"
                                                className={getLinkClassName(matchHouse)}
                                              >
                                                <FaHouseFlag />
                                                House Program
                                              </Link>
                                            </li>

                                            
                                          </>
                                        );
                                      }
                                    })()} 

              <li>
                      <Link
                        to="/resources"
                        className={getLinkClassName(matchresources)}
                      >
                        <IoDocumentText  />
                        Resources
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/school_venues"
                        className={getLinkClassName(matchVenues)}
                      >
                        <FaLocationDot />
                        Venues
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/inventory"
                        className={getLinkClassName(matchInv)}
                      >
                        <FaBoxArchive />
                        Inventory
                      </Link>
                    </li>
 */}

                        {(() => {
                          if (user["role"] === "Network Admin") {
                            return (
                              <>
                                <li>
                                  <Link
                                    to="/house_program"
                                    className={getLinkClassName(matchHouse)}
                                  >
                                    <FaHouseFlag />
                                    {t("House Program")}
                                  </Link>
                                </li>
                              </>
                            );
                          }
                        })()}

                        {user["role"] === "Network Admin" ||
                        user["role"] === "School Admin" ? (
                          <li>
                            <details className="group [&_summary::-webkit-details-marker]:hidden">
                              <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                                <span className="text-sm font-normal flex items-center gap-2">
                                  <FaBox /> {t("Miscellaneous")}{" "}
                                </span>
                                <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                </span>
                              </summary>
                              <ul className="mt-2 space-y-1 px-4">
                                <li>
                                  <Link
                                    to="/resources"
                                    className={getLinkClassName(matchresources)}
                                  >
                                    <IoDocumentText />
                                    {t("Resources")}
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    to="/school_venues"
                                    className={getLinkClassName(matchVenues)}
                                  >
                                    <FaLocationDot />
                                    {t("Venues")}
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    to="/inventory"
                                    className={getLinkClassName(matchInv)}
                                  >
                                    <FaBoxArchive />
                                    {t("Inventory")}
                                  </Link>
                                </li>
                              </ul>
                            </details>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        id="application-sidebar"
        className={`hs-overlay hs-overlay-open:translate-x-0  transition-all duration-300 transform hidden  top-0 ${
          i18n.language === "en"
            ? "start-0 fixed -translate-x-full lg:end-auto"
            : "fixed end-0"
        } bottom-0 z-[60] w-64 bg-white border-e border-gray-200 pt-7 pb-10 hover:overflow-y-auto lg:block lg:translate-x-0  lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 darki:[&::-webkit-scrollbar-track]:bg-slate-700 darki:[&::-webkit-scrollbar-thumb]:bg-slate-500 darki:bg-gray-800 darki:border-gray-700`}
      >
        <div className="">
          <div className="px-4">
            <div className="">
              <div className="">
                {/* {user["role"] === "Network Admin" ? (
          <img src={profile?.avatar} className="h-10 w-auto mx-auto" />
        ) : user["role"] === "School Admin" || user["role"] === "Staff" ? (
          <img src={profile?.logo} className="h-10 w-auto mx-auto" />
        ) : null} */}
              </div>

              <div id="application-sidebar" className="mt-[4rem]">
                <div className="">
                  <div className="">
                    <ul className="mt-2 space-y-1 flex flex-col gap-4">
                      {(() => {
                        if (user["role"] === "Network Admin") {
                          return (
                            <li>
                              <Link
                                to="/admin"
                                className={getLinkClassName(matchDashboard)}
                              >
                                <FaHouseChimney />
                                {t("Dashboard")}
                              </Link>
                            </li>
                          );
                        } else if (
                          user["role"] === "School Admin" ||
                          user["role"] === "Staff"
                        ) {
                          return (
                            <li>
                              <Link
                                to="/school"
                                className={getLinkClassName(
                                  matchDashboardSchool
                                )}
                              >
                                <FaHouseChimney />
                                {t("Dashboard")}
                              </Link>
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })()}

                      {/* 
              {(() => {
                      if (user["role"] === "Network Admin") {
                        return (
                          <>
                            <li>
                              <Link
                                to="/network_schools"
                                className={getLinkClassName(matchTeams)}
                              >
                                <FaBuildingColumns />
                                Campuses
                              </Link>
                            </li>

                          </>
                        );
                      }
                    })()} */}

                      {user["role"] === "Network Admin" ||
                      user["role"] === "School Admin" ? (
                        <li>
                          <details className="group [&_summary::-webkit-details-marker]:hidden">
                            <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                              <span className="text-sm font-normal flex items-center gap-2">
                                <FaUsers /> {t("Users")}{" "}
                              </span>
                              <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </summary>
                            <ul className="mt-2 space-y-1 px-4">
                              <li>
                                <Link
                                  to="/network_staff"
                                  className={getLinkClassName(matchStaff)}
                                >
                                  <FaUserTie />
                                  {t("Staff Members")}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/network_students"
                                  className={getLinkClassName(matchStd)}
                                >
                                  <FaUserGraduate />
                                  {t("Students")}
                                </Link>
                              </li>
                            </ul>
                          </details>
                        </li>
                      ) : null}

                      <li>
                        <Link
                          to="/admin-calendar"
                          className={getLinkClassName(adminCalendar)}
                        >
                          <FaCalendarDays />
                          {t("Calendar")}
                        </Link>
                      </li>

                      {(() => {
                        if (user["role"] === "Network Admin") {
                          return (
                            <>
                              <li>
                                <Link
                                  to="/student_councils"
                                  className={getLinkClassName(
                                    matchstudent_councils
                                  )}
                                >
                                  <FaGraduationCap />
                                  {t("Council")}
                                </Link>
                              </li>
                            </>
                          );
                        }
                      })()}

                      <li>
                        <Link
                          to="/clubs"
                          className={getLinkClassName(matchclubs)}
                        >
                          <FaStarOfLife />
                          {t("Clubs")}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/admin_schedule"
                          className={getLinkClassName(matchCalendar)}
                        >
                          <FaCalendarCheck />
                          {t("Events")}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/campaigns"
                          className={getLinkClassName(campaigns)}
                        >
                          <MdScheduleSend />
                          {t("Campaigns")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/sports" className={getLinkClassName(sports)}>
                          <FaFootball />
                          {t("Sports")}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/communicate"
                          className={getLinkClassName(matchchats)}
                        >
                          <IoChatbubbleEllipsesSharp />
                          {t("Chats")}
                        </Link>
                      </li>

                      {/* {user['role']!=="Staff"? (
                  <li>
                <Link
                  to="/attendance"
                  className={getLinkClassName(matchattendance)}
                >
                  <FaFileCircleCheck />
                  Attendance
                </Link>
              </li>
                 ) :null}
               */}

                      <li>
                        <Link
                          to="/moderate_network"
                          className={getLinkClassName(matchmoderation)}
                        >
                          <FaCircleCheck />
                          {t("Moderation")}
                        </Link>
                      </li>

                      {(() => {
                        if (user["role"] === "Network Admin") {
                          return (
                            <>
                              <li>
                                <Link
                                  to="/house_program"
                                  className={getLinkClassName(matchHouse)}
                                >
                                  <FaHouseFlag />
                                  {t("House Program")}
                                </Link>
                              </li>
                            </>
                          );
                        }
                      })()}

                      {user["role"] === "Network Admin" ||
                      user["role"] === "School Admin" ? (
                        <li>
                          <details className="group [&_summary::-webkit-details-marker]:hidden">
                            <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                              <span className="text-sm font-normal flex items-center gap-2">
                                <FaBox /> {t("Miscellaneous")}{" "}
                              </span>
                              <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </summary>
                            <ul className="mt-2 space-y-1 px-4">
                              <li>
                                <Link
                                  to="/resources"
                                  className={getLinkClassName(matchresources)}
                                >
                                  <IoDocumentText />
                                  {t("Resources")}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/school_venues"
                                  className={getLinkClassName(matchVenues)}
                                >
                                  <FaLocationDot />
                                  {t("Venues")}
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/inventory"
                                  className={getLinkClassName(matchInv)}
                                >
                                  <FaBoxArchive />
                                  {t("Inventory")}
                                </Link>
                              </li>
                            </ul>
                          </details>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashSideBar;
