import React, { useState } from "react";
import { axiosInstance } from "../Axios";
import axios from "axios";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";

import Network_StudentList from "../components/Network_StudentList";
import NetworkStats from "../components/NetworkStats";
import NetworkStudents from "../components/NetworkStudents";
import toast from "react-hot-toast";
import ReturnNav from "./ReturnNav";
import ReactModal from "react-modal";
import AdminLayout from "./AdminLayout";
export default function AddClubCategory() {
  let { user } = useContext(AuthContext);

  let profile = user["user_id"];
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  const [showAddCategoryForm, setShowAddCategoryForm] = useState(false);
  const toggleAddCategoryForm = () => {
    setShowAddCategoryForm((prevState) => !prevState);
  };
  const [picture, setPicture] = useState("");
  const [title, setTitle] = useState("");

  const [formData, setFormData] = useState({
    icon: "",
    title: "",
    created_by: profile,
  });

  const [category, setCategory] = useState([]);
  const getClubbCayegories = () => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_category/`)
      .then((res) => {
        setCategory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getClubbCayegories();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.error(picture);
      setFormData({
        ...formData,
        icon: picture,
      });
      const res = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/add_category/`,
        formData
      );
      console.log(res);
      getClubbCayegories();
      toast.success("Success");
      setPicture("");
      setFormData({
        icon: "",
        title: "",
        created_by: profile,
      });
      toggleAddCategoryForm();
    } catch (err) {
      toast.error("Error");
      console.log(err);
    }
  };
  function formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const submitImage = async (e, id, category) => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "sda7kxdr");
    data.append("cloud_name", "doxi2odzm");
    const response = await fetch(
      "https://api.cloudinary.com/v1_1/doxi2odzm/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    if (!response.ok) {
      toast.error("Error uploading image");
      throw new Error("Image upload failed");
    } else {
      const sendData = await response.json();
      toast.success("Image Upload Success");
      console.log("URL---", sendData.secure_url);
      setPicture(sendData.secure_url);
      setFormData({
        ...formData,
        icon: sendData.secure_url,
      });
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_KEY}/api/update_club_category/${id}/`
      )
      .then((res) => {
        getClubbCayegories();
        toast.success("Success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const lanArabic = i18n.language === "ar";
  return (
    <AdminLayout>
    <div>

{/* <ReturnNav /> */}


<div className="w-full px-6 py-6 mx-auto">
<div className="flex justify-end mb-8">
<button
    type="button"
    className="btn btn-primary"
    // onClick={toggleAddCategoryForm}
    onClick={openModal}
  >
    + Add a new category
  </button>
  </div>
  <div className="bg-white rounded-lg">
    <div className="overflow-x-auto">
    
      <table className="table">
        {/* head */}
        <thead className="bg-transparent">
          <tr>
            <th></th>
            <th> Category Name</th>
            <th>Create at</th>
            <th>Clubs Attached</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {/* row 1 */}
          {category.map((category) => (
            <tr className="border-b" key={category.id}>
              <td></td>
              <td className="flex items-center flex-row">
                <div className="avatar">
                  <div className="mask mask-circle w-12 h-12">
                    <img
                      src={category.icon}
                      alt="Avatar Tailwind CSS Component"
                      className="img-fit"
                    />
                  </div>
                </div>
                {"  "}
                <h3 className="text-lg font-medium m-0 pl-4">{category.title}</h3>
              </td>
              <td>{formatDate(category.created_at)}</td>
              <td className="pl-7">{category.club_count}</td>
              <th className="flex gap-2">
                <button className="text-violet-700 mr-2">
                  Edit
                </button>
                <button
                  className="btn bg-red-200 text-red-800"
                  onClick={() => handleDelete(category.id)}
                >
                  Delete
                </button>
              </th>
            </tr>
          ))}
        </tbody>

        {/* foot */}
      </table>
    </div>
  </div>
  


  
{/* 
  {showAddCategoryForm && (
    // Add Category Form

  )} */}
</div>


      <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Modal"
      className={'w-6/12 mt-64 m-auto rounded-3xl shadow-2xl bg-white p-6'}
    >
      
      <div className="my-8 p-6 border rounded-lg bg-white">
                  <div className="flex items-center gap-4 mb-4">
                    <label className="text-lg font-semibold">Icon:</label>
                    {/* <input
                      type="text"
                      name="icon"
                      value={formData.icon}
                      className="input input-bordered"
                      onChange={handleChange}
                    /> */}
                    <input
                      type="file"
                      class="block w-full text-sm text-gray-500
                            file:me-4 file:py-2 file:px-4
                            file:rounded-lg file:border-0
                            file:text-sm file:font-semibold
                            file:bg-blue-600 file:text-white
                            hover:file:bg-blue-700
                            file:disabled:opacity-50 file:disabled:pointer-events-none
                            dark:file:bg-blue-500
                            dark:hover:file:bg-blue-400
                            "
                      onChange={submitImage}
                    ></input>
                  </div>
                  <div className="flex items-center gap-4 mb-4">
                    <label className="font-semibold">Name:</label>
                    <input
                      type="text"
                      name="title"
                      value={formData.title}
                      className="input input-bordered"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex flex-row gap-4 mt-2">
                  <button
                    type="submit"
                    className="btn bg-primary text-white px-4 py-2"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <button onClick={closeModal}>Close Modal</button>
                  </div>
                </div>


      
    </ReactModal>

    </div>
    </AdminLayout>
  );
}
