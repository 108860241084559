import React, { useState, useEffect, useContext } from "react";
import { createGlobalStyle } from "styled-components";
import RoleDropdown from "./RoleDropdown";
import ClubSidebar from "./ClubSidebar";
import ClubListList from "./ClubListList";
import ClubPagination from "./ClubPagination";
import clubsData from "./ClubsData";
import SchoolNews from "./SchoolNews";
import PublicNav from "./PublicNav";
import AuthContext from "../context/AuthContext";

function MyClubsStd({ props }) {
  const { user } = useContext(AuthContext);
  let userID = 0;

  if(user[`role`]===`Parent`){
    userID = 12;
  } else {
    userID = user["user_id"];
  }

  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${userID}`
        );
        let data = await response.json();
        setProfile(data?.id);
      } catch (err) {
        console.log(err);
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    return () => clearTimeout(timer);
  }, [userID]);
  console.warn(userID);
  const [state, setState] = useState("");

  useEffect(() => {
    return () => {};
  }, []);

  const GlobalStyle = createGlobalStyle`
    html {
      background: #F9F3FF !important;
      /* Add other global styles here if needed */
    }
  `;

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 5;
  const [clubs, setClubs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/my_clubs/${userID}/`
        );
        let data = await response.json();
        setClubs(data);
        console.log("CLUBS ", data);
      } catch (err) {
        console.log("ERROR");
      }
    };
    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);
    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // Filter blogs based on selected category
  const filteredBlogs = selectedCategory
    ? clubsData.filter((blog) => blog.category === selectedCategory)
    : clubsData;

  // Pagination logic
  const totalBlogs = filteredBlogs.length;
  const totalPages = Math.ceil(totalBlogs / blogsPerPage);
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = filteredBlogs.slice(0, 3);

  const handleCategorySelect = (category) => {
    setCurrentPage(1);
    setSelectedCategory(category);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleShowAll = () => {
    setCurrentPage(1);
    setSelectedCategory(null);
  };

  const uniqueCategories = [...new Set(clubsData.map((blog) => blog.category))];

  return (
    <div>
      {/* <GlobalStyle />
<PublicNav/>
  */}
      <div className="container mx-auto mt-8">
        {/* <RoleDropdown  /> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-4">
          {" "}
          {/* Increase or decrease the number of columns as needed */}
          <div className="md:col-span-4">
            {" "}
            {/* Adjust the number of columns spanned by the content */}
            {profile? (<ClubListList  blogs={clubs} />):null}
            
            {/* <ClubPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}
          </div>
        </div>
      </div>

      {/* <SchoolNews/> */}
    </div>
  );
}

export default MyClubsStd;
