import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import AuthContext from "../context/AuthContext";
import BackNav from "./BackNav";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useLanguage } from "../components/language";

const Lang = () => {
  let { user } = useContext(AuthContext);
  const history = useNavigate();

  // extract profile id
  let params = useParams();
  let profileID = 0;

  if(user[`role`]===`Parent`){
    profileID = 12;
  } else{
    profileID = user["user_id"];
  }

  // validate the user if he is the owner of the profile
  let isMyself = true;
  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const { t, changeLanguage, lanArabic } = useLanguage();
  // initialize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const getProfile = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
      );
      const data = await response.json();

      setProfile(data);
      setIsLoading(false);
    } catch (err) {
      console.log("The requested profile does not exist.");
      setIsLoading(false);
    }
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  console.log("profile LEad" + profile);

  // Function to get the current date in the required format (YYYY-MM-DD)
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    activity_name: "",
    prof: "",
    grade: 1,
    duration: "",
    scope: "",
    service_hours: "",
    school_in: profile ? profile.school_in : "", // Use profile data if available
    status: 0,
    score: 0,
    feedback: 0,
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Update post_date and posted_by fields
    const dataToSend = {
      ...formData,
      post_date: getCurrentDate(),
      posted_by: user ? user.user_id : "", // Use the user's ID if available
      school_in: profile?.school_in,
      status: 0,
      score: 0,
      feedback: 0,
      start_date: 0,
    };

    try {
      setIsSubmitting(true); // Set the submitting state to true

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/add_lang/`,
        dataToSend
      );

      // Reset the form data after successful submission
      setFormData({
        activity_name: "",
        prof: "",
        school_in: profile ? profile.school_in : "",
      });

      // Reset the date range picker
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);

      toast.success("Good Job! Activity Saved", {
        icon: "👏",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    } catch (error) {
      console.error("Error creating activity:", error.response.data);
      toast.error("Oh No! Something went wrong", {
        icon: "😓",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    } finally {
      setIsSubmitting(false); // Set the submitting state back to false after the request is done
    }
  };

  const getDurationMonths = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    return diffMonths;
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleChangeDate = (ranges) => {
    setDateRange([ranges.selection]);
    const duration = getDurationMonths(
      ranges.selection.startDate,
      ranges.selection.endDate
    );
    setFormData((prevData) => ({
      ...prevData,
      duration: String(duration),
      start_date: ranges.selection.startDate.toISOString().split("T")[0],
      end_date: ranges.selection.endDate.toISOString().split("T")[0],
    }));
  };

  const handleIncrementGrade = () => {
    if (formData.grade < 13) {
      setFormData((prevData) => ({
        ...prevData,
        grade: String(parseInt(prevData.grade) + 1),
      }));
    }
  };

  const handleDecrementGrade = () => {
    if (formData.grade > 1) {
      setFormData((prevData) => ({
        ...prevData,
        grade: String(parseInt(prevData.grade) - 1),
      }));
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="">
      <BackNav />

      <div className="mx-auto max-w-screen-xl px-4 py- sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg lg:max-w-2xl">
          <form
            className="mb-0 mt-6 space-y-4 rounded-lg px-6 py-8 bg-white"
            onSubmit={handleSubmit}
          >
            <h1 className="text-center text-2xl font-bold sm:text-3xl mt-6">
              🔠 Languages
            </h1>
            <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
              Do you know any other language apart from English/Urdu
            </p>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Activity</span>
              </label>
              <select
                className="select select-bordered"
                name="activity_name"
                value={formData.activity_name}
                onChange={handleChange}
              >
                <option selected>Pick one</option>
                <option value="af">Afrikaans</option>
                <option value="sq">Albanian - shqip</option>
                <option value="am">Amharic - አማርኛ</option>
                <option value="ar">Arabic - العربية</option>
                <option value="an">Aragonese - aragonés</option>
                <option value="hy">Armenian - հայերեն</option>
                <option value="ast">Asturian - asturianu</option>
                <option value="az">Azerbaijani - azərbaycan dili</option>
                <option value="eu">Basque - euskara</option>
                <option value="be">Belarusian - беларуская</option>
                <option value="bn">Bengali - বাংলা</option>
                <option value="bs">Bosnian - bosanski</option>
                <option value="br">Breton - brezhoneg</option>
                <option value="bg">Bulgarian - български</option>
                <option value="ca">Catalan - català</option>
                <option value="ckb">
                  Central Kurdish - کوردی (دەستنوسی عەرەبی)
                </option>
                <option value="zh">Chinese - 中文</option>
                <option value="zh-HK">
                  Chinese (Hong Kong) - 中文（香港）
                </option>
                <option value="zh-CN">
                  Chinese (Simplified) - 中文（简体）
                </option>
                <option value="zh-TW">
                  Chinese (Traditional) - 中文（繁體）
                </option>
                <option value="co">Corsican</option>
                <option value="hr">Croatian - hrvatski</option>
                <option value="cs">Czech - čeština</option>
                <option value="da">Danish - dansk</option>
                <option value="nl">Dutch - Nederlands</option>
                <option value="eo">Esperanto - esperanto</option>
                <option value="et">Estonian - eesti</option>
                <option value="fo">Faroese - føroyskt</option>
                <option value="fil">Filipino</option>
                <option value="fi">Finnish - suomi</option>
                <option value="fr">French - français</option>
                <option value="fr-CA">
                  French (Canada) - français (Canada)
                </option>
                <option value="fr-FR">
                  French (France) - français (France)
                </option>
                <option value="fr-CH">
                  French (Switzerland) - français (Suisse)
                </option>
                <option value="gl">Galician - galego</option>
                <option value="ka">Georgian - ქართული</option>
                <option value="de">German - Deutsch</option>
                <option value="de-AT">
                  German (Austria) - Deutsch (Österreich)
                </option>
                <option value="de-DE">
                  German (Germany) - Deutsch (Deutschland)
                </option>
                <option value="de-LI">
                  German (Liechtenstein) - Deutsch (Liechtenstein)
                </option>
                <option value="de-CH">
                  German (Switzerland) - Deutsch (Schweiz)
                </option>
                <option value="el">Greek - Ελληνικά</option>
                <option value="gn">Guarani</option>
                <option value="gu">Gujarati - ગુજરાતી</option>
                <option value="ha">Hausa</option>
                <option value="haw">Hawaiian - ʻŌlelo Hawaiʻi</option>
                <option value="he">Hebrew - עברית</option>
                <option value="hi">Hindi - हिन्दी</option>
                <option value="hu">Hungarian - magyar</option>
                <option value="is">Icelandic - íslenska</option>
                <option value="id">Indonesian - Indonesia</option>
                <option value="ia">Interlingua</option>
                <option value="ga">Irish - Gaeilge</option>
                <option value="it">Italian - italiano</option>
                <option value="it-IT">
                  Italian (Italy) - italiano (Italia)
                </option>
                <option value="it-CH">
                  Italian (Switzerland) - italiano (Svizzera)
                </option>
                <option value="ja">Japanese - 日本語</option>
                <option value="kn">Kannada - ಕನ್ನಡ</option>
                <option value="kk">Kazakh - қазақ тілі</option>
                <option value="km">Khmer - ខ្មែរ</option>
                <option value="ko">Korean - 한국어</option>
                <option value="ku">Kurdish - Kurdî</option>
                <option value="ky">Kyrgyz - кыргызча</option>
                <option value="lo">Lao - ລາວ</option>
                <option value="la">Latin</option>
                <option value="lv">Latvian - latviešu</option>
                <option value="ln">Lingala - lingála</option>
                <option value="lt">Lithuanian - lietuvių</option>
                <option value="mk">Macedonian - македонски</option>
                <option value="ms">Malay - Bahasa Melayu</option>
                <option value="ml">Malayalam - മലയാളം</option>
                <option value="mt">Maltese - Malti</option>
                <option value="mr">Marathi - मराठी</option>
                <option value="mn">Mongolian - монгол</option>
                <option value="ne">Nepali - नेपाली</option>
                <option value="no">Norwegian - norsk</option>
                <option value="nb">Norwegian Bokmål - norsk bokmål</option>
                <option value="nn">Norwegian Nynorsk - nynorsk</option>
                <option value="oc">Occitan</option>
                <option value="or">Oriya - ଓଡ଼ିଆ</option>
                <option value="om">Oromo - Oromoo</option>
                <option value="ps">Pashto - پښتو</option>
                <option value="fa">Persian - فارسی</option>
                <option value="pl">Polish - polski</option>
                <option value="pt">Portuguese - português</option>
                <option value="pt-BR">
                  Portuguese (Brazil) - português (Brasil)
                </option>
                <option value="pt-PT">
                  Portuguese (Portugal) - português (Portugal)
                </option>
                <option value="pa">Punjabi - ਪੰਜਾਬੀ</option>
                <option value="qu">Quechua</option>
                <option value="ro">Romanian - română</option>
                <option value="mo">
                  Romanian (Moldova) - română (Moldova)
                </option>
                <option value="rm">Romansh - rumantsch</option>
                <option value="ru">Russian - русский</option>
                <option value="gd">Scottish Gaelic</option>
                <option value="sr">Serbian - српски</option>
                <option value="sh">Serbo-Croatian - Srpskohrvatski</option>
                <option value="sn">Shona - chiShona</option>
                <option value="sd">Sindhi</option>
                <option value="si">Sinhala - සිංහල</option>
                <option value="sk">Slovak - slovenčina</option>
                <option value="sl">Slovenian - slovenščina</option>
                <option value="so">Somali - Soomaali</option>
                <option value="st">Southern Sotho</option>
                <option value="es">Spanish - español</option>
                <option value="es-AR">
                  Spanish (Argentina) - español (Argentina)
                </option>
                <option value="es-419">
                  Spanish (Latin America) - español (Latinoamérica)
                </option>
                <option value="es-MX">
                  Spanish (Mexico) - español (México)
                </option>
                <option value="es-ES">
                  Spanish (Spain) - español (España)
                </option>
                <option value="es-US">
                  Spanish (United States) - español (Estados Unidos)
                </option>
                <option value="su">Sundanese</option>
                <option value="sw">Swahili - Kiswahili</option>
                <option value="sv">Swedish - svenska</option>
                <option value="tg">Tajik - тоҷикӣ</option>
                <option value="ta">Tamil - தமிழ்</option>
                <option value="tt">Tatar</option>
                <option value="te">Telugu - తెలుగు</option>
                <option value="th">Thai - ไทย</option>
                <option value="ti">Tigrinya - ትግርኛ</option>
                <option value="to">Tongan - lea fakatonga</option>
                <option value="tr">Turkish - Türkçe</option>
                <option value="tk">Turkmen</option>
                <option value="tw">Twi</option>
                <option value="uk">Ukrainian - українська</option>
                <option value="ug">Uyghur</option>
                <option value="uz">Uzbek - o‘zbek</option>
                <option value="vi">Vietnamese - Tiếng Việt</option>
                <option value="wa">Walloon - wa</option>
                <option value="cy">Welsh - Cymraeg</option>
                <option value="fy">Western Frisian</option>
                <option value="xh">Xhosa</option>
                <option value="yi">Yiddish</option>
                <option value="yo">Yoruba - Èdè Yorùbá</option>
                <option value="zu">Zulu - isiZulu</option>
              </select>
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Scope</span>
              </label>
              <select
                className="select select-bordered"
                name="prof"
                value={formData.prof}
                onChange={handleChange}
              >
                <option selected>Pick one</option>
                <option value="1">Beginner</option>
                <option value="1.2">Early Intermediate</option>
                <option value="1.23">Intermediate</option>
                <option value="1.24">Fluent</option>
                <option value="1.5">Native</option>
              </select>
            </div>
            <textarea
              name="description"
              id="description"
              class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 dark:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
              rows="3"
              placeholder="Tell us more about your experience.."
              onChange={handleChange}
              value={formData.description}
            ></textarea>

            <button
              type="submit"
              className="block btn-primary mt-4 btn w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
              disabled={
                isSubmitting || !formData.activity_name || !formData.prof
              }
            >
              Add Activity
            </button>
          </form>
        </div>
      </div>

      <div style={{ zIndex: 999999999999999, marginBottom: "2vh" }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
};

export default Lang;
