import React from 'react';
import ReactApexChart from 'react-apexcharts';

function ClubContributions() {
  const seriesData = [{
    name: 'Special Interest',
    data: [1, 2, 4]
  }, {
    name: 'Technology and Innovation',
    data: [1, 3, 0]
  }, {
    name: 'Technology and Innovation',
    data: [1, 3, 0]
  }, {
    name: 'Community Service and Leadership',
    data: [3, 0, 1]
  }, {
    name: 'Arts and Culture',
    data: [2, 3, 3]
  }, {
    name: 'Academics & STEM',
    data: [3, 5, 4]
  }, {
    name: 'Sports',
    data: [2, 4, 1]
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 430,
      width: 300
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      categories: ['North', 'South', 'Central'],
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={seriesData} type="bar" height={600} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default ClubContributions;
