import React, { useState } from 'react';

function LandingTabs() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="relative p-6 md:p-16">
        <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center">
          <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
            <h2 className="text-2xl text-gray-800 font-bold text-primary sm:text-3xl darki:text-gray-200">
            Automate Your School's Extracurricular Experience

            </h2>
            <nav
              className="grid gap-4 mt-5 md:mt-10"
              aria-label="Tabs"
              role="tablist"
            >
              <button
                type="button"
                className={`${
                  activeTab === 1
                    ? 'hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent'
                    : ''
                } text-start hover:bg-white hover:text-primary p-4 md:p-5 rounded-xl darki:hs-tab-active:bg-slate-900 darki:hover:bg-gray-700 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600`}
                onClick={() => handleTabClick(1)}
                role="tab"
              >
                <span className="flex">


                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 darki:hs-tab-active:text-blue-500 darki:text-gray-200">
  <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
  <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clip-rule="evenodd" />
</svg>

                  <span className="grow ms-6">
                    <span className="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 darki:hs-tab-active:text-blue-500 darki:text-gray-200">
                    Streamline Scheduling with Precision

                    </span>
                    <span className="block mt-1 text-gray-800 darki:hs-tab-active:text-gray-200 darki:text-gray-200">
                    Automate after-school clubs and events with our scheduler.





</span>
                  </span>
                </span>
              </button>
              <button
                type="button"
                className={`${
                  activeTab === 2
                    ? 'hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent'
                    : ''
                } text-start hover:bg-white hover:text-primary p-4 md:p-5 rounded-xl darki:hs-tab-active:bg-slate-900 darki:hover:bg-gray-700 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600`}
                onClick={() => handleTabClick(2)}
                role="tab"
              >
                <span className="flex">


<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 darki:hs-tab-active:text-blue-500 darki:text-gray-200">
  <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z" />
</svg>

                  <span className="grow ms-6">
                    <span className="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 darki:hs-tab-active:text-blue-500 darki:text-gray-200">
                    Effortlessly Manage School Clubs
                    </span>
                    <span className="block mt-1 text-gray-800 darki:hs-tab-active:text-gray-200 darki:text-gray-200">
                    Effortlessly manage student clubs, both after-school and community-based.











                    </span>
                  </span>
                </span>
              </button>
              <button
                type="button"
                className={`${
                  activeTab === 3
                    ? 'hs-tab-active:bg-white hs-tab-active:shadow-md hs-tab-active:hover:border-transparent'
                    : ''
                } text-start hover:bg-white hover:text-primary p-4 md:p-5 rounded-xl darki:hs-tab-active:bg-slate-900 darki:hover:bg-gray-700 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600`}
                onClick={() => handleTabClick(3)}
                role="tab"
              >
                <span className="flex">
                    
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="flex-shrink-0 mt-2 h-6 w-6 md:w-7 md:h-7 hs-tab-active:text-blue-600 text-gray-800 darki:hs-tab-active:text-blue-500 darki:text-gray-200">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
</svg>

                  <span className="grow ms-6">
                    <span className="block text-lg font-semibold hs-tab-active:text-blue-600 text-gray-800 darki:hs-tab-active:text-blue-500 darki:text-gray-200">
                    Extracurricular Reports
                    </span>
                    <span className="block mt-1 text-gray-800 darki:hs-tab-active:text-gray-200 darki:text-gray-200">
                    Efficiently generate reports on extracurriculars with moderation support
                    </span>
                  </span>
                </span>
              </button>
            </nav>
          </div>
          <div className="lg:col-span-6">
            <div className="relative">
              <div>
                <div
                  className={`${activeTab === 1 ? '' : 'hidden'}`}
                  role="tabpanel"
                >
                  {/* Replace this with your tab content */}
                  <img
                    className="shadow-xl shadow-gray-200 rounded-xl darki:shadow-gray-900/[.2] w-full"
                    src="https://res.cloudinary.com/ahbranding/image/upload/v1706975159/wi3bafv0uvakuprfgs6w.png"
                    alt="Image Description"
                  />
                </div>
                <div
                  className={`${activeTab === 2 ? '' : 'hidden'}`}
                  role="tabpanel"
                >
                  {/* Replace this with your tab content */}
                  <img
                    className="shadow-xl shadow-gray-200 rounded-xl darki:shadow-gray-900/[.2] w-full"
                    src="https://res.cloudinary.com/ahbranding/image/upload/v1706975160/zebbcwlxag8koy2f54fs.png"
                    alt="Image Description"
                  />
                </div>
                <div
                  className={`${activeTab === 3 ? '' : 'hidden'}`}
                  role="tabpanel"
                >
                  {/* Replace this with your tab content */}
                  <img
                    className="shadow-xl shadow-gray-200 rounded-xl darki:shadow-gray-900/[.2] w-full"
                    src="https://res.cloudinary.com/ahbranding/image/upload/v1706975162/dh9ttblfs53vpc2ziqgg.png"
                    alt="Image Description"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 grid grid-cols-12 w-full h-full">
          <div className="col-span-full lg:col-span-7 lg:col-start-6 bg-[#f3e9fe70] w-full h-5/6 rounded-xl sm:h-3/4 lg:h-full darki:bg-white/[.075]" />
        </div>
        
      </div>
    </div>
  );
}

export default LandingTabs;
