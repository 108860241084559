import React from "react";
import Comments from "./Comments";

const CommentThread = ({ comments }) => (
  <div className="bg-white">
    {comments?.map((comment, index) => (
      <div
        key={index}
        className="flex-col w-full py-4 mx-auto bg-white border-gray-200 sm:px-4 sm:py-4 md:px-4 rounded-lg shadow-xl mb-4"
      >
        <div className="flex flex-row">
          {/* Render comment */}
          <Comments comment={comment} />
        </div>
        <hr className="my-2 ml-16 border-gray-200" />
      </div>
    ))}
  </div>
);

export default CommentThread;
